import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserReportRequestPayload, UserState } from 'store/types';
import { IResponse } from '@types';

const initialState: UserState = {
  error: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reportUserRequest: (
      state,
      action: PayloadAction<IUserReportRequestPayload>,
    ) => {
      state.error = '';
    },
    reportUserSuccess: (state, action: PayloadAction<IResponse>) => {
      state.error = '';
    },
    reportUserFailure: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { reportUserRequest, reportUserSuccess, reportUserFailure } =
  userSlice.actions;

export default userSlice.reducer;
