import {
  takeLatest,
  all,
  select,
  takeEvery,
  put,
  take,
} from 'redux-saga/effects';
import { RootState } from 'store/reducers';
import {
  getCampLogListRequest,
  getCampLogListSuccess,
  getCampLogListFailure,
  reviseCampingLogFailure,
  reviseCampingLogRequest,
  reviseCampingLogSuccess,
  writeCampingLogFailure,
  writeCampingLogRequest,
  writeCampingLogSuccess,
  deleteCampingLogRequest,
  deleteCampingLogSuccess,
  deleteCampingLogFailure,
  getCampLogsCntFailure,
  getCampLogsCntRequest,
  getCampLogsCntSuccess,
  getCampingLogFailure,
  getCampingLogRequest,
  getCampingLogsFailure,
  getCampingLogsRequest,
  getCampingLogsSuccess,
  getCampingLogSuccess,
  getCampingLogsByCampRequest,
  getCampingLogsByCampSuccess,
  getCampingLogsByCampFailure,
  getCampLogTotalCntRequest,
  getCampLogTotalCntSuccess,
  getCampLogTotalCntFailure,
  updateTimestamp,
} from 'store/reducers/campingLog';
import {
  countReviewsByCamp,
  getReviewsByCamp,
  createReview,
  deleteCampingLog,
  findReview,
  findReviews,
  reviseReview,
} from 'api';
import { PayloadAction } from '@reduxjs/toolkit';
import { IGetCampingLogRequestPayload } from 'store/types';
import { IReviseCampingLogResponse } from '@types';
import { ILogUpdate } from 'gtm/types';
import sendGtmDataLayer from 'utils/sendGtmDataLayer';
import { failure } from './failure';
import {
  createFetchAction,
  createV2FetchAction,
  createV2FetchFuncWithStringError,
} from './createFetchAction';
import { customHistory } from '../../App';

const CAMPLOGLISTLIMIT = 5;
const CAMPINGLOGLIMIT = 3;

function* getCampingLogSaga() {
  yield takeEvery(
    getCampingLogRequest.type,
    createFetchAction(
      findReview,
      getCampingLogSuccess,
      getCampingLogFailure,
      undefined,
      function* fail() {
        yield alert('캠핑로그를 찾을 수 없습니다.');
        customHistory.replace('/mypage/reservation/edit');
      },
    ),
  );
}

function* getMoreCampingLogs() {
  while (true) {
    const action: PayloadAction<IGetCampingLogRequestPayload> = yield take(
      updateTimestamp.type,
    );

    yield put(getCampingLogsRequest(action.payload));
  }
}

function* fetchCampingLogListSaga() {
  yield takeEvery(
    getCampingLogsRequest.type,
    createFetchAction(
      findReviews,
      getCampingLogsSuccess,
      getCampingLogsFailure,
    ),
  );
}

function* getCampLogListSaga() {
  const { isOnlyPhotoChecked } = yield select(
    (state: RootState) => state.campingLogReducer,
  );

  const params = {
    mediaOnly: isOnlyPhotoChecked,
    limit: CAMPLOGLISTLIMIT,
  };

  yield takeLatest(
    getCampLogListRequest.type,
    createV2FetchAction(
      getReviewsByCamp,
      getCampLogListSuccess,
      getCampLogListFailure,
      params,
    ),
  );
}

function* writeCampingLogSaga() {
  yield takeLatest(
    writeCampingLogRequest.type,
    createV2FetchFuncWithStringError(
      createReview,
      writeCampingLogSuccess,
      writeCampingLogFailure,
      undefined,
      function* success(
        data: any,
        payload: { formData: FormData; logUpdateData: ILogUpdate },
      ) {
        yield sendGtmDataLayer(payload.logUpdateData);
        yield customHistory.push(
          `/reservation/result/${payload.formData.get('bookingId')}?banner=1`,
        );
      },
      failure,
    ),
  );
}

function* reviseCampingLogSaga() {
  yield takeLatest(
    reviseCampingLogRequest.type,
    createFetchAction(
      reviseReview,
      reviseCampingLogSuccess,
      reviseCampingLogFailure,
      function* success(data: IReviseCampingLogResponse) {
        const { reviewId } = yield data;
        yield customHistory.replace(`/log/${reviewId}`);
      },
      failure,
    ),
  );
}

function* deleteCampingLogSaga() {
  yield takeLatest(
    deleteCampingLogRequest.type,
    createFetchAction(
      deleteCampingLog,
      deleteCampingLogSuccess,
      deleteCampingLogFailure,
      function* success() {
        yield alert('삭제되었습니다.');
        yield customHistory.push('/mypage/reservation/edit');
      },
      failure,
    ),
  );
}

function* getLogListByCampSaga() {
  const { isOnlyPhotoChecked } = yield select(
    (state: RootState) => state.campingLogReducer,
  );
  const params = {
    mediaOnly: isOnlyPhotoChecked,
    limit: CAMPINGLOGLIMIT,
  };

  yield takeLatest(
    getCampingLogsByCampRequest.type,
    createV2FetchAction(
      getReviewsByCamp,
      getCampingLogsByCampSuccess,
      getCampingLogsByCampFailure,
      params,
    ),
  );
}
function* getCampLogTotalCntSaga() {
  const params = {
    mediaOnly: false,
  };

  yield takeLatest(
    getCampLogTotalCntRequest.type,
    createV2FetchAction(
      countReviewsByCamp,
      getCampLogTotalCntSuccess,
      getCampLogTotalCntFailure,
      params,
    ),
  );
}

function* getCampLogCountSaga() {
  const { isOnlyPhotoChecked } = yield select(
    (state: RootState) => state.campingLogReducer,
  );

  const params = {
    mediaOnly: isOnlyPhotoChecked,
  };

  yield takeLatest(
    getCampLogsCntRequest.type,
    createV2FetchAction(
      countReviewsByCamp,
      getCampLogsCntSuccess,
      getCampLogsCntFailure,
      params,
    ),
  );
}

export function* campingLogSaga() {
  yield all([
    fetchCampingLogListSaga(),
    getCampingLogSaga(),
    getMoreCampingLogs(),
    getCampLogListSaga(),
    writeCampingLogSaga(),
    reviseCampingLogSaga(),
    deleteCampingLogSaga(),
    getLogListByCampSaga(),
    getCampLogTotalCntSaga(),
    getCampLogCountSaga(),
  ]);
}
