import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';

const useRoute = () => {
  const history = useHistory();

  const goBack = useCallback(() => {
    history.goBack();
  }, []);

  return {
    goBack,
  };
};

export default useRoute;
