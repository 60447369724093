import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, select, call, put } from 'redux-saga/effects';
import { RootState } from 'store/reducers';
import { showMoreZoneList, checkLoadStatus } from 'store/reducers/zone';
import { ZoneState } from 'store/types';
import { getZones, ZONELIMIT } from './camp';

function* getMoreZones(action: PayloadAction<string>) {
  const { isMoreBtnClicked, isLoadedAll }: ZoneState = yield select(
    (state: RootState) => state.zoneReducer,
  );

  if (isMoreBtnClicked && !isLoadedAll) {
    yield call(getZones, action.payload, 50 - ZONELIMIT, ZONELIMIT);
    yield put(checkLoadStatus());
  }
}

export function* getMoreZonesSaga() {
  yield takeEvery(showMoreZoneList.type, getMoreZones);
}
