import { all, takeEvery, call, take, put, select } from 'redux-saga/effects';
import {
  getProductMainExhibitionsFailure,
  getProductMainExhibitionsRequest,
  getProductMainExhibitionsSuccess,
  getSingleProductExhibitionRequest,
  getSingleProductExhibitionSuccess,
  getSingleProductExhibitionFailure,
  getExhibitionProductsRequest,
  getExhibitionProductsSuccess,
  getExhibitionProductsFailure,
  addOffset,
  getProductExhibitionsRequest,
  getroductExhibitionsSuccess,
  getroductExhibitionsFailure,
  addProductExhibitionsPage,
} from 'store/reducers/productExhibition';
import {
  getProductMainExhibitions,
  getSingleProductExhibition,
  getExhibitionProducts,
  getProductExhibitions,
} from 'api';
import { PayloadAction } from '@reduxjs/toolkit';
import { EnumProuctExhibitions } from '@types';
import { createFetchAction, fetchApi } from './createFetchAction';
import { RootState } from '../reducers/index';

function* getProductMainExhibitionsSaga() {
  yield takeEvery(
    getProductMainExhibitionsRequest.type,
    createFetchAction(
      getProductMainExhibitions,
      getProductMainExhibitionsSuccess,
      getProductMainExhibitionsFailure,
    ),
  );
}

function* getProductExhibitionDetailSaga() {
  while (true) {
    const action: PayloadAction<string> = yield take(
      getSingleProductExhibitionRequest.type,
    );
    yield call<any>(
      fetchApi,
      getSingleProductExhibition,
      getSingleProductExhibitionRequest.type,
      getSingleProductExhibitionSuccess,
      getSingleProductExhibitionFailure,
      action.payload,
    );

    yield put(
      getExhibitionProductsRequest({
        code: action.payload,
        skip: 0,
        limit: 10,
      }),
    );
  }
}

function* getExhibitionProductsSaga() {
  yield takeEvery(
    getExhibitionProductsRequest.type,
    createFetchAction(
      getExhibitionProducts,
      getExhibitionProductsSuccess,
      getExhibitionProductsFailure,
    ),
  );
}

function* getMoreExhibitionProductsSaga() {
  while (true) {
    const action: PayloadAction<number> = yield take(addOffset.type);

    const { code } = yield select(
      (state: RootState) => state.productExhibitionReducer.productExhibition,
    );

    yield put(
      getExhibitionProductsRequest({ code, skip: action.payload, limit: 10 }),
    );
  }
}

function* getProductExhibitionsSaga() {
  yield takeEvery(
    getProductExhibitionsRequest.type,
    createFetchAction(
      getProductExhibitions,
      getroductExhibitionsSuccess,
      getroductExhibitionsFailure,
    ),
  );
}

function* getMoreExhibitionsSaga() {
  while (true) {
    const action: PayloadAction<number> = yield take(
      addProductExhibitionsPage.type,
    );

    yield put(
      getProductExhibitionsRequest({
        sampleSize: 0,
        sort: EnumProuctExhibitions.undefined,
        page: action.payload,
        pageSize: 10,
      }),
    );
  }
}

export default function* productExhibitionsSaga() {
  yield all([
    getProductMainExhibitionsSaga(),
    getProductExhibitionDetailSaga(),
    getExhibitionProductsSaga(),
    getMoreExhibitionProductsSaga(),
    getProductExhibitionsSaga(),
    getMoreExhibitionsSaga(),
  ]);
}
