/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  ISiteExhibitionState,
  ISiteExhibitionRequestPayload,
  ISiteExhibitionResponsePayload,
  IExhibitionSiteMoreResponsePayload,
  IExhibitionSiteMoreRequestPayload,
} from 'store/types';

const initialState: ISiteExhibitionState = {
  siteExhibition: {
    data: undefined,
    renderedDataSet: new Set(),
    error: null,
  },
};

export const siteCurationSlice = createSlice({
  name: 'siteCuration',
  initialState,
  reducers: {
    getSiteExhibitionRequest: (
      state,
      action: PayloadAction<ISiteExhibitionRequestPayload>,
    ) => {},
    getSiteExhibitionSuccess: (
      state,
      action: PayloadAction<{
        key: keyof typeof initialState;
        data: ISiteExhibitionResponsePayload;
      }>,
    ) => {
      const {
        key,
        data: { data },
      } = action.payload;
      data.sitesGroupByDate = [];
      state[key].data = data;
    },
    getSiteExhibitionFailure: (
      state,
      action: PayloadAction<{
        key: string;
        error: Error | string | AxiosError;
      }>,
    ) => {
      const { key, error } = action.payload;
      state[key as keyof typeof initialState] = {
        data: null,
        error: (error as AxiosError).response || error.toString(),
      };
    },
    getExhibitionSitesRequest: (
      state,
      action: PayloadAction<IExhibitionSiteMoreRequestPayload>,
    ) => {},
    getExhibitionSitesSuccess: (
      state,
      action: PayloadAction<{
        key: keyof typeof initialState;
        data: IExhibitionSiteMoreResponsePayload;
      }>,
    ) => {
      const {
        key,
        data: { data },
      } = action.payload;

      state[key].data?.sitesGroupByDate.push(data);
    },
    getExhibitionSitesFailure: (
      state,
      action: PayloadAction<{
        key: string;
        error: Error | string;
      }>,
    ) => {
      state[action.payload.key as keyof typeof initialState]!.error =
        action.payload.error;
    },
    resetExhibitionSites: (
      state,
      action: PayloadAction<{
        key: string;
      }>,
    ) => {
      const { key } = action.payload;
      if (state[key].data) {
        state[key].data!.sitesGroupByDate = [];
      }
    },
    setRenderedDataSet: (
      state,
      action: PayloadAction<{
        key: string;
        value: Set<any>;
      }>,
    ) => {
      const { key, value } = action.payload;
      if (state[key].renderedDataSet) {
        state[key].renderedDataSet = value;
      }
    },
  },
});

export const {
  getSiteExhibitionRequest,
  getSiteExhibitionSuccess,
  getSiteExhibitionFailure,
  getExhibitionSitesRequest,
  getExhibitionSitesSuccess,
  getExhibitionSitesFailure,
  resetExhibitionSites,
  setRenderedDataSet,
} = siteCurationSlice.actions;

export default siteCurationSlice.reducer;
