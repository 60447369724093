import { createTheme, Theme as MuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { DefaultTheme } from 'styled-components';

// Create a theme instance.
const muiTheme: MuiTheme = createTheme({
  palette: {
    primary: {
      main: '#61d377',
    },
    secondary: {
      main: '#2acb6b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});

const fontSizes = {
  h24: '2.4rem',
  h22: '2.2rem',
  h20: '2rem',
  h18: '1.8rem',
  h17: '1.7rem',
  h16: '1.6rem',
  b15: '1.5rem',
  b14: '1.4rem',
  c13: '1.3rem',
  c12: '1.2rem',
  c11: '1.1rem',
  c10: '1rem',
};

const colors = {
  primary120: '#CDF9E3',
  primary110: '#C1EFD9',
  primary100: '#ACE4C9',
  primary90: '#56D196',
  primary80: '#61d377',
  primary70: '#32AE7A',
  primary60: '#309C75',
  primary50: '#268764',
  primary40: '#227758',
  primary30: '#1D674D',
  primary20: '#195841',
  primary10: '#134935',
  primary00: '#103729',
  primary000: '#13221C',
  secondly: '#727cf5',
  bw00: '#050606',
  bw10: '#0F1110',
  bw20: '#1A1D1B',
  bw30: '#252826',
  bw40: '#343735',
  bw50: '#5A5E5B',
  bw60: '#767A78',
  bw65: '#858A88',
  bw70: '#9FA5A2',
  bw75: '#B7BDBA',
  bw80: '#C9CFCC',
  bw85: '#D4D9D6',
  bw90: '#E1E5E3',
  bw95: '#EAEEEC',
  bw97: '#F1F5F3',
  bw98: '#F7FAF8',
  bw99: '#FCFCFC',
  bw100: '#FFFFFF',
  acssentBlue: '#727CF5',
  red: { alert: '#CC3D3D', sale: '#ff0000' },
  orange: { warning: { primary: '#FEB01A', tint: '#FFEB9D', dark: '#8D4C00' } },
  background: '#F9F9F9',
  lightBackground: '#F3F6F7',
  black: '#0E0E0E',
};

const newColors = {
  /* GreyScale */
  lightWhite: '#FFFFFF1A',
  white: '#FFFFFF',
  black: '#0E0E0E',
  lightGrey1: '#EFF2F2',
  lightGrey2: '#DDE2E3',
  grey1: '#B6BDBE',
  grey2: '#83898C',
  grey3: '#696F73',
  grey4: '#4E5354',
  darkGrey1: '#3E4243',
  darkGrey2: '#2E3132',

  /* Brand */
  primary: '#61d377',
  primary60: '#F1FBF3',
  secondary: '#2acb6b',
  tertiary: '#23b07e',

  /* Secondary */
  acssentGreenTertiary: '#DDFFBC',
  acssentGreenPrimary: '#7DD6AB',
  acssentOrangePrimary: '#F87600',
  acssentOrangeSecondary: '#FFC061',
  acssentBluePrimary: '#727CF5',
  acssentBlueSecondary: '#AAB0F9',
  violet50: '#B65FFB',
  violet70: '#F3E5FE',
  pink50: '#F048DF',
  pink80: '#FEEDFC',
  green80: '#F1FFE5',
  orange40: '#F85900',
  orange80: '#FFF9E4',

  /* Tertiary */
  acssentBlueTertiary: '#ECEDFF',

  /* Semantic */
  red: '#FF3D00',
  red80: '#FFF6F3',
  yellow: '#FEB01A',
  yellow40: '#E09400',
  yellow50: '#FFD53D',
  yellow80: '#FFF6D6',
  blue: '#1A9EFE',
  blue80: '#E7F5FF',

  /* Background */
  background: '#F8FAFB',
  lightBackground1: '#F3F6F6',
  lightBackground2: '#EAEDEE',
};

const boxShadows = {
  shadow10: '0px 2px 18px rgba(212, 217, 214, 0.1)',
  shadow20: '0px 2px 18px rgba(212, 217, 214, 0.2)',
  shadow50: '1px 1px 16px rgba(212, 217, 214, 0.5)',
  shadow60: '0px 3px 18px rgba(212, 217, 214, 0.6)',
  shadow70: '1px 6px 16px rgba(212, 217, 214, 0.7)',
  shadow86: '0px 3px 18px rgba(212, 217, 214, 0.86)',
  green18: '0px 4px 14px rgba(25, 88, 65, 0.18)',
};

const newBoxShadows = {
  shadow10: '4px 4px 10px 0px rgba(62, 66, 67, 0.04)',
};

const btnColors = {
  filled: {
    inactive: { bg: colors.bw95, text: colors.bw65 },
    active: { bg: colors.primary80, text: colors.bw100 },
    warning: { bg: colors.orange.warning.primary, text: colors.bw100 },
  },
  outlined: {
    normal: { bg: colors.bw100, text: colors.bw30, border: colors.bw85 },
    highlight: {
      bg: colors.bw100,
      text: colors.primary80,
      border: colors.primary80,
    },
  },
};

const divider = {
  top: `border-top: 1px solid ${colors.bw95}`,
  bottom: `border-bottom: 1px solid ${colors.bw95}`,
  newTop: `border-top: 1px solid #EBEBEB`,
  newBottom: `border-bottom: 1px solid #EBEBEB`,
};

const border = `1px solid ${colors.bw95}`;

const form = {
  ractangle: `
    width: 100%;
    border: 1px solid ${colors.bw85};
    border-radius: 8px;
    line-height: 140%;

    &::placeholder{
      color: ${colors.bw65};
    }
  `,
  underline: `
    width: 100%;
    ${divider.bottom};
    border-radius:0;
    line-height: 160%;

    &::placeholder {
      color: ${colors.bw65};
    }
  `,
};

const deviceSizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '390px',
  mobileXL: '425px',
  pc: '530px',
};

export const devices = {
  mobileS: `(min-width: ${deviceSizes.mobileS})`,
  mobileM: `(min-width: ${deviceSizes.mobileM})`,
  mobileL: `(min-width: ${deviceSizes.mobileL})`,
  mobileXL: `(min-width: ${deviceSizes.mobileXL})`,
  pc: `(min-width: ${deviceSizes.pc})`,
};

export const borderRadius = {
  image: `7px`,
};

const height = 52;
export const header = {
  height,
  heightPx: `${height}px`,
};

const lnbHeight = 52;
export const localNavBar = {
  height: lnbHeight,
  heightPx: `${lnbHeight}px`,
};

export const store = {
  exposedContentHeight: 688,
};

export const theme: DefaultTheme = {
  ...muiTheme,
  fontSizes,
  colors,
  newColors,
  boxShadows,
  newBoxShadows,
  btnColors,
  divider,
  form,
  border,
  deviceSizes,
  devices,
  borderRadius,
  header,
  localNavBar,
  store,
};
