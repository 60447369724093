import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IShopbyBestRevewRequestPayload,
  IShopbyBestRevewResponsePayload,
  IShopbyBestReviewState,
} from 'store/types';
import getRandomElementsFromArray from 'utils/getRandomElementsFromArray';
import { IShopbyBestReview, ITShopbyErrorRes } from '@types';

const initialState: IShopbyBestReviewState = {
  totalCount: 0,
  items: [] as IShopbyBestReview[],
  pageNumber: 1,
  shopbyError: undefined,
};

export const shopbyBestReviewSlice = createSlice({
  name: 'shopbyBestReview',
  initialState,
  reducers: {
    getShopbyBestReviewRequest: (
      state,
      action: PayloadAction<IShopbyBestRevewRequestPayload>,
    ) => {
      if (action.payload.pageNumber === 1) {
        state.totalCount = 0;
        state.items = [] as IShopbyBestReview[];
      }

      state.shopbyError = undefined;
    },
    getShopbyBestReviewSuccess: (
      state,
      action: PayloadAction<{ data: IShopbyBestRevewResponsePayload }>,
    ) => {
      const { totalCount, items } = action.payload.data;

      const randomReview = getRandomElementsFromArray(
        items,
        items.length,
      ) as IShopbyBestReview[];

      state.totalCount = totalCount;
      state.items = randomReview;
      state.shopbyError = undefined;
    },
    getShopbyBestReviewFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.shopbyError = action.payload;
    },
  },
});

export const {
  getShopbyBestReviewRequest,
  getShopbyBestReviewSuccess,
  getShopbyBestReviewFailure,
} = shopbyBestReviewSlice.actions;

export default shopbyBestReviewSlice.reducer;
