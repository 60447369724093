import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPetsResponse } from '@types';
import {
  ISetMyPeysPayload,
  IUpdateMyPetPayload,
  MyPetsInfoState,
} from 'store/types';

const initialState: MyPetsInfoState = {
  pets: [],
  error: '',
};

export const myPetsSlice = createSlice({
  name: 'myPets',
  initialState,
  reducers: {
    getMyPetsRequest: (state, action: PayloadAction) => {
      state.pets = [];
      state.error = '';
    },
    getMyPetsSuccess: (state, action: PayloadAction<IPetsResponse>) => {
      state.pets = action.payload.pets;
      state.error = '';
    },
    getMyPetsFailure: (state, action: PayloadAction<Error | string>) => {
      state.pets = [];
      state.error = action.payload;
    },

    setMyPetsRequest: (state, action: PayloadAction<ISetMyPeysPayload>) => {
      state.error = '';
    },
    setMyPetsSuccess: (state, action: PayloadAction) => {
      state.error = '';
    },
    setMyPetsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    deleteMyPetsRequest: (state, action: PayloadAction<string>) => {
      state.error = '';
    },
    deleteMyPetsSuccess: (state, action: PayloadAction) => {
      state.error = '';
    },
    deleteMyPetsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    updateMyPetsRequest: (
      state,
      action: PayloadAction<IUpdateMyPetPayload>,
    ) => {
      state.error = '';
    },
    updateMyPetsSuccess: (state, action: PayloadAction) => {
      state.error = '';
    },
    updateMyPetsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  getMyPetsRequest,
  getMyPetsSuccess,
  getMyPetsFailure,
  setMyPetsRequest,
  setMyPetsSuccess,
  setMyPetsFailure,
  deleteMyPetsRequest,
  deleteMyPetsSuccess,
  deleteMyPetsFailure,
  updateMyPetsRequest,
  updateMyPetsSuccess,
  updateMyPetsFailure,
  setError,
} = myPetsSlice.actions;

export default myPetsSlice.reducer;
