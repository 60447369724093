export const getDeviceId = () => {
  const cookieArr = document.cookie.split(';');

  for (let i = 0; i < cookieArr.length; i += 1) {
    const cookiePair = cookieArr[i].split('=');

    // 쿠키 이름의 공백을 제거
    const cookieName = cookiePair[0].trim();

    // 주어진 이름과 일치하는 쿠키를 찾으면 그 값을 반환
    if (cookieName === 'deviceId') {
      return decodeURIComponent(cookiePair[1]);
    }
  }

  return null;
};
