import {
  takeEvery,
  select,
  all,
  take,
  call,
  put,
  actionChannel,
  ActionPattern,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import {
  getNewCampListRequest,
  getNewCampListSuccess,
  getNewCampListFailure,
  getCampRequest,
  getCampSuccess,
  getCampFailure,
  setScrollTop,
} from 'store/reducers/camp';
import {
  getZoneListRequest,
  getZoneListSuccess,
  getZoneListFailure,
  getZoneCntRequest,
  getZoneCntSuccess,
  getZoneCntFailure,
  setCampId,
  // getLongTermZonesRequest,
  getLongTermZonesSuccess,
  getLongTermZoneCntSuccess,
} from 'store/reducers/zone';
import {
  getCampDetail,
  getZonesByCamp,
  countZonesByCamp,
  findHolidays,
  getCampAnnounces,
  getCampAnnouncesCnt,
  getMainNewCampList,
} from 'api';
import {
  getHolidaysRequest,
  getHolidaysSuccess,
  getHolidaysFailure,
} from 'store/reducers/calendar';
import makePeriodChangeToastMessage from 'utils/makePeriodChangeToastMessage';
import { changeDateFormat } from 'utils/calculateDate';
import { IRecentCamp, ICampDetail, IZone } from '@types';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';
import {
  getCampAnnouncesByCampFailure,
  getCampAnnouncesByCampRequest,
  getCampAnnouncesByCampSuccess,
  getCampAnnouncesCntFailure,
  getCampAnnouncesCntRequest,
  getCampAnnouncesCntSuccess,
} from 'store/reducers/campAnnounces';
import { getLongTermZones, getLongTermZonesCount } from 'api/longTerm';
import {
  BookingType,
  setBookingType,
  setPrevLongStayPeriod,
} from 'store/reducers/longStay';
import { toast } from 'react-toastify';
import { failure } from './failure';
import { PeopleCnt, ReservationDate } from '../types';
import { createFetchAction, fetchApi } from './createFetchAction';

export const ZONELIMIT = 4;

function saveRecentCamp(camp: ICampDetail) {
  const recents: string | null = localStorage.getItem('recents');

  let camps: IRecentCamp[] = [];

  if (typeof recents === 'string') {
    camps = JSON.parse(recents);
  }

  const now = new Date();
  const sCamp = {
    id: camp.id,
    thumbnail: camp.thumbnail,
    name: camp.name,
    date: changeDateFormat(now, 'yyyy.MM.dd'),
    city: camp.city,
    major: camp.major,
  } as IRecentCamp;

  if (!camps.find(c => c.id === sCamp.id)) {
    camps = [sCamp, ...camps];
  } else {
    camps = [sCamp, ...camps.filter(c => c.id !== sCamp.id)];
  }

  if (camps.length > 10) {
    camps = camps.slice(0, 10);
  }
  localStorage.setItem('recents', JSON.stringify(camps));
}

export function* fetchCamp() {
  const subChannel: ActionPattern = yield actionChannel(getCampRequest.type);
  while (true) {
    const action: PayloadAction<{
      id: string;
      zoneId?: string;
      bookingType?: BookingType;
    }> = yield take(subChannel);

    const currCampId = action.payload.id;
    const bookingType = action.payload.bookingType;

    if (bookingType) {
      yield put(setBookingType(bookingType));
    }

    const campId: string = yield select(
      (state: RootState) => state.zoneReducer.campId,
    );

    // const action: PayloadAction<{
    //   id: string;
    //   zoneId?: string;
    //   bookingType?: string;
    // }> = yield take(getCampRequest.type);

    if (currCampId !== campId) {
      yield call<any>(
        fetchApi,
        getCampDetail,
        action.type,
        getCampSuccess,
        getCampFailure,
        action.payload,
      );

      yield call(getCampAnnouncesByCamp, action.payload.id);
    }

    const camp: ICampDetail = yield select(
      (state: RootState) => state.campReducer.camp,
    );

    if (Object.keys(camp).length) {
      const isCampsSaving: string | null =
        localStorage.getItem('isCampsSaving');
      if (isCampsSaving === 'true') yield call(saveRecentCamp, camp);

      // if (campId !== camp.id) {
      yield put(setCampId(camp.id));

      // 장박 예약 시
      if (bookingType === 'longStay') {
        // yield getLongTermZonesFnc(1, 4);
        yield call(getZones, camp.id, ZONELIMIT, 0);
      }

      // 일반 예약 시
      else {
        yield call(getZones, camp.id, ZONELIMIT, 0);

        yield put(getZoneCntRequest());
        yield call<any>(
          fetchApi,
          countZonesByCamp,
          getZoneCntRequest.type,
          getZoneCntSuccess,
          getZoneCntFailure,
          camp.id,
        );
      }
      // }
    }
  }
}

function* getCampAnnouncesByCamp(campId: string) {
  const params = {
    search: '',
    campId,
    page: 1,
    pageSize: 3,
  };

  yield put(getCampAnnouncesByCampRequest(params));
  yield call<any>(
    fetchApi,
    getCampAnnounces,
    getCampAnnouncesByCampRequest.type,
    getCampAnnouncesByCampSuccess,
    getCampAnnouncesByCampFailure,
    params,
  );

  yield put(getCampAnnouncesCntRequest({ search: '', campId }));
  yield call<any>(
    fetchApi,
    getCampAnnouncesCnt,
    getCampAnnouncesCntRequest.type,
    getCampAnnouncesCntSuccess,
    getCampAnnouncesCntFailure,
    { search: '', campId },
  );
}

export function* getZones(id: string, limit: number, skip: number) {
  const bookingType: BookingType = yield select(
    (state: RootState) => state.longStayReducer.bookingType,
  );

  if (bookingType === 'longStay') {
    yield getLongTermZonesFnc(skip, limit);
  } else {
    const date: ReservationDate = yield select(
      (state: RootState) => state.reservationReducer.date,
    );
    const peopleCnt: PeopleCnt = yield select(
      (state: RootState) => state.reservationReducer.peopleCnt,
    );

    yield put(
      getZoneListRequest({
        id,
        adult: peopleCnt.adultCnt,
        teen: peopleCnt.teenCnt,
        child: peopleCnt.childCnt,
        startTimestamp: date.start ? date.start.getTime() : 0,
        endTimestamp: date.end ? date.end.getTime() : 0,
        limit,
        skip,
      }),
    );
    yield call<any>(
      fetchApi,
      getZonesByCamp,
      getZoneListRequest.type,
      getZoneListSuccess,
      getZoneListFailure,
      {
        id,
        adult: peopleCnt.adultCnt,
        teen: peopleCnt.teenCnt,
        child: peopleCnt.childCnt,
        startTimestamp: date.start ? date.start.getTime() : 0,
        endTimestamp: date.end ? date.end.getTime() : 0,
        limit,
        skip,
      },
    );
  }
}

export function* getLongTermZonesFnc(skip: number, limit: number) {
  try {
    const camp: ICampDetail = yield select(
      (state: RootState) => state.campReducer.camp,
    );

    const adultCnt: string = yield select(
      (state: RootState) => state.longStayReducer.adultCnt,
    );

    const teenCnt: string = yield select(
      (state: RootState) => state.longStayReducer.teenCnt,
    );

    const childCnt: string = yield select(
      (state: RootState) => state.longStayReducer.childCnt,
    );

    const startDate: Date | null = yield select(
      (state: RootState) => state.longStayReducer.startDate,
    );

    const endDate: Date | null = yield select(
      (state: RootState) => state.longStayReducer.endDate,
    );

    const prevStartDate: Date | null = yield select(
      (state: RootState) => state.longStayReducer.prevStartDate,
    );

    const prevEndDate: Date | null = yield select(
      (state: RootState) => state.longStayReducer.prevEndDate,
    );

    const zoneList: IZone[] = yield select(
      (state: RootState) => state.zoneReducer.zoneList,
    );

    const response: {
      status: 'success' | 'fail';
      data: IZone[];
    } = yield call(getLongTermZones, camp.id, {
      page: skip,
      pageSize: limit,
      startTimestamp: startDate?.getTime() || 0,
      endTimestamp: endDate?.getTime() || 0,
      adult: adultCnt,
      teen: teenCnt,
      child: childCnt,
      // redux에서 같은 파라미터일 경우, 캐싱되는 이슈가 있어 ts를 추가함
      _ts: Date.now(),
    });

    const countResponse: {
      status: 'success' | 'fail';
      data: { count: number };
    } = yield call(
      getLongTermZonesCount,
      camp.id,
      startDate?.getTime(),
      endDate?.getTime(),
    );

    const zones = response.data;
    const zoneCount = countResponse.data.count;

    yield put(
      getLongTermZonesSuccess({
        zones: skip === 0 ? zones : [...zoneList, ...zones],
        isMoreBtnClicked: skip !== 0,
      }),
    );
    yield put(getLongTermZoneCntSuccess(zoneCount));
    yield put(setBookingType('longStay'));

    if (prevStartDate && prevEndDate && startDate && endDate) {
      if (
        prevStartDate.getTime() !== startDate.getTime() ||
        prevEndDate.getTime() !== endDate.getTime()
      ) {
        const msg = makePeriodChangeToastMessage({
          startDate,
          endDate,
        });

        toast.success(msg);
      }
    }

    yield put(
      setPrevLongStayPeriod({
        startDate,
        endDate,
      }),
    );
  } catch (error: any) {
    console.log('fetchCamp longStay ERROR : ', error);
    const msg = error?.response?.data?.message;
    if (msg) alert(msg);
    else alert('장박 존 목록 요청 중 에러가 발생했습니다');
  }
}

// Main
function* getNewCampListSaga() {
  yield takeLeading(
    getNewCampListRequest.type,
    createFetchAction(
      getMainNewCampList,
      getNewCampListSuccess,
      getNewCampListFailure,
      undefined,
      failure,
    ),
  );
}
export function* campSaga() {
  yield all([
    takeEvery(
      getHolidaysRequest.type,
      createFetchAction(findHolidays, getHolidaysSuccess, getHolidaysFailure),
    ),
    fetchCamp(),
    getNewCampListSaga(),
  ]);
}
