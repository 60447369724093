import { ErrorInfo } from 'react';
import axios, { AxiosInstance } from 'axios';
import { getEnvironment } from 'utils/common';

const slacApi: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SLACK_CAMFIT_CAT_BASE_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export const postSlackNotiFrontendError = async (
  error: Error,
  info: ErrorInfo,
) => {
  const { name, message, stack } = error;
  const { componentStack } = info;

  const trimmedComponentStackInfo = componentStack
    .substring(0, componentStack.indexOf('at Switch'))
    .trim();

  const environment = getEnvironment().toUpperCase();

  const text = `
  🚨 *프론트엔드 : ${environment}* 🚨
  \`\`\`
  path : ${window.location.href}
  name : ${name}
  message : ${message}
  stack : ${stack}

  componentStackInfo : ${trimmedComponentStackInfo}
  \`\`\`
  `;

  try {
    await slacApi.post(`${process.env.REACT_APP_NOTI_FRONTEND_ERROR}`, {
      text,
    });
  } catch (error) {
    console.log('error', error);
  }
};
