import { getLocationTable } from 'api';
import { takeEvery, put, call } from 'redux-saga/effects';
import {
  getLocationTableFailure,
  getLocationTableRequest,
  getLocationTableSuccess,
} from 'store/reducers/filter';

import { createFetchAction } from './createFetchAction';

export default function* fetchLocationsSaga() {
  yield takeEvery(
    getLocationTableRequest.type,
    createFetchAction(
      getLocationTable,
      getLocationTableSuccess,
      getLocationTableFailure,
    ),
  );
}
