import { useState, useEffect } from 'react';
import styled, { CSSProp, css } from 'styled-components';
import { getHexOpacity } from 'utils/common';
import { getNumberTimeToString } from 'utils/calculateTime';
import Typography from './Typography';

export interface TimerProps {
  /**
   * 타이머에 처음 세팅되는 시작과 종료 시간의 차 (타이머 지속시간)
   */
  targetEndTimeMilliseconds: number;
  /**
   * 타이머 미표기 여부
   */
  isTimerInvisible?: boolean;
  /**
   * 타이머가 시작한 후, 종료될 때 실행되는 함수
   */
  onFinishTime?: () => void;
  /**
   * 타이머의 상태가 변할 때 실행되는 함수
   */
  onUpdateTimerStatus: () => void;
  /**
   * 타이머 내에 표기되는 텍스트
   */
  text?: string;
  /**
   * 타이머 style 효과
   */
  textStyle?: 'textTimer' | 'timerText' | 'text';
  getTimeCustomedFormat?: (targetEndMilli: number) => string;
  backgroundColor?: string;
  opacity?: number;
  imminentStandardMilliSeconds?: number;
  cssObject?: CSSProp;
  className?: string;
}

export default function Timer({
  targetEndTimeMilliseconds,
  onFinishTime,
  onUpdateTimerStatus,
  isTimerInvisible,
  text,
  textStyle,
  backgroundColor,
  opacity,
  imminentStandardMilliSeconds,
  cssObject,
  className,
  getTimeCustomedFormat,
}: TimerProps) {
  const [remainingTime, setRemainingTime] = useState<number>(
    () => (targetEndTimeMilliseconds - new Date().getTime()) / 1000,
  );

  useEffect(() => {
    if (!targetEndTimeMilliseconds) return;
    setRemainingTime((targetEndTimeMilliseconds - new Date().getTime()) / 1000);

    const updateTimer = () => {
      const now = new Date().getTime();
      setRemainingTime(prev => {
        if (prev <= 1) {
          onFinishTime && onFinishTime();
          onUpdateTimerStatus();
          clearInterval(countdown);
          return 0;
        }
        if (
          imminentStandardMilliSeconds &&
          Math.floor(prev) === imminentStandardMilliSeconds / 1000
        ) {
          onUpdateTimerStatus();
        }
        return (targetEndTimeMilliseconds - now) / 1000;
      });
    };

    const countdown = setInterval(updateTimer, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [targetEndTimeMilliseconds]);

  const hours = getNumberTimeToString(Math.floor(remainingTime / 3600));
  const minutes = getNumberTimeToString(
    Math.floor((remainingTime % 3600) / 60),
  );
  const seconds = getNumberTimeToString(Math.floor(remainingTime % 60));

  const getText = () => {
    if (isTimerInvisible) return text;

    const timer =
      (getTimeCustomedFormat &&
        getTimeCustomedFormat(targetEndTimeMilliseconds)) ||
      `${hours}:${minutes}:${seconds}`;

    switch (textStyle) {
      case 'textTimer':
        return `${text} ${timer}`;
      case 'timerText':
        return `${timer} ${text}`;
      default:
        return text;
    }
  };

  return (
    <TimerBox
      backgroundColor={backgroundColor}
      opacity={opacity}
      cssObject={cssObject}
      className={className}
    >
      <Typography
        variant="number12"
        weight="regular"
        color="white"
        cssObject={flexCss}
      >
        {getText()}
      </Typography>
    </TimerBox>
  );
}

const flexCss = css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
`;

const TimerBox = styled.div<{
  backgroundColor?: string;
  opacity?: number;
  cssObject?: CSSProp;
}>`
  ${flexCss}
  border-radius: 6px;
  background: ${({ backgroundColor, opacity }) =>
    opacity
      ? backgroundColor
        ? `${backgroundColor}${getHexOpacity(opacity).hex}`
        : `rgba(14, 14, 14, ${opacity})`
      : backgroundColor || 'rgba(14, 14, 14, 1)'};
  width: 100%;
  height: 26px;
  ${({ cssObject }) => cssObject && cssObject}
`;
