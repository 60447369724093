import { takeEvery, take, put, all, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getBoardFailure,
  getBoardRequest,
  getBoardsFailure,
  getBoardsRequest,
  getBoardsSuccess,
  getBoardSuccess,
  selectMainTopic,
  selectProductType,
  selectSubject,
  updateTimestamp,
  getBoardMainRequest,
  getBoardMainSuccess,
  getBoardMainFailure,
} from 'store/reducers/board';
import { getCampingLogsRequest } from 'store/reducers/campingLog';
import { IUpdateTimestampPayload } from 'store/types';
import { getCommunityHomeDate, getPost, getPosts } from 'api';
import { RootState } from 'store/reducers';
import { TArticleType, TSubjectType } from '@types';
import { getArticlesRequest } from 'store/reducers/articles';
import { createFetchAction } from './createFetchAction';
import { customHistory } from '../../App';
import { failure } from './failure';

function* getBoardListSaga() {
  while (true) {
    const action: PayloadAction<string> = yield take(selectSubject.type);

    const subject: TSubjectType = yield select(
      (state: RootState) => state.boardReducer.subject,
    );

    const articleType: TArticleType = yield select(
      (state: RootState) => state.articlesReducer.articleType,
    );

    switch (subject) {
      case 'review':
        break;
      case 'article':
        if (articleType === '') {
          yield put(
            getArticlesRequest({
              articleType,
              page: 1,
              pageSize: 10,
            }),
          );
        }
        break;
      default:
        yield put(
          getBoardsRequest({
            boardType: subject === 'all' ? '' : subject,
            timestamp: 0,
            limit: 10,
          }),
        );
    }
  }
}

// selectMainTopic 액션이 발생했을 때 호출되는 saga 입니다. / ywroh / 2022.02.28
function* getQuestionBoardListSaga() {
  while (true) {
    const action: PayloadAction<string> = yield take(selectMainTopic.type);

    const { subject, mainTopic, productType } = yield select(
      (state: RootState) => state.boardReducer,
    );

    if (mainTopic === '장비') {
      yield put(
        getBoardsRequest({
          boardType: subject === 'all' ? '' : subject,
          mainTopic,
          productType: productType === '전체' ? '' : productType,
          timestamp: 0,
          limit: 10,
        }),
      );
    } else {
      yield put(
        getBoardsRequest({
          boardType: subject === 'all' ? '' : subject,
          mainTopic: mainTopic === '전체' ? '' : mainTopic,
          timestamp: 0,
          limit: 10,
        }),
      );
    }
  }
}

// selectProductType 액션이 발생했을 때 호출되는 saga 입니다. / ywroh / 2022.02.28
function* getQuestionBoardListByProductTypeSaga() {
  while (true) {
    const action: PayloadAction<string> = yield take(selectProductType.type);

    const { subject, mainTopic, productType } = yield select(
      (state: RootState) => state.boardReducer,
    );

    if (mainTopic === '장비' && productType) {
      yield put(
        getBoardsRequest({
          boardType: subject === 'all' ? '' : subject,
          mainTopic,
          productType: productType === '전체' ? '' : productType,
          timestamp: 0,
          limit: 10,
        }),
      );
    }
  }
}

function* getPostFailure(error: Error) {
  yield failure(error);
  yield customHistory.goBack();
}

function* getPostSaga() {
  yield takeEvery(
    getBoardRequest.type,
    createFetchAction(
      getPost,
      getBoardSuccess,
      getBoardFailure,
      undefined,
      getPostFailure,
    ),
  );
}

function* getPostsSaga() {
  yield takeEvery(
    getBoardsRequest.type,
    createFetchAction(getPosts, getBoardsSuccess, getBoardsFailure),
  );
}

// 무한스크롤 조회 사용하는 함수입니다. / ywroh / 2022.02.28
function* getMorePosts() {
  while (true) {
    const action: PayloadAction<IUpdateTimestampPayload> = yield take(
      updateTimestamp.type,
    );

    const { timestamp, boardType } = action.payload;

    const { mainTopic, productType } = yield select(
      (state: RootState) => state.boardReducer,
    );

    yield put(
      getBoardsRequest({
        boardType: boardType === 'all' ? '' : boardType,
        mainTopic: mainTopic === '전체' ? '' : mainTopic,
        productType: productType === '전체' ? '' : productType,
        timestamp,
        limit: 10,
      }),
    );
  }
}

function* getBoardMainSaga() {
  yield takeEvery(
    getBoardMainRequest.type,
    createFetchAction(
      getCommunityHomeDate,
      getBoardMainSuccess,
      getBoardMainFailure,
    ),
  );
}

export function* getBoardSaga() {
  yield all([
    getPostSaga(),
    getPostsSaga(),
    getMorePosts(),
    getBoardListSaga(),
    getQuestionBoardListSaga(),
    getQuestionBoardListByProductTypeSaga(),
    getBoardMainSaga(),
  ]);
}
