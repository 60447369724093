import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OpenSchedulesState } from 'store/types';
import { IOpenSchedules } from '@types';

const initialState: OpenSchedulesState = {
  openSchedules: {} as IOpenSchedules,
  openSchedulesMap: new Map<string, number>(),
  error: '',
};

export const openSchedulesSlice = createSlice({
  name: 'openSchedules',
  initialState,
  reducers: {
    getOpenSchedulesRequest: state => {
      state.openSchedules = {} as IOpenSchedules;
      state.error = '';
    },
    getOpenSchedulesSuccess: (state, action: PayloadAction<IOpenSchedules>) => {
      state.openSchedules = action.payload;
      state.error = '';
    },
    getOpenSchedulesFailure: (state, action: PayloadAction<Error | string>) => {
      state.openSchedules = {} as IOpenSchedules;
      state.error = action.payload;
    },

    saveOpenSchedulesMap: (
      state,
      action: PayloadAction<Map<string, number>>,
    ) => {
      state.openSchedulesMap = action.payload;
    },
  },
});

export const {
  getOpenSchedulesRequest,
  getOpenSchedulesSuccess,
  getOpenSchedulesFailure,
  saveOpenSchedulesMap,
} = openSchedulesSlice.actions;

export default openSchedulesSlice.reducer;
