import { ITo } from '@types';
import {
  forwardRef,
  memo,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  Ref,
  ElementType,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  NumberTextVariant,
  TextVariant,
  TextWeight,
} from 'static/styles/style';
import { theme } from 'static/styles/theme';
import styled, { css, CSSProp } from 'styled-components';

interface NewBasicButtonProps {
  children: ReactNode | JSX.Element | JSX.Element[];
  type?: 'contained' | 'outlined' | 'ghost';
  size?: 'full' | 'l' | 'm' | 's' | 'xs' | 'xxs';
  padding?: string;
  isRound?: boolean;
  backgroundColor?: keyof typeof theme.newColors;
  borderColor?: keyof typeof theme.newColors;
  color?: keyof typeof theme.newColors;
  width?: string;
  height?: string;
  disabled?: boolean;
  onClick?: (
    e?: any,
  ) =>
    | void
    | React.MouseEventHandler<HTMLButtonElement>
    | React.FormEventHandler<HTMLInputElement>;
  to?: string | ITo;
  toActionType?: 'push' | 'replace';
  isFullBleed?: boolean;
  iconAlign?: 'right' | 'left';
  cssObject?: CSSProp;
  submitType?: boolean;
  textVariant?: 'default' | TextVariant | NumberTextVariant;
  textWeight?: TextWeight;
  className?: string;
  zIndex?: number;
  externalLinkButton?: {
    href: string;
    target: '_blank';
    rel: 'external' | 'noopener noreferrer';
  };
  id?: string;
}

/**
 * Basic Button
 * @param {string} size - 디자인 시스템에서 사용되는 버튼 사이즈. full 의 경우, 시스템엔 없으나 컨테이너에 따라 너비가 자동으로 조절되는 버튼일 시 사용
 * @param {string} type - 디자인 시스템에서 사용되는 버튼 스타일. 예) contained 등
 * @param {string} width - size full 이외의 사이즈는 전부 필수값임.
 * @returns {ReactComponentElement} 디자인 시스템이 적용된 버튼 컴포넌트
 */

function NewBasicButton(
  {
    children,
    size = 'full',
    padding,
    isRound = false,
    height,
    width,
    type = 'contained',
    backgroundColor,
    borderColor,
    color,
    disabled = false,
    onClick,
    to,
    toActionType = 'push',
    isFullBleed = false,
    iconAlign,
    cssObject,
    submitType,
    textVariant = 'default',
    textWeight,
    className,
    zIndex,
    externalLinkButton,
    id,
  }: NewBasicButtonProps,
  ref: Ref<HTMLButtonElement>,
) {
  const history = useHistory();

  const onClickButton = (e?: MouseEvent) => {
    // NOTE: onClick 없이 submit 자체 기능을 쓰는 경우에는 이벤트 발생
    if (!(submitType && !onClick) && !externalLinkButton) {
      e && e.preventDefault();
      e && e.stopPropagation();
    }

    onClick && onClick(e);
    to && (toActionType === 'push' ? history.push(to) : history.replace(to));
  };

  const textVariantClass =
    textVariant !== 'default' ? `typography-${textVariant}` : '';

  const classes = className
    ? `${textVariantClass} ${className}`
    : textVariantClass;

  const htmlElementName = useMemo(() => {
    switch (true) {
      case submitType:
        return 'input';
      case !!externalLinkButton:
        return 'a';
      default:
        return 'button';
    }
  }, [submitType, externalLinkButton]);

  const StyledButton = (
    <Button
      id={id}
      size={size || undefined}
      buttonHeight={height}
      buttonWidth={width}
      buttonType={type}
      padding={padding}
      isRound={isRound}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      textColor={color}
      disabled={disabled}
      onClick={onClickButton as MouseEventHandler<HTMLButtonElement>}
      clickEvent={
        !disabled && (!!onClick || !!to || submitType || !!externalLinkButton)
      }
      ref={ref}
      isFullBleed={isFullBleed}
      iconAlign={iconAlign}
      css={cssObject}
      as={htmlElementName}
      type={submitType ? 'submit' : undefined}
      value={submitType ? (children as string) : undefined}
      textVariant={textVariant}
      textWeight={textWeight}
      className={classes}
      externalLinkButton={!!externalLinkButton}
      {...(!!externalLinkButton && externalLinkButton)}
    >
      {!submitType ? children : undefined}
    </Button>
  );

  const StyledFixedButton = (
    <FixedButtonBackground
      size={size || undefined}
      buttonHeight={height}
      className={classes}
      zIndex={zIndex}
    >
      {StyledButton}
    </FixedButtonBackground>
  );

  return isFullBleed ? StyledFixedButton : StyledButton;
}

export default memo(forwardRef(NewBasicButton));

interface INewBasicButtonStyle {
  size?: 'full' | 'l' | 'm' | 's' | 'xs' | 'xxs';
  padding?: string;
  buttonHeight?: string;
  buttonWidth?: string;
  buttonType: 'contained' | 'outlined' | 'ghost';
  isRound: boolean;
  backgroundColor?: keyof typeof theme.newColors;
  borderColor?: keyof typeof theme.newColors;
  textColor?: keyof typeof theme.newColors;
  disabled?: boolean;
  onClick?: MouseEventHandler<
    HTMLButtonElement | HTMLDivElement | HTMLLIElement
  >;
  isFullBleed: boolean;
  fontSize?: string;
  iconAlign?: 'left' | 'right';
  css?: CSSProp;
  as: ElementType;
  value?: string;
  type?: 'submit';
  textVariant: 'default' | TextVariant | NumberTextVariant;
  textWeight?: TextWeight;
  clickEvent?: boolean;
  externalLinkButton?: boolean;
}

interface IBackgroundStyle {
  size?: 'full' | 'l' | 'm' | 's' | 'xs' | 'xxs';
  buttonHeight?: string;
  zIndex?: number;
}

const fullBleedCss = css`
  position: fixed;
  bottom: 0%;
  border-radius: 0;
  height: 70px;
`;

const FixedButtonBackground = styled.div<IBackgroundStyle>`
  position: fixed;
  max-width: 530px;
  ${({ size, buttonHeight }) => getButtonSizeCss(buttonHeight, size)};
  ${fullBleedCss}
  z-index: ${({ zIndex }) => zIndex || 999};
`;

const Button = styled.button<INewBasicButtonStyle>`
  display: flex;
  max-width: 530px;
  text-align: center;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  padding: ${({ padding }) => padding};
  pointer-events: ${({ clickEvent }) => (!clickEvent ? 'none' : 'auto')};
  cursor: ${({ disabled, onClick, type }) =>
    disabled
      ? 'not-allowed'
      : !onClick && type !== 'submit'
      ? 'normal'
      : 'pointer'};

  border-radius: ${({ isRound, buttonType, size }) =>
    getShapeCss(isRound, buttonType, size)};

  font-weight: ${({ textWeight }) =>
    textWeight ? getTextWeight(textWeight) : 'bold'};

  > svg {
    margin: ${({ iconAlign }) =>
      iconAlign ? (iconAlign === 'left' ? '0 2px 0 0' : '0 0 0 2px') : '0'};
  }

  ${({ size, buttonHeight, buttonWidth }) =>
    getButtonSizeCss(buttonHeight, size, buttonWidth)}

  ${({ textVariant, size }) =>
    textVariant === 'default' && getButtonTextCss(size)}

  ${({ buttonType, backgroundColor, textColor, disabled, borderColor }) =>
    getButtonStyleCss(
      buttonType,
      backgroundColor,
      borderColor,
      textColor,
      disabled,
    )}

  ${({ isFullBleed }) => (isFullBleed ? fullBleedCss : '')}

  ${({ css }) => css}
`;

const getTextWeight = (textWeight: TextWeight) => {
  switch (textWeight) {
    case 'regular':
      return 400;
    case 'medium':
      return 500;
    default:
      return 700;
  }
};

const getButtonSizeCss = (
  buttonHeight?: string,
  size?: 'full' | 'l' | 'm' | 's' | 'xs' | 'xxs',
  buttonWidth?: string,
) => {
  const width = buttonWidth || '100%';
  let height;

  switch (size) {
    case 'l':
      height = buttonHeight || '52px';
      break;
    case 'm':
      height = buttonHeight || '48px';
      break;
    case 's':
      height = buttonHeight || '42px';
      break;
    case 'xs':
      height = buttonHeight || '34px';
      break;
    case 'xxs':
      height = buttonHeight || '28px';
      break;
    default:
      height = buttonHeight || '52px';
      break;
  }
  return css`
    width: ${width};
    height: ${height};
  `;
};

const getButtonTextCss = (size?: 'full' | 'l' | 'm' | 's' | 'xs' | 'xxs') => {
  // typography-subheadline 기준
  const defaultCss = css`
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 140%;
    letter-spacing: -0.03rem;
  `;
  switch (size) {
    case 'l':
      return defaultCss;

    case 'm':
      return defaultCss;

    case 's':
      return css`
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 179%;
        letter-spacing: -0.03rem;
      `;
    case 'xs':
      return css`
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.008rem;
      `;
    case 'xxs':
      return css`
        font-size: 1.1rem;
        font-weight: 700;
        line-height: 145%;
        letter-spacing: -0.008rem;
      `;
    default:
      // full 의 경우
      return defaultCss;
  }
};

const getButtonStyleCss = (
  buttonType: 'contained' | 'outlined' | 'ghost',
  backgroundColor?: keyof typeof theme.newColors,
  borderColor?: keyof typeof theme.newColors,
  textColor?: keyof typeof theme.newColors,
  disabled?: boolean,
) => {
  const containedDisabledCss = css`
    background: ${theme.newColors.lightGrey2};
    color: ${theme.newColors.white};
  `;
  const outlinedDisabledCss = css`
    color: ${theme.newColors.lightGrey2};
    border: 1px solid ${theme.newColors.lightGrey2};
  `;
  const background = backgroundColor && theme.newColors[backgroundColor];
  const color = textColor && theme.newColors[textColor];
  const border = borderColor && theme.newColors[borderColor];

  switch (buttonType) {
    case 'contained':
      if (disabled) return containedDisabledCss;
      return css`
        background-color: ${background || theme.newColors.primary};
        color: ${color || theme.newColors.white};
      `;
    case 'outlined':
      if (disabled) return outlinedDisabledCss;
      return css`
        background-color: ${background || theme.newColors.white};
        color: ${color || theme.newColors.primary};
        border: 1px solid ${border || theme.newColors.primary};
      `;
    case 'ghost':
      if (disabled)
        return css`
          color: ${theme.newColors.lightGrey2};
        `;
      return css`
        color: ${color || theme.newColors.primary};
      `;
    default:
      if (disabled) return containedDisabledCss;
      return css`
        background-color: ${background};
        color: ${color};
      `;
  }
};

const getShapeCss = (
  isRound: boolean,
  buttonType: 'contained' | 'outlined' | 'ghost',
  size?: 'full' | 'l' | 'm' | 's' | 'xs' | 'xxs',
) => {
  if (isRound) {
    return '100px';
  }

  if (size && size === 'xxs') {
    return '6px';
  }

  if (buttonType && (buttonType === 'contained' || buttonType === 'outlined'))
    return '8px';
};
