import { getShopbyBestReviews } from 'api/shopby';
import { all, takeEvery } from 'redux-saga/effects';
import {
  getShopbyBestReviewFailure,
  getShopbyBestReviewRequest,
  getShopbyBestReviewSuccess,
} from 'store/reducers/shopbyBestReview';
import { createShopbyFetchAction } from 'store/sagas/createFetchAction';

function* getShopbyBestReviewSaga() {
  yield takeEvery(
    getShopbyBestReviewRequest.type,
    createShopbyFetchAction(
      getShopbyBestReviews,
      getShopbyBestReviewSuccess,
      getShopbyBestReviewFailure,
    ),
  );
}

export default function* shopbyBestReviewSaga() {
  yield all([getShopbyBestReviewSaga()]);
}
