import { useState, useEffect } from 'react';
import useThrottle from 'hooks/useThrottle';

const useActiveHeaderOption = (handleScroll: () => void, isActive: boolean) => {
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [isVisible, setIsVisible] = useState(false);
  const [isTop, setIsTop] = useState(false);
  const [activeScrollY, setActiveScrollY] = useState(0);

  useEffect(() => {
    isActive && window.addEventListener('scroll', throttleOnScroll);
    return () => {
      isActive && window.removeEventListener('scroll', throttleOnScroll);
    };
  }, [prevScrollPos]);

  const throttleOnScroll = useThrottle(handleScroll, 100);

  const handleWhiteHeader = (targetHeight: number) => {
    const { scrollY } = window;
    const visible = prevScrollPos > scrollY;
    setActiveScrollY(scrollY);
    // // campDetail - header + lnb
    setIsVisible(targetHeight < scrollY && visible);
    setPrevScrollPos(scrollY);
    setIsTop(scrollY === 0);
  };

  return {
    handleWhiteHeader,
    activeScrollY,
    isVisible,
    isTop,
  };
};

export default useActiveHeaderOption;
