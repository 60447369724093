import { getFeatureFlags } from 'api';
import { takeLatest } from 'redux-saga/effects';
import {
  getFeatureFlagsFailure,
  getFeatureFlagsRequest,
  getFeatureFlagsSuccess,
} from 'store/reducers/featureFlags';
import { createFetchActionWithKey } from 'store/sagas/createFetchAction';

function* getFeatureFlagsSaga() {
  yield takeLatest(
    getFeatureFlagsRequest.type,
    createFetchActionWithKey(
      getFeatureFlags,
      getFeatureFlagsSuccess,
      getFeatureFlagsFailure,
    ),
  );
}

export default function* featureFlagsSaga() {
  yield getFeatureFlagsSaga();
}
