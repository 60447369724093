import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppPushState, IToggleAppPushRqPayload } from 'store/types';
import { IAppPushStatus } from '@types';

const initialState: AppPushState = {
  isAdDenied: false,
  error: '',
};

export const appPushSlice = createSlice({
  name: 'appPush',
  initialState,
  reducers: {
    getAppPushStatusRequest: (state, action: PayloadAction<string>) => {
      state.isAdDenied = false;
      state.error = '';
    },
    getAppPushStatusSuccess: (state, action: PayloadAction<IAppPushStatus>) => {
      state.isAdDenied = action.payload.isAdDenied;
      state.error = '';
    },
    getAppPushStatusFailure: (state, action: PayloadAction<Error | string>) => {
      state.isAdDenied = false;
      state.error = action.payload;
    },

    toggleAppPushRequest: (
      state,
      action: PayloadAction<IToggleAppPushRqPayload>,
    ) => {
      state.error = '';
    },
    toggleAppPushSuccess: state => {
      state.isAdDenied = !state.isAdDenied;
      state.error = '';
    },
    toggleAppPushFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  getAppPushStatusRequest,
  getAppPushStatusSuccess,
  getAppPushStatusFailure,
  toggleAppPushRequest,
  toggleAppPushSuccess,
  toggleAppPushFailure,
} = appPushSlice.actions;

export default appPushSlice.reducer;
