import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NoticeState, GetNoticeRequestPayload } from 'store/types';
import { IAnnounce } from '@types';

const initialState: NoticeState = {
  announces: [],
  announce: {} as IAnnounce,
  hasMore: true,
  offset: 0,
  error: '',
};

export const noticeSlice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    getNoticesRequest: (
      state,
      action: PayloadAction<GetNoticeRequestPayload>,
    ) => {
      state.announces = action.payload.skip ? state.announces : [];
      state.offset = action.payload.skip ? state.offset : 0;
      state.error = '';
    },
    getNoticesSuccess: (state, action: PayloadAction<IAnnounce[]>) => {
      state.announces = state.announces.concat(action.payload);
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },
    getNoticesFailure: (state, action: PayloadAction<Error | string>) => {
      state.announces = [];
      state.error = action.payload;
    },

    getNoticeRequest: (state, action: PayloadAction<string>) => {
      state.announce = {} as IAnnounce;
      state.error = '';
    },
    getNoticeSuccess: (state, action: PayloadAction<IAnnounce>) => {
      state.announce = action.payload;
      state.error = '';
    },
    getNoticeFailure: (state, action: PayloadAction<Error | string>) => {
      state.announce = {} as IAnnounce;
      state.error = action.payload;
    },

    addOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
  },
});

export const {
  getNoticesRequest,
  getNoticesSuccess,
  getNoticesFailure,
  getNoticeRequest,
  getNoticeSuccess,
  getNoticeFailure,
  addOffset,
} = noticeSlice.actions;

export default noticeSlice.reducer;
