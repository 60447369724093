import { takeLatest } from 'redux-saga/effects';
import { postPhoneAuth, getNonReservList } from 'api';
import { IGetNonReservListResponse, IPostPhoneAuthResponse } from '@types';
import {
  getNonReservListRequest,
  getNonReservListSuccess,
  getNonReservListFailure,
  postPhoneAuthRequest,
  postPhoneAuthSuccess,
  postPhoneAuthFailure,
} from 'store/reducers/myReservations';
import { customHistory } from 'App';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

export function* postPhoneAuthSaga() {
  yield takeLatest(
    postPhoneAuthRequest.type,
    createFetchAction(
      postPhoneAuth,
      postPhoneAuthSuccess,
      postPhoneAuthFailure,
      null,
      failure,
    ),
  );
}

export function* getNonReservationListSaga() {
  yield takeLatest(
    getNonReservListRequest.type,
    createFetchAction(
      getNonReservList,
      getNonReservListSuccess,
      getNonReservListFailure,
      null,
      function* failure(err: IGetNonReservListResponse) {
        if (err instanceof Error) {
          alert('요청에 실패했습니다.');
          yield customHistory.replace('/mypage/non/reservation');
        } else {
          yield alert(err.message);
          if (err.needRedirect) {
            customHistory.replace('/');
          }
          customHistory.replace('/mypage/non/reservation');
        }
      },
    ),
  );
}
