import { getMyCarNumber, updateMyCarNumber } from 'api';
import { customHistory } from 'App';
import { takeLatest, all, takeEvery, put, select } from 'redux-saga/effects';
import { updateAlarmStatusFailure } from 'store/reducers/alarms';
import {
  getMyCarNumberFailure,
  getMyCarNumberRequest,
  getMyCarNumberSuccess,
  updateMyCarNumberRequest,
  updateMyCarNumberSuccess,
} from 'store/reducers/myCarNumber';
import { refreshMyInfoRequest } from 'store/reducers/myInfo';
import isChrome from 'utils/isChrome';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

function* getMyCarNumberSaga() {
  yield takeEvery(
    getMyCarNumberRequest.type,
    createFetchAction(
      getMyCarNumber,
      getMyCarNumberSuccess,
      getMyCarNumberFailure,
    ),
  );
}

function* updateMyCarNumberSaga() {
  yield takeLatest(
    updateMyCarNumberRequest.type,
    createFetchAction(
      updateMyCarNumber,
      updateMyCarNumberSuccess,
      updateAlarmStatusFailure,
      function* success() {
        yield all([
          customHistory.replace('/mypage/info/edit'),
          customHistory.goBack(),
        ]);

        if (!isChrome()) {
          yield put(refreshMyInfoRequest());
        }
      },
      failure,
    ),
  );
}

export default function* myCarNumberSaga() {
  yield all([getMyCarNumberSaga(), updateMyCarNumberSaga()]);
}
