import { all, takeEvery } from 'redux-saga/effects';
import { getShopbyProductSearch } from 'api/shopby';
import { createShopbyFetchActionWithoutRequestPayload } from 'store/sagas/createFetchAction';
import {
  getShopbyProductSearchFailure,
  getShopbyProductSearchRequest,
  getShopbyProductSearchSuccess,
} from './shopbyProductSearchReducer';

function* getShopbyProductSearchSaga() {
  yield takeEvery(
    getShopbyProductSearchRequest.type,
    createShopbyFetchActionWithoutRequestPayload(
      getShopbyProductSearch,
      getShopbyProductSearchSuccess,
      getShopbyProductSearchFailure,
    ),
  );
}
export default function* shopbyProductSearchSaga() {
  yield all([getShopbyProductSearchSaga()]);
}
