import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IFeedCampaign } from 'types/feed';

interface InitState {
  campaigns: IFeedCampaign[];
}

const initialState: InitState = {
  campaigns: [],
};

export const campaignSlice = createSlice({
  name: 'campaignSlice',
  initialState,
  reducers: {
    setCampaigns: (state, action: PayloadAction<IFeedCampaign[]>) => {
      state.campaigns = action.payload;
    },
  },
});

export const { setCampaigns } = campaignSlice.actions;

export default campaignSlice.reducer;
