import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IShopbyGetAccumulationsRequestPayload,
  IShopbyGetAccumulationsResponsePayload,
  IShopbyGetAccumulationsSummaryRequestPayload,
  IShopbyGetAccumulationsSummaryResponsePayload,
  ITShopbyErrorRes,
} from '@types';
import { AxiosError } from 'axios';
import { myPointListLnb } from 'constants/lnbConstants';
import { IShopbyPointState } from 'store/types';

const initialBaseState = {
  data: undefined,
  error: undefined,
};

const initialListState = {
  ...initialBaseState,
  paging: {
    pageNumber: 1,
    pageSize: 10,
  },
};

const initalWholeListState = {
  ALL: initialListState,
  ADD: initialListState,
  SUB: initialListState,
};

const initialState: IShopbyPointState = {
  activeTabKey: 'ALL',
  summary: initialBaseState,
  list: initalWholeListState,
};

export const shopbyPointSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setActiveTab: (
      state,
      action: PayloadAction<keyof typeof myPointListLnb>,
    ) => {
      const key = action.payload;
      state.activeTabKey = key;
    },
    getShopbyPointSummaryRequest: () => {},
    getShopbyPointCountSummarySuccess: (
      state,
      action: PayloadAction<{
        data: IShopbyGetAccumulationsSummaryResponsePayload;
      }>,
    ) => {
      state.summary.data = action.payload.data;
    },
    getShopbyPointCountSummaryError: (
      state,
      action: PayloadAction<any | Error | AxiosError | ITShopbyErrorRes>,
    ) => {
      state.summary.error = action.payload;
    },
    getShopbyPointListRequest: (
      state,
      action: PayloadAction<{
        key: keyof typeof myPointListLnb;
        payload: IShopbyGetAccumulationsRequestPayload;
      }>,
    ) => {},
    getShopbyPointListSuccess: (
      state,
      action: PayloadAction<{
        key: keyof typeof myPointListLnb;
        data: IShopbyGetAccumulationsResponsePayload;
        requestPayload: IShopbyGetAccumulationsRequestPayload;
      }>,
    ) => {
      const { key, data } = action.payload;
      if (state.list[key].data?.items) {
        state.list[key].data?.items.push(...data.items);
      } else {
        state.list[key].data = data;
      }
      state.list[key].paging.pageNumber += 1;
    },
    getShopbyPointListError: (
      state,
      action: PayloadAction<{
        key: keyof typeof myPointListLnb;
        error: any | ITShopbyErrorRes | AxiosError<ITShopbyErrorRes>;
      }>,
    ) => {
      const { key, error } = action.payload;
      state.list[key].error = error;
    },
    getShopbyPointListMore: (
      state,
      action: PayloadAction<{
        key: keyof typeof myPointListLnb;
        payload: IShopbyGetAccumulationsRequestPayload;
      }>,
    ) => {},
    resetShopbyPointWholeList: state => {
      state.list = initalWholeListState;
    },
  },
});

export const {
  setActiveTab,
  getShopbyPointSummaryRequest,
  getShopbyPointCountSummarySuccess,
  getShopbyPointCountSummaryError,

  getShopbyPointListRequest,
  getShopbyPointListSuccess,
  getShopbyPointListError,
  getShopbyPointListMore,
  resetShopbyPointWholeList,
} = shopbyPointSlice.actions;

export default shopbyPointSlice.reducer;
