import { takeEvery, all, takeLatest } from 'redux-saga/effects';
import {
  getAppPushStatusFailure,
  getAppPushStatusRequest,
  getAppPushStatusSuccess,
  toggleAppPushFailure,
  toggleAppPushRequest,
  toggleAppPushSuccess,
} from 'store/reducers/appPush';
import { getMyAppPushStatus, toggleAppPush } from 'api';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

function* getAppPushStatusSaga() {
  yield takeEvery(
    getAppPushStatusRequest.type,
    createFetchAction(
      getMyAppPushStatus,
      getAppPushStatusSuccess,
      getAppPushStatusFailure,
      undefined,
      failure,
    ),
  );
}

function* toggleAppPushSaga() {
  yield takeLatest(
    toggleAppPushRequest.type,
    createFetchAction(
      toggleAppPush,
      toggleAppPushSuccess,
      toggleAppPushFailure,
      undefined,
      failure,
    ),
  );
}

export default function* appPushSaga() {
  yield all([getAppPushStatusSaga(), toggleAppPushSaga()]);
}
