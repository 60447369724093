import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICheckProductResponse, IMyItem } from '@types';
import { IGetCamperItemsPayload, CamperItemsState } from 'store/types';

const initialState: CamperItemsState = {
  items: [],
  hasMore: true,
  offset: 0,
  error: '',
};

export const camperItemsSlice = createSlice({
  name: 'camperItems',
  initialState,
  reducers: {
    getCamperItemsRequest: (
      state,
      action: PayloadAction<IGetCamperItemsPayload>,
    ) => {
      action.payload.skip ? state.items : (state.items = []);
      state.error = '';
    },
    getCamperItemsSuccess: (state, action: PayloadAction<IMyItem[]>) => {
      state.items = state.items.concat(action.payload);
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },
    getCamperItemsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    addOffset: (state, action: PayloadAction<IGetCamperItemsPayload>) => {
      state.offset = action.payload.skip;
    },
  },
});

export const {
  getCamperItemsRequest,
  getCamperItemsSuccess,
  getCamperItemsFailure,
  addOffset,
} = camperItemsSlice.actions;

export default camperItemsSlice.reducer;
