import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, put, take, all, call } from 'redux-saga/effects';
import { getComments, getComment, getThreads } from 'api';
import {
  getCommentFailure,
  getCommentRequest,
  getCommentsFailure,
  getCommentsRequest,
  getCommentsSuccess,
  getCommentSuccess,
  getCommentsThreadsFailure,
  getCommentsThreadsRequest,
  getCommentsThreadsSuccess,
  getThreadsFailure,
  getThreadsRequest,
  getThreadsSuccess,
  showMoreThreads,
  sortComments,
  startFetchingComment,
  updateCommentTimestamp,
} from 'store/reducers/comments';
import {
  IGetThreadsRequestPayload,
  ISortCommentsPayload,
  IGetCommentsPayload,
} from 'store/types';
import { createFetchAction, fetchApi } from './createFetchAction';

function* fetchCommentSaga() {
  while (true) {
    const action: PayloadAction<string> = yield take(startFetchingComment.type);
    yield put(getCommentRequest(action.payload));
    yield call<any>(
      fetchApi,
      getComment,
      getCommentRequest.type,
      getCommentSuccess,
      getCommentFailure,
      action.payload,
    );

    yield put(
      getThreadsRequest({
        commentId: action.payload,
        timestamp: 0,
        limit: 10,
      }),
    );
  }
}

function* getCommentsSaga() {
  while (true) {
    const action: PayloadAction<IGetCommentsPayload> = yield take(
      updateCommentTimestamp.type,
    );

    const { boardId, timestamp, sort, limit } = action.payload;
    yield put(getCommentsRequest({ timestamp, boardId, sort, limit }));
  }
}

function* fetchComments() {
  yield takeEvery(
    getCommentsRequest.type,
    createFetchAction(getComments, getCommentsSuccess, getCommentsFailure),
  );
}

function* getCommentsThreadsSaga() {
  yield takeEvery(
    getCommentsThreadsRequest.type,
    createFetchAction(
      getThreads,
      getCommentsThreadsSuccess,
      getCommentsThreadsFailure,
    ),
  );
}

function* getThreadsSaga() {
  yield takeEvery(
    getThreadsRequest.type,
    createFetchAction(getThreads, getThreadsSuccess, getThreadsFailure),
  );
}

function* showThreadsSaga() {
  while (true) {
    const action: PayloadAction<IGetThreadsRequestPayload> = yield take(
      showMoreThreads.type,
    );
    const { commentId, timestamp, limit } = action.payload;
    const params = {
      commentId,
      timestamp,
      limit,
    };

    yield put(getCommentsThreadsRequest(params));
  }
}

function* sortCommentsSaga() {
  while (true) {
    const action: PayloadAction<ISortCommentsPayload> = yield take(
      sortComments.type,
    );
    const { id, sort } = action.payload;

    yield put(
      getCommentsRequest({
        boardId: id,
        timestamp: 0,
        limit: 10,
        sort,
      }),
    );
  }
}

export function* commentsSaga() {
  yield all([
    getCommentsSaga(),
    getCommentsThreadsSaga(),
    getThreadsSaga(),
    showThreadsSaga(),
    sortCommentsSaga(),
    fetchCommentSaga(),
    fetchComments(),
  ]);
}
