import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ISocialLoginSelfAuthenticationRequestPayload,
  ISocialLoginSelfAuthenticationResponsePayload,
  ISocialLoginSelfAuthenticationResultRequestPayload,
  ISocialLoginSelfAuthenticationResultResponsePayload,
  ISocialLoginSelfAuthenticationState,
} from 'store/types';

const initialState: ISocialLoginSelfAuthenticationState = {
  pageName: null,
  process: {
    data: { receiptId: null, name: null },
  },
  result: {
    data: { certificationId: null },
  },
  error: '',
};

export const socialLoginSelfAuthenticationSlice = createSlice({
  name: 'socialLogin',
  initialState,
  reducers: {
    // store 초기화
    resetSocialLoginSelfAuthentication: state => {
      state.pageName = null;
      state.process = {
        data: { receiptId: null, name: null },
      };
      state.result = {
        data: { certificationId: null },
      };
    },

    // 부트페이 본인인증 요청
    socialLoginSelfAuthenticationRequest: (
      state,
      action: PayloadAction<ISocialLoginSelfAuthenticationRequestPayload>,
    ) => {
      state.pageName = action.payload.pageName;
      state.process = {
        data: { receiptId: null, name: null },
      };
      state.result = {
        data: { certificationId: null },
      };
    },
    // 본인인증 성공하면 callback으로 넘겨 dispatch
    socialLoginSelfAuthenticationSuccess: (
      state,
      action: PayloadAction<ISocialLoginSelfAuthenticationResponsePayload>,
    ) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { receipt_id, name } = action.payload;

      state.process.data = {
        receiptId: receipt_id,
        name,
      };
    },
    // 본인인증 실패하면 callback으로 넘겨 dispatch
    socialLoginSelfAuthenticationFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;

      state.pageName = null;
      state.process = {
        data: { receiptId: null, name: null },
      };
      state.result = {
        data: { certificationId: null },
      };
    },

    // 본인인증 결과 백엔드 전달
    socialLoginSelfAuthenticationResultRequest: (
      state,
      action: PayloadAction<ISocialLoginSelfAuthenticationResultRequestPayload>,
    ) => {
      state.result = {
        data: { certificationId: null },
      };
    },
    socialLoginSelfAuthenticationResultSuccess: (
      state,
      action: PayloadAction<ISocialLoginSelfAuthenticationResultResponsePayload>,
    ) => {
      if (action.payload.certificationId) {
        state.result.data.certificationId = action.payload.certificationId;
      }
    },
    socialLoginSelfAuthenticationResultFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;

      state.pageName = null;
      state.process = {
        data: { receiptId: null, name: null },
      };
      state.result = {
        data: { certificationId: null },
      };
    },
  },
});

export const {
  resetSocialLoginSelfAuthentication,

  socialLoginSelfAuthenticationRequest,
  socialLoginSelfAuthenticationSuccess,
  socialLoginSelfAuthenticationFailure,

  socialLoginSelfAuthenticationResultRequest,
  socialLoginSelfAuthenticationResultSuccess,
  socialLoginSelfAuthenticationResultFailure,
} = socialLoginSelfAuthenticationSlice.actions;

export default socialLoginSelfAuthenticationSlice.reducer;
