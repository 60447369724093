import {
  takeLatest,
  take,
  call,
  put,
  select,
  all,
  takeEvery,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  // bookFailure,
  // bookRequest,
  // bookSuccess,
  // checkPaymentFailure,
  // checkPaymentRequest,
  // checkPaymentSuccess,
  paymentsConfirmFailure,
  paymentsConfirmRequest,
  paymentsConfirmSuccess,
  // startBooking,
} from 'store/reducers/payment';
import { book, checkServerStatus, checkPayment, paymentsConfirm } from 'api';
import { IBookResponse, IResponse, ICheckPaymentResponse } from '@types';
// import { IStartBookingPayload } from 'store/types';
// import { RootState } from 'store/reducers';
import sendGtmDataLayer from 'utils/sendGtmDataLayer';
import { createFetchAction } from './createFetchAction';
import { customHistory } from '../../App';

const BootPay = require('bootpay-js');

const isLoggedIn = localStorage.getItem('jwt');

// function* checkPaymentSaga() {
//   yield takeEvery(
//     checkPaymentRequest.type,
//     createFetchAction(
//       checkPayment,
//       checkPaymentSuccess,
//       checkPaymentFailure,
//       function* success(res: ICheckPaymentResponse) {
//         sendGtmDataLayer({
//           event: 'reservation_complete',
//           payment_type: 'card',
//         });
//         const token = isLoggedIn ? '?isNew=1' : `?token=${res.token}&isNew=1`;
//         yield customHistory.push(
//           `/reservation/result/${res.bookingId}${token}`,
//         );
//       },
//     ),
//   );
// }

// function processPayment(paymentId: string, amount: number) {
//   BootPay.default
//     .request({
//       price: amount,
//       application_id: process.env.REACT_APP_BOOTPAY_APPLICATION_ID as string,
//       name: '캠핏 캠핑장 예약',
//       pg: '',
//       method: '',
//       show_agree_window: 0,
//       order_id: paymentId,
//       params: { paymentId },
//     })
//     .error((e: { action: string; message: string; receipt_id: string }) => {
//       console.log(e);
//       customHistory.push(
//         `/reservation/payment/loading?paymentId=${BootPay.default.params.order_id}&receiptId=disabled&message=${e.message}&action=${e.action}`,
//       );
//     })
//     .cancel((data: any) => {
//       console.log(data);
//       customHistory.push(
//         `/reservation/payment/loading?paymentId=${BootPay.default.params.order_id}&receiptId=cancelled`,
//       );
//     })
//     .ready((data: any) => {
//       console.log(data);
//     })
//     .confirm((data: any) => {
//       console.log(data);
//       const enable = true;
//       if (enable) {
//         BootPay.default.transactionConfirm(data);
//       } else {
//         BootPay.default.removePaymentWindow();
//       }
//     })
//     .close((data: any) => {
//       console.log(data);
//     })
//     .done((data: any) => {
//       console.log(data);
//       window.location.href = `/reservation/payment/loading?paymentId=${data.order_id}&receiptId=${data.receipt_id}`;
//     });
// }

// function* bookSaga() {
// yield takeLatest(
//   bookRequest.type,
//   createFetchAction(
//     book,
//     bookSuccess,
//     bookFailure,
//     function* success(result: IBookResponse) {
//       if (result.needOnlinePayment) {
//         yield processPayment(result.payment, result.onBookingCharge);
//       } else {
//         sendGtmDataLayer({
//           event: 'reservation_complete',
//           payment_type: 'bank',
//         });
//         const token = localStorage.getItem('jwt')
//           ? '?isNew=1'
//           : `?token=${result.token}&isNew=1`;
//         customHistory.push(`/reservation/result/${result.bookingId}${token}`);
//       }
//     },
//     function* fail(result: IBookResponse) {
//       if (result.message) {
//         if (result.isBlacklist) {
//           // customHistory.push(`/reservation/payment/loading?isUnavailable=1`);
//         } else {
//           yield alert(result.message);
//         }
//       } else {
//         alert('예약에 실패했습니다.');
//       }
//     },
//   ),
// );
// }

// function* bookCampSaga() {
// while (true) {
//   const action: PayloadAction<IStartBookingPayload> = yield take(
//     startBooking.type,
//   );
//   const { name, contact, request, paymentMethod, coupon, couponDiscount } =
//     action.payload;
//   try {
//     const res: IResponse = yield call(checkServerStatus);
//     if (res.status === 'fail') {
//       alert(res.message);
//       return;
//     }
//     const {
//       site,
//       date,
//       peopleCnt,
//       carInfo,
//       selectedServices: option,
//       bookingDetail,
//       petInfo,
//     } = yield select((state: RootState) => state.reservationReducer);
//     const { start, end } = date;
//     const { adultCnt, teenCnt, childCnt } = peopleCnt;
//     const { carCnt, carNumbers, hasTrailer, hasCampingCar } = carInfo;
//     yield put(
//       bookRequest({
//         siteId: site.id,
//         checkInDate: {
//           year: start.getFullYear(),
//           month: start.getMonth() + 1,
//           day: start.getDate(),
//         },
//         checkoutDate: {
//           year: end.getFullYear(),
//           month: end.getMonth() + 1,
//           day: end.getDate(),
//         },
//         numOfAdults: adultCnt,
//         numOfTeens: teenCnt,
//         numOfChildren: childCnt,
//         numOfCars: carCnt,
//         services: option,
//         name,
//         contact,
//         request,
//         paymentMethod,
//         coupon,
//         couponDiscount,
//         campingPass: null,
//         carNumbers,
//         accommodationPrice: bookingDetail.accommodationCharge || 0,
//         parkingPrice: bookingDetail.extraCarCharge || 0,
//         servicePrice: bookingDetail.servicePrice || 0,
//         hasTrailer,
//         hasCampingCar,
//         petCharge: bookingDetail.extraPetCharge,
//         pets: petInfo,
//       }),
//     );
//   } catch (e) {
//     yield console.log(e);
//   }
// }
// }

function* paymentsConfirmSaga() {
  yield takeLatest(
    paymentsConfirmRequest.type,
    createFetchAction(
      paymentsConfirm,
      paymentsConfirmSuccess,
      paymentsConfirmFailure,
      function* success(res: {
        status: string;
        result: {
          bookingId: string;
          token: string;
        };
      }) {
        const { bookingId, token: bookingHash } = res.result;

        sendGtmDataLayer({
          event: 'reservation_complete',
          payment_type: 'card',
        });

        const token = isLoggedIn ? '?isNew=1' : `?token=${bookingHash}&isNew=1`;

        yield customHistory.replace(`/reservation/result/${bookingId}${token}`);
      },
      function* fail(res: any) {
        const { result, code } = res;
        const { bookingId, token } = result;

        let query = `/reservation/payment/fail?bookingId=${bookingId}&token=${token}`;

        if (code) {
          query += `&code=${code}`;
        }

        yield customHistory.push(query);
      },
    ),
  );
}

export default function* paymentSaga() {
  yield all([
    // bookCampSaga(), bookSaga(),
    // checkPaymentSaga(),
    paymentsConfirmSaga(),
  ]);
}
