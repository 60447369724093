import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ArticlesState,
  IGetArticlesRequestPayload,
  IGetArticleCardsRequestPayload,
  IAddContentsOffsetPayload,
  IAddArticlePagePayload,
} from 'store/types';
import {
  ISingleArticle,
  IArticle,
  IArticleContentCard,
  TArticleType,
} from '@types';

const initialState: ArticlesState = {
  articles: [],
  articlesCnt: 0,
  article: {} as ISingleArticle,
  articleType: '',
  mainArticle: {} as IArticle,
  articleContents: [],
  articlePage: 1,
  articleContentsOffset: 0,
  hasMoreArticles: true,
  hasMoreArticleContents: true,
  error: '',
};

export const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    getArticlesRequest: (
      state,
      action: PayloadAction<IGetArticlesRequestPayload>,
    ) => {
      action.payload.page > 1 ? state.articles : (state.articles = []);
      state.articlePage = action.payload.page;
      state.error = '';
    },
    getArticlesSuccess: (state, action: PayloadAction<IArticle[]>) => {
      state.articles = state.articles.concat(action.payload);
      state.hasMoreArticles = action.payload.length === 10;
      state.error = '';
    },
    getArticlesFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    getSingleArticleRequest: (state, action: PayloadAction<string>) => {
      state.article = {} as ISingleArticle;
      state.error = '';
    },
    getSingleArticleSuccess: (state, action: PayloadAction<ISingleArticle>) => {
      state.article = action.payload;
      state.error = '';
    },
    getSingleArticleFailure: (state, action: PayloadAction<Error | string>) => {
      state.article = {} as ISingleArticle;
      state.error = action.payload;
    },

    addArticlePage: (state, action: PayloadAction<IAddArticlePagePayload>) => {
      state.articlePage = action.payload.page;
    },

    getArticlesCntRequest: (state, action: PayloadAction<TArticleType>) => {
      state.articlesCnt = 0;
      state.error = '';
    },
    getArticlesCntSuccess: (state, action: PayloadAction<number>) => {
      state.articlesCnt = action.payload;
      state.error = '';
    },
    getArticlesCntFailure: (state, action) => {
      state.articlesCnt = 0;
      state.error = '';
    },

    getArticleContentsRequest: (
      state,
      action: PayloadAction<IGetArticleCardsRequestPayload>,
    ) => {
      action.payload.skip
        ? state.articleContents
        : (state.articleContents = []);
      state.articleContentsOffset = action.payload.skip;
      state.error = '';
    },
    getArticleContentsSuccess: (
      state,
      action: PayloadAction<IArticleContentCard[]>,
    ) => {
      state.articleContents = state.articleContents.concat(action.payload);
      state.hasMoreArticleContents = action.payload.length === 10;
      state.error = '';
    },
    getArticleContentsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    addContentsOffset: (
      state,
      action: PayloadAction<IAddContentsOffsetPayload>,
    ) => {
      state.articleContentsOffset = action.payload.skip;
    },

    selectArticleType: (state, action: PayloadAction<TArticleType>) => {
      state.articleType = action.payload;
    },
  },
});

export const {
  getArticlesRequest,
  getArticlesSuccess,
  getArticlesFailure,
  getArticlesCntRequest,
  getArticlesCntSuccess,
  getArticlesCntFailure,
  getSingleArticleRequest,
  getSingleArticleSuccess,
  getSingleArticleFailure,
  getArticleContentsRequest,
  getArticleContentsSuccess,
  getArticleContentsFailure,
  addContentsOffset,
  addArticlePage,
  selectArticleType,
} = articlesSlice.actions;

export default articlesSlice.reducer;
