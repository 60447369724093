import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IHostsAndSuppliersState,
  IHostsAndSuppliersRequestPayload,
  THostsAndSuppliers,
} from 'store/types';

const initialState: IHostsAndSuppliersState = {
  name: undefined,
  dataList: [],
  paging: {
    page: 1,
    pageSize: 20,
    totalCount: 0,
  },
  error: '',
};

export const HostsAndSuppliersSlice = createSlice({
  name: 'HostsAndSuppliers',
  initialState,
  reducers: {
    getHostsAndSuppliersRequest: (
      state,
      action: PayloadAction<IHostsAndSuppliersRequestPayload>,
    ) => {
      state.name = action.payload.name;
      state.paging.page = action.payload.paging.page || state.paging.page;
      state.paging.pageSize =
        action.payload.paging.pageSize || state.paging.pageSize;
    },

    getHostsAndSuppliersSuccess: (state, action: PayloadAction<string[]>) => {
      state.dataList = action.payload;
    },

    getHostsAndSuppliersFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    setPage: (state, action: PayloadAction<{ page: number }>) => {
      state.paging.page = action.payload.page;
    },

    getHostsAndSuppliersCountRequest: (
      state,
      action: PayloadAction<THostsAndSuppliers>,
    ) => {
      state.name = action.payload;
      state.dataList = [];
    },

    getHostsAndSuppliersCountSuccess: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.paging.totalCount = action.payload;
      state.paging.page = 1;
    },

    getHostsAndSuppliersCountFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
  },
});

export const {
  getHostsAndSuppliersRequest,
  getHostsAndSuppliersSuccess,
  getHostsAndSuppliersFailure,
  setPage,
  getHostsAndSuppliersCountRequest,
  getHostsAndSuppliersCountSuccess,
  getHostsAndSuppliersCountFailure,
} = HostsAndSuppliersSlice.actions;

export default HostsAndSuppliersSlice.reducer;
