import styled from 'styled-components';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface INewToast {
  containerId?: string;
}

function NewToast({ containerId }: INewToast) {
  const closeMillis = 2000;

  return (
    <Toast
      containerId={containerId}
      position="bottom-center"
      autoClose={closeMillis}
      hideProgressBar
      closeOnClick
      closeButton={false}
      icon={false}
      newestOnTop
      rtl={false}
      pauseOnHover
      limit={1}
      transition={Slide}
    />
  );
}

export default NewToast;

const Toast = styled(ToastContainer)<{ width?: number }>`
  &&&.Toastify__toast-container {
    padding: 0px;

    left: calc(50vw);

    @media screen and (max-width: 960px) {
      left: 50vw;
      transform: translate(-50%);
    }
  }

  &&&.Toastify__toast-container--top-center {
    top: ${({ theme }) => `calc(${theme.header.heightPx} + 16px)`};
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &&&.Toastify__toast-container--bottom-center {
    bottom: 36px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .Toastify__toast {
    min-height: 42px;
    margin-bottom: 0px;
    padding: 12px 16px 12px 16px;
    border-radius: 8px;
    width: calc(100% - 32px);
    max-width: 498px;
  }
  .Toastify__toast-body {
    margin: 0px;
    padding: 0px;
    color: ${({ theme }) => theme.newColors.white};
    font-size: ${({ theme }) => theme.fontSizes.c12};
    font-weight: 400;
    text-align: center;
  }
  .Toastify__toast-theme--light {
    background-color: rgba(14, 14, 14, 0.8);
  }
`;
