import { all, takeEvery } from 'redux-saga/effects';
import {
  getShopbyMyReviewableList,
  getShopbyMyReviewedList,
  getShopbyReviewDetail,
  postShopbyMyItemReview,
  updateShopbyMyItemReview,
} from 'api/shopby';
import {
  getShopbyMyReviewDetailFailure,
  getShopbyMyReviewDetailRequest,
  getShopbyMyReviewDetailSuccess,
  getShopbyMyReviewListFailure,
  getShopbyMyReviewListMoreRequest,
  getShopbyMyReviewListRequest,
  getShopbyMyReviewListSuccess,
  getShopbyReviewableItemListFailure,
  getShopbyReviewableItemListMoreRequest,
  getShopbyReviewableItemListRequest,
  getShopbyReviewableItemListsuccess,
  postShopbyReviewableItemFailure,
  postShopbyReviewableItemRequest,
  postShopbyReviewableItemSuccess,
  updateShopbyMyReviewFailure,
  updateShopbyMyReviewRequest,
  updateShopbyMyReviewSuccess,
} from 'store/reducers/shopbyMyReviewList';
import { createShopbyFetchAction } from 'store/sagas/createFetchAction';
import { customHistory } from 'App';
import {
  IPostShopbyReviewableItemRequestPayload,
  IPostShopbyReviewableItemResponsePayload,
  IUpdateShopbyMyReviewRequestPayload,
} from 'store/types';

function* getShopbyMyReviewableListSaga() {
  yield takeEvery(
    [
      getShopbyReviewableItemListRequest.type,
      getShopbyReviewableItemListMoreRequest.type,
    ],
    createShopbyFetchAction(
      getShopbyMyReviewableList,
      getShopbyReviewableItemListsuccess,
      getShopbyReviewableItemListFailure,
    ),
  );
}

function* postShopbyMyItemReviewSaga() {
  yield takeEvery(
    postShopbyReviewableItemRequest.type,
    createShopbyFetchAction(
      postShopbyMyItemReview,
      postShopbyReviewableItemSuccess,
      postShopbyReviewableItemFailure,
      undefined,
      function* onSuccess(
        data: IPostShopbyReviewableItemResponsePayload,
        payload: IPostShopbyReviewableItemRequestPayload,
      ) {
        const { productNo, urls } = payload;
        const { reviewNo } = data;

        const isPhotoReview = !!urls.length;

        yield customHistory.replace(
          `/mypage/store/review/edit/success/${productNo}/${reviewNo}`,
          {
            isPhotoReview,
          },
        );
      },
    ),
  );
}
function* getShopbyMyReviewedListSaga() {
  yield takeEvery(
    [getShopbyMyReviewListRequest.type, getShopbyMyReviewListMoreRequest.type],
    createShopbyFetchAction(
      getShopbyMyReviewedList,
      getShopbyMyReviewListSuccess,
      getShopbyMyReviewListFailure,
    ),
  );
}

function* getShopbyMyItemRevewDetailSaga() {
  yield takeEvery(
    getShopbyMyReviewDetailRequest.type,
    createShopbyFetchAction(
      getShopbyReviewDetail,
      getShopbyMyReviewDetailSuccess,
      getShopbyMyReviewDetailFailure,
    ),
  );
}

function* updateShopbyMyReviewSaga() {
  yield takeEvery(
    updateShopbyMyReviewRequest.type,
    createShopbyFetchAction(
      updateShopbyMyItemReview,
      updateShopbyMyReviewSuccess,
      updateShopbyMyReviewFailure,
      undefined,
      function* onSuccess(
        data: any,
        payload: IUpdateShopbyMyReviewRequestPayload,
      ) {
        yield window.sessionStorage.setItem('updateMyReview', 'success');
        const { productNo, reviewNo } = payload;
        yield customHistory.replace(
          `/mypage/store/review/detail/${productNo}/${reviewNo}`,
        );
      },
    ),
  );
}

export default function* shopbyMyItemReviewSaga() {
  yield all([
    getShopbyMyReviewableListSaga(),
    postShopbyMyItemReviewSaga(),
    getShopbyMyReviewedListSaga(),
    getShopbyMyItemRevewDetailSaga(),
    updateShopbyMyReviewSaga(),
  ]);
}
