import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IResponse, IUser } from '@types';
import {
  CommnunityBlockState,
  IBlockPostRequestPayload,
  IBlockUserRequestPayload,
  IGetArrayPayload,
} from 'store/types';

const initialState: CommnunityBlockState = {
  blockedUsers: [],
  blockedUsersCnt: 0,
  offset: 0,
  hasMore: true,
  error: '',
};

export const communityBlockSlice = createSlice({
  name: 'communityBlock',
  initialState,
  reducers: {
    blockPostRequest: (
      state,
      action: PayloadAction<IBlockPostRequestPayload>,
    ) => {
      state.error = '';
    },
    blockPostSuccess: (state, action: PayloadAction<IResponse>) => {
      state.error = '';
    },
    blockPostFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    blockUserRequest: (
      state,
      action: PayloadAction<IBlockUserRequestPayload>,
    ) => {
      state.error = '';
    },
    blockUserSuccess: (state, action: PayloadAction<IResponse>) => {
      state.error = '';
    },
    blockUserFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    getBlockedUsersRequest: (
      state,
      action: PayloadAction<IGetArrayPayload>,
    ) => {
      state.blockedUsers = action.payload.skip ? state.blockedUsers : [];
      state.offset = action.payload.skip ? state.offset : 0;
      state.error = '';
    },
    getBlockedUsersSuccess: (state, action: PayloadAction<IUser[]>) => {
      state.blockedUsers = state.blockedUsers.concat(action.payload);
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },
    getBlockedUsersFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    getBlockedUsersCntRequest: state => {
      state.blockedUsersCnt = 0;
      state.error = '';
    },
    getBlockedUsersCntSuccess: (state, action: PayloadAction<number>) => {
      state.blockedUsersCnt = action.payload;
      state.error = '';
    },
    getBlockedUsersCntFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.blockedUsersCnt = 0;
      state.error = action.payload;
    },

    addOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
  },
});

export const {
  blockPostRequest,
  blockPostSuccess,
  blockPostFailure,
  blockUserRequest,
  blockUserSuccess,
  blockUserFailure,
  getBlockedUsersRequest,
  getBlockedUsersSuccess,
  getBlockedUsersFailure,
  getBlockedUsersCntRequest,
  getBlockedUsersCntSuccess,
  getBlockedUsersCntFailure,
  addOffset,
} = communityBlockSlice.actions;

export default communityBlockSlice.reducer;
