import { differenceInMilliseconds, formatDuration } from 'date-fns';

export type TDuration = {
  hours: number;
  minutes: number;
  seconds: number;
};

export type TDurationString = {
  hours: string;
  minutes: string;
  seconds: string;
};

export const getNumberTimeToString = (time: number) => {
  return String(time).padStart(2, '0');
};

export const calculateDiffTime = (
  startDate: Date | number,
  endDate: Date | number,
): number => {
  return differenceInMilliseconds(endDate, startDate);
};

export const convertToMilliseconds = (timeString: string): number => {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);

  const hoursInMilliseconds = hours * 60 * 60 * 1000;
  const minutesInMilliseconds = minutes * 60 * 1000;
  const secondsInMilliseconds = seconds * 1000;

  return hoursInMilliseconds + minutesInMilliseconds + secondsInMilliseconds;
};
export const convertMillisecondsToDuration = (
  timeMilliSeconds: number,
): TDuration => {
  return {
    hours: Math.floor(timeMilliSeconds / 3600000),
    minutes: Math.floor((timeMilliSeconds % 3600000) / 60000),
    seconds: Math.floor(((timeMilliSeconds % 3600000) % 60000) / 1000),
  };
};

export const convertMillisecondsToDurationString = (
  timeMilliSeconds: number,
): TDurationString => {
  const duration = convertMillisecondsToDuration(timeMilliSeconds);
  return {
    hours: getNumberTimeToString(duration.hours),
    minutes: getNumberTimeToString(duration.minutes),
    seconds: getNumberTimeToString(duration.seconds),
  };
};

export const calculateDiffTimeToEach = (
  startDate: Date | number,
  endDate: Date | number,
): TDuration => {
  const timeDiff = differenceInMilliseconds(endDate, startDate);

  return convertMillisecondsToDuration(timeDiff);
};

export const calculateDiffTimeToString = (
  startDate: Date | number,
  endDate: Date | number,
): string => {
  return formatDuration(calculateDiffTimeToEach(startDate, endDate));
};

export const ONEDAY_MILLISECONDS = 24 * 60 * 60 * 1000;
export const ONEHOUR_MILLISECONDS = 60 * 60 * 1000;
