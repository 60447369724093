import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AutoCompleteState } from 'store/types';

const initialState: AutoCompleteState = {
  words: [],
  camps: [],
  error: '',
};

export const autoCompleteSlice = createSlice({
  name: 'autoComplete',
  initialState,
  reducers: {
    autoCompleteRequest: (state, action: PayloadAction<string>) => {
      state.error = '';
    },
    campsAutoCompleteRequest: (state, action: PayloadAction<string>) => {
      state.error = '';
    },
    autoCompleteSuccess: (state, action: PayloadAction<string[]>) => {
      state.words = action.payload;
      state.error = '';
    },
    autoCompleteFailure: (state, action: PayloadAction<Error | string>) => {
      state.words = [];
      state.error = action.payload;
    },
    resetWords: state => {
      state.words = [];
    },
    campAutoCompleteRequest: (state, action: PayloadAction<string>) => {
      state.error = '';
    },
    campAutoCompleteSuccess: (state, action: PayloadAction<string[]>) => {
      state.camps = action.payload;
      state.error = '';
    },
    campAutoCompleteFailure: (state, action: PayloadAction<Error | string>) => {
      state.camps = [];
      state.error = action.payload;
    },
    resetCamps: state => {
      state.camps = [];
    },
  },
});

export const {
  campsAutoCompleteRequest,
  autoCompleteRequest,
  autoCompleteSuccess,
  autoCompleteFailure,
  resetWords,
  campAutoCompleteRequest,
  campAutoCompleteSuccess,
  campAutoCompleteFailure,
  resetCamps,
} = autoCompleteSlice.actions;

export default autoCompleteSlice.reducer;
