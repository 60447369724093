import { all, put, take, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  addPageNumber,
  getShopbySearchZipCodeFailure,
  getShopbySearchZipCodeRequest,
  getShopbySearchZipCodeSuccess,
} from 'store/reducers/shopbySearchZipCode';
import { createShopbyFetchAction } from 'store/sagas/createFetchAction';
import { getShopbySearchZipCode } from 'api/shopby';
import { IShopbyZipCodeRequestPayload } from '@types';

function* getShopbySearchZipCodeSaga() {
  yield takeEvery(
    getShopbySearchZipCodeRequest.type,
    createShopbyFetchAction(
      getShopbySearchZipCode,
      getShopbySearchZipCodeSuccess,
      getShopbySearchZipCodeFailure,
      undefined,
      undefined,
      undefined,
    ),
  );
}

function* getShopbySearchZipCodeMoewSaga() {
  while (true) {
    const action: PayloadAction<IShopbyZipCodeRequestPayload> = yield take(
      addPageNumber.type,
    );

    yield put(getShopbySearchZipCodeRequest(action.payload));
  }
}

export default function* shopbySearchZipCodeSaga() {
  yield all([getShopbySearchZipCodeSaga(), getShopbySearchZipCodeMoewSaga()]);
}
