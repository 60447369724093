import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, all, take, select, put } from 'redux-saga/effects';
import { getZoneReservationStatus } from 'api';
import {
  getZoneReservationStatusRequest,
  getZoneReservationStatusSuccess,
  getZoneReservationStatusFailure,
  updateMonth,
} from 'store/reducers/zoneReservationStatus';
import { RootState } from 'store/reducers';
import { IUpdateMonthPayload } from 'store/types';
import { IZoneReservationStatus } from '@types';
import { createFetchAction } from './createFetchAction';
import { customHistory } from '../../App';

function* checkMonth() {
  while (true) {
    const action: PayloadAction<IUpdateMonthPayload> = yield take(
      updateMonth.type,
    );
    const { zoneId, date } = action.payload;

    yield put(getZoneReservationStatusRequest({ zoneId, ...date }));
  }
}

function* fetchZoneReservationStatus() {
  yield takeEvery(
    getZoneReservationStatusRequest.type,
    createFetchAction(
      getZoneReservationStatus,
      getZoneReservationStatusSuccess,
      getZoneReservationStatusFailure,
      undefined,
      // function* success(res: IZoneReservationStatus) {
      //   const { date, isNextMonth } = yield select(
      //     (state: RootState) => state.zoneReservationStatusReducer,
      //   );

      //   if (res.data.length === 0) {
      //     window.confirm(
      //       `${date.year}년 ${date.month}월은 모든 사이트가 예약 불가능합니다. 다음달을 보시겠습니까?`,
      //     );

      //     const newDate = isNextMonth
      //       ? date.month === 1
      //         ? { year: date.year - 1, month: 12 }
      //         : { year: date.year, month: date.month - 1 }
      //       : date.month === 12
      //       ? { year: date.year + 1, month: 1 }
      //       : { year: date.year, month: date.month + 1 };

      //     const zoneId = customHistory.location.pathname.split('/')[2];

      //     yield put(updateMonth({ zoneId, date: newDate }));
      //   }
      // },
      function* fail(error: Error | string) {
        yield alert(error || '캘린더를 불러올 수 없습니다');
      },
    ),
  );
}

export default function* getZoneReservationStatusSaga() {
  yield all([checkMonth(), fetchZoneReservationStatus()]);
}
