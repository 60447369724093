import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, all, take, put, select } from 'redux-saga/effects';
import {
  addContentsOffset,
  getArticleContentsFailure,
  getArticleContentsRequest,
  getArticleContentsSuccess,
  getArticlesFailure,
  getArticlesRequest,
  getArticlesSuccess,
  getSingleArticleFailure,
  getSingleArticleRequest,
  getSingleArticleSuccess,
  addArticlePage,
  getArticlesCntRequest,
  getArticlesCntSuccess,
  getArticlesCntFailure,
  selectArticleType,
} from 'store/reducers/articles';
import {
  getArticles,
  getSingleArticle,
  getArticleContents,
  getArticlesCount,
  getMainMagazine,
} from 'api';
import { IAddContentsOffsetPayload, IAddArticlePagePayload } from 'store/types';
import { RootState } from 'store/reducers';
import { TArticleType } from '@types';
import { createFetchAction } from './createFetchAction';

function* getArticlesSaga() {
  yield takeEvery(
    getArticlesRequest.type,
    createFetchAction(getArticles, getArticlesSuccess, getArticlesFailure),
  );
}

function* getMoreArticlesSaga() {
  while (true) {
    const action: PayloadAction<IAddArticlePagePayload> = yield take(
      addArticlePage.type,
    );

    yield put(getArticlesRequest(action.payload));
  }
}

function* getArticlesCntSaga() {
  yield takeEvery(
    getArticlesCntRequest.type,
    createFetchAction(
      getArticlesCount,
      getArticlesCntSuccess,
      getArticlesCntFailure,
    ),
  );
}

function* getSingleArticleSaga() {
  yield takeEvery(
    getSingleArticleRequest.type,
    createFetchAction(
      getSingleArticle,
      getSingleArticleSuccess,
      getSingleArticleFailure,
    ),
  );
}

function* getArticleContentsSaga() {
  yield takeEvery(
    getArticleContentsRequest.type,
    createFetchAction(
      getArticleContents,
      getArticleContentsSuccess,
      getArticleContentsFailure,
    ),
  );
}

function* getMoreArticleContentsSaga() {
  while (true) {
    const action: PayloadAction<IAddContentsOffsetPayload> = yield take(
      addContentsOffset.type,
    );

    const { id, skip, limit } = action.payload;

    yield put(
      getArticleContentsRequest({
        parentType: 'article',
        parentId: id,
        skip,
        limit,
      }),
    );
  }
}

// articleType 에 따라 아티클을 받아오는 saga입니다. / ywroh / 2022.03.02
function* getArticleListSaga() {
  while (true) {
    const action: PayloadAction<string> = yield take(selectArticleType.type);

    const articleType: TArticleType = yield select(
      (state: RootState) => state.articlesReducer.articleType,
    );

    yield put(
      getArticlesRequest({
        articleType,
        page: 1,
        pageSize: 10,
      }),
    );
  }
}

export default function* articlesSaga() {
  yield all([
    getArticlesSaga(),
    getSingleArticleSaga(),
    getArticleContentsSaga(),
    getMoreArticleContentsSaga(),
    getMoreArticlesSaga(),
    getArticlesCntSaga(),
    getArticleListSaga(),
  ]);
}
