import { all, takeLatest } from 'redux-saga/effects';
import {
  searchGeoRequest,
  searchGeoSuccess,
  searchGeoFailure,
} from 'store/reducers/searchGeo';
import { createFetchAction } from 'store/sagas/createFetchAction';
import { searchGeo } from 'api';
import { toast } from 'react-toastify';

const queryString = require('query-string');

// function* getMoreGeoCamps() {
//   while (true) {
//     const parsed = queryString.parse(window.location.search);

//     yield put(
//       searchGeoRequest({
//         search: parsed.camp,
//         city: parsed.city,
//         majors: parsed.majors,
//         types: parsed.types,
//         leisureTypes: parsed.leisureTypes,
//         facilities: parsed.facilities,
//         locationTypes: parsed.locationTypes,
//         additionalFacilities: parsed.additionalFacilities,
//         floorTypes: parsed.floorTypes,
//         parkingTypes: parsed.parkingTypes,
//         minBookableDays: parsed.minBookableDays,
//         checkInTimestamp: parsed.checkInTimestamp,
//         checkoutTimestamp: parsed.checkoutTimestamp,
//         longitude: parsed.longitude,
//         latitude: parsed.latitude,
//         maxDistance: parsed.maxDistance,
//         page: parsed.page,
//         sort: parsed.sort,
//       }),
//     );
//   }
// }

export default function* searchGeoCampSaga() {
  yield all([
    takeLatest(
      searchGeoRequest.type,
      createFetchAction(
        searchGeo,
        searchGeoSuccess,
        searchGeoFailure,
        function* success(data: any, payload: any) {
          if (data.camps.length === 0)
            yield toast.success('검색결과가 없습니다 다시 검색해주세요!');
        },
      ),
    ),
  ]);
}
