import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, all, take, put, takeLatest } from 'redux-saga/effects';
import {
  addOffset,
  registerProductFailure,
  registerProductRequest,
  registerProductSuccess,
  searchProductsBriefFailure,
  searchProductsBriefRequest,
  searchProductsBriefSuccess,
  selectProduct,
  resetRegisterProductResult,
} from 'store/reducers/productPick';
import { searchProductsBrief, registerProduct } from 'api';
import { ISearchProductsBriefRqPayload } from 'store/types';
import { IRegisterProductResponse } from '@types';
import { showToast } from 'store/reducers/toast';
import { createFetchAction } from './createFetchAction';
import { customHistory } from '../../App';
import { failure } from './failure';

function* registerProductSaga() {
  yield takeLatest(
    registerProductRequest.type,
    createFetchAction(
      registerProduct,
      registerProductSuccess,
      registerProductFailure,
      function* success(data: IRegisterProductResponse) {
        const { id, createrInfo, thumbnails } = data.product;

        if (customHistory.location.pathname.includes('product')) {
          // 장비 홈에서만
          yield put(showToast('요청되었습니다'));
        } else {
          yield put(
            selectProduct({
              id,
              brand: createrInfo.brand,
              name: createrInfo.name,
              thumbnail: thumbnails[0],
            }),
          );
        }

        // NOTE: 상품 직접 등록 모달창 닫기
        yield put(resetRegisterProductResult());
      },
      failure,
    ),
  );
}

function* searchProductsBriefSaga() {
  yield takeLatest(
    searchProductsBriefRequest.type,
    createFetchAction(
      searchProductsBrief,
      searchProductsBriefSuccess,
      searchProductsBriefFailure,
    ),
  );
}

function* getMoreProductsSaga() {
  while (true) {
    const action: PayloadAction<ISearchProductsBriefRqPayload> = yield take(
      addOffset.type,
    );

    yield put(searchProductsBriefRequest(action.payload));
  }
}

export default function* productPickSaga() {
  yield all([
    searchProductsBriefSaga(),
    getMoreProductsSaga(),
    registerProductSaga(),
  ]);
}
