import { getShopbyCategoryInfo, getShopbyProductSearch } from 'api/shopby';
import { all, takeEvery } from 'redux-saga/effects';
import {
  getShopbyCategoryInfoFailure,
  getShopbyCategoryInfoRequest,
  getShopbyCategoryInfoSuccess,
  getShopbyCategoryItemListFailure,
  getShopbyCategoryItemListRequest,
  getShopbyCategoryItemListSuccess,
} from 'store/reducers/shopbyCategory';
import { createShopbyFetchAction } from 'store/sagas/createFetchAction';

function* getShopbyCategoryInfoSaga() {
  yield takeEvery(
    getShopbyCategoryInfoRequest.type,
    createShopbyFetchAction(
      getShopbyCategoryInfo,
      getShopbyCategoryInfoSuccess,
      getShopbyCategoryInfoFailure,
    ),
  );
}

function* getShopbyCategoryItemListSaga() {
  yield takeEvery(
    getShopbyCategoryItemListRequest.type,
    createShopbyFetchAction(
      getShopbyProductSearch,
      getShopbyCategoryItemListSuccess,
      getShopbyCategoryItemListFailure,
    ),
  );
}
export default function* shopbyCategorySaga() {
  yield all([getShopbyCategoryInfoSaga(), getShopbyCategoryItemListSaga()]);
}
