import {
  MyReservationsState,
  IGetMyReservationsRequestPayload,
  IGetNonMemberBookingTokenRequestPayload,
  IGetMyReservationsCntRqPayload,
} from 'store/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IMyReservation,
  IGetNonMemberBookingToken,
  IPostPhoneAuth,
  IGetNonReservList,
  IPostPhoneAuthResponse,
  IGetNonReservListResponse,
} from '@types';

const initialState: MyReservationsState = {
  myReservations: [],
  nonMemberReservation: {} as IMyReservation,
  hasMore: true,
  count: 0,
  offset: 0,
  activeTab: 0,
  error: '',
  verificationId: '',
  nonReservations: [],
  authCode: '',
  authStatus: false,
  bookingCountSummary: {
    all: 0,
    pending: 0,
    paid: 0,
    checkout: 0,
    cancelled: 0,
  },
};

export const myReservationsSlice = createSlice({
  name: 'myReservations',
  initialState,
  reducers: {
    getMyReservationsRequest: (
      state,
      action: PayloadAction<IGetMyReservationsRequestPayload>,
    ) => {
      action.payload.skip ? state.myReservations : (state.myReservations = []);
      state.offset = action.payload.skip ? state.offset : 0;
      state.error = '';
    },
    getMyReservationsSuccess: (
      state,
      action: PayloadAction<IMyReservation[]>,
    ) => {
      state.myReservations = state.myReservations.concat(action.payload);
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },
    getMyReservationsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    addOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },

    getMyReservationsCntRequest: (
      state,
      action: PayloadAction<IGetMyReservationsCntRqPayload>,
    ) => {
      state.count = 0;
      state.error = '';
    },
    getMyReservationsCntSuccess: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
      state.error = '';
    },
    getMyReservationsCntFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.count = 0;
      state.error = action.payload;
    },
    getNonMemeberBookingTokenRequest: (
      state,
      action: PayloadAction<IGetNonMemberBookingTokenRequestPayload>,
    ) => {
      state.error = '';
    },
    getNonMemeberBookingTokenSuccess: (
      state,
      action: PayloadAction<IGetNonMemberBookingToken>,
    ) => {
      state.error = '';
    },
    getNonMemeberBookingTokenFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
    setReservationStatus: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
    postPhoneAuthRequest: (state, action: PayloadAction<IPostPhoneAuth>) => {
      state.error = '';
      state.authStatus = false;
    },
    postPhoneAuthSuccess: (
      state,
      action: PayloadAction<IPostPhoneAuthResponse>,
    ) => {
      state.error = '';
      state.authStatus = true;
      state.verificationId = action.payload.verificationId;
    },
    postPhoneAuthFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
      state.authStatus = false;
    },
    getNonReservListRequest: (
      state,
      action: PayloadAction<IGetNonReservList>,
    ) => {
      state.error = '';
    },
    getNonReservListSuccess: (
      state,
      action: PayloadAction<IGetNonReservListResponse>,
    ) => {
      state.error = '';
      state.nonReservations = action.payload.bookings;
    },
    getNonReservListFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    setNonReservReq: (state, action: PayloadAction<string>) => {
      state.authCode = action.payload;
    },
    resetState: state => {
      state.verificationId = '';
      state.authStatus = false;
      state.nonReservations = [];
    },

    getBookimgCountSummaryRequest: (state, action) => {
      state.error = '';
    },
    getBookimgCountSummarySuccess: (
      state,
      action: PayloadAction<{
        numOfBooking: number; // 전체 예약건수
        numOfPending: number; // 결제대기
        numOfPaid: number; // 결제완료
        numOfCheckout: number; // 이용완료
        numOfCancelled: number; // 취소/환불
      }>,
    ) => {
      const {
        numOfBooking,
        numOfPending,
        numOfPaid,
        numOfCheckout,
        numOfCancelled,
      } = action.payload;

      state.bookingCountSummary.all = numOfBooking;
      state.bookingCountSummary.pending = numOfPending;
      state.bookingCountSummary.paid = numOfPaid;
      state.bookingCountSummary.checkout = numOfCheckout;
      state.bookingCountSummary.cancelled = numOfCancelled;
    },
    getBookimgCountSummaryFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
  },
});

export const {
  getMyReservationsRequest,
  getMyReservationsSuccess,
  getMyReservationsFailure,
  getMyReservationsCntRequest,
  getMyReservationsCntSuccess,
  getMyReservationsCntFailure,
  addOffset,
  getNonMemeberBookingTokenRequest,
  getNonMemeberBookingTokenSuccess,
  getNonMemeberBookingTokenFailure,
  setReservationStatus,
  getNonReservListRequest,
  getNonReservListSuccess,
  getNonReservListFailure,
  postPhoneAuthRequest,
  postPhoneAuthSuccess,
  postPhoneAuthFailure,
  setNonReservReq,
  resetState,

  getBookimgCountSummaryRequest,
  getBookimgCountSummarySuccess,
  getBookimgCountSummaryFailure,
} = myReservationsSlice.actions;

export default myReservationsSlice.reducer;
