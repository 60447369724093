import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, all, take, put } from 'redux-saga/effects';
import {
  getEventListRequest,
  getEventListSuccess,
  getEventListFailure,
  addEventListOffset,
} from 'store/reducers/event';
import { createFetchAction } from 'store/sagas/createFetchAction';
import { getEventList } from 'api';

function* getEventListSaga() {
  yield takeEvery(
    getEventListRequest.type,
    createFetchAction(getEventList, getEventListSuccess, getEventListFailure),
  );
}

function* getMoreEventListSaga() {
  while (true) {
    const action: PayloadAction<number> = yield take(addEventListOffset.type);

    yield put(
      getEventListRequest({
        place: { name: '이벤트', value: 'event' },
        skip: action.payload,
        limit: 10,
      }),
    );
  }
}

export default function* eventListSaga() {
  yield all([getEventListSaga(), getMoreEventListSaga()]);
}
