import {
  ProductPickState,
  ISearchProductsBriefRqPayload,
  IRegisterProductRqPayload,
  ISelectedProduct,
} from 'store/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProduct, IRegisterProductResponse, IMedia } from '@types';

const initialState: ProductPickState = {
  products: [],
  hasMore: true,
  offset: 0,
  selectedProduct: {} as ISelectedProduct,
  error: '',
  registerResult: '',
};

export const productPickSlice = createSlice({
  name: 'productPick',
  initialState,
  reducers: {
    searchProductsBriefRequest: (
      state,
      action: PayloadAction<ISearchProductsBriefRqPayload>,
    ) => {
      action.payload.skip ? state.products : (state.products = []);
      state.offset = action.payload.skip ? state.offset : 0;
      state.error = '';
    },
    searchProductsBriefSuccess: (state, action: PayloadAction<IProduct[]>) => {
      state.products = state.products.concat(action.payload);
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },
    searchProductsBriefFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
    addOffset: (
      state,
      action: PayloadAction<ISearchProductsBriefRqPayload>,
    ) => {
      state.offset = action.payload.skip;
    },
    resetProducts: state => {
      state.products = [];
      state.offset = 0;
      state.hasMore = true;
      state.error = '';
    },
    resetSelectedProduct: state => {
      state.selectedProduct = {} as ISelectedProduct;
    },
    registerProductRequest: (
      state,
      action: PayloadAction<IRegisterProductRqPayload>,
    ) => {
      state.error = '';
    },
    registerProductSuccess: (
      state,
      action: PayloadAction<IRegisterProductResponse>,
    ) => {
      state.error = '';
      state.registerResult = action.payload.status;
    },
    registerProductFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    selectProduct: (state, action: PayloadAction<ISelectedProduct>) => {
      if (state.selectedProduct.categoryName) {
        state.selectedProduct.id = action.payload.id;
        state.selectedProduct.brand = action.payload.brand;
        state.selectedProduct.name = action.payload.name;
      } else {
        state.selectedProduct = action.payload;
      }
      action.payload.thumbnail
        ? (state.selectedProduct.thumbnail = action.payload.thumbnail)
        : (state.selectedProduct.thumbnail = {} as IMedia);
    },
    addSelectedProductCategory: (state, action: PayloadAction<string>) => {
      state.selectedProduct.categoryName = action.payload;
    },

    resetRegisterProductResult: state => {
      state.registerResult = '';
    },
  },
});

export const {
  searchProductsBriefRequest,
  searchProductsBriefSuccess,
  searchProductsBriefFailure,
  addOffset,
  registerProductRequest,
  registerProductSuccess,
  registerProductFailure,
  selectProduct,
  resetSelectedProduct,
  resetProducts,
  addSelectedProductCategory,
  resetRegisterProductResult,
} = productPickSlice.actions;

export default productPickSlice.reducer;
