import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ITShopbyErrorRes,
  IShopbyZipCodeBaseAddress,
  IShopbyZipCodeRequestPayload,
} from '@types';
import { IShopbyZipcodeState } from 'store/types';

const PAGE_SIZE = 10;

const initialState: IShopbyZipcodeState = {
  totalCount: 0,
  addressList: [],
  pageSize: PAGE_SIZE,
  pageNumber: 1,
  hasMore: true,
  shopbyError: undefined,
};

export const shopbySearchZipCodeSlice = createSlice({
  name: 'shopbySearchZipCode',
  initialState,
  reducers: {
    getShopbySearchZipCodeRequest: (
      state,
      action: PayloadAction<IShopbyZipCodeRequestPayload>,
    ) => {
      if (action.payload.pageNumber === 1) {
        state.addressList = [];
        state.pageNumber = 1;
        state.totalCount = 0;
      }

      state.hasMore = true;
      state.shopbyError = undefined;
    },
    getShopbySearchZipCodeSuccess: (
      state,
      action: PayloadAction<{
        data: {
          totalCount: number;
          items: IShopbyZipCodeBaseAddress[];
        };
      }>,
    ) => {
      const data = action.payload.data;
      if (state.pageNumber === 1) {
        state.totalCount = data.totalCount;
        state.addressList = data.items;
      } else {
        state.addressList = state.addressList.concat(data.items);
      }

      state.hasMore = state.addressList.length < data.totalCount;
    },
    getShopbySearchZipCodeFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.shopbyError = action.payload;
    },

    resetShopbySearchZipCode: state => {
      state.totalCount = 0;
      state.addressList = [];
      state.pageNumber = 1;
      state.hasMore = true;
      state.shopbyError = undefined;
    },

    addPageNumber: (
      state,
      action: PayloadAction<IShopbyZipCodeRequestPayload>,
    ) => {
      state.pageNumber = action.payload.pageNumber;
    },
  },
});

export const {
  getShopbySearchZipCodeRequest,
  getShopbySearchZipCodeSuccess,
  getShopbySearchZipCodeFailure,

  resetShopbySearchZipCode,

  addPageNumber,
} = shopbySearchZipCodeSlice.actions;

export default shopbySearchZipCodeSlice.reducer;
