import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, all, take, put, select } from 'redux-saga/effects';
import {
  getMyReviewsCntRequest,
  getMyReviewsCntFailure,
  getMyReviewsCntSuccess,
  getMyReviewsFailure,
  getMyReviewsRequest,
  getMyReviewsSuccess,
  getMyPostsRequest,
  getMyPostsSuccess,
  getMyPostsFailure,
  getMyPostsCntRequest,
  getMyPostsCntFailure,
  getMyPostsCntSuccess,
  getMyCommentsRequest,
  getMyCommentsSuccess,
  getMyCommentsFailure,
  getMyCommentsCntRequest,
  getMyCommentsCntFailure,
  getMyCommentsCntSuccess,
  addReviewsOffset,
  addPostsOffset,
  addCommentsOffset,
  setActiveSubject,
} from 'store/reducers/myList';
import {
  getMyReviews,
  countMyReviews,
  getMyPosts,
  getMyPostsCnt,
  getMyComments,
  getMyCommentsCount,
} from 'api';
import { createFetchAction } from 'store/sagas/createFetchAction';
import { RootState } from 'store/reducers';
import { customHistory } from 'App';
import { TMyListLNBSubject } from '@types';

function* getMyPostsSaga() {
  yield takeLatest(
    getMyPostsRequest.type,
    createFetchAction(getMyPosts, getMyPostsSuccess, getMyPostsFailure),
  );
}

function* getMyPostsCntSaga() {
  yield takeLatest(
    getMyPostsCntRequest.type,
    createFetchAction(
      getMyPostsCnt,
      getMyPostsCntSuccess,
      getMyPostsCntFailure,
    ),
  );
}

function* getMyReviewsSaga() {
  yield takeLatest(
    getMyReviewsRequest.type,
    createFetchAction(getMyReviews, getMyReviewsSuccess, getMyReviewsFailure),
  );
}

function* getMyReviewsCntSaga() {
  yield takeLatest(
    getMyReviewsCntRequest.type,
    createFetchAction(
      countMyReviews,
      getMyReviewsCntSuccess,
      getMyReviewsCntFailure,
    ),
  );
}

function* getMyCommentsSaga() {
  yield takeLatest(
    getMyCommentsRequest.type,
    createFetchAction(
      getMyComments,
      getMyCommentsSuccess,
      getMyCommentsFailure,
    ),
  );
}

function* getMyCommentsCntSaga() {
  yield takeLatest(
    getMyCommentsCntRequest.type,
    createFetchAction(
      getMyCommentsCount,
      getMyCommentsCntSuccess,
      getMyCommentsCntFailure,
    ),
  );
}

function* getMoreReviews() {
  while (true) {
    const action: PayloadAction<number> = yield take(addReviewsOffset.type);

    yield put(getMyReviewsRequest({ skip: action.payload, limit: 10 }));
  }
}

function* getMorePosts() {
  while (true) {
    const action: PayloadAction<number> = yield take(addPostsOffset.type);

    yield put(getMyPostsRequest({ skip: action.payload, limit: 10 }));
  }
}

function* getMoreComments() {
  while (true) {
    const action: PayloadAction<number> = yield take(addCommentsOffset.type);

    yield put(getMyCommentsRequest({ skip: action.payload, limit: 10 }));
  }
}

function* getMyContentsSaga() {
  while (true) {
    const action: PayloadAction<TMyListLNBSubject> = yield take(
      setActiveSubject.type,
    );

    const { myPosts, myComments, myReviews } = yield select(
      (state: RootState) => state.myListReducer,
    );

    if (action.payload === 'boards') {
      if (myPosts.length) {
        return;
      }

      yield put(
        getMyPostsRequest({
          skip: 0,
          limit: 10,
        }),
      );
    } else if (action.payload === 'replies') {
      if (myComments.length) {
        return;
      }

      yield put(
        getMyCommentsRequest({
          skip: 0,
          limit: 10,
        }),
      );
    } else if (action.payload === 'reviews') {
      if (myReviews.length) {
        return;
      }
      yield put(getMyReviewsRequest({ skip: 0, limit: 10 }));
    }
  }
}

export function* fetchMyListSaga() {
  yield all([
    getMyReviewsCntSaga(),
    getMyReviewsSaga(),
    getMyPostsSaga(),
    getMyPostsCntSaga(),
    getMyCommentsSaga(),
    getMyCommentsCntSaga(),
    getMoreReviews(),
    getMorePosts(),
    getMoreComments(),
    getMyContentsSaga(),
  ]);
}
