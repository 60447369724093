import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventState, IEventListRequestPayload } from 'store/types';
import { IBanner } from '@types';

const initialState: EventState = {
  eventList: [],
  event: {} as IBanner,
  hasMore: true,
  offset: 0,
  skip: 0,
  limit: 10,
  error: '',
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    getEventListRequest: (
      state,
      action: PayloadAction<IEventListRequestPayload>,
    ) => {
      state.eventList = action.payload.skip ? state.eventList : [];
      state.offset = action.payload.skip;
      state.error = '';
    },
    getEventListSuccess: (state, action: PayloadAction<IBanner[]>) => {
      state.eventList = action.payload;
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },
    getEventListFailure: (state, action: PayloadAction<Error | string>) => {
      state.eventList = [];
      state.error = action.payload;
    },

    addEventListOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
  },
});

export const {
  getEventListRequest,
  getEventListSuccess,
  getEventListFailure,
  addEventListOffset,
} = eventSlice.actions;

export default eventSlice.reducer;
