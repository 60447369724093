import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CampingLogState,
  IGetCampingLogRequestPayload,
  IGetCampingLogByCampRequestPayload,
  IGetCampLogListRequestPayload,
  IGetCampLogListCntRequestPayload,
} from 'store/types';
import {
  ICampingLog,
  ICampingLogContent,
  IResponse,
  IReviseCampingLogResponse,
  IWriteReviewResponse,
} from '@types';
import { ILogUpdate } from 'gtm/types';

const initialState: CampingLogState = {
  campingLogs: [],
  campingLogsByCommunity: [],
  campingLog: {} as ICampingLog,
  writingCampingLogResult: { isCouponIssued: false } as IWriteReviewResponse,
  campingLogsByCamp: [],
  error: '',
  hasMoreByCommunity: true,
  hasMore: true,
  campingLogsCnt: 0,
  totalCampingLogsCnt: 0,
  offset: 0,
  timestamp: 0,
  isOnlyPhotoCheckedByCommunity: true,
  isOnlyPhotoChecked: false,
  campingLogContents: [],
};

export const campingLogSlice = createSlice({
  name: 'campingLog',
  initialState,
  reducers: {
    getCampingLogsRequest: (
      state,
      action: PayloadAction<IGetCampingLogRequestPayload>,
    ) => {
      +action.payload.timestamp
        ? state.campingLogsByCommunity
        : (state.campingLogsByCommunity = []);
      state.error = '';
    },
    getCampingLogsSuccess: (state, action: PayloadAction<ICampingLog[]>) => {
      state.campingLogsByCommunity = state.campingLogsByCommunity.concat(
        action.payload,
      );
      state.hasMoreByCommunity =
        !state.timestamp && action.payload.length === 5
          ? true
          : action.payload.length === 10;
      state.error = '';
    },
    getCampingLogsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    setCommunityPhotoChecked: (state, action: PayloadAction<boolean>) => {
      state.isOnlyPhotoCheckedByCommunity = action.payload;
    },

    getCampingLogRequest: (state, action: PayloadAction<string>) => {
      state.campingLog = {} as ICampingLog;
      state.error = '';
    },
    getCampingLogSuccess: (state, action: PayloadAction<ICampingLog>) => {
      state.campingLog = action.payload;
    },
    getCampingLogFailure: (state, action: PayloadAction<Error | string>) => {
      state.campingLog = {} as ICampingLog;
      state.error = action.payload;
    },

    resetTimestamp: state => {
      state.timestamp = 0;
      state.hasMore = true;
    },

    updateTimestamp: (
      state,
      action: PayloadAction<IGetCampingLogRequestPayload>,
    ) => {
      state.timestamp = action.payload.timestamp;
    },

    getCampingLogsByCampRequest: (
      state,
      action: PayloadAction<IGetCampingLogByCampRequestPayload>,
    ) => {
      state.campingLogsByCamp = [];
    },
    getCampingLogsByCampSuccess: (
      state,
      action: PayloadAction<ICampingLog[]>,
    ) => {
      state.campingLogsByCamp = state.campingLogsByCamp.concat(action.payload);
      state.error = '';
    },
    getCampingLogsByCampFailure: (state, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
    },
    resetCampingLogsByCamp: state => {
      state.campingLogsByCamp = [];
      state.error = '';
    },
    resetCampLogs: state => {
      state.campingLogs = [];
      state.hasMore = true;
      state.offset = 0;
      state.error = '';
    },
    getCampLogListRequest: (
      state,
      action: PayloadAction<IGetCampLogListRequestPayload>,
    ) => {},
    getCampLogListSuccess: (state, action: PayloadAction<ICampingLog[]>) => {
      state.campingLogs = state.campingLogs.concat(action.payload);
      state.offset += action.payload.length;
      state.hasMore = state.offset < state.campingLogsCnt;
    },
    getCampLogListFailure: (state, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
    },
    getCampLogTotalCntRequest: (
      state,
      action: PayloadAction<{ id: string }>,
    ) => {
      state.totalCampingLogsCnt = 0;
    },
    getCampLogTotalCntSuccess: (state, action: PayloadAction<number>) => {
      if (state.campingLogsCnt === 0 && state.totalCampingLogsCnt === 0)
        state.campingLogsCnt = action.payload;
      state.totalCampingLogsCnt = action.payload;
    },
    getCampLogTotalCntFailure: (state, action: PayloadAction<AxiosError>) => {
      state.totalCampingLogsCnt = 0;
      state.error = action.payload;
    },
    getCampLogsCntRequest: (
      state,
      action: PayloadAction<IGetCampLogListCntRequestPayload>,
    ) => {
      state.campingLogs = [];
    },
    getCampLogsCntSuccess: (state, action: PayloadAction<number>) => {
      state.campingLogsCnt = action.payload;
      state.hasMore = action.payload > 0;
    },
    getCampLogsCntFailure: (state, action: PayloadAction<AxiosError>) => {
      state.campingLogsCnt = 0;
      state.error = action.payload;
    },
    setPhotoChecked: (state, action: PayloadAction<boolean>) => {
      state.isOnlyPhotoChecked = action.payload;
    },
    writeCampingLogRequest: (
      state,
      action: PayloadAction<{ formData: FormData; logUpdateData: ILogUpdate }>,
    ) => {
      state.error = '';
    },
    writeCampingLogSuccess: (
      state,
      action: PayloadAction<IWriteReviewResponse>,
    ) => {
      state.writingCampingLogResult = action.payload;
    },
    writeCampingLogFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    resetLogCouponIssueStatus: state => {
      state.writingCampingLogResult.isCouponIssued = false;
      state.writingCampingLogResult.coupon = undefined;
    },

    reviseCampingLogRequest: (state, action) => {
      state.error = '';
    },
    reviseCampingLogSuccess: (
      state,
      action: PayloadAction<IReviseCampingLogResponse>,
    ) => {},
    reviseCampingLogFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    addCampingLogContent: (
      state,
      action: PayloadAction<ICampingLogContent>,
    ) => {
      if (!action.payload.url && action.payload.file) {
        action.payload.url = URL.createObjectURL(action.payload.file);
      }
      state.campingLogContents = state.campingLogContents.concat(
        action.payload,
      );
    },
    removeCampingLogContent: (state, action: PayloadAction<number>) => {
      const splicedCampingLogContents = state.campingLogContents;
      splicedCampingLogContents.splice(action.payload, 1);
      state.campingLogContents = [...splicedCampingLogContents];
    },
    resetCampingLogContents: state => {
      state.campingLogContents.forEach(campingLogContent => {
        if (!campingLogContent.isExisted && campingLogContent.url) {
          URL.revokeObjectURL(campingLogContent.url);
        }
      });
      state.campingLogContents = [];
    },
    deleteCampingLogRequest: (state, action: PayloadAction<string>) => {
      state.error = '';
    },
    deleteCampingLogSuccess: (state, action: PayloadAction<IResponse>) => {},
    deleteCampingLogFailure: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  getCampingLogsRequest,
  getCampingLogsSuccess,
  getCampingLogsFailure,
  setCommunityPhotoChecked,
  resetTimestamp,
  getCampingLogRequest,
  getCampingLogSuccess,
  getCampingLogFailure,
  updateTimestamp,
  getCampingLogsByCampRequest,
  getCampingLogsByCampSuccess,
  getCampingLogsByCampFailure,
  getCampLogListRequest,
  getCampLogListSuccess,
  getCampLogListFailure,
  getCampLogsCntRequest,
  getCampLogsCntSuccess,
  getCampLogsCntFailure,
  getCampLogTotalCntRequest,
  getCampLogTotalCntSuccess,
  getCampLogTotalCntFailure,
  writeCampingLogRequest,
  writeCampingLogSuccess,
  writeCampingLogFailure,
  reviseCampingLogRequest,
  reviseCampingLogSuccess,
  reviseCampingLogFailure,
  setPhotoChecked,
  resetCampingLogsByCamp,
  resetCampLogs,
  addCampingLogContent,
  removeCampingLogContent,
  resetCampingLogContents,
  deleteCampingLogRequest,
  deleteCampingLogSuccess,
  deleteCampingLogFailure,
  resetLogCouponIssueStatus,
} = campingLogSlice.actions;

export default campingLogSlice.reducer;
