import { takeLatest, throttle } from 'redux-saga/effects';
import {
  campsAutoCompleteRequest,
  autoCompleteSuccess,
  autoCompleteFailure,
} from 'store/reducers/autoComplete';
import { campAutoComplete } from 'api';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

function* autoCompleteSaga() {
  yield takeLatest(
    campsAutoCompleteRequest.type,
    createFetchAction(
      campAutoComplete,
      autoCompleteSuccess,
      autoCompleteFailure,
      undefined,
      failure,
    ),
  );
}

export default autoCompleteSaga;
