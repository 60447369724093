import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BestPhotosState, IGetArrayPayload } from 'store/types';
import { IBestPhoto } from '@types';

const initialState: BestPhotosState = {
  bestPhotos: [],
  error: '',
};

export const bestPhotosSlice = createSlice({
  name: 'bestPhotos',
  initialState,
  reducers: {
    getBestPhotosRequest: (state, action: PayloadAction<IGetArrayPayload>) => {
      state.bestPhotos = [];
      state.error = '';
    },
    getBestPhotosSuccess: (state, action: PayloadAction<IBestPhoto[]>) => {
      state.bestPhotos = action.payload;
      state.error = '';
    },
    getBestPhotosFailure: (state, action: PayloadAction<Error | string>) => {
      state.bestPhotos = [];
      state.error = action.payload;
    },
  },
});

export const {
  getBestPhotosRequest,
  getBestPhotosSuccess,
  getBestPhotosFailure,
} = bestPhotosSlice.actions;

export default bestPhotosSlice.reducer;
