import { PayloadAction } from '@reduxjs/toolkit';
import {
  take,
  all,
  takeEvery,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { getCamperItems, getCamperItem, getProductStatistics } from 'api';
import {
  addOffset,
  getProductReviewsFailure,
  getProductReviewsRequest,
  getProductReviewsSuccess,
  getProductReviewFailure,
  getProductReviewRequest,
  getProductReviewSuccess,
  getProductStatisticsRequest,
  getProductStatisticsSuccess,
  getProductStatisticsFailure,
  filterReviews,
} from 'store/reducers/productReviews';
import { RootState } from 'store/reducers';
import {
  IAddProductReviewsOffsetPayload,
  IFilterReviewsPayload,
} from 'store/types';
import { createFetchAction } from './createFetchAction';

function* getProductReviewSaga() {
  yield takeEvery(
    getProductReviewRequest.type,
    createFetchAction(
      getCamperItem,
      getProductReviewSuccess,
      getProductReviewFailure,
    ),
  );
}

function* filterReviewsSaga() {
  while (true) {
    const action: PayloadAction<IFilterReviewsPayload> = yield take(
      filterReviews.type,
    );

    const { productId } = action.payload;

    const { isPhotoOnly, isOwnerOnly } = yield select(
      (state: RootState) => state.productReviewsReducer,
    );

    yield put(
      getProductReviewsRequest({
        productId,
        isOwnerOnly,
        hasMedia: isPhotoOnly,
        skip: 0,
        limit: 5,
      }),
    );
  }
}

function* getProductReviewsSaga() {
  yield takeEvery(
    getProductReviewsRequest.type,
    createFetchAction(
      getCamperItems,
      getProductReviewsSuccess,
      getProductReviewsFailure,
    ),
  );
}

function* getProductStatisticsSaga() {
  yield takeLatest(
    getProductStatisticsRequest.type,
    createFetchAction(
      getProductStatistics,
      getProductStatisticsSuccess,
      getProductStatisticsFailure,
    ),
  );
}

function* getMoreReviewsSaga() {
  while (true) {
    const action: PayloadAction<IAddProductReviewsOffsetPayload> = yield take(
      addOffset.type,
    );

    const { productId, skip, limit } = action.payload;

    const { isPhotoOnly, isOwnerOnly } = yield select(
      (state: RootState) => state.productReviewsReducer,
    );

    yield put(
      getProductReviewsRequest({
        productId,
        isOwnerOnly,
        hasMedia: isPhotoOnly,
        skip,
        limit,
      }),
    );
  }
}

function* productReviewsSaga() {
  yield all([
    getProductReviewsSaga(),
    getMoreReviewsSaga(),
    filterReviewsSaga(),
    getProductReviewSaga(),
    getProductStatisticsSaga(),
  ]);
}

export default productReviewsSaga;
