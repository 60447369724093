import { takeLatest, all } from 'redux-saga/effects';
import {
  getSiteExhibitionRequest,
  getSiteExhibitionSuccess,
  getSiteExhibitionFailure,
  getExhibitionSitesRequest,
  getExhibitionSitesSuccess,
  getExhibitionSitesFailure,
} from 'store/siteExhibition/reducer';
import { createFetchActionWithKey } from 'store/sagas/createFetchAction';
import { getExhibitionSites, getSiteExhibition } from 'api';

function* getSiteExhibitionSaga() {
  yield takeLatest(
    getSiteExhibitionRequest.type,
    createFetchActionWithKey(
      getSiteExhibition,
      getSiteExhibitionSuccess,
      getSiteExhibitionFailure,
    ),
  );
}
function* getExhibitionSitesSaga() {
  yield takeLatest(
    getExhibitionSitesRequest.type,
    createFetchActionWithKey(
      getExhibitionSites,
      getExhibitionSitesSuccess,
      getExhibitionSitesFailure,
    ),
  );
}
export default function* siteExhibitionSaga() {
  yield all([getSiteExhibitionSaga(), getExhibitionSitesSaga()]);
}
