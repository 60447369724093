import { takeLatest, put, all } from 'redux-saga/effects';
import {
  withdrawFailure,
  withdrawRequest,
  withdrawSuccess,
  getWithdrawInfoRequset,
  getWithdrawInfoSuccess,
  getWithdrawInfoFailure,
} from 'store/reducers/withdraw';
import { clickLogout } from 'store/reducers/logout';
import { getWithdrawInfo, withdraw } from 'api';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

function* withdrawSaga() {
  yield takeLatest(
    withdrawRequest.type,
    createFetchAction(
      withdraw,
      withdrawSuccess,
      withdrawFailure,
      function* success() {
        yield alert('정상적으로 탈퇴처리 되었습니다.');
        yield put(clickLogout(!!window.BootpayRNWebView));
      },
      failure,
    ),
  );
}

function* getWithdrawInfoSaga() {
  yield takeLatest(
    getWithdrawInfoRequset.type,
    createFetchAction(
      getWithdrawInfo,
      getWithdrawInfoSuccess,
      getWithdrawInfoFailure,
    ),
  );
}

export default function* withdrawalSaga() {
  yield all([withdrawSaga(), getWithdrawInfoSaga()]);
}
