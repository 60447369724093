import styled from 'styled-components';

interface Style {
  scroll?: boolean;
  btnSpin?: boolean;
  width?: string;
  margin?: string;
}

const SpinLoader = styled.div<Style>`
  border-radius: 50%;
  width: ${props => (props.width ? props.width : props.scroll ? '4em' : '8em')};
  height: ${props =>
    props.width ? props.width : props.scroll ? '4em' : '8em'};
  margin: ${props => (props.margin ? props.margin : '0 auto')};
  font-size: ${({ theme }) => theme.fontSizes.c10};
  position: initial;
  text-indent: -9999em;
  border-top: ${({ scroll, btnSpin, theme }) => `${
    scroll ? '3.8px' : '5px'
  } solid
   ${btnSpin ? theme.colors.bw100 : theme.colors.primary80}`};
  border-right: ${({ btnSpin, scroll, theme }) => `${
    scroll ? '3.8px' : '5px'
  } solid
   ${btnSpin ? theme.colors.bw100 : theme.colors.primary80}`};
  border-bottom: ${({ btnSpin, scroll, theme }) => `${
    scroll ? '3.8px' : '5px'
  } solid
   ${btnSpin ? theme.colors.bw100 : theme.colors.primary80}`};
  border-left: ${({ theme, scroll, btnSpin }) =>
    scroll
      ? `3.8px solid ${btnSpin ? 'transparent' : theme.colors.bw95}`
      : `5px solid ${theme.colors.bw100}`};
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  ::after {
    border-radius: 50%;
    width: ${props => (props.scroll ? '4em' : '9em')};
    height: ${props => (props.scroll ? '4em' : '9em')};
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default SpinLoader;
