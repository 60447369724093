import {
  findExhibitionCamps,
  getCampExhibitionCodeList,
  getCampExhibitionInfo,
} from 'api';
import { all, takeLatest } from 'redux-saga/effects';
import {
  getCampExhibitionCampListFailure,
  getCampExhibitionCampListRequest,
  getCampExhibitionCampListSuccess,
  getCampExhibitionCodeListFailure,
  getCampExhibitionCodeListRequest,
  getCampExhibitionCodeListSuccess,
  getCampExhibitionInfoFailure,
  getCampExhibitionInfoRequest,
  getCampExhibitionInfoSuccess,
  getMoreCampExhibitionCampListRequest,
} from 'store/reducers/campExhibition';
import { createFetchActionWithKey } from 'store/sagas/createFetchAction';

function* getCampExhibitionCodeListSaga() {
  yield takeLatest(
    getCampExhibitionCodeListRequest.type,
    createFetchActionWithKey(
      getCampExhibitionCodeList,
      getCampExhibitionCodeListSuccess,
      getCampExhibitionCodeListFailure,
    ),
  );
}

function* getCampExhibitionInfoSaga() {
  yield takeLatest(
    getCampExhibitionInfoRequest.type,
    createFetchActionWithKey(
      getCampExhibitionInfo,
      getCampExhibitionInfoSuccess,
      getCampExhibitionInfoFailure,
    ),
  );
}

function* getCampExhibitionCampListSaga() {
  yield takeLatest(
    [
      getCampExhibitionCampListRequest.type,
      getMoreCampExhibitionCampListRequest.type,
    ],
    createFetchActionWithKey(
      findExhibitionCamps,
      getCampExhibitionCampListSuccess,
      getCampExhibitionCampListFailure,
    ),
  );
}

export default function* campExhibitionSaga() {
  yield all([
    getCampExhibitionCodeListSaga(),
    getCampExhibitionInfoSaga(),
    getCampExhibitionCampListSaga(),
  ]);
}
