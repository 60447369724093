import { changeAdditionalOpts, getChangableOnSiteServices } from 'api';
import {
  all,
  takeEvery,
  takeLatest,
  take,
  select,
  put,
} from 'redux-saga/effects';
import {
  changeOnSiteServicesFailure,
  changeOnSiteServicesRequest,
  changeOnSiteServicesSuccess,
  clickServiceChangeBtn,
  getOnSiteServicesFailure,
  getOnSiteServicesRequest,
  getOnSiteServicesSuccess,
} from 'store/reducers/serviceInventory';
import { PayloadAction } from '@reduxjs/toolkit';
import { IChangableOnSiteService, IResponse } from '@types';
import { RootState } from 'store/reducers';
import { IOnSiteServicesPayload } from 'store/types';
import { createFetchAction } from './createFetchAction';
import { customHistory } from '../../App';
import { failure } from './failure';

const queryString = require('query-string');

function* getOnSiteServiceSaga() {
  yield takeEvery(
    getOnSiteServicesRequest.type,
    createFetchAction(
      getChangableOnSiteServices,
      getOnSiteServicesSuccess,
      getOnSiteServicesFailure,
      undefined,
      function* fail(error: Error | IResponse) {
        yield failure(error);
        yield customHistory.goBack();
      },
    ),
  );
}

function* changeOnSiteServicesSaga() {
  yield takeLatest(
    changeOnSiteServicesRequest.type,
    createFetchAction(
      changeAdditionalOpts,
      changeOnSiteServicesSuccess,
      changeOnSiteServicesFailure,
      function* success() {
        const { id } = yield select(
          (state: RootState) => state.reservationReducer.bookingInfo,
        );

        const token = queryString.parse(customHistory.location.search).token;
        const query = token ? `?token=${token}` : '';

        yield customHistory.push(`/reservation/result/${id}${query}`);
      },
      failure,
    ),
  );
}

function* clickChangeBtnSaga() {
  while (true) {
    const action: PayloadAction<IOnSiteServicesPayload> = yield take(
      clickServiceChangeBtn.type,
    );

    const inventory: IChangableOnSiteService = yield select(
      (state: RootState) => state.serviceInventoryReducer.inventory,
    );

    const selectedServices = inventory.services.filter(s => {
      if (s.hasDailyLimit) {
        return s.dailyUsages.some(d => d.dailyQuantity > 0);
      }
      return s.quantity > 0;
    });

    const paramsServices = selectedServices.map(s => {
      return s.hasDailyLimit
        ? {
            id: s.id,
            dailyUsages: s.dailyUsages.filter(d => d.dailyQuantity > 0),
          }
        : {
            id: s.id,
            quantity: s.quantity,
          };
    });

    yield put(
      changeOnSiteServicesRequest({
        ...action.payload,
        services: paramsServices,
      }),
    );
  }
}

export default function* serviceInventorySaga() {
  yield all([
    getOnSiteServiceSaga(),
    changeOnSiteServicesSaga(),
    clickChangeBtnSaga(),
  ]);
}
