import ko from 'date-fns/locale/ko';
import {
  format,
  differenceInCalendarDays,
  intervalToDuration,
  differenceInCalendarWeeks,
  startOfWeek,
  startOfMonth,
  addDays,
} from 'date-fns';

export const makeHyphenDateStringToSlash = (
  dateString: string, // 'YYYY-MM-DD' 형태와 같은 하이픈이 포함된 dateString
) => {
  return dateString.replace(/-/g, '/');
};

export function getKoreaDate(date: number | Date) {
  const now = new Date();

  const koreaTimeOffset = 9 * 60 * 60 * 1000; // 9시간을 밀리초로 변환
  const utc =
    (typeof date === 'number' ? date : date.getTime()) +
    now.getTimezoneOffset() * 60000; // UTC 시간 계산
  const koreaDate = new Date(utc + koreaTimeOffset);
  return koreaDate;
}

export const changeKoreaDateFormat = (
  date: Date | number,
  dateFormat: string = 'yy.MM.dd',
) => {
  if (date) {
    // 한국 시간 포맷팅
    const koreaTime = getKoreaDate(date);

    return format(koreaTime, dateFormat, {
      locale: ko,
    });
  }
};

export const changeDateFormat = (
  date: Date | number,
  dateFormat: string = 'yy.MM.dd',
) => {
  if (date) {
    return format(date, dateFormat, {
      locale: ko,
    });
  }
};

export const calculateDiffInDays = (
  endDate: Date | number,
  startDate: Date | number,
) => {
  return differenceInCalendarDays(endDate, startDate);
};

export const calculateBookableLimitDate = (
  bookableMonth: number,
  dailyOpenHour: number,
) => {
  const now = new Date();
  return new Date(
    now.setDate(
      now.getDate() +
        (dailyOpenHour > 0 && now.getHours() < dailyOpenHour ? -1 : 0) +
        30 * bookableMonth,
    ),
  );
};

export const changeRegisterDateFormat = (date: Date | number) => {
  const now = new Date();
  const dateInterval = intervalToDuration({ start: date, end: now });
  const { seconds, minutes, hours, days, months, years } = dateInterval;
  let intervalMark = '';

  switch (true) {
    case years && years > 0:
      intervalMark = `${years}년 전`;
      break;

    case months && months < 12:
      intervalMark = `${months}달 전`;
      break;

    case days && days < 31:
      intervalMark = `${days}일 전`;
      break;

    case hours && hours < 24:
      intervalMark = `${hours}시간 전`;
      break;

    case minutes && minutes < 60:
      intervalMark = `${minutes}분 전`;
      break;

    case (seconds as number) >= 0 || (seconds as number) < 60:
      intervalMark = '방금 전';
      break;

    default:
      break;
  }

  return intervalMark;
};

export const getWeekNumberOfMonth = (date: Date | number) => {
  const firstDayOfMonth = startOfMonth(date);
  const startOfFirstWeekOfMonth = startOfWeek(firstDayOfMonth, {
    weekStartsOn: 0,
  });

  const startOfWeekCurrentDate = startOfWeek(date, { weekStartsOn: 0 });

  const weekNumber =
    differenceInCalendarWeeks(startOfWeekCurrentDate, startOfFirstWeekOfMonth, {
      weekStartsOn: 0,
    }) + 1;

  return weekNumber;
};

export type WeekDate = {
  id: string;
  dateObj: Date;
  date: number;
  day: '월' | '화' | '수' | '목' | '금' | '토' | '일';
  disabled: boolean;
};
export const getThisWeekDates = (
  date: Date | number,
  startDate: Date,
  endDate: Date,
  activeDatetimeArray: number[],
): WeekDate[] => {
  const days = ['월', '화', '수', '목', '금', '토', '일'] as WeekDate['day'][];
  const startOfTheWeek = startOfWeek(date, { weekStartsOn: 1 });
  const todayDate = format(new Date(), 'dd');

  const weekDates = [] as WeekDate[];
  for (let i = 0; i < 7; i += 1) {
    const dateObj = i === 0 ? startOfTheWeek : addDays(startOfTheWeek, i);
    const date = format(dateObj, 'dd');

    weekDates.push({
      id: format(dateObj, 'yyyy-MM-dd'),
      dateObj,
      date: Number(date),
      day: days[i],
      disabled: !activeDatetimeArray.includes(dateObj.getTime()),
    });
  }

  return weekDates;
};

export const getMultipleDates = (
  startDate: Date,
  endDate: Date,
  activeDatetimeArray: number[],
) => {
  let standardDate = new Date(startDate.setHours(0, 0, 0, 0));
  const weekDatesArray = [] as WeekDate[][];
  let isDatesGenerating = true;

  while (isDatesGenerating) {
    weekDatesArray.push(
      getThisWeekDates(standardDate, startDate, endDate, activeDatetimeArray),
    );
    standardDate = addDays(standardDate, 7);
    isDatesGenerating = standardDate <= endDate;
  }

  return weekDatesArray;
};
