import { takeEvery, all, select, put, take } from 'redux-saga/effects';
import {
  searchProductsRequest,
  searchProductsSuccess,
  searchProductsFailure,
  getProductSearchDataRequest,
  getProductSearchDataSuccess,
  getProductSearchDataFailure,
  addPage,
  sortProdcuts,
} from 'store/reducers/productSearch';
import { searchProducts, getProductSearchData } from 'api';
import { TProductSortType } from '@types';
import { RootState } from 'store/reducers';
import { PayloadAction } from '@reduxjs/toolkit';
import { IAddProductsPagePayload, ISortProductPayload } from 'store/types';
import { createFetchAction } from './createFetchAction';

const queryString = require('query-string');

function* searchProductsSaga() {
  yield takeEvery(
    searchProductsRequest.type,
    createFetchAction(
      searchProducts,
      searchProductsSuccess,
      searchProductsFailure,
    ),
  );
}

function* getMoreProductsSaga() {
  while (true) {
    const action: PayloadAction<IAddProductsPagePayload> = yield take(
      addPage.type,
    );

    const { id, page } = action.payload;

    const sortedType: TProductSortType = yield select(
      (state: RootState) => state.productSearchReducer.sortedType,
    );

    const parsed = queryString.parse(window.location.search);

    yield put(
      searchProductsRequest({
        q: parsed.word,
        cat: id,
        p: page,
        ps: 10,
        s: sortedType === 'recommendDesc' ? undefined : sortedType,
      }),
    );
  }
}

function* sortProductsSaga() {
  while (true) {
    const action: PayloadAction<ISortProductPayload> = yield take(
      sortProdcuts.type,
    );

    const { id, sort } = action.payload;

    const parsed = queryString.parse(window.location.search);

    yield put(
      searchProductsRequest({
        q: parsed.word,
        cat: id,
        p: 1,
        ps: 10,
        s: sort === 'recommendDesc' ? undefined : sort,
      }),
    );
  }
}

function* getProductSearchDataSaga() {
  yield takeEvery(
    getProductSearchDataRequest.type,
    createFetchAction(
      getProductSearchData,
      getProductSearchDataSuccess,
      getProductSearchDataFailure,
    ),
  );
}

export default function* productSearchSaga() {
  yield all([
    searchProductsSaga(),
    getProductSearchDataSaga(),
    getMoreProductsSaga(),
    sortProductsSaga(),
  ]);
}
