import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IComment,
  IToggleLikeBtnResponse,
  IWriteCommentsResponse,
  IThread,
} from '@types';
import {
  CommentsState,
  IGetCommentsPayload,
  IToggleLikeBtnPayload,
  IGetThreadsRequestPayload,
  ISortCommentsPayload,
} from 'store/types';
import { AxiosError } from 'axios';

const initialState: CommentsState = {
  comments: [],
  comment: {} as IComment,
  hasMore: true,
  timestamp: 0,
  threadsHasMore: true,
  threadsTimestamp: 0,
  isThreadsHided: false,
  commentsThreadsHasMore: true,
  commentsThreadsTimestamp: 0,
  isCommentsThreadsHided: false,
  sort: 'asc',
  error: '',
  isCommentWriteSuccess: false,
  isThreadWriteSuccess: false,
  mentionedUserNickname: '',
  writingThreadId: '',
};

export const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    getCommentsRequest: (state, action: PayloadAction<IGetCommentsPayload>) => {
      action.payload.timestamp ? state.comments : (state.comments = []);
      state.isCommentsThreadsHided = false;
      state.commentsThreadsHasMore = true;
      state.error = '';
    },
    getCommentsSuccess: (state, action: PayloadAction<IComment[]>) => {
      state.comments = state.comments.concat(action.payload);
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },
    getCommentsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    updateCommentTimestamp: (
      state,
      action: PayloadAction<IGetCommentsPayload>,
    ) => {
      state.timestamp = action.payload.timestamp;
    },
    resetTimestamp: (state, action) => {
      state.timestamp = 0;
    },
    getCommentRequest: (state, action: PayloadAction<string>) => {
      state.comment = {} as IComment;
      state.error = '';
    },
    getCommentSuccess: (state, action: PayloadAction<IComment>) => {
      state.comment = action.payload;
      state.error = '';
    },
    getCommentFailure: (state, action: PayloadAction<AxiosError>) => {
      state.comment = {} as IComment;
      state.error = action.payload;
    },
    getCommentsThreadsRequest: (
      state,
      action: PayloadAction<IGetThreadsRequestPayload>,
    ) => {
      state.commentsThreadsHasMore = true;
      state.isCommentsThreadsHided = false;
      state.error = '';
    },
    getCommentsThreadsSuccess: (state, action: PayloadAction<IThread[]>) => {
      state.comments.forEach(c => {
        action.payload.forEach(t => {
          if (c.id === t.commentId) {
            c.headThreads = c.headThreads.concat(t);
          }
        });
      });
      state.commentsThreadsHasMore = action.payload.length === 10;
      state.error = '';
    },
    getCommentsThreadsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
    getThreadsRequest: (
      state,
      action: PayloadAction<IGetThreadsRequestPayload>,
    ) => {
      state.comment.headThreads = [];
      state.threadsHasMore = true;
      state.isThreadsHided = false;
      state.error = '';
    },
    getThreadsSuccess: (state, action: PayloadAction<IThread[]>) => {
      state.comment.headThreads = state.comment.headThreads.concat(
        action.payload,
      );
      state.threadsHasMore = action.payload.length === 10;
      state.error = '';
    },
    getThreadsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    showMoreThreads: (
      state,
      action: PayloadAction<IGetThreadsRequestPayload>,
    ) => {
      action.payload.isComments
        ? (state.commentsThreadsTimestamp = action.payload.timestamp)
        : (state.threadsTimestamp = action.payload.timestamp);
    },
    hideThreads: (state, action: PayloadAction<boolean>) => {
      action.payload
        ? (state.isCommentsThreadsHided = !state.isCommentsThreadsHided)
        : (state.isThreadsHided = !state.isThreadsHided);
    },
    writeCommentsRequest: (state, action: PayloadAction<FormData>) => {
      state.error = '';
    },
    writeCommentsSuccess: (
      state,
      action: PayloadAction<IWriteCommentsResponse>,
    ) => {
      const payloadComment = action.payload.comment;
      const newComment: IComment = {
        hasLiked: payloadComment.hasLiked,
        headThreads: [],
        id: payloadComment.id,
        isAuthor: payloadComment.isAuthor,
        isBest: false,
        isOwner: false,
        isUserBlocked: false,
        medias: payloadComment.medias,
        numOfLikes: payloadComment.numOfLikes,
        numOfThreads: payloadComment.numOfThreads,
        registerTimestamp: payloadComment.registerTimestamp,
        text: payloadComment.text,
        user: payloadComment.user,
        lastModifiedTimestamp: 0,
        isBlocked: false,
      };
      state.sort === 'desc' && state.comments.unshift(newComment);
      state.comments =
        state.sort === 'asc'
          ? state.comments.concat(newComment)
          : state.comments;
      state.error = '';
      state.isCommentWriteSuccess = true;
    },
    writeCommentsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    writeCommentThreadsRequest: (state, action: PayloadAction<FormData>) => {
      state.error = '';
    },
    writeCommentThreadsSuccess: (
      state,
      action: PayloadAction<IWriteCommentsResponse>,
    ) => {
      state.comment.headThreads = state.comment.headThreads.concat(
        action.payload.thread,
      );
      state.error = '';
      state.isThreadWriteSuccess = true;
    },
    writeCommentThreadsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
    toggleLikeBtnOnCommentRequest: (
      state,
      action: PayloadAction<IToggleLikeBtnPayload>,
    ) => {
      state.error = '';
    },
    toggleLikeBtnOnCommentSuccess: (
      state,
      action: PayloadAction<IToggleLikeBtnResponse>,
    ) => {
      const { id, type, hasLiked, numOfLikes } = action.payload;
      const newComments: IComment[] = [];

      state.comments.forEach(b => {
        if (type === 'comment') {
          if (b.id === id) {
            b.hasLiked = hasLiked;
            b.numOfLikes = numOfLikes;
          }
        }
        if (type === 'thread') {
          b.headThreads.forEach(h => {
            if (h.id === id) {
              h.hasLiked = hasLiked;
              h.numOfLikes = numOfLikes;
            }
          });
        }
        newComments.push(b);
      });

      state.comments = newComments;

      if (type === 'comment') {
        state.comment.hasLiked = hasLiked;
        state.comment.numOfLikes = numOfLikes;
      } else if (type === 'thread') {
        state.comment.headThreads.filter(
          thread => thread.id === id,
        )[0].hasLiked = hasLiked;
        state.comment.headThreads.filter(
          thread => thread.id === id,
        )[0].numOfLikes = numOfLikes;
      }

      state.error = '';
    },
    toggleLikeBtnOnCommentFailure: (state, action) => {
      state.error = action.payload;
    },
    sortComments: (state, action: PayloadAction<ISortCommentsPayload>) => {
      state.sort = action.payload.sort;
    },
    reviseCommentRequest: (state, action) => {
      state.error = '';
    },
    reviseCommentSuccess: (
      state,
      action: PayloadAction<IWriteCommentsResponse>,
    ) => {
      state.error = '';
    },
    reviseCommentFailure: (state, action) => {
      state.error = action.payload;
    },
    reviseThreadRequest: (state, action) => {
      state.error = '';
    },
    reviseThreadSuccess: (
      state,
      action: PayloadAction<IWriteCommentsResponse>,
    ) => {
      state.error = '';
    },
    reviseThreadFailure: (state, action) => {
      state.error = action.payload;
    },
    startFetchingComment: (state, action: PayloadAction<string>) => {
      state;
    },
    resetIsCommentWriteSuccess: state => {
      state.isCommentWriteSuccess = false;
    },
    resetIsThreadWriteSuccess: state => {
      state.isThreadWriteSuccess = false;
    },
    setMentionedUserNickname: (state, action: PayloadAction<string>) => {
      state.mentionedUserNickname = action.payload;
    },
    resetMentionedUserNickname: state => {
      state.mentionedUserNickname = '';
    },
    setWritingThreadId: (state, action: PayloadAction<string | undefined>) => {
      state.writingThreadId = action.payload;
    },
    resetWritingThreadId: state => {
      state.writingThreadId = '';
    },
  },
});

export const {
  startFetchingComment,
  getCommentsRequest,
  getCommentsSuccess,
  getCommentsFailure,
  updateCommentTimestamp,
  resetTimestamp,
  getCommentRequest,
  getCommentSuccess,
  getCommentFailure,
  getCommentsThreadsRequest,
  getCommentsThreadsSuccess,
  getCommentsThreadsFailure,
  getThreadsRequest,
  getThreadsSuccess,
  getThreadsFailure,
  writeCommentsRequest,
  writeCommentsSuccess,
  writeCommentsFailure,
  writeCommentThreadsRequest,
  writeCommentThreadsSuccess,
  writeCommentThreadsFailure,
  toggleLikeBtnOnCommentRequest,
  toggleLikeBtnOnCommentSuccess,
  toggleLikeBtnOnCommentFailure,
  showMoreThreads,
  hideThreads,
  sortComments,
  reviseCommentRequest,
  reviseCommentSuccess,
  reviseCommentFailure,
  reviseThreadRequest,
  reviseThreadSuccess,
  reviseThreadFailure,
  resetIsCommentWriteSuccess,
  resetIsThreadWriteSuccess,
  setMentionedUserNickname,
  resetMentionedUserNickname,
  setWritingThreadId,
  resetWritingThreadId,
} = commentsSlice.actions;

export default commentsSlice.reducer;
