import { takeLatest, all, put, select } from 'redux-saga/effects';
import {
  deleteBookmarkRequest,
  deleteBookmarkSuccess,
  deleteBookmarkFailure,
  createBookmarkRequest,
  createBookmarkSuccess,
  createBookmarkFailure,
  setUserEnv,
  getMyBookmarksRequest,
  getMyBookmarksSuccess,
  getMyBookmarksFailure,
  getMyBookmarksMoreRequest,
  getMyBookmarkCountRequest,
  getMyBookmarkCountSuccess,
  getMyBookmarkCountFailure,
  deleteBulkBookmarkRequest,
  deleteBulkBookmarkSuccess,
  deleteBulkBookmarkFailure,
  changeAppPushOnOffRequest,
  changeAppPushOnOffSuccess,
  changeAppPushOnOffFailure,
  changeAppPushOnOffError,
  setIsBookmarkChanged,
  deleteBookmarkByCampId,
  deleteBookmarks,
} from 'store/reducers/bookmark';
import {
  deleteBookmark,
  createBookmark,
  getMyBookmarks,
  getMyBookmarkCount,
  deleteBulkBookmark,
  changeAppPushOnOff,
} from 'api';
import { toast } from 'react-toastify';
import { isMobile } from 'utils/share';
import { setCampIsBookmarked } from 'store/reducers/camp';
import { RootState } from 'store/reducers';
import sendGtmDataLayer from 'utils/sendGtmDataLayer';
import { ICampGtmInfo } from 'gtm/types';
import {
  createFetchAction,
  createFetchActionWithFailResult,
  createV2FetchFuncWithStringError,
} from './createFetchAction';
import { failure } from './failure';

function* deleteBookmarkSaga() {
  yield takeLatest(
    deleteBookmarkRequest.type,
    createV2FetchFuncWithStringError(
      deleteBookmark,
      deleteBookmarkSuccess,
      deleteBookmarkFailure,
      undefined,
      function* success(data: any, payload: any) {
        const id: string = yield select(
          (state: RootState) => state.campReducer.camp.id,
        );

        yield toast.success('캠핑장 찜이 해제되었습니다!');
        yield put(setCampIsBookmarked(false));
        // NOTE: 해제의 경우 굳이 찜목록 데이터 새로 불러올 필요가 없어 직접 필터링.
        yield put(deleteBookmarkByCampId({ campid: id }));
        yield sendGtmDataLayer({
          ecommerce: null,
        });
        yield sendGtmDataLayer({
          event: 'remove_from_cart',
          ecommerce: {
            items: [
              {
                item_id: payload.campId,
                item_name: payload.campName,
                affiliation: 'Reservation Service',
                quantity: 1,
              },
            ],
          },
        });
      },
      failure,
    ),
  );
}

function* createBookmarkSaga() {
  yield takeLatest(
    createBookmarkRequest.type,
    createV2FetchFuncWithStringError(
      createBookmark,
      createBookmarkSuccess,
      createBookmarkFailure,
      undefined,
      function* success(data: any, payload: { camp: ICampGtmInfo }) {
        const isAppUser: string = yield select(
          (state: RootState) => state.bookmarkReducer.isAppUser,
        );
        const { camp } = payload;
        if (window.BootpayRNWebView || isAppUser) {
          yield put(setUserEnv('app'));
        } else if (isMobile()) {
          yield put(setUserEnv('mobile'));
        } else {
          yield put(setUserEnv('pc'));
        }
        yield put(setCampIsBookmarked(true));
        yield put(setIsBookmarkChanged(true));
        yield sendGtmDataLayer({
          ecommerce: null,
        });
        yield sendGtmDataLayer({
          event: 'add_to_cart',
          ecommerce: {
            items: [
              {
                item_id: camp.id,
                item_name: camp.name,
                affiliation: 'Reservation Service',
                quantity: 1,
              },
            ],
          },
        });
      },

      failure,
    ),
  );
}
function* getMyBookmarksSaga() {
  yield takeLatest(
    getMyBookmarksRequest.type,
    createFetchAction(
      getMyBookmarks,
      getMyBookmarksSuccess,
      getMyBookmarksFailure,
      function* onSuccess() {
        const skip: string = yield select(
          (state: RootState) =>
            state.bookmarkReducer.myBookmarksPagination.skip,
        );
        const wholeCount: string = yield select(
          (state: RootState) =>
            state.bookmarkReducer.myBookmarksPagination.wholeCount,
        );
        if (skip >= wholeCount) {
          // NOTE: 전체 데이터 로드가 끝나면, 북마크 데이터를 새로 불러올지 구분하는 변수의 초기값 세팅
          yield put(setIsBookmarkChanged(false));
        }
      },
    ),
  );
}

function* getMoreMyBookmarkSaga() {
  yield takeLatest(
    getMyBookmarksMoreRequest.type,
    createFetchAction(
      getMyBookmarks,
      getMyBookmarksSuccess,
      getMyBookmarksFailure,
      function* onSuccess() {
        const skip: string = yield select(
          (state: RootState) =>
            state.bookmarkReducer.myBookmarksPagination.skip,
        );
        const wholeCount: string = yield select(
          (state: RootState) =>
            state.bookmarkReducer.myBookmarksPagination.wholeCount,
        );
        if (skip >= wholeCount) {
          // NOTE: 전체 데이터 로드가 끝나면, 북마크 데이터를 새로 불러올지 구분하는 변수의 초기값 세팅
          yield put(setIsBookmarkChanged(false));
        }
      },
    ),
  );
}

function* getMyBookmarkCountSaga() {
  yield takeLatest(
    getMyBookmarkCountRequest.type,
    createFetchAction(
      getMyBookmarkCount,
      getMyBookmarkCountSuccess,
      getMyBookmarkCountFailure,
      function* onSuccess(data: { count: number; forceUpdate?: boolean }) {
        if (data.count > 0) {
          const limit: number = yield select(
            (state: RootState) =>
              state.bookmarkReducer.myBookmarksPagination.limit,
          );
          yield put(
            getMyBookmarksRequest({
              limit: 10,
              skip: 0,
              forceUpdate: data.forceUpdate ?? false,
            }),
          );
        }
      },
    ),
  );
}

function* deleteBulkBookmarkSaga() {
  yield takeLatest(
    deleteBulkBookmarkRequest.type,
    createV2FetchFuncWithStringError(
      deleteBulkBookmark,
      deleteBulkBookmarkSuccess,
      deleteBulkBookmarkFailure,
      undefined,
      function* success(
        data: {
          status: 'success' | 'fail';
          message: undefined | string;
          campIds: string[];
        },
        payload: any,
      ) {
        yield toast.success(
          `${payload.deleteCount}개의 캠핑장이 목록에서 삭제되었어요`,
        );
        yield put(deleteBookmarks({ campIds: data.campIds }));
        yield sendGtmDataLayer({
          ecommerce: null,
        });
        yield sendGtmDataLayer({
          event: 'remove_from_cart',
          ecommerce: {
            items: payload.RemoveFromCartItems,
          },
        });
      },
      failure,
    ),
  );
}

function* ChangeAppPushOnOffSaga() {
  yield takeLatest(
    changeAppPushOnOffRequest.type,
    createFetchActionWithFailResult(
      changeAppPushOnOff,
      changeAppPushOnOffSuccess,
      changeAppPushOnOffFailure,
      changeAppPushOnOffError,
    ),
  );
}
function* bookmarkSaga() {
  yield all([
    deleteBookmarkSaga(),
    createBookmarkSaga(),
    getMyBookmarksSaga(),
    getMoreMyBookmarkSaga(),
    getMyBookmarkCountSaga(),
    deleteBulkBookmarkSaga(),
    ChangeAppPushOnOffSaga(),
  ]);
}

export default bookmarkSaga;
