import styled from 'styled-components';
import SpinLoader from 'widgets/SpinLoader';
import PageContainer from 'widgets/PageContainer';
// import loadingImg from 'static/images/logos/loadingLogo.svg';
import loadingImg from 'static/images/logos/loadingLogo.png';

const Loading = () => {
  return (
    <PageContainer maxWidth={false} disableGutters $backgroundColor="white">
      <LoadingWrapper>
        <div className="loader-box">
          <SpinLoader />
          <Logo src={loadingImg} alt="loading" />
        </div>
      </LoadingWrapper>
    </PageContainer>
  );
};

export default Loading;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .loader-box {
    position: relative;
    height: fit-content;
  }
`;

const Logo = styled.img`
  width: 3.6rem;
  height: 3.6rem;
  margin: 0 auto;
  position: absolute;
  top: calc(50%);
  left: calc(50% - 1.8rem);
  transform: translateY(-50%);
`;
