import { put } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { selectSite, setReservationState } from 'store/reducers/reservation';
import { setZoneState } from 'store/reducers/zone';
import { setLongTermReservationState } from 'store/reducers/longStay';

function* setReservationInfoState() {
  const reservationStateInfo = JSON.parse(
    localStorage.getItem('reservationStateInfo') || 'null',
  );
  const zoneStateInfo = JSON.parse(
    localStorage.getItem('zoneStateInfo') || 'null',
  );

  if (!isEmpty(reservationStateInfo)) {
    const bookingType = reservationStateInfo.bookingType;

    // 장박은 다른 reducer에 저장
    if (bookingType === 'longStay') {
      yield put(setLongTermReservationState(reservationStateInfo));
      yield put(selectSite(reservationStateInfo.site));
    } else {
      yield put(setReservationState(reservationStateInfo));
    }
  }

  if (!isEmpty(zoneStateInfo)) {
    yield put(setZoneState(zoneStateInfo));
  }

  yield localStorage.removeItem('reservationStateInfo');
  yield localStorage.removeItem('zoneStateInfo');
}

export default setReservationInfoState;
