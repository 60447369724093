import {
  ISelfAuthenticationPayload,
  ISocialLoginSelfAuthenticationRequestPayload,
} from 'store/types';

const BootPay = require('bootpay-js');

export default function bootPaySelfAuthentication(
  params: ISocialLoginSelfAuthenticationRequestPayload,
) {
  const { userInfo, functions } = params;

  const { onSuccess, onCancel, onError } = functions;

  const id = Math.floor(Math.random() * 10000);

  BootPay.default
    .request({
      price: 0,
      application_id: process.env.REACT_APP_BOOTPAY_APPLICATION_ID as string,
      pg: 'danal',
      name: '본인인증',
      method: 'auth',
      show_agree_window: 0,
      order_id: `${Date.now()}${id}`,
      user_info: {
        phone: userInfo.phone,
      },
      extra: {
        age_limit: '14',
      },
    })
    .ready((data: any) => {
      // NOTE: 해당 함수 처리가 없으면, 앱에서 인증창이 뜨지 않음
      console.log(data);
    })
    .confirm((data: any) => {
      console.log(data);
      BootPay.default.transactionConfirm(data);
    })
    .error((data: any) => {
      console.log(data);
      onError && onError(data);
    })
    .cancel((data: any) => {
      console.log(data);
      onCancel && onCancel(data);
    })
    .done((data: ISelfAuthenticationPayload) => {
      onSuccess(data);
    });
}
