import {
  ServiceInventoryState,
  IOnSiteServicesPayload,
  IChangeOnSiteServicesRqPayload,
  IChangeQuantityPayload,
} from 'store/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChangableOnSiteService } from '@types';

const initialState: ServiceInventoryState = {
  inventory: {} as IChangableOnSiteService,
  error: '',
};

export const serviceInventorySlice = createSlice({
  name: 'serviceInventory',
  initialState,
  reducers: {
    getOnSiteServicesRequest: (
      state,
      action: PayloadAction<IOnSiteServicesPayload>,
    ) => {
      state.inventory = {} as IChangableOnSiteService;
      state.error = '';
    },
    getOnSiteServicesSuccess: (
      state,
      action: PayloadAction<IChangableOnSiteService>,
    ) => {
      state.inventory = action.payload;
      state.error = '';
    },
    getOnSiteServicesFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.inventory = {} as IChangableOnSiteService;
      state.error = action.payload;
    },

    plusQuantity: (state, action: PayloadAction<IChangeQuantityPayload>) => {
      const { id, timestamp } = action.payload;

      state.inventory.services.forEach(s => {
        if (s.id === id) {
          if (timestamp) {
            s.dailyUsages.forEach(d => {
              if (d.useTimestamp === timestamp) {
                d.dailyQuantity += 1;
              }
            });
          } else {
            s.quantity += 1;
          }
        }
      });
    },

    minusQuantity: (state, action: PayloadAction<IChangeQuantityPayload>) => {
      const { id, timestamp } = action.payload;

      state.inventory.services.forEach(s => {
        if (s.id === id) {
          if (timestamp) {
            s.dailyUsages.forEach(d => {
              if (d.useTimestamp === timestamp && d.dailyQuantity > 0) {
                d.dailyQuantity -= 1;
              }
            });
          } else if (s.quantity > 0) s.quantity -= 1;
        }
      });
    },

    clickServiceChangeBtn: (
      state,
      action: PayloadAction<IOnSiteServicesPayload>,
    ) => {
      state;
    },

    changeOnSiteServicesRequest: (
      state,
      action: PayloadAction<IChangeOnSiteServicesRqPayload>,
    ) => {
      state.error = '';
    },
    changeOnSiteServicesSuccess: (
      state,
      action: PayloadAction<IChangableOnSiteService>,
    ) => {
      state.error = '';
    },
    changeOnSiteServicesFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
  },
});

export const {
  getOnSiteServicesRequest,
  getOnSiteServicesSuccess,
  getOnSiteServicesFailure,
  plusQuantity,
  minusQuantity,
  clickServiceChangeBtn,
  changeOnSiteServicesRequest,
  changeOnSiteServicesSuccess,
  changeOnSiteServicesFailure,
} = serviceInventorySlice.actions;

export default serviceInventorySlice.reducer;
