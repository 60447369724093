import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import SEOHelmet from 'SEOHelmet';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createBrowserHistory } from 'history';
import sendGtmDataLayer from 'utils/sendGtmDataLayer';
import ChannelTalkService from 'utils/ChannelTalkService';
import ScrollToTop from 'hooks/useScrollToTop';
import SwitchRoute from 'SwitchRoute';
import Loading from 'pages/Loading';
import IERender from 'pages/IERender';
import { initAlgoliaRecommendIndex } from 'store/reducers/algoliaRecommend';
import { RootState } from 'store/reducers';
import { Product, Wrapper } from 'widgets/RootWrapper';
import PCContents from 'components/PCContents';
import NewToast from 'components/common/NewToast';
import {
  getUserTrackingInfoRequest,
  initAlgoliaInsight,
  initAlgoliaSearchIndex,
} from 'store/reducers/algoliaSearch';
import { setChatTotalCount } from 'store/reducers/channerTalkService';
import ErrorModalComponent from 'ErrorModalComponent';
import { toast } from 'react-toastify';
import { setToastContent } from 'store/reducers/common';
import { ErrorBoundary } from 'react-error-boundary';
import styled, { css } from 'styled-components';
import Typography from 'components/atoms/Typography';
import NewBasicButton from 'components/atoms/NewBasicButton';
import { getEnvironment } from 'utils/common';
import { postSlackNotiFrontendError } from 'api/slack';
import { enableMapSet } from 'immer';

export const customHistory = createBrowserHistory();

// Internet Explorer 6-11
const isIE =
  navigator.userAgent.indexOf('MSIE') !== -1 ||
  !!document.documentMode === true;

export const App = () => {
  const googleClientId = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID as string;

  const dispatch = useDispatch();
  const isLoggedIn = localStorage.getItem('jwt');
  const isSagaInitCompleted = useSelector(
    (state: RootState) => state.initReducer.isSagaInitCompleted,
  );

  const isLoadingUserTrackingInfo = useSelector(
    (state: RootState) => state.loadingReducer[getUserTrackingInfoRequest.type],
  );

  const isLoadinginitAlgoliaSearchIndex = useSelector(
    (state: RootState) => state.loadingReducer[initAlgoliaSearchIndex.type],
  );

  const { trackingId, channelTalkMemberInfo } = useSelector(
    (state: RootState) => state.algoliaSearchReducer,
  );

  const errorModalContent = useSelector(
    (state: RootState) => state.commonReducer.errorModal,
  );

  const toastContent = useSelector(
    (state: RootState) => state.commonReducer.toast,
  );

  useEffect(() => {
    enableMapSet();

    dispatch(initAlgoliaRecommendIndex());
  }, []);

  useEffect(() => {
    if (toastContent) {
      toast.success(toastContent.message, {
        toastId: toastContent.id,
        autoClose: 800,
        onClose: () => {
          dispatch(setToastContent(undefined));
        },
        ...(toastContent.option && toastContent.option),
      });
    }
  }, [toastContent?.id, toastContent?.message]);

  useEffect(() => {
    if (isLoggedIn) dispatch(getUserTrackingInfoRequest());
    else sendGtmDataLayer({ algoliaUserToken: null });
  }, []);

  useEffect(() => {
    if (isLoadingUserTrackingInfo === false) {
      sendGtmDataLayer({ algoliaUserToken: trackingId || null });
    }
  }, [isLoadingUserTrackingInfo, trackingId]);

  useEffect(() => {
    dispatch(initAlgoliaSearchIndex('recommendDesc'));
    dispatch(initAlgoliaInsight());
  }, []);

  useEffect(() => {
    ChannelTalkService.boot(
      {
        pluginKey: process.env.REACT_APP_CHANNEL_TALK_PLUGIN_KEY as string,
        hideChannelButtonOnBoot: true,
        mobileMessengerMode: 'iframe',
        ...channelTalkMemberInfo,
      },
      (error: any, user: any) => {
        ChannelTalkService.onBadgeChanged((count: number) => {
          dispatch(setChatTotalCount(count));
        });
        ChannelTalkService.onChatCreated(() => {
          ChannelTalkService.setIsChatOpen(true);
        });
        ChannelTalkService.onHideMessenger(() => {
          ChannelTalkService.setIsChatOpen(false);
        });
        ChannelTalkService.onShowMessenger(() => {
          ChannelTalkService.setIsChatOpen(true);
        });
        error && console.log('* channelTalk error :: ', error);
      },
    );

    return () => ChannelTalkService.clearCallbacks();
  }, [channelTalkMemberInfo]);

  if (
    !isSagaInitCompleted ||
    isLoadingUserTrackingInfo ||
    isLoadinginitAlgoliaSearchIndex
  ) {
    return <Loading />;
  }

  document.oncontextmenu = () => {
    if (customHistory.location.pathname.includes('/log')) {
      return true;
    }

    if (!customHistory.location.pathname.includes('/board')) {
      return false;
    }
  };

  document.ondragstart = () => {
    if (
      customHistory.location.pathname.includes('/log') &&
      !customHistory.location.pathname.includes('/edit')
    ) {
      return true;
    }

    if (!customHistory.location.pathname.includes('/board')) {
      return false;
    }
  };

  document.onselectstart = () => {
    if (
      customHistory.location.pathname.includes('/log') &&
      !customHistory.location.pathname.includes('/edit')
    ) {
      return true;
    }

    if (!customHistory.location.pathname.includes('/board')) {
      return false;
    }
  };

  const NotProductionErrorFallback = ({
    error,
    resetErrorBoundary,
  }: {
    error: Error;
    resetErrorBoundary: () => void;
  }) => {
    const { name, message, stack } = error;
    return (
      <ErrorContainer>
        <Typography weight="bold" variant="title1" color="red">
          Something went wrong.
        </Typography>

        <Typography
          weight="regular"
          variant="footnote"
          color="blue"
          cssObject={css`
            padding-bottom: 16px;
          `}
        >
          이 화면이 보인다면 스크린샷을 찍어 프로덕트팀에게 전달해주세요.
        </Typography>

        <Typography weight="regular" variant="caption2">
          <span>path : </span>
          {window.location.href}
        </Typography>

        <Typography weight="regular" variant="caption2">
          <span>name : </span>
          {name}
        </Typography>

        <Typography weight="regular" variant="caption2">
          <span>message : </span>
          {message}
        </Typography>

        <Typography weight="regular" variant="caption2">
          <span>stack : </span>
          {stack}
        </Typography>

        <div className="button-container">
          <NewBasicButton onClick={resetErrorBoundary}>
            Try again
          </NewBasicButton>
          <NewBasicButton
            onClick={() => {
              customHistory.push('/');
              window.location.reload();
            }}
          >
            Go to home
          </NewBasicButton>
        </div>
      </ErrorContainer>
    );
  };

  const productionFallback = () => {
    return (
      <>
        <div onClick={() => {}} onKeyDown={() => {}} />
      </>
    );
  };

  return (
    <Wrapper>
      <PCContents />
      <Product>
        <GoogleOAuthProvider clientId={googleClientId}>
          {isIE ? (
            <IERoute />
          ) : (
            <Suspense fallback={<Loading />}>
              {getEnvironment() === 'production' ? (
                <ErrorBoundary
                  FallbackComponent={productionFallback}
                  onError={(error, info) => {
                    postSlackNotiFrontendError(error, info);
                  }}
                >
                  <Router history={customHistory}>
                    <SEOHelmet />
                    <ScrollToTop />
                    <SwitchRoute />
                    <ErrorModalComponent
                      errorModalContent={errorModalContent}
                    />
                    <NewToast />
                  </Router>
                </ErrorBoundary>
              ) : (
                <ErrorBoundary
                  FallbackComponent={NotProductionErrorFallback}
                  onError={(error, info) => {
                    console.log('================================');
                    console.log('error : ', error);
                    console.log('info : ', info);

                    if (getEnvironment() !== 'local') {
                      postSlackNotiFrontendError(error, info);
                    }
                  }}
                >
                  <Router history={customHistory}>
                    <SEOHelmet />
                    <ScrollToTop />
                    <SwitchRoute />
                    <ErrorModalComponent
                      errorModalContent={errorModalContent}
                    />
                    <NewToast />
                  </Router>
                </ErrorBoundary>
              )}
            </Suspense>
          )}
        </GoogleOAuthProvider>
      </Product>
    </Wrapper>
  );
};

const IERoute = () => {
  return (
    <Router history={customHistory}>
      <Switch>
        <Route component={IERender} path="/" />
      </Switch>
    </Router>
  );
};

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;

  span {
    font-weight: bold;
  }

  .button-container {
    width: 100%;
    display: flex;
    gap: 8px;
    margin-top: 16px;
  }
`;
