import { all, takeEvery } from 'redux-saga/effects';
import {
  setActiveTab,
  getShopbyPointSummaryRequest,
  getShopbyPointCountSummarySuccess,
  getShopbyPointCountSummaryError,
  getShopbyPointListRequest,
  getShopbyPointListSuccess,
  getShopbyPointListError,
  getShopbyPointListMore,
} from 'store/reducers/shopbyPoint';
import {
  createShopbyFetchAction,
  createShopbyFetchActionWithKey,
} from './createFetchAction';
import {
  getShopbyMyPointList,
  getShopbyMyPointSummary,
} from '../../api/shopby';

function* getShopbyPointSummarySaga() {
  yield takeEvery(
    getShopbyPointSummaryRequest.type,
    createShopbyFetchAction(
      getShopbyMyPointSummary,
      getShopbyPointCountSummarySuccess,
      getShopbyPointCountSummaryError,
    ),
  );
}

function* getShopbyPointListSaga() {
  yield takeEvery(
    [getShopbyPointListRequest.type, getShopbyPointListMore.type],
    createShopbyFetchActionWithKey(
      getShopbyMyPointList,
      getShopbyPointListSuccess,
      getShopbyPointListError,
    ),
  );
}

export default function* shopbyPointSaga() {
  yield all([getShopbyPointSummarySaga(), getShopbyPointListSaga()]);
}
