import { takeEvery } from 'redux-saga/effects';
import {
  getBestPhotosFailure,
  getBestPhotosRequest,
  getBestPhotosSuccess,
} from 'store/reducers/bestPhotos';
import { getBestPhotos } from 'api';
import { createFetchAction } from './createFetchAction';

export default function* fetchBestPhotosSaga() {
  yield takeEvery(
    getBestPhotosRequest.type,
    createFetchAction(
      getBestPhotos,
      getBestPhotosSuccess,
      getBestPhotosFailure,
    ),
  );
}
