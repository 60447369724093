import { takeLatest, all, put } from 'redux-saga/effects';
import {
  uploadMyItemFailure,
  uploadMyItemRequest,
  uploadMyItemSuccess,
  uploadMyReviewRequest,
  reviseMyItemRequest,
  reviseMyItemSuccess,
  reviseMyItemFailure,
  reviseMyItemAsEquipmentRequest,
  deleteMyItemRequest,
  deleteMyItemSuccess,
  deleteMyItemFailure,
  checkProductRequest,
  checkProductSuccess,
  checkProductFailure,
} from 'store/reducers/myItems';
import { uploadMyItem, reviseMyItem, deleteMyItem, checkProduct } from 'api';
import { selectProduct } from 'store/reducers/productPick';
import { showToast } from 'store/reducers/toast';
import { ICheckProductResponse, IProductEditResponse } from '@types';
import { toast } from 'react-toastify';
import { removeSourcePath } from '../../utils/sessionStorageUtils';
import { createFetchAction } from './createFetchAction';
import { customHistory } from '../../App';
import { failure } from './failure';

// 내장비(실사용자 등록) 작성 시
function* uploadMyItemSaga() {
  yield takeLatest(
    uploadMyItemRequest.type,
    createFetchAction(
      uploadMyItem,
      uploadMyItemSuccess,
      uploadMyItemFailure,
      function* success() {
        yield customHistory.replace('/complete/registering/real-user');
      },
      failure,
    ),
  );
}

// 리뷰만 등록, 내장비(실사용자 등록) X
function* uploadReviewSaga() {
  yield takeLatest(
    uploadMyReviewRequest.type,
    createFetchAction(
      uploadMyItem,
      uploadMyItemSuccess,
      uploadMyItemFailure,
      function* success(data: IProductEditResponse) {
        yield customHistory.replace(`/product/reviews/${data.equipmentId}`);
        toast.success('등록되었습니다');
      },
      failure,
    ),
  );
}

// 리뷰만 수정 시
function* reviseMyItemSaga() {
  yield takeLatest(
    reviseMyItemRequest.type,
    createFetchAction(
      reviseMyItem,
      reviseMyItemSuccess,
      reviseMyItemFailure,
      function* success(data: IProductEditResponse) {
        yield customHistory.replace(`/product/reviews/${data.equipmentId}`);
        toast.success('수정되었습니다');
      },
      failure,
    ),
  );
}

// 리뷰 수정 시, 내 장비(실사용자)로 등록하면
function* reviseMyItemAsMyEquipmentSaga() {
  yield takeLatest(
    reviseMyItemAsEquipmentRequest.type,
    createFetchAction(
      reviseMyItem,
      reviseMyItemSuccess,
      reviseMyItemFailure,
      function* success(data: IProductEditResponse) {
        yield customHistory.replace('/complete/registering/real-user');
        toast.success('수정되었습니다');
      },
      failure,
    ),
  );
}

function* deleteMyItemSaga() {
  yield takeLatest(
    deleteMyItemRequest.type,
    createFetchAction(
      deleteMyItem,
      deleteMyItemSuccess,
      deleteMyItemFailure,
      function success() {
        toast.success(`삭제되었습니다`);
        removeSourcePath();

        if (customHistory.location.pathname.includes('product/reviews')) {
          customHistory.go(-1);
        }
      },
      failure,
    ),
  );
}

function* checkProductSaga() {
  yield takeLatest(
    checkProductRequest.type,
    createFetchAction(
      checkProduct,
      checkProductSuccess,
      checkProductFailure,
      function* success(data: ICheckProductResponse) {
        const { isRegistered, product } = data;

        if (!isRegistered && product) {
          const { brand, name, id, category, thumbnails } = product;
          yield put(
            selectProduct({
              brand: brand?.name,
              name,
              id,
              categoryId: category?.id,
              thumbnail: thumbnails[0],
            }),
          );
        }
      },
      failure,
    ),
  );
}

export default function* myItemsEditSaga() {
  yield all([
    uploadMyItemSaga(),
    uploadReviewSaga(),
    reviseMyItemSaga(),
    reviseMyItemAsMyEquipmentSaga(),
    deleteMyItemSaga(),
    checkProductSaga(),
  ]);
}
