import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import PageContainer from 'widgets/PageContainer';
import IE from 'static/images/IE.png';
import { ReactComponent as Chrome } from 'static/icons/ieRender/chrome.svg';
import logo from 'static/images/logos/mainLogo.png';

const IERender = () => {
  const location = useLocation();

  useEffect(() => {
    if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
      window.location.href = `microsoft-edge:https://camfit.co.kr${location.pathname}`;
    }
  }, []);

  return (
    <PageContainer maxWidth={false} disableGutters bg="bw95">
      <IEWrapper>
        <IEContainer>
          <div>
            <Guide>
              <img src={IE} alt="ie" />
              <p className="mention">
                인터넷 익스플로러는 <br />
                서비스 이용이 어려워요
              </p>
              <p>
                더 나은 서비스 경험을 위해서
                <br />
                크롬 브라우저 다운로드를 권장드립니다.
              </p>
            </Guide>
            <a
              href="https://www.google.com/intl/ko/chrome/"
              target="_blank"
              rel="noreferrer"
            >
              <ChromeBtn>
                <Chrome />
                크롬 브라우저 다운로드하기
              </ChromeBtn>
            </a>
          </div>
        </IEContainer>
        <img src={logo} className="logo" alt="camfit" />
      </IEWrapper>
    </PageContainer>
  );
};

export default IERender;

const IEWrapper = styled.div`
  background: ${({ theme }) => theme.colors.bw100};
  height: 100vh;
  .logo {
    display: block;
    margin: 0 auto;
    width: auto;
    height: 20px;
  }
`;

const IEContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
`;

const Guide = styled.div`
  width: 234px;
  text-align: center;

  img {
    display: block;
    margin: 0 auto;
  }

  .mention {
    margin: 27px 0 14px 0;
    font-size: ${({ theme }) => theme.fontSizes.h18};
    line-height: 27px;
    color: ${({ theme }) => theme.colors.bw30};
    font-weight: 500;
  }

  p {
    color: ${({ theme }) => theme.colors.bw50};
    line-height: 24px;
  }
`;

const ChromeBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  padding: 16px 0;
  margin-top: 40.5px;
  border: 1px solid ${({ theme }) => theme.colors.bw85};
  border-radius: 8px;
  font-weight: 700;
`;
