import { toast } from 'react-toastify';
import { all, takeEvery } from 'redux-saga/effects';
import {
  getSampleShopbyItemReviewListRequest,
  getSampleShopbyItemReviewListSuccess,
  getSampleShopbyItemReviewListFailure,
  getShopbyItemReviewListRequest,
  getMoreShopbyItemReviewListRequest,
  getShopbyItemReviewListSuccess,
  getShopbyItemReviewListFailure,
  getSampleShopbyItemPhotoReviewListRequest,
  getSampleShopbyItemPhotoReviewListSuccess,
  getSampleShopbyItemPhotoReviewListFailure,
  getShopbyItemPhotoReviewListRequest,
  getMoreShopbyItemPhotoReviewListRequest,
  getShopbyItemPhotoReviewListSuccess,
  getShopbyItemPhotoReviewListFailure,
  getShopbyItemPhotoReviewDetailRequest,
  getShopbyItemPhotoReviewDetailSuccess,
  getShopbyItemPhotoReviewDetailFailure,
  postShopbyItemReviewReportRequest,
  postShopbyItemReviewReportSuccess,
  postShopbyItemReviewReportFailure,
} from 'store/reducers/shopbyReview';
import {
  getShopbyItemReviewList,
  getShopbyPhotoReviewDetail,
  getShopbyPhotoReviewList,
  postShopbyReviewReport,
} from 'api/shopby';
import { ITShopbyErrorRes } from '@types';
import { createShopbyFetchAction } from './createFetchAction';

function* getSampleShopbyItemReviewListSaga() {
  yield takeEvery(
    getSampleShopbyItemReviewListRequest.type,
    createShopbyFetchAction(
      getShopbyItemReviewList,
      getSampleShopbyItemReviewListSuccess,
      getSampleShopbyItemReviewListFailure,
    ),
  );
}

function* getShopbItemyReviewListSga() {
  yield takeEvery(
    [
      getShopbyItemReviewListRequest.type,
      getMoreShopbyItemReviewListRequest.type,
    ],
    createShopbyFetchAction(
      getShopbyItemReviewList,
      getShopbyItemReviewListSuccess,
      getShopbyItemReviewListFailure,
      undefined,
      undefined,
      function onError(errorPayload: ITShopbyErrorRes) {
        alert(errorPayload.message);
      },
    ),
  );
}

function* getSampleShopbyItemPhotoReviewListSaga() {
  yield takeEvery(
    getSampleShopbyItemPhotoReviewListRequest.type,
    createShopbyFetchAction(
      getShopbyPhotoReviewList,
      getSampleShopbyItemPhotoReviewListSuccess,
      getSampleShopbyItemPhotoReviewListFailure,
    ),
  );
}

function* getShopbyPhotoReviewListSaga() {
  yield takeEvery(
    [
      getShopbyItemPhotoReviewListRequest.type,
      getMoreShopbyItemPhotoReviewListRequest,
    ],
    createShopbyFetchAction(
      getShopbyPhotoReviewList,
      getShopbyItemPhotoReviewListSuccess,
      getShopbyItemPhotoReviewListFailure,
    ),
  );
}

function* getShopbyPhotoReviewDetailSaga() {
  yield takeEvery(
    getShopbyItemPhotoReviewDetailRequest.type,
    createShopbyFetchAction(
      getShopbyPhotoReviewDetail,
      getShopbyItemPhotoReviewDetailSuccess,
      getShopbyItemPhotoReviewDetailFailure,
    ),
  );
}

function* postShopbyItemReviewReportSaga() {
  yield takeEvery(
    postShopbyItemReviewReportRequest.type,
    createShopbyFetchAction(
      postShopbyReviewReport,
      postShopbyItemReviewReportSuccess,
      postShopbyItemReviewReportFailure,
      undefined,
      function onSuccess() {
        toast.success('상품 리뷰 신고 완료');
      },
    ),
  );
}

export default function* shopbyItemReviewSaga() {
  yield all([
    getSampleShopbyItemReviewListSaga(),
    getShopbItemyReviewListSga(),
    getSampleShopbyItemPhotoReviewListSaga(),
    getShopbyPhotoReviewListSaga(),
    getShopbyPhotoReviewDetailSaga(),
    postShopbyItemReviewReportSaga(),
  ]);
}
