import {
  takeLatest,
  take,
  all,
  takeEvery,
  select,
  put,
} from 'redux-saga/effects';
import {
  getV2CouponListRequest,
  getV2CouponListMore,
  getV2CouponListSuccess,
  getV2CouponListFailure,
  getMyCouponsCountRequest,
  getMyCouponsCountSuccess,
  getMyCouponsCountFailure,
  getMyCouponListRequest,
  getMyCouponListSuccess,
  getMyCouponListFailure,
  issueV2CouponRequest,
  issueV2CouponSuccess,
  issueV2CouponFailure,
  downloadV2CouponRequest,
  downloadV2CouponSuccess,
  downloadV2CouponFailure,
  downloadCamfitCouponRequest,
  downloadCamfitCouponSuccess,
  downloadCamfitCouponFailure,
  getCouponDetailRequest,
  getCouponDetailSuccess,
  getCouponDetailFailure,
} from 'store/reducers/v2Coupon';
import {
  createFetchAction,
  createFetchActionWithKey,
} from 'store/sagas/createFetchAction';
import {
  countUserCoupons,
  getCouponDetail,
  getMyCouponList,
  getV2CouponList,
  issueV2Coupon,
} from 'api';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { RootState } from 'store/reducers';
import { TCouponListName } from 'store/types';
import { customHistory } from 'App';
import sendGtmDataLayer, {
  sendCouponGtmDataLayer,
} from 'utils/sendGtmDataLayer';
import { getDiscountPhrase } from 'utils/coupon';
import { IV2CouponIssueResponse } from '@types';

function* getV2CouponListSaga() {
  yield takeLatest(
    getV2CouponListRequest.type,
    createFetchActionWithKey(
      getV2CouponList,
      getV2CouponListSuccess,
      getV2CouponListFailure,
    ),
  );
}

function* getMyCouponCountSaga() {
  yield takeEvery(
    getMyCouponsCountRequest.type,
    createFetchAction(
      countUserCoupons,
      getMyCouponsCountSuccess,
      getMyCouponsCountFailure,
    ),
  );
}

function* getMyCouponListSaga() {
  yield takeEvery(
    getMyCouponListRequest.type,
    createFetchAction(
      getMyCouponList,
      getMyCouponListSuccess,
      getMyCouponListFailure,
    ),
  );
}

function* issueCouponSaga() {
  yield takeLatest(
    issueV2CouponRequest.type,
    createFetchActionWithKey(
      issueV2Coupon,
      issueV2CouponSuccess,
      issueV2CouponFailure,
      undefined,
      function* success(res: IV2CouponIssueResponse) {
        yield sendCouponGtmDataLayer({
          camp: res.campName,
          name: res.couponName,
          type: res.couponTarget,
          masterCode: res.masterCode,
          issuance: '코드 입력',
          price: getDiscountPhrase(res),
        });

        yield sendGtmDataLayer({
          event: 'booking_coupon_download',
          item_name: 'register',
          event_category: 'true',
          event_label: 'success',
        });

        yield customHistory.goBack();
        yield window.sessionStorage.setItem('isRegisterCoupon', 'true');
      },
    ),
  );
}

function* downloadCouponSaga() {
  yield takeLatest(
    downloadV2CouponRequest.type,
    createFetchActionWithKey(
      issueV2Coupon,
      downloadV2CouponSuccess,
      downloadV2CouponFailure,
    ),
  );
}

function* getV2CouponListMoreSaga() {
  while (true) {
    const action: PayloadAction<{
      key: TCouponListName;
      params: { campId: string };
    }> = yield take(getV2CouponListMore.type);

    const {
      payload: {
        key,
        params: { campId },
      },
    } = action;

    const {
      [key]: { paging },
    } = yield select((state: RootState) => state.v2CouponReducer);

    const { hasMore, ...pagingParams } = paging;

    yield put(
      getV2CouponListRequest({ key, params: { ...pagingParams, campId } }),
    );
  }
}

function* downloadCamfitCouponSaga() {
  yield takeLatest(
    downloadCamfitCouponRequest.type,
    createFetchActionWithKey(
      issueV2Coupon,
      downloadCamfitCouponSuccess,
      downloadCamfitCouponFailure,
    ),
  );
}

function* getCoupontDetailSaga() {
  yield takeEvery(
    getCouponDetailRequest.type,
    createFetchActionWithKey(
      getCouponDetail,
      getCouponDetailSuccess,
      getCouponDetailFailure,
    ),
  );
}
export default function* v2CouponSaga() {
  yield all([
    getV2CouponListSaga(),
    getV2CouponListMoreSaga(),
    getMyCouponCountSaga(),
    getMyCouponListSaga(),
    issueCouponSaga(),
    downloadCouponSaga(),
    downloadCamfitCouponSaga(),
    getCoupontDetailSaga(),
  ]);
}
