import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAuthNumRequestPayload,
  AuthState,
  ISendAuthNumRequestPayload,
} from 'store/types';
import { ICertResponse, ICheckAuthResponse } from '@types';

const initialState: AuthState = {
  certResponse: {} as ICertResponse,
  authenticationRes: {} as ICheckAuthResponse,
  isCertSent: false,
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    sendAuthenticationNumRequest: (
      state,
      action: PayloadAction<ISendAuthNumRequestPayload>,
    ) => {
      state.certResponse = {} as ICertResponse;
      state.authenticationRes = {} as ICheckAuthResponse;
      state.isCertSent = false;
      state.error = null;
    },
    sendAuthenticationNumSuccess: (
      state,
      action: PayloadAction<ICertResponse>,
    ) => {
      state.certResponse = action.payload;
      state.isCertSent = action.payload.status === 'success';
      state.error = null;
    },
    sendAuthenticationNumFailure: (
      state,
      action: PayloadAction<AxiosError>,
    ) => {
      state.certResponse = {} as ICertResponse;
      state.authenticationRes = {} as ICheckAuthResponse;
      state.isCertSent = false;
      state.error = action.payload;
    },

    authenticateNumRequest: (
      state,
      action: PayloadAction<IAuthNumRequestPayload>,
    ) => {
      state.authenticationRes = {} as ICheckAuthResponse;
      state.error = null;
    },
    authenticateNumSuccess: (
      state,
      action: PayloadAction<ICheckAuthResponse>,
    ) => {
      state.authenticationRes = action.payload;
      state.isCertSent = action.payload.status !== 'success';
      state.error = null;
    },
    authenticateNumFailure: (state, action: PayloadAction<AxiosError>) => {
      state.authenticationRes = {} as ICheckAuthResponse;
      state.error = action.payload;
    },

    clearResponse: state => {
      state.certResponse = {} as ICertResponse;
      state.authenticationRes = {} as ICheckAuthResponse;
      state.isCertSent = false;
    },
  },
});

export const {
  authenticateNumRequest,
  authenticateNumSuccess,
  authenticateNumFailure,
  sendAuthenticationNumRequest,
  sendAuthenticationNumSuccess,
  sendAuthenticationNumFailure,
  clearResponse,
} = authSlice.actions;

export default authSlice.reducer;
