import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TNotionInitialState } from 'store/types';

const initialState: TNotionInitialState = {
  notionDbData: undefined,
  notionPageBlocks: undefined,
  notionBlock: undefined,
  notionBlockChildren: undefined,
  hasMore: true,
  offset: 0,
  skip: 0,
  limit: 10,
  error: '',
};

export const notionSlice = createSlice({
  name: 'notion',
  initialState,
  reducers: {
    getNotionDbRequest: (state, action: PayloadAction<any>) => {},
    getNotionDbSuccess: (state, action: PayloadAction<any>) => {
      state.notionDbData = action.payload.data;
    },
    getNotionDbFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    getNotionPageRequest: (state, action: PayloadAction<any>) => {},
    getNotionPageSuccess: (state, action: PayloadAction<any>) => {
      state.notionPageBlocks = action.payload.data;
    },
    getNotionPageFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    getNotionBlockRequest: (state, action: PayloadAction<any>) => {},
    getNotionBlockSuccess: (state, action: PayloadAction<any>) => {
      state.notionBlock = action.payload.data;
    },
    getNotionBlockFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    getNotionBlockChildrenRequest: (state, action: PayloadAction<any>) => {},
    getNotionBlockChildrenSuccess: (state, action: PayloadAction<any>) => {
      state.notionBlockChildren = action.payload.data;
    },
    getNotionBlockChildrenFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
    resetNotionDbData: state => {
      state.notionDbData = undefined;
    },
    resetPageData: state => {
      state.notionPageBlocks = undefined;
    },
    resetNotionBlock: state => {
      state.notionBlock = undefined;
    },

    resetAll: state => {
      return initialState;
    },
    createNotionPageRequest: (state, action: PayloadAction<any>) => {},
    createNotionPageSuccess: (state, action: PayloadAction<any>) => {},
    createNotionPageFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  getNotionDbRequest,
  getNotionDbSuccess,
  getNotionDbFailure,
  getNotionPageRequest,
  getNotionPageSuccess,
  getNotionPageFailure,
  getNotionBlockRequest,
  getNotionBlockSuccess,
  getNotionBlockFailure,
  getNotionBlockChildrenRequest,
  getNotionBlockChildrenSuccess,
  getNotionBlockChildrenFailure,
  resetNotionDbData,
  resetPageData,
  resetNotionBlock,
  resetAll,
  createNotionPageRequest,
  createNotionPageSuccess,
  createNotionPageFailure,
} = notionSlice.actions;

export default notionSlice.reducer;
