export const getCookie = (name: string) => () => {
  const value = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);

  return value ? value[2] : null;
};

export const setCookie = (name: string, value: number, day: number) => {
  const now = new Date();
  now.setTime(now.getTime() + day * 60 * 60 * 24 * 1000);

  document.cookie = `${name}=${value}; expires=${now.toUTCString()}; path=/`;
};

export const setCookieWithExpiry = (
  name: string,
  value: string,
  minutes: number,
) => {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + minutes * 60 * 1000);

  const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
  document.cookie = cookieString;
};

export const deleteCookie = (name: string) => {
  const expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() - 1); // 현재 날짜로부터 1년 전으로 설정

  const cookieString = `${name}=; expires=${expirationDate.toUTCString()}; path=/`;
  document.cookie = cookieString;
};

export const getCookieDirect = (name: string): string | null => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split('; ');

  for (const cookie of cookieArray) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      return cookieValue;
    }
  }

  return null;
};
