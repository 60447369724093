import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IShopbyItems,
  IShopbyProductSearchRequest,
  IShopbyProductSearchResponse,
  ITShopbyErrorRes,
} from '@types';

interface InitState {
  /**
   * 모아보기 알약 클릭 시 스켈레톤 UI를 보여주기 위해 skeleton 변수를 여기에 만듬.
   * (기존의 loadingReducer로는 API 통신 req, res에 밖에 처리를 못하기 때문.)
   */
  isSkeletonOn: boolean;
  items: IShopbyItems[];
  activeTab: number;
  pageNumber: number;
  /**
   * 스토어 홈에서 다른 경로로 이동 후 다시 돌아왔을 때, 기존에 받아왔던 activeTab의 pageNumber 데이터를 다시 요청하지 않게 하기 위한 변수.
   */
  isTouring: boolean;
}

const initialState: InitState = {
  isSkeletonOn: false,
  items: [],
  activeTab: 0,
  pageNumber: 1,
  isTouring: false,
};

export const shopbyProductSearchSlice = createSlice({
  name: 'shopbyProductSearch',
  initialState,
  reducers: {
    onSkeleton: state => {
      state.isSkeletonOn = true;
    },
    resetItems: state => {
      state.items = [];
    },
    resetPageNumber: state => {
      state.pageNumber = 1;
    },
    getShopbyProductSearchRequest: (
      state,
      action: PayloadAction<IShopbyProductSearchRequest>,
    ) => {},
    getShopbyProductSearchSuccess: (
      state,
      action: PayloadAction<IShopbyProductSearchResponse>,
    ) => {
      state.items.push(...action.payload.items);
      state.isSkeletonOn = false;
    },
    getShopbyProductSearchFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.isSkeletonOn = false;
    },
    changeActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
    increasePageNumber: state => {
      state.pageNumber += 1;
    },
    onTouring: state => {
      state.isTouring = true;
    },
    offTouring: state => {
      state.isTouring = false;
    },
  },
});

export const {
  onSkeleton,
  resetItems,
  resetPageNumber,
  getShopbyProductSearchRequest,
  getShopbyProductSearchSuccess,
  getShopbyProductSearchFailure,
  changeActiveTab,
  increasePageNumber,
  onTouring,
  offTouring,
} = shopbyProductSearchSlice.actions;

export default shopbyProductSearchSlice.reducer;
