import { takeLatest } from 'redux-saga/effects';
import { mainRequest, mainSuccess, mainFailure } from 'store/reducers/main';
import { createV2FetchFuncWithStringError } from 'store/sagas/createFetchAction';
import { getV2Main } from 'api';

function* getMainSaga() {
  yield takeLatest(
    mainRequest.type,
    createV2FetchFuncWithStringError(getV2Main, mainSuccess, mainFailure),
  );
}

export default function* mainSaga() {
  yield getMainSaga();
}
