import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IResponse } from '@types';
import { LogoutState } from 'store/types';

const initialState: LogoutState = {
  error: '',
};

export const logoutSlice = createSlice({
  name: 'logout',
  initialState,
  reducers: {
    clickLogout: (state, action: PayloadAction<boolean>) => {
      state;
    },
    logoutInAppRequest: (state, action: PayloadAction<string>) => {
      state.error = '';
    },
    logoutInAppSuccess: (state, action: PayloadAction<IResponse>) => {
      state.error = '';
    },
    logoutInAppFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  clickLogout,
  logoutInAppRequest,
  logoutInAppSuccess,
  logoutInAppFailure,
} = logoutSlice.actions;

export default logoutSlice.reducer;
