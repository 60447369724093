import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import algoliarecommend from '@algolia/recommend';
import {
  recommendProductPageSize,
  relatedProductDataConst,
  trendingProductDataConst,
} from 'constants/AlgoliaConstants';

interface IAlgoliaRecommend {
  recommendClient: any;
  algoliaTrendingItems: any;
  algoliaRelatedProducts: any;
  error: string | Error;
}

const initialState: IAlgoliaRecommend = {
  recommendClient: null,
  algoliaTrendingItems: null,
  algoliaRelatedProducts: null,
  error: '',
};

export const algoliaRecommendSlice = createSlice({
  name: 'algoliaRecommend',
  initialState,
  reducers: {
    initAlgoliaRecommendIndex: state => {
      if (!state.recommendClient) {
        state.recommendClient = algoliarecommend(
          process.env.REACT_APP_ALGOLIA_APP_ID as string,
          process.env.REACT_APP_ALGOLIA_API_KEY as string,
        );
      }
    },
    algoliaGetTrendingItemsRequest: (state, action: PayloadAction<any>) => {
      state.error = '';
    },
    algoliaGetTrendingItemsSuccess: (state, action: PayloadAction<any>) => {
      const products = action.payload?.results[0]?.hits?.filter(
        (product: any) => product.status === 'linked',
      );

      const trendingProductData = {
        products,
        skip: 0,
      };
      localStorage.setItem(
        trendingProductDataConst,
        JSON.stringify(trendingProductData),
      );
      state.algoliaTrendingItems = trendingProductData;
    },
    algoliaGetTrendingItemsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
      state.algoliaTrendingItems = null;
    },
    algoliaSetTrendingItems: (state, action: PayloadAction<any>) => {
      const productsLength = action.payload?.products?.length;
      const productsSkip = action.payload?.skip;

      let newSkip = 0;
      if (productsSkip + recommendProductPageSize >= productsLength) {
        newSkip = productsSkip + recommendProductPageSize - productsLength;
      } else newSkip = productsSkip + recommendProductPageSize;

      state.algoliaTrendingItems = { ...action.payload, skip: newSkip };
      localStorage.setItem(
        trendingProductDataConst,
        JSON.stringify(state.algoliaTrendingItems),
      );
    },
    algoliaGetRelatedProductsRequest: (state, action: PayloadAction<any>) => {
      state.error = '';
    },
    algoliaGetRelatedProductsSuccess: (state, action: PayloadAction<any>) => {
      const products = action.payload?.res?.results[0]?.hits?.filter(
        (product: any) => product.status === 'linked',
      );
      const relatedProductData = {
        products,
        lastViewedProduct: action.payload?.objectID,
        skip: 0,
        expiryDate: new Date().getTime(),
      };
      localStorage.setItem(
        relatedProductDataConst,
        JSON.stringify(relatedProductData),
      );
      state.algoliaRelatedProducts = relatedProductData;
    },
    algoliaGetRelatedProductsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
      state.algoliaRelatedProducts = null;
    },
    algoliaSetRelatedProducts: (state, action: PayloadAction<any>) => {
      const productsLength = action.payload?.products?.length;
      const productsSkip = action.payload?.skip;
      let newSkip = 0;
      if (productsSkip + recommendProductPageSize >= productsLength) {
        newSkip = productsSkip + recommendProductPageSize - productsLength;
      } else newSkip = productsSkip + recommendProductPageSize;

      state.algoliaRelatedProducts = action.payload;
      localStorage.setItem(
        relatedProductDataConst,
        JSON.stringify({ ...action.payload, skip: newSkip }),
      );
    },
    algoliaSetRelatedProductsState: (state, action: PayloadAction<any>) => {
      state.algoliaRelatedProducts = action.payload;
    },
  },
});

export const {
  initAlgoliaRecommendIndex,
  algoliaGetTrendingItemsRequest,
  algoliaGetTrendingItemsSuccess,
  algoliaGetTrendingItemsFailure,
  algoliaSetTrendingItems,
  algoliaGetRelatedProductsRequest,
  algoliaGetRelatedProductsSuccess,
  algoliaGetRelatedProductsFailure,
  algoliaSetRelatedProducts,
  algoliaSetRelatedProductsState,
} = algoliaRecommendSlice.actions;

export default algoliaRecommendSlice.reducer;
