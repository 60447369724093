import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, all, take, put } from 'redux-saga/effects';
import {
  getQuestionsFailure,
  getQuestionsSuccess,
  getQuestionsRequest,
} from 'store/reducers/category';
import { selectProduct } from 'store/reducers/productPick';
import { ISelectedProduct } from 'store/types';
import { getQuestionsByCategory } from 'api';
import { createFetchAction } from './createFetchAction';

function* startGettingQuestionsSaga() {
  while (true) {
    const action: PayloadAction<ISelectedProduct> = yield take(
      selectProduct.type,
    );

    if (action.payload.categoryId) {
      yield put(getQuestionsRequest(action.payload.categoryId));
    } else {
      yield put(getQuestionsSuccess([]));
    }
  }
}

function* getQuestionsSaga() {
  yield takeEvery(
    getQuestionsRequest.type,
    createFetchAction(
      getQuestionsByCategory,
      getQuestionsSuccess,
      getQuestionsFailure,
    ),
  );
}

export default function* categorySaga() {
  yield all([getQuestionsSaga(), startGettingQuestionsSaga()]);
}
