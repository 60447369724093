import { takeLatest, all, select } from 'redux-saga/effects';
import {
  getBannerListRequest,
  getBannerListSuccess,
  getBannerListFailure,
  addBannerListOffset,
  getBannersRequest,
  getBannersSuccess,
  getBannersFailure,
} from 'store/reducers/bannerList';
import {
  createFetchAction,
  createV2FetchFuncWithStringError,
} from 'store/sagas/createFetchAction';
import { getBannerList, getV2Banners } from 'api';
import { RootState } from 'store/reducers';

function* getBannerListSaga() {
  yield takeLatest(
    getBannerListRequest.type,
    createV2FetchFuncWithStringError(
      getBannerList,
      getBannerListSuccess,
      getBannerListFailure,
    ),
  );
}
function* getMoreBannerListSaga() {
  const { page } = yield select((state: RootState) => state.bannerListReducer);
  const { paging } = page;
  const { skip, limit } = paging;
  const params = {
    skip,
    limit,
  };

  yield takeLatest(
    addBannerListOffset.type,
    createFetchAction(
      getBannerList,
      getBannerListSuccess,
      getBannerListFailure,
      params,
    ),
  );
}

function* getBannersSaga() {
  yield takeLatest(
    getBannersRequest.type,
    createV2FetchFuncWithStringError(
      getV2Banners,
      getBannersSuccess,
      getBannersFailure,
    ),
  );
}

export default function* bannerListSaga() {
  yield all([getBannerListSaga(), getMoreBannerListSaga(), getBannersSaga()]);
}
