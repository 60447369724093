import { takeEvery, all } from 'redux-saga/effects';
import {
  getBannersFailure,
  getBannersRequest,
  getBannersSuccess,
  getPendingBannersFailure,
  getPendingBannersRequest,
  getPendingBannersSuccess,
  getLandingFailure,
  getLandingRequest,
  getLandingSuccess,
  getProductBannersSuccess,
  getProductBannersFailure,
  getProductBannersRequest,
  getCommunityBannersRequest,
  getCommunityBannersSuccess,
  getCommunityBannersFailure,
  getIntroBannersRequest,
  getIntroBannersSuccess,
  getIntroBannersFailure,
} from 'store/reducers/banner';
import {
  getBanners,
  getCommunityBanners,
  getIntroBanners,
  getLanding,
  getPendingBanners,
  getProductMainBanners,
} from 'api';
import { createFetchAction } from './createFetchAction';

function* getProductBannersSaga() {
  yield takeEvery(
    getProductBannersRequest.type,
    createFetchAction(
      getProductMainBanners,
      getProductBannersSuccess,
      getProductBannersFailure,
    ),
  );
}

function* getPendingBannersSaga() {
  yield takeEvery(
    getPendingBannersRequest.type,
    createFetchAction(
      getPendingBanners,
      getPendingBannersSuccess,
      getPendingBannersFailure,
    ),
  );
}

function* getBannersSaga() {
  yield takeEvery(
    getBannersRequest.type,
    createFetchAction(getBanners, getBannersSuccess, getBannersFailure),
  );
}

function* getLandingSaga() {
  yield takeEvery(
    getLandingRequest.type,
    createFetchAction(getLanding, getLandingSuccess, getLandingFailure),
  );
}

function* getCommunityBannersSaga() {
  yield takeEvery(
    getCommunityBannersRequest.type,
    createFetchAction(
      getCommunityBanners,
      getCommunityBannersSuccess,
      getCommunityBannersFailure,
    ),
  );
}

function* getIntroBannersSaga() {
  yield takeEvery(
    getIntroBannersRequest.type,
    createFetchAction(
      getIntroBanners,
      getIntroBannersSuccess,
      getIntroBannersFailure,
    ),
  );
}

export default function* bannerSaga() {
  yield all([
    getBannersSaga(),
    getLandingSaga(),
    getPendingBannersSaga(),
    getProductBannersSaga(),
    getCommunityBannersSaga(),
    getIntroBannersSaga(),
  ]);
}
