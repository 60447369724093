import { takeLatest } from 'redux-saga/effects';
import {
  reportUserRequest,
  reportUserSuccess,
  reportUserFailure,
} from 'store/reducers/user';
import { reportUser } from 'api';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

function* reportUserSaga() {
  yield takeLatest(
    reportUserRequest.type,
    createFetchAction(
      reportUser,
      reportUserSuccess,
      reportUserFailure,
      function* success() {
        yield alert('신고가 접수되었습니다.');
        yield window.location.reload();
      },
      failure,
    ),
  );
}

export default reportUserSaga;
