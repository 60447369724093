import { memo, useEffect, useRef, useState } from 'react';
import LazyImage from 'widgets/New/LazyImage';

interface Props {
  alt: string;
  src: string;
  className?: string;
  onLoad?: () => void;
  placeholderImage?: string;
  backgroundColor?: string;
  animation?: 'fadeIn' | 'blur';
}

const NewLazyImage = ({
  alt,
  src,
  className,
  onLoad,
  placeholderImage,
  backgroundColor,
  animation,
}: Props) => {
  const imgTarget = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!imgTarget.current) return;
    if (placeholderImage) {
      imgTarget.current.src = placeholderImage;
      imgTarget.current.onload = onLoadImage;
    } else {
      onLoadImage();
    }
  }, [imgTarget.current]);

  const onLoadImage = () => {
    if (!imgTarget.current) return;
    imgTarget.current.src = src;
    imgTarget.current.onload = () => {
      animation && imgTarget.current?.classList.add(animation);
      onLoad && onLoad();
    };
  };

  return (
    <LazyImage
      ref={imgTarget}
      className={className}
      alt={alt}
      backgroundColor={backgroundColor}
    />
  );
};

export default memo(NewLazyImage);
