import { takeLatest, put, all, select } from 'redux-saga/effects';
import {
  writeCommentsFailure,
  writeCommentsRequest,
  writeCommentsSuccess,
  writeCommentThreadsRequest,
  writeCommentThreadsFailure,
  writeCommentThreadsSuccess,
  reviseCommentRequest,
  reviseCommentSuccess,
  reviseCommentFailure,
  reviseThreadRequest,
  reviseThreadSuccess,
  reviseThreadFailure,
} from 'store/reducers/comments';
import { plusCommentsCnt } from 'store/reducers/board';
import {
  writeCommments,
  writeCommentThreads,
  reviseComment,
  reviseThread,
} from 'api';
import { RootState } from 'store/reducers';
import sendGtmDataLayer from 'utils/sendGtmDataLayer';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

function* success() {
  yield put(plusCommentsCnt());

  const { board } = yield select((state: RootState) => state.boardReducer);

  board &&
    board.isOwnerOnly &&
    board.mainTopic === '장비' &&
    sendGtmDataLayer({
      event: 'wiki_answer',
      question_type: board.topic,
      real_user: board.isOwnerOnly,
      question_product: board.product.name,
    });
}

function* writeCommentsSaga() {
  yield takeLatest(
    writeCommentsRequest.type,
    createFetchAction(
      writeCommments,
      writeCommentsSuccess,
      writeCommentsFailure,
      success,
      failure,
    ),
  );
}

function* writeCommentThreadsSaga() {
  yield takeLatest(
    writeCommentThreadsRequest.type,
    createFetchAction(
      writeCommentThreads,
      writeCommentThreadsSuccess,
      writeCommentThreadsFailure,
      undefined,
      failure,
    ),
  );
}

function* reviseCommentSaga() {
  yield takeLatest(
    reviseCommentRequest.type,
    createFetchAction(
      reviseComment,
      reviseCommentSuccess,
      reviseCommentFailure,
    ),
  );
}

function* reviseThreadSaga() {
  yield takeLatest(
    reviseThreadRequest.type,
    createFetchAction(reviseThread, reviseThreadSuccess, reviseThreadFailure),
  );
}

export function* editCommentsSaga() {
  yield all([
    writeCommentsSaga(),
    writeCommentThreadsSaga(),
    reviseCommentSaga(),
    reviseThreadSaga(),
  ]);
}
