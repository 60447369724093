import { PayloadAction } from '@reduxjs/toolkit';
import { take, all, takeEvery, put, select } from 'redux-saga/effects';
import {
  getProductQuestionsFailure,
  getProductQuestionsRequest,
  getProductQuestionsSuccess,
  selectTopic,
  updateTimestamp,
} from 'store/reducers/productQna';
import { getPosts } from 'api';
import { RootState } from 'store/reducers';
import { TTopic } from '@types';
import { createFetchAction } from './createFetchAction';

const queryString = require('query-string');

function* getProductQuestionsSaga() {
  while (true) {
    const action: PayloadAction<TTopic> = yield take(selectTopic.type);

    const topic = action.payload;

    const parsed = queryString.parse(window.location.search);

    yield put(
      getProductQuestionsRequest({
        topic: topic === '전체' ? '' : topic,
        productId: parsed.id,
        productType: parsed.category,
        boardType: 'question',
        mainTopic: '장비',
        hasProduct: true,
        timestamp: 0,
        limit: 10,
      }),
    );
  }
}

function* fetchProductQuestionsSaga() {
  yield takeEvery(
    getProductQuestionsRequest.type,
    createFetchAction(
      getPosts,
      getProductQuestionsSuccess,
      getProductQuestionsFailure,
    ),
  );
}

function* getMoreQuestionsSaga() {
  while (true) {
    const action: PayloadAction<number> = yield take(updateTimestamp.type);

    const timestamp = action.payload;

    const parsed = queryString.parse(window.location.search);

    const { topic } = yield select(
      (state: RootState) => state.productQnaReducer,
    );

    yield put(
      getProductQuestionsRequest({
        topic: topic === '전체' ? '' : topic,
        productId: parsed.id,
        productType: parsed.category,
        boardType: 'question',
        mainTopic: '장비',
        hasProduct: true,
        timestamp,
        limit: 10,
      }),
    );
  }
}

function* productQnaSaga() {
  yield all([
    getProductQuestionsSaga(),
    fetchProductQuestionsSaga(),
    getMoreQuestionsSaga(),
  ]);
}

export default productQnaSaga;
