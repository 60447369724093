import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IBoardState,
  IGetBoardsPayload,
  IToggleLikeBtnPayload,
  IReportRequestPayload,
  IUpdateTimestampPayload,
  IBlockPostRequestPayload,
} from 'store/types';
import { OpenedMenu } from 'hooks/Board/useMenuModal';
import {
  IBoard,
  IWriteOnBoardResponse,
  IToggleLikeBtnResponse,
  IResponse,
  TSubjectType,
  ICampingLogContent,
  ICommunityMainPage,
} from '@types';

const initialState: IBoardState = {
  boardList: [],
  board: {} as IBoard,
  subject: 'all',
  mainTopic: '',
  productType: '',
  timestamp: 0,
  hasMore: true,
  error: '',
  boardContents: [],
  boardMain: {} as ICommunityMainPage,
};

export const boardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    writeOnBoardRequest: (state, action: PayloadAction<FormData>) => {
      state.error = '';
    },
    writeOnBoardSuccess: (
      state,
      action: PayloadAction<IWriteOnBoardResponse>,
    ) => {
      state.error = '';
    },
    writeOnBoardFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    getBoardRequest: (state, action: PayloadAction<string>) => {
      state.board = {} as IBoard;
      state.error = '';
    },
    getBoardSuccess: (state, action: PayloadAction<IBoard>) => {
      state.board = action.payload;
      state.error = '';
    },
    getBoardFailure: (state, action: PayloadAction<Error | string>) => {
      state.board = {} as IBoard;
      state.error = action.payload;
    },

    getBoardsRequest: (state, action: PayloadAction<IGetBoardsPayload>) => {
      action.payload.timestamp ? state.boardList : (state.boardList = []);
      state.error = '';
    },
    getBoardsSuccess: (state, action: PayloadAction<IBoard[]>) => {
      state.boardList = state.boardList.concat(action.payload);
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },
    getBoardsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    updateTimestamp: (
      state,
      action: PayloadAction<IUpdateTimestampPayload>,
    ) => {
      state.timestamp = action.payload.timestamp;
    },

    toggleLikeBtnRequest: (
      state,
      action: PayloadAction<IToggleLikeBtnPayload>,
    ) => {
      state.error = '';
    },
    toggleLikeBtnSuccess: (
      state,
      action: PayloadAction<IToggleLikeBtnResponse>,
    ) => {
      const { id, type, hasLiked, numOfLikes } = action.payload;
      const newBoardList: IBoard[] = [];

      state.boardList.forEach(b => {
        if (b.id === id && type === 'board') {
          b.hasLiked = hasLiked;
          b.numOfLikes = numOfLikes;
        }
        newBoardList.push(b);
      });

      state.boardList = newBoardList;
      state.board.hasLiked = hasLiked;
      state.board.numOfLikes = numOfLikes;
      state.error = '';
    },
    toggleLikeBtnFailure: (state, action) => {
      state.error = action.payload;
    },

    plusCommentsCnt: state => {
      state.board.numOfComments += 1;
    },

    revisePostRequest: (state, action) => {
      state.error = '';
    },
    revisePostSuccess: (
      state,
      action: PayloadAction<IWriteOnBoardResponse>,
    ) => {
      state.error = '';
    },
    revisePostFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    reportRequest: (state, action: PayloadAction<IReportRequestPayload>) => {
      state.error = '';
    },
    reportSuccess: (state, action: PayloadAction<IResponse>) => {
      state.error = '';
    },
    reportFailure: (state, action) => {
      state.error = action.payload;
    },

    deleteContentsRequest: (state, action: PayloadAction<OpenedMenu>) => {
      state.error = '';
    },
    deleteContentsSuccess: (state, action: PayloadAction<IResponse>) => {
      state.error = '';
    },
    deleteContentsFailure: (state, action) => {
      state.error = action.payload;
    },

    selectSubject: (state, action: PayloadAction<TSubjectType>) => {
      // 궁금해요 주제가 아닌 다른 주제를 선택하면 mainTopic 과 productType 을 초기화 합니다. / ywroh / 2022.02.28
      if (action.payload === 'question') {
        state.subject = action.payload;
      } else {
        state.subject = action.payload;
        state.mainTopic = '';
        state.productType = '';
      }
    },

    selectMainTopic: (state, action: PayloadAction<string>) => {
      state.mainTopic = action.payload;
    },

    selectProductType: (state, action: PayloadAction<string>) => {
      state.productType = action.payload;
    },

    blockPostRequest: (
      state,
      action: PayloadAction<IBlockPostRequestPayload>,
    ) => {
      state.error = '';
    },
    blockPostSuccess: (state, action: PayloadAction<IResponse>) => {
      state.error = '';
    },
    blockPostFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    addBoardContent: (state, action: PayloadAction<ICampingLogContent>) => {
      if (!action.payload.url && action.payload.file) {
        action.payload.url = URL.createObjectURL(action.payload.file);
      }
      state.boardContents = state.boardContents.concat(action.payload);
    },
    removeBoardContent: (state, action: PayloadAction<number>) => {
      const splicedBoardContents = state.boardContents;
      splicedBoardContents.splice(action.payload, 1);
      state.boardContents = [...splicedBoardContents];
    },
    resetBoardContents: state => {
      state.boardContents.forEach(boardContent => {
        if (!boardContent.isExisted && boardContent.url) {
          URL.revokeObjectURL(boardContent.url);
        }
      });
      state.boardContents = [];
    },
    getBoardMainRequest: state => {
      state.boardMain = {} as ICommunityMainPage;
      state.error = '';
    },
    getBoardMainSuccess: (state, action: PayloadAction<ICommunityMainPage>) => {
      state.boardMain = action.payload;
      state.error = '';
    },
    getBoardMainFailure: (state, action: PayloadAction<Error | string>) => {
      state.boardMain = {} as ICommunityMainPage;
      state.error = action.payload;
    },
  },
});

export const {
  writeOnBoardRequest,
  writeOnBoardSuccess,
  writeOnBoardFailure,
  getBoardRequest,
  getBoardSuccess,
  getBoardFailure,
  getBoardsRequest,
  getBoardsSuccess,
  getBoardsFailure,
  updateTimestamp,
  toggleLikeBtnRequest,
  toggleLikeBtnSuccess,
  toggleLikeBtnFailure,
  plusCommentsCnt,
  revisePostRequest,
  revisePostSuccess,
  revisePostFailure,
  reportRequest,
  reportSuccess,
  reportFailure,
  deleteContentsRequest,
  deleteContentsSuccess,
  deleteContentsFailure,
  selectSubject,
  selectMainTopic,
  selectProductType,
  blockPostRequest,
  blockPostSuccess,
  blockPostFailure,
  addBoardContent,
  removeBoardContent,
  resetBoardContents,
  getBoardMainRequest,
  getBoardMainSuccess,
  getBoardMainFailure,
} = boardSlice.actions;

export default boardSlice.reducer;
