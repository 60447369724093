import { writeOnBoard, revisePost } from 'api';
import { takeLatest, all } from 'redux-saga/effects';
import {
  revisePostFailure,
  revisePostRequest,
  revisePostSuccess,
  writeOnBoardFailure,
  writeOnBoardRequest,
  writeOnBoardSuccess,
} from 'store/reducers/board';
import { IWriteOnBoardResponse } from '@types';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

function* writeOnBoardSaga() {
  yield takeLatest(
    writeOnBoardRequest.type,
    createFetchAction(
      writeOnBoard,
      writeOnBoardSuccess,
      writeOnBoardFailure,
      function* success(result: IWriteOnBoardResponse) {
        // if 문 추가, 장비 상세 페이지로 떨어지게
        if (window.sessionStorage.getItem('source_path')) {
          yield window.location.replace(
            window.sessionStorage.getItem('source_path') || '/board',
          );
        } else {
          yield window.location.replace(`/board`);
        }
      },
      failure,
    ),
  );
}

function* revisePostSaga() {
  yield takeLatest(
    revisePostRequest.type,
    createFetchAction(
      revisePost,
      revisePostSuccess,
      revisePostFailure,
      function* success(result: IWriteOnBoardResponse) {
        yield window.location.replace(`/board/${result.boardId}`);
      },
      failure,
    ),
  );
}

export function* editPostSaga() {
  yield all([writeOnBoardSaga(), revisePostSaga()]);
}
