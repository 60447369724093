import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IGetSiteRSRequestPayload,
  ZoneReservationStatusState,
  IUpdateMonthPayload,
} from 'store/types';
import { IZoneReservationStatus } from '@types';

const initialState: ZoneReservationStatusState = {
  date: { year: new Date().getFullYear(), month: new Date().getMonth() + 1 },
  monthDates: [],
  sites: [],
  timestamp: 0,
  error: '',
};

export const zoneReservationStatusSlice = createSlice({
  name: 'zoneReservationStatus',
  initialState,
  reducers: {
    getZoneReservationStatusRequest: (
      state,
      action: PayloadAction<IGetSiteRSRequestPayload>,
    ) => {
      state.monthDates = [];
      state.sites = [];
      state.timestamp = 0;
      state.error = '';
    },
    getZoneReservationStatusSuccess: (
      state,
      action: PayloadAction<IZoneReservationStatus>,
    ) => {
      const { data, timestamp } = action.payload;

      if (!data.length) {
        state;
        return;
      }

      const days = ['일', '월', '화', '수', '목', '금', '토'];
      const endOfMonthDate = new Date(
        state.date.year,
        state.date.month,
        0,
      ).getDate();
      const monthDates = [];

      const sites = data[0].sites.map(s => {
        return {
          name: s.name,
          availabilities: [] as { date: number; isAvailable: boolean }[],
        };
      });

      for (let i = 1; i <= endOfMonthDate; i += 1) {
        const d = data.find(d => d.date === i);

        monthDates.push({
          date: i,
          day: days[
            new Date(state.date.year, state.date.month - 1, i).getDay()
          ],
          holiday: d ? d.holiday : null,
        });

        for (const s of sites) {
          s.availabilities.push({
            date: i,
            isAvailable: !d
              ? false
              : d.sites.find(t => t.name === s.name)?.isAvailable || false,
          });
        }
      }

      state.monthDates = monthDates;
      state.sites = sites;
      state.timestamp = timestamp;
      state.error = '';
    },
    getZoneReservationStatusFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.monthDates = [];
      state.sites = [];
      state.timestamp = 0;
      state.error = action.payload;
    },

    updateMonth: (state, action: PayloadAction<IUpdateMonthPayload>) => {
      state.date = action.payload.date;
      state.isNextMonth = action.payload.isNextMonth;
    },
  },
});

export const {
  getZoneReservationStatusRequest,
  getZoneReservationStatusSuccess,
  getZoneReservationStatusFailure,
  updateMonth,
} = zoneReservationStatusSlice.actions;

export default zoneReservationStatusSlice.reducer;
