import { ICancelCharge, IResponse, TStoreValueState } from '@types';
import {
  IGetBookingInfoPayload,
  CancelState,
  ICancelBookingRequestPayload,
} from 'store/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: CancelState = {
  cancelCharge: {} as ICancelCharge,
  refundState: 'pending',
  error: '',
};

export const cancelSlice = createSlice({
  name: 'cancel',
  initialState,
  reducers: {
    getCancelChargeRequest: (
      state,
      action: PayloadAction<IGetBookingInfoPayload>,
    ) => {
      state.cancelCharge = {} as ICancelCharge;
      state.error = '';
    },
    getCancelChargeSuccess: (state, action: PayloadAction<ICancelCharge>) => {
      state.cancelCharge = action.payload;
      state.error = '';
    },
    getCancelChargeFailure: (state, action: PayloadAction<Error | string>) => {
      state.cancelCharge = {} as ICancelCharge;
      state.error = action.payload;
    },

    cancelBookingRequest: (
      state,
      action: PayloadAction<ICancelBookingRequestPayload>,
    ) => {
      state.refundState = 'pending';
      state.error = '';
    },
    cancelBookingSuccess: (state, action: PayloadAction<IResponse>) => {
      state.error = '';
    },
    cancelBookingFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    updateRefundInfoRequest: (
      state,
      action: PayloadAction<Omit<ICancelBookingRequestPayload, 'refundAmount'>>,
    ) => {
      state.refundState = 'pending';
      state.error = '';
    },
    updateRefundInfoSuccess: (state, action: PayloadAction<IResponse>) => {
      state.refundState = 'success';
      state.error = '';
    },
    updateRefundInfoFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    setRefundState: (state, action: PayloadAction<TStoreValueState>) => {
      state.refundState = action.payload;
    },
  },
});

export const {
  getCancelChargeRequest,
  getCancelChargeSuccess,
  getCancelChargeFailure,
  cancelBookingRequest,
  cancelBookingSuccess,
  cancelBookingFailure,
  updateRefundInfoRequest,
  updateRefundInfoSuccess,
  updateRefundInfoFailure,
  setRefundState,
} = cancelSlice.actions;

export default cancelSlice.reducer;
