import { ITo } from '@types';
import {
  forwardRef,
  memo,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  Ref,
  useCallback,
  ElementType,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { TextVariant, TextWeight } from 'static/styles/style';
import { theme } from 'static/styles/theme';
import styled, { css, CSSProp } from 'styled-components';

interface BasicButtonProps {
  children: ReactNode | JSX.Element | JSX.Element[];
  size?: 'full' | 'l' | 'm' | 's' | 'xs';
  isFixed?: boolean;
  padding?: string;
  isRound?: boolean;
  background?: string;
  color?: string;
  width?: string;
  type?: 'contained' | 'outlined' | 'ghost' | 'fullBleed';
  height?: string;
  disabled?: boolean;
  onClick?: (
    e?: any,
  ) =>
    | void
    | React.MouseEventHandler<HTMLButtonElement>
    | React.FormEventHandler<HTMLInputElement>;
  to?: string | ITo;
  isFullBleed?: boolean;
  iconAlign?: 'right' | 'left';
  cssObject?: CSSProp;
  submitType?: boolean;
  textVariant?: 'default' | TextVariant;
  textWeight?: TextWeight;
  className?: string;
  id?: string;
}

/**
 * Basic Button
 * @param {string} size - 디자인 시스템에서 사용되는 버튼 사이즈. full 의 경우, 시스템엔 없으나 컨테이너에 따라 너비가 자동으로 조절되는 버튼일 시 사용
 * @param {string} type - 디자인 시스템에서 사용되는 버튼 스타일. 예) contained 등
 * @param {string} width - size full 이외의 사이즈는 전부 필수값임.
 * @param {string} padding - css string, isFixed = false 일 때, 필수값임. (고정된 패딩값 필요) 예) 0px 40px 0 40px
 * @returns {ReactComponentElement} 디자인 시스템이 적용된 버튼 컴포넌트
 */

function BasicButton(
  {
    children,
    size = 'full',
    isFixed = true,
    padding,
    isRound = false,
    height = '52px',
    width,
    type = 'contained',
    background = theme.newColors.primary,
    color = theme.newColors.white,
    disabled = false,
    onClick,
    to,
    isFullBleed = false,
    iconAlign,
    cssObject,
    submitType,
    textVariant = 'default',
    textWeight,
    className,
    id,
  }: BasicButtonProps,
  ref: Ref<HTMLButtonElement>,
) {
  const history = useHistory();
  const onClickButton = useCallback(
    (e?: MouseEvent) => {
      // NOTE: onClick 없이 submit 자체 기능을 쓰는 경우에는 이벤트 발생
      !(submitType && !onClick) && e && e.preventDefault();
      onClick && onClick();
      to && history.push(to);
    },
    [onClick, to],
  );

  const textVariantClass = useMemo(() => {
    const textClass = ` typography-${textVariant}`;
    return textVariant !== 'default' ? textClass : '';
  }, [textVariant]);

  const textWeightClass = useMemo(() => {
    const textClass = ` typography-${textWeight}`;
    return textWeight ? textClass : '';
  }, [textWeight]);

  const classes = useMemo(
    () =>
      className
        ? className + textVariantClass + textWeightClass
        : textVariantClass + textWeightClass,
    [className, textVariantClass, textWeightClass],
  );

  const StyledButton = (
    <Button
      size={size || undefined}
      buttonHeight={height}
      buttonWidth={width}
      buttonType={type}
      isFixed={isFixed}
      padding={padding}
      isRound={isRound}
      background={background}
      color={color}
      disabled={disabled}
      onClick={onClickButton as MouseEventHandler<HTMLButtonElement>}
      clickEvent={!disabled && (!!onClick || !!to || submitType)}
      ref={ref}
      isFullBleed={isFullBleed}
      iconAlign={iconAlign}
      css={cssObject}
      as={submitType ? 'input' : 'button'}
      type={submitType ? 'submit' : undefined}
      value={submitType ? (children as string) : undefined}
      textVariant={textVariant}
      className={classes}
      id={id}
    >
      {!submitType ? children : undefined}
    </Button>
  );

  const StyledFixedButton = (
    <FixedButtonBackground
      size={size || undefined}
      buttonHeight={height}
      className={classes}
    >
      {StyledButton}
    </FixedButtonBackground>
  );

  return isFullBleed ? StyledFixedButton : StyledButton;
}

export default memo(forwardRef(BasicButton));

interface IBasicButtonStyle {
  size?: 'full' | 'l' | 'm' | 's' | 'xs';
  isFixed?: boolean;
  padding?: string;
  buttonHeight: string;
  buttonWidth?: string;
  buttonType?: 'contained' | 'outlined' | 'ghost' | 'fullBleed';
  isRound: boolean;
  background: string;
  color: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  isFullBleed?: boolean;
  fontSize?: string;
  iconAlign?: 'left' | 'right';
  css?: CSSProp;
  as: ElementType;
  value?: string;
  type?: 'submit';
  textVariant: 'default' | TextVariant;
  textWeight?: TextWeight;
  clickEvent?: boolean;
}

interface IBackgroundStyle {
  size?: 'full' | 'l' | 'm' | 's' | 'xs';
  buttonHeight: string;
}

const fullBleedCss = css`
  position: fixed;
  bottom: 0%;
  border-radius: 0;
  z-index: 999;
`;

const FixedButtonBackground = styled.div<IBackgroundStyle>`
  position: fixed;
  max-width: 530px;
  ${({ size, buttonHeight }) => getButtonSizeCss(buttonHeight, size)};
  ${fullBleedCss}
  z-index: 998;
`;

const Button = styled.button<IBasicButtonStyle>`
  display: flex;
  max-width: 530px;
  text-align: center;

  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  pointer-events: ${({ clickEvent }) => (!clickEvent ? 'none' : 'auto')};
  cursor: ${({ disabled, onClick, type }) =>
    disabled
      ? 'not-allowed'
      : !onClick && type !== 'submit'
      ? 'normal'
      : 'pointer'};

  > svg {
    margin: ${({ iconAlign }) =>
      iconAlign ? (iconAlign === 'left' ? '0 2px 0 0' : '0 0 0 2px') : '0'};
  }

  ${({ isFixed, padding }) => getPadding(isFixed, padding)}

  ${({ size, buttonHeight, buttonWidth }) =>
    getButtonSizeCss(buttonHeight, size, buttonWidth)}

  ${({ textVariant, size }) =>
    textVariant === 'default' && getButtonTextCss(size)}

  ${({ buttonType, background, color, disabled }) =>
    getButtonStyleCss(background, color, disabled, buttonType)}

  ${({ isRound, buttonType }) => getShapeCss(isRound, buttonType)}

  ${({ isFullBleed }) => (isFullBleed ? fullBleedCss : '')}

  ${({ css }) => css}
`;

const getPadding = (isFixed?: boolean, padding?: string) => {
  return isFixed
    ? css`
        padding: ${padding};
      `
    : '';
};

const getButtonSizeCss = (
  buttonHeight: string,
  size?: 'full' | 'l' | 'm' | 's' | 'xs',
  width?: string,
) => {
  switch (size) {
    case 'l':
      return css`
        width: ${width || '100%'};
        height: 52px;
      `;
    case 'm':
      return css`
        width: ${width || '100%'};
        height: ${buttonHeight !== '52px' ? buttonHeight : '48px'};
      `;
    case 's':
      return css`
        width: ${width || '100%'};
        height: 42px;
      `;
    case 'xs':
      return css`
        width: ${width || '100%'};
        height: 34px;
      `;
    default:
      // full 의 경우 해당 로직으로
      return css`
        width: ${width || '100%'};
        height: ${buttonHeight || '52px'};
      `;
  }
};

const getButtonTextCss = (size?: 'full' | 'l' | 'm' | 's' | 'xs') => {
  const defaultCss = css`
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 140%;
    letter-spacing: -0.03rem;
  `;
  switch (size) {
    case 'l':
      return defaultCss;

    case 'm':
      return defaultCss;

    case 's':
      return css`
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 179%;
        letter-spacing: -0.03rem;
      `;
    case 'xs':
      return css`
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.008rem;
      `;
    default:
      // full 의 경우
      return defaultCss;
  }
};

const getButtonStyleCss = (
  background: string,
  color: string,
  disabled?: boolean,
  buttonType?: 'contained' | 'outlined' | 'ghost' | 'fullBleed',
) => {
  const disabledDefaultCss = css`
    background: ${theme.newColors.lightGrey2};
    color: ${theme.newColors.white};
  `;

  switch (buttonType) {
    case 'contained':
      if (disabled) return disabledDefaultCss;
      return css`
        background: ${background};
        color: ${color};
      `;
    case 'outlined':
      if (disabled)
        return css`
          color: ${theme.newColors.lightGrey2};
          border: 1px solid ${theme.newColors.lightGrey2};
        `;
      return css`
        background: ${theme.newColors.white};
        color: ${color === theme.newColors.white
          ? theme.newColors.primary
          : color};
        border: 1px solid
          ${color === theme.newColors.white
            ? theme.newColors.primary
            : background};
      `;
    case 'ghost':
      if (disabled)
        return css`
          color: ${theme.newColors.lightGrey2};
        `;
      return css`
        color: ${color === theme.newColors.white
          ? theme.newColors.primary
          : color};
      `;
    case 'fullBleed':
      if (disabled) return disabledDefaultCss;
      return css`
        background: ${background || theme.newColors.primary};
        color: ${color};
      `;
    default:
      if (disabled) return disabledDefaultCss;
      return css`
        background: ${background};
        color: ${color};
      `;
  }
};

const getShapeCss = (
  isRound: boolean,
  buttonType?: 'contained' | 'outlined' | 'ghost' | 'fullBleed',
) => {
  if (isRound) {
    return css`
      border-radius: 100px;
    `;
  }
  if (buttonType && (buttonType === 'contained' || buttonType === 'outlined'))
    return css`
      border-radius: 8px;
    `;
};
