import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TnitState } from 'store/types';

const initialState: TnitState = {
  isSagaInitCompleted: false,
  shopbyInvalidTokenError: false,
};

export const initSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    permitFetching: state => {
      state.isSagaInitCompleted = true;
    },
    setShopbyInvalidTokenError: (state, action: PayloadAction<boolean>) => {
      state.shopbyInvalidTokenError = action.payload;
    },
  },
});

export const { permitFetching, setShopbyInvalidTokenError } = initSlice.actions;

export default initSlice.reducer;
