import { takeEvery, all, take, put } from 'redux-saga/effects';
import { findAnnounces, findAnnounce } from 'api';
import {
  getNoticesFailure,
  getNoticesSuccess,
  getNoticesRequest,
  getNoticeFailure,
  getNoticeSuccess,
  getNoticeRequest,
  addOffset,
} from 'store/reducers/notice';
import { PayloadAction } from '@reduxjs/toolkit';
import { createFetchAction } from './createFetchAction';

function* fetchNoticesSaga() {
  yield takeEvery(
    getNoticesRequest.type,
    createFetchAction(findAnnounces, getNoticesSuccess, getNoticesFailure),
  );
}

function* fetchNoticeSaga() {
  yield takeEvery(
    getNoticeRequest.type,
    createFetchAction(findAnnounce, getNoticeSuccess, getNoticeFailure),
  );
}

function* getMoreNoticesSaga() {
  while (true) {
    const action: PayloadAction<number> = yield take(addOffset.type);

    yield put(
      getNoticesRequest({
        isMain: false,
        skip: action.payload,
        limit: 10,
      }),
    );
  }
}

export default function* getNoticesSaga() {
  yield all([fetchNoticesSaga(), fetchNoticeSaga(), getMoreNoticesSaga()]);
}
