import { deleteBoard } from 'api';
import { takeLatest } from 'redux-saga/effects';
import {
  deleteContentsFailure,
  deleteContentsRequest,
  deleteContentsSuccess,
} from 'store/reducers/board';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

function* deleteBoardSaga() {
  yield takeLatest(
    deleteContentsRequest.type,
    createFetchAction(
      deleteBoard,
      deleteContentsSuccess,
      deleteContentsFailure,
      function* success() {
        yield alert('삭제되었습니다.');
        window.location.reload();
      },
      failure,
    ),
  );
}

export default deleteBoardSaga;
