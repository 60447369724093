import { toast } from 'react-toastify';
import { all, takeEvery } from 'redux-saga/effects';
import {
  deleteShopbyMyinquiryFailure,
  deleteShopbyMyinquiryRequest,
  deleteShopbyMyinquirySuccess,
  editShopbyMyinquiryFailure,
  editShopbyMyinquiryRequest,
  editShopbyMyinquirySuccess,
  getShopbyMyInquiryFailure,
  getShopbyMyInquiryMoreRequest,
  getShopbyMyInquiryRequest,
  getShopbyMyInquirySuccess,
  postShopbyItemInquiryFailure,
  postShopbyItemInquiryRequest,
  postShopbyItemInquirySuccess,
} from 'store/reducers/shopbyMyInquiry';
import { createShopbyFetchAction } from 'store/sagas/createFetchAction';
import {
  deleteShopbyMyInquiry,
  editShopbyMyInquiry,
  getShopbyMyInquiry,
  postShopbyMyInquiry,
} from 'api/shopby';
import { customHistory } from 'App';

function* getShopbyMyInquirySaga() {
  yield takeEvery(
    [getShopbyMyInquiryRequest.type, getShopbyMyInquiryMoreRequest.type],
    createShopbyFetchAction(
      getShopbyMyInquiry,
      getShopbyMyInquirySuccess,
      getShopbyMyInquiryFailure,
    ),
  );
}

function* postShopbyItemInquirySaga() {
  yield takeEvery(
    postShopbyItemInquiryRequest.type,
    createShopbyFetchAction(
      postShopbyMyInquiry,
      postShopbyItemInquirySuccess,
      postShopbyItemInquiryFailure,
      undefined,
      function onSuccess() {
        const { pathname } = customHistory.location;

        customHistory.replace(`${pathname}/success`);
      },
    ),
  );
}

function* editShopbyItmInquirySaga() {
  yield takeEvery(
    editShopbyMyinquiryRequest.type,
    createShopbyFetchAction(
      editShopbyMyInquiry,
      editShopbyMyinquirySuccess,
      editShopbyMyinquiryFailure,
      undefined,
      function onSuccess() {
        window.sessionStorage.setItem('editInquiry', 'success');
        customHistory.replace('/mypage/store/inquiry');
      },
    ),
  );
}

function* deleteShopbyMyInquirySaga() {
  yield takeEvery(
    deleteShopbyMyinquiryRequest.type,
    createShopbyFetchAction(
      deleteShopbyMyInquiry,
      deleteShopbyMyinquirySuccess,
      deleteShopbyMyinquiryFailure,
      undefined,
      function onSuccess() {
        toast.success('상품문의 삭제 완료');
      },
    ),
  );
}

export default function* shopbyMyInquirySaga() {
  yield all([
    getShopbyMyInquirySaga(),
    postShopbyItemInquirySaga(),
    editShopbyItmInquirySaga(),
    deleteShopbyMyInquirySaga(),
  ]);
}
