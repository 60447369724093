import { takeLatest } from 'redux-saga/effects';
import {
  toggleLikeBtnFailure,
  toggleLikeBtnRequest,
  toggleLikeBtnSuccess,
} from 'store/reducers/board';
import {
  toggleLikeBtnOnCommentFailure,
  toggleLikeBtnOnCommentRequest,
  toggleLikeBtnOnCommentSuccess,
} from 'store/reducers/comments';
import { toggleLikeBtn } from 'api';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

export function* likeSaga() {
  yield takeLatest(
    toggleLikeBtnRequest.type,
    createFetchAction(
      toggleLikeBtn,
      toggleLikeBtnSuccess,
      toggleLikeBtnFailure,
      null,
      failure,
    ),
  );
  yield takeLatest(
    toggleLikeBtnOnCommentRequest.type,
    createFetchAction(
      toggleLikeBtn,
      toggleLikeBtnOnCommentSuccess,
      toggleLikeBtnOnCommentFailure,
      null,
      failure,
    ),
  );
}
