import { takeEvery, all, take, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getCamperProfileFailure,
  getCamperProfileRequest,
  getCamperProfileSuccess,
  getCamperPostsFailure,
  getCamperPostsRequest,
  getCamperPostsSuccess,
  getCamperReviewsFailure,
  getCamperReviewsRequest,
  getCamperReviewsSuccess,
  setTabMenu,
  addPostsOffset,
  addReviewsOffset,
} from 'store/reducers/camper';
import { RootState } from 'store/reducers';
import { getCamperProfile, getCamperBoards } from 'api';
import { IProfile } from '@types';
import { createFetchAction } from './createFetchAction';
import { customHistory } from '../../App';
import { failure } from './failure';

function* getCamperProfileSaga() {
  yield takeEvery(
    getCamperProfileRequest,
    createFetchAction(
      getCamperProfile,
      getCamperProfileSuccess,
      getCamperProfileFailure,
      function* success(data: IProfile) {
        if (data.nickname === '유저 비공개') {
          yield customHistory.goBack();
        }
      },
      function* fail(error: Error) {
        yield failure(error);
        yield customHistory.goBack();
      },
    ),
  );
}

function* fetchCamperPostsSaga() {
  yield takeEvery(
    getCamperPostsRequest,
    createFetchAction(
      getCamperBoards,
      getCamperPostsSuccess,
      getCamperPostsFailure,
    ),
  );
}

function* fetchCamperReviewsSaga() {
  yield takeEvery(
    getCamperReviewsRequest,
    createFetchAction(
      getCamperBoards,
      getCamperReviewsSuccess,
      getCamperReviewsFailure,
    ),
  );
}

function* getMoreReviewsSaga() {
  while (true) {
    const action: PayloadAction<number> = yield take(addReviewsOffset.type);

    const { nickname } = yield select(
      (state: RootState) => state.camperReducer.profile,
    );

    yield put(
      getCamperReviewsRequest({
        nickname,
        type: 'reviews',
        skip: action.payload,
        limit: 5,
      }),
    );
  }
}

function* getMorePostsSaga() {
  while (true) {
    const action: PayloadAction<number> = yield take(addPostsOffset.type);

    const { nickname } = yield select(
      (state: RootState) => state.camperReducer.profile,
    );

    yield put(
      getCamperPostsRequest({
        nickname,
        type: 'boards',
        skip: action.payload,
        limit: 5,
      }),
    );
  }
}

function* getInitialCamperPostsSaga() {
  while (true) {
    const action: PayloadAction<IProfile> = yield take(
      getCamperProfileSuccess.type,
    );

    yield put(setTabMenu(0));
  }
}

function* getCamperContentsSaga() {
  while (true) {
    const action: PayloadAction<number> = yield take(setTabMenu.type);

    const { nickname } = yield select(
      (state: RootState) => state.camperReducer.profile,
    );

    const { reviewsOffset, postsOffset } = yield select(
      (state: RootState) => state.camperReducer,
    );

    if (action.payload) {
      if (reviewsOffset && customHistory.action === 'POP') {
        return;
      }

      yield put(
        getCamperReviewsRequest({
          nickname,
          skip: 0,
          limit: 5,
          type: 'reviews',
        }),
      );
    } else {
      if (postsOffset && customHistory.action === 'POP') {
        return;
      }

      yield put(
        getCamperPostsRequest({
          nickname,
          skip: 0,
          limit: 5,
          type: 'boards',
        }),
      );
    }
  }
}

export default function* getCamperInfoSaga() {
  yield all([
    getCamperProfileSaga(),
    getInitialCamperPostsSaga(),
    fetchCamperPostsSaga(),
    fetchCamperReviewsSaga(),
    getCamperContentsSaga(),
    getMoreReviewsSaga(),
    getMorePostsSaga(),
  ]);
}
