/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TChannelTalkServiceState } from 'store/types';

// NOTE: 사용되는 페이지나 카테고리(key)를 명시하기 위한 initialState
const initialState: TChannelTalkServiceState = {
  totalChatCount: 0,
};

export const channelTalkServiceSlice = createSlice({
  name: 'channelTalkService',
  initialState,
  reducers: {
    setChatTotalCount: (state, action: PayloadAction<number>) => {
      state.totalChatCount = action.payload;
    },
    resetChatTotalCount: (state, action: PayloadAction) => {
      state.totalChatCount = 0;
    },
  },
});

export const { setChatTotalCount, resetChatTotalCount } =
  channelTalkServiceSlice.actions;

export default channelTalkServiceSlice.reducer;
