import { IResponse } from '../../@types/index';

export function* failure(error: Error | IResponse) {
  if (error instanceof Error) {
    yield alert('요청에 실패했습니다.');
  } else if (error.message !== '실사용자만 답변이 가능한 게시글입니다') {
    yield alert(error.message);
  }
}

export function* failureWithErrorFuntion(
  error: Error | IResponse,
  errorFunction: () => void,
) {
  yield alert(error.message);
  yield errorFunction();
}
