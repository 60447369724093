import { takeLatest } from 'redux-saga/effects';
import {
  reportFailure,
  reportRequest,
  reportSuccess,
} from 'store/reducers/board';
import { reportBoard } from 'api';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

function* reportBoardSaga() {
  yield takeLatest(
    reportRequest.type,
    createFetchAction(
      reportBoard,
      reportSuccess,
      reportFailure,
      function* success() {
        yield alert('신고가 접수되었습니다.');
        yield window.location.reload();
      },
      failure,
    ),
  );
}

export default reportBoardSaga;
