import {
  takeEvery,
  take,
  put,
  all,
  call,
  takeLatest,
} from 'redux-saga/effects';
import {
  addOffset,
  getAlarmsFailure,
  getAlarmsRequest,
  getAlarmsSuccess,
  getAlarmsCountFailure,
  getAlarmsCountRequest,
  getAlarmsCountSuccess,
  updateAlarmStatusRequest,
  updateAlarmStatusSuccess,
  updateAlarmStatusFailure,
  readAllMyAlarmsRequest,
  readAllMyAlarmsSuccess,
  readAllMyAlarmsFailure,
} from 'store/reducers/alarms';
import {
  getMyAlarms,
  getMyAlarmsCount,
  readAllMyAlarms,
  updateViewedAlarm,
} from 'api';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  minusNumOfNewAlarms,
  resetNumOfNewAlarms,
} from 'store/reducers/myInfo';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

function* getAlarmsSaga() {
  yield takeEvery(
    getAlarmsRequest.type,
    createFetchAction(getMyAlarms, getAlarmsSuccess, getAlarmsFailure),
  );
}

function* getMoreAlarmsSaga() {
  while (true) {
    const action: PayloadAction<number> = yield take(addOffset.type);

    yield put(
      getAlarmsRequest({ isViewed: null, skip: action.payload, limit: 10 }),
    );
  }
}

function* getAlarmsCountSaga() {
  yield takeEvery(
    getAlarmsCountRequest.type,
    createFetchAction(
      getMyAlarmsCount,
      getAlarmsCountSuccess,
      getAlarmsCountFailure,
    ),
  );
}

function* updateAlarmStatusSaga() {
  yield takeEvery(
    updateAlarmStatusRequest.type,
    createFetchAction(
      updateViewedAlarm,
      updateAlarmStatusSuccess,
      updateAlarmStatusFailure,
      function* success() {
        yield put(minusNumOfNewAlarms());
      },
      undefined,
    ),
  );
}

function* readAllMyAlarmsSaga() {
  yield takeLatest(
    readAllMyAlarmsRequest.type,
    createFetchAction(
      readAllMyAlarms,
      readAllMyAlarmsSuccess,
      readAllMyAlarmsFailure,
      function* success() {
        yield put(resetNumOfNewAlarms());
      },
      failure,
    ),
  );
}

export default function* alarmSaga() {
  yield all([
    getAlarmsSaga(),
    getMoreAlarmsSaga(),
    getAlarmsCountSaga(),
    updateAlarmStatusSaga(),
    readAllMyAlarmsSaga(),
  ]);
}
