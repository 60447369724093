import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CamperState,
  IGetCamperContentsRequestPayload,
  IGetCamperContentsCntRequestPayload,
} from 'store/types';
import { IProfile, IBoard, ICampingLog } from '@types';

const initialState: CamperState = {
  activeTab: 0,
  profile: {} as IProfile,
  posts: [],
  reviews: [],
  postsOffset: 0,
  reviewsOffset: 0,
  hasMorePosts: true,
  hasMoreReviews: true,
  error: '',
};

export const camperSlice = createSlice({
  name: 'camper',
  initialState,
  reducers: {
    getCamperProfileRequest: (state, action: PayloadAction<string>) => {
      state.profile = {} as IProfile;
      state.error = '';
    },
    getCamperProfileSuccess: (state, action: PayloadAction<IProfile>) => {
      state.profile = action.payload;
      state.error = '';
    },
    getCamperProfileFailure: (state, action: PayloadAction<Error | string>) => {
      state.profile = {} as IProfile;
      state.error = action.payload;
    },

    getCamperPostsRequest: (
      state,
      action: PayloadAction<IGetCamperContentsRequestPayload>,
    ) => {
      action.payload.skip ? state.posts : (state.posts = []);
      state.error = '';
    },
    getCamperPostsSuccess: (state, action: PayloadAction<IBoard[]>) => {
      state.posts = state.posts.concat(action.payload);
      state.hasMorePosts = action.payload.length === 5;
      state.error = '';
    },
    getCamperPostsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    getCamperReviewsRequest: (
      state,
      action: PayloadAction<IGetCamperContentsRequestPayload>,
    ) => {
      action.payload.skip ? state.reviews : (state.reviews = []);
      state.error = '';
    },
    getCamperReviewsSuccess: (state, action: PayloadAction<ICampingLog[]>) => {
      state.reviews = state.reviews.concat(action.payload);
      state.hasMoreReviews = action.payload.length === 5;
      state.error = '';
    },
    getCamperReviewsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    setTabMenu: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
      action.payload ? (state.reviewsOffset = 0) : (state.postsOffset = 0);
    },

    addPostsOffset: (state, action: PayloadAction<number>) => {
      state.postsOffset = action.payload;
    },

    addReviewsOffset: (state, action: PayloadAction<number>) => {
      state.reviewsOffset = action.payload;
    },
  },
});

export const {
  getCamperProfileRequest,
  getCamperProfileSuccess,
  getCamperProfileFailure,
  getCamperPostsRequest,
  getCamperPostsSuccess,
  getCamperPostsFailure,
  getCamperReviewsRequest,
  getCamperReviewsSuccess,
  getCamperReviewsFailure,
  setTabMenu,
  addPostsOffset,
  addReviewsOffset,
} = camperSlice.actions;

export default camperSlice.reducer;
