import { all, delay, takeLatest } from 'redux-saga/effects';
import {
  autoCompleteFailure,
  autoCompleteRequest,
  autoCompleteSuccess,
  campAutoCompleteRequest,
  campAutoCompleteSuccess,
  campAutoCompleteFailure,
} from 'store/reducers/autoComplete';
import { autoComplete, campAutoComplete } from 'api';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

function* productsAutoCompleteSaga() {
  yield takeLatest(
    autoCompleteRequest.type,
    createFetchAction(
      autoComplete,
      autoCompleteSuccess,
      autoCompleteFailure,
      undefined,
      failure,
    ),
  );
}

function* campAutoCompleteSaga() {
  yield takeLatest(
    campAutoCompleteRequest.type,
    createFetchAction(
      campAutoComplete,
      campAutoCompleteSuccess,
      campAutoCompleteFailure,
      undefined,
      failure,
    ),
  );
}

export default function* autoCompleteSaga() {
  yield all([productsAutoCompleteSaga(), campAutoCompleteSaga()]);
}
