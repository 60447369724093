export const RESERVATION = {
  VACANCY_ALARM_GUIDES: [
    '빈자리가 생기면 알림을 받을 수 있어요',
    '최대 2박까지 빈자리 알림을 신청할 수 있어요',
    '알림이 오지 않는 경우, 휴대폰 설정을 확인해 주세요',
  ],
  VACANCY_ALARM_EMPTY_GUIDES: [
    '예약이 완료된 캠핑존 상세페이지에서 신청할 수 있어요',
    '빈자리가 생기면 알림을 받을 수 있어요',
    '최대 2박까지 빈자리 알림을 신청할 수 있어요',
  ],
  VACANCY_ALARM_REQUEST_SUCCESS_INFO:
    '이제 앱 푸시 알림으로\n빈자리 알림을 받을 수 있어요.\n빈자리 알림 내역은 [마이] > [나의 캠핑]  > [빈자리 구독] 에서 확인할 수 있어요.',
  VACANCY_ALARM_CANCEL_REQUEST_CONFIRM_HEADMESSAGE: '빈자리 구독을 취소할까요?',
  VACANCY_ALARM_CANCEL_REQUEST_CONFIRM_INFO:
    '빈자리 구독을 취소하면\n빈자리 알림을 받을 수 없어요',
  VACANCY_ALARM_CANCEL_REQUEST_SUCCESS_INFO: '빈자리 구독 취소 완료',
};

export const SHOPBY = {
  // 주문 카드 내 버튼 워딩
  NEXT_ACTION_CANCEL: '취소',
  NEXT_ACTION_EXCHANGE: '교환',
  NEXT_ACTION_RETURN: '반품',
  NEXT_ACTION_WITHDRAW_CANCEL: '취소철회',
  NEXT_ACTION_WITHDRAW_EXCHANGE: '교환철회',
  NEXT_ACTION_WITHDRAW_RETURN: '반품철회',
  // NOTE: VIEW_CLAIM의 경우 프론트상에서 클레임 타입에 따라 취소,교환,반품 구분해서 어미 붙여서 쓰고 있음.
  NEXT_ACTION_VIEW_CLAIM: '정보',
  NEXT_ACTION_VIEW_DELIVERY: '배송조회',
  NEXT_ACTION_CONFIRM_ORDER: '구매확정',
  NEXT_ACTION_WRITE_REVIEW: '리뷰 작성',
  NEXT_ACTION_CHANGE_ADDRESS: '배송지변경',

  ORDER_REQUEST_PAY_DONE: '결제 완료',
  ORDER_REQUEST_PRODUCT_PREPARE: '상품 준비 중',
  ORDER_REQUEST_DELIVERY_PREPARE: '배송 준비 중',
  ORDER_REQUEST_DELIVERY_ING: '배송중',
  ORDER_REQUEST_DELIVERY_DONE: '배송 완료',
  ORDER_REQUEST_BUY_CONFIRM: '구매확정',
  ORDER_REQUEST_CANCEL_DONE: '취소 완료',
  ORDER_REQUEST_RETURN_DONE: '반품 완료',
  ORDER_REQUEST_EXCHANGE_DONE: '교환 완료',
  ORDER_REQUEST_CANCEL_PROCESSING: '취소 처리중',
  ORDER_REQUEST_RETURN_PROCESSING: '반품 처리 중',
  ORDER_REQUEST_EXCHANGE_WAIT: '교환 대기 중',
  ORDER_REQUEST_EXCHANGE_PROCESSING: '교환 처리 중',
  ORDER_REQUEST_REFUND_DONE: '환불완료',
  ORDER_STATUS_DEPOSIT_WAIT: '입금대기',
  ORDER_STATUS_PAY_DONE: '결제완료',
  ORDER_STATUS_PRODUCT_PREPARE: '상품 준비 중',
  ORDER_STATUS_DELIVERY_PREPARE: '배송 준비 중',
  ORDER_STATUS_DELIVERY_ING: '배송중',
  ORDER_STATUS_DELIVERY_DONE: '배송 완료',
  ORDER_STATUS_BUY_CONFIRM: '구매확정',
  ORDER_STATUS_CANCEL_DONE: '취소 완료',
  ORDER_STATUS_RETURN_DONE: '반품 완료',
  ORDER_STATUS_EXCHANGE_DONE: '교환 완료',
  ORDER_STATUS_PAY_WAIT: '결제대기',
  ORDER_STATUS_PAY_CANCEL: '결제포기',
  ORDER_STATUS_PAY_FAIL: '결제실패',
  ORDER_STATUS_DELETE: '삭제',
  ORDER_STATUS_EXCHANGE_WAIT: '교환 대기',
  ORDER_STATUS_REFUND_DONE: '환불 완료',

  CLAIM_STATUS_CANCEL_REQUEST: '취소 처리 중', // 승인대기
  CLAIM_STATUS_CANCEL_NO_REFUND: '취소 완료', // 환불없음
  CLAIM_STATUS_CANCEL_PROC_REQUEST_REFUND: '취소 처리 중', // 환불보류
  CLAIM_STATUS_CANCEL_PROC_WAITING_REFUND: '취소 처리 중', // 환불대기
  CLAIM_STATUS_CANCEL_DONE: '취소 완료', // 환불완료
  CLAIM_STATUS_RETURN_REQUEST: '반품 요청', // 승인대기
  CLAIM_STATUS_RETURN_REJECT_REQUEST: '반품 요청', // 철회대기
  CLAIM_STATUS_RETURN_PROC_BEFORE_RECEIVE: '반품 처리 중', // 수거진행
  CLAIM_STATUS_RETURN_PROC_REQUEST_REFUND: '반품 처리 중', // 환불보류
  CLAIM_STATUS_RETURN_PROC_WAITING_REFUND: '반품 처리 중', // 환불대기
  CLAIM_STATUS_RETURN_REFUND_AMT_ADJUST_REQUESTED: '반품 처리 중', // 조정요청
  CLAIM_STATUS_RETURN_DONE: '반품 완료', // 환불완료
  CLAIM_STATUS_RETURN_NO_REFUND: '반품 완료', // 환불없음
  CLAIM_STATUS_EXCHANGE_REQUEST: '교환 요청', // 승인대기
  CLAIM_STATUS_EXCHANGE_REJECT_REQUEST: '교환 처리 중', // 철회대기
  CLAIM_STATUS_EXCHANGE_PROC_BEFORE_RECEIVE: '교환 처리 중', // 수거진행
  CLAIM_STATUS_EXCHANGE_PROC_REQUEST_PAY: '교환 처리 중', // 결제대기
  CLAIM_STATUS_EXCHANGE_PROC_REQUEST_REFUND: '교환 처리 중', // 환불보류
  CLAIM_STATUS_EXCHANGE_PROC_WAITING: '교환 처리 중', // 처리대기
  CLAIM_STATUS_EXCHANGE_PROC_WAITING_PAY: '교환 처리 중', // 입금처리대기
  CLAIM_STATUS_EXCHANGE_PROC_WAITING_REFUND: '교환 처리 중', // 환불대기
  CLAIM_STATUS_EXCHANGE_DONE: '교환 완료', // 차액없음
  CLAIM_STATUS_EXCHANGE_DONE_PAY_DONE: '교환 완료', // 결제완료
  CLAIM_STATUS_EXCHANGE_DONE_REFUND_DONE: '교환 완료', // 환불완료

  CLAIM_REASON_CHANGE_MIND: '단순변심',
  CLAIM_REASON_DEFECTIVE_PRODUCT: '상품불량/파손',
  CLAIM_REASON_WRONG_DELIVERY: '배송누락/오배송',
  CLAIM_REASON_OUT_OF_STOCK_SYSTEM: '재고부족',
  CLAIM_REASON_WRONG_PRODUCT_DETAIL: '상품상세 정보와 다름',
  CLAIM_REASON_OUT_OF_STOCK: '상품 품절/재고 없음',
  CLAIM_REASON_OTHERS_SELLER: '기타',
  CLAIM_REASON_OTHERS_BUYER: '기타',
  CLAIM_REASON_LATER_INPUT_ORDER: '배송미입력 취소',

  // 클레임 가능 여부 판단 API를 거쳐 반환하는 타입에 따라 프론트에서 서버에러 모달창으로 표기하는 에러 정보
  ERROR_MESSAGE_FROM_VALIDATION_EXISTS_AFTER_CLAIM_WITH_CART_COUPON:
    '같은 주문번호 내에 클레임 신청건과 장바구니 쿠폰이 적용되어 있어 철회가 불가합니다',
  ERROR_MESSAGE_FROM_VALIDATION_EXISTS_SAME_SHIPPING_NO_CLAIM:
    '묶음 배송상품 중 다른 클레임 신청건이 있어 철회가 불가합니다.',

  ACCUMULATION_REASON_ADD: '적립',
  ACCUMULATION_REASON_SUB: '사용',
  ACCUMULATION_STATUS_GROUP_PAYMENT: '적립',
  ACCUMULATION_STATUS_GROUP_DEDUCTION: '사용',

  ACCUMULATION_RESERVE_REASON_ADD_AFTER_PAYMENT: '상품 구매확정 적립',
  ACCUMULATION_RESERVE_REASON_ADD_AFTER_EVENT_PAYMENT: '이벤트 구매확정 적립',
  ACCUMULATION_RESERVE_REASON_ADD_AFTER_REPLACE_PAYMENT:
    '상품 교환 결제 추가 적립',
  ACCUMULATION_RESERVE_REASON_ADD_POSTING: '상품평 작성 적립',
  ACCUMULATION_RESERVE_REASON_ADD_CANCEL: '주문취소 재적립',
  ACCUMULATION_RESERVE_REASON_ADD_RETURN: '반품 재적립',
  ACCUMULATION_RESERVE_REASON_ADD_MANUAL: '운영자 적립',
  ACCUMULATION_RESERVE_REASON_ADD_EVENT: '이벤트성 적립',
  ACCUMULATION_RESERVE_REASON_ADD_SIGNUP: '회원가입 적립금',
  ACCUMULATION_RESERVE_REASON_ADD_BIRTHDAY: '생일축하 적립금',
  ACCUMULATION_RESERVE_REASON_ADD_APP_INSTALL: '앱설치 적립금',
  ACCUMULATION_RESERVE_REASON_ADD_GRADE: '회원등급 적립금',
  ACCUMULATION_RESERVE_REASON_ADD_GRADE_BENEFIT: '등급 혜택 즉시 지급 적립금',
  ACCUMULATION_RESERVE_REASON_SUB_PAYMENT_USED: '상품 결제 사용',
  ACCUMULATION_RESERVE_REASON_SUB_EXTRA_PAYMENT_USED:
    '교환 상품 추가 결제 사용',
  ACCUMULATION_RESERVE_REASON_SUB_CANCEL: '사용적립금 주문취소 재적립',
  ACCUMULATION_RESERVE_REASON_SUB_RETURN: '상품 구매확정 취소 사용',
  ACCUMULATION_RESERVE_REASON_SUB_DELETE_POSTING: '상품평 삭제 반환',
  ACCUMULATION_RESERVE_REASON_SUB_EXPIRED: '유효기간 만료',
  ACCUMULATION_RESERVE_REASON_SUB_MANUAL: '운영자 차감',
  ACCUMULATION_RESERVE_REASON_SUB_DELETE_ACCOUNT: '회원탈퇴 반환',
  ACCUMULATION_RESERVE_REASON_EXTERNAL_ACCUMULATION: '외부적립금',

  REVIEW_REPORT_REASON_COPYRIGHT: '저작권 침해 및 기타 사유',
  REVIEW_REPORT_REASON_SLANDER: '욕설 또는 비방',
};

export const ERROR = {
  COMMON: '요청 도중 에러가 발생하였습니다. 다시 한 번 시도해주세요',
  EXPIRE: '다시 로그인해주세요',
};
