import { IFeedbackLogRequest, ITrackIdRequest } from 'types/feedbackLogs';
import instance from './instance';

export const getTrackId = async (body: ITrackIdRequest) => {
  const res = await instance.post<{ trackId: string }>(
    `/camfit-insight/generate-track-id`,
    body,
  );

  return res.data.trackId;
};

export const createFeedbackLog = async (body: IFeedbackLogRequest) => {
  const res = await instance.post<{ trackId: string }>(
    `/camfit-insight/feedback-log`,
    body,
  );
  return res.data.trackId;
};
