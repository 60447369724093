import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICarNumberResponse } from '@types';
import { MyCarNumberState } from 'store/types';

const initialState: MyCarNumberState = {
  carNumber: '',
  error: '',
};

export const myCarNumberSlice = createSlice({
  name: 'myCarNumber',
  initialState,
  reducers: {
    getMyCarNumberRequest: (state, action: PayloadAction) => {
      state.carNumber = '';
      state.error = '';
    },
    getMyCarNumberSuccess: (
      state,
      action: PayloadAction<ICarNumberResponse>,
    ) => {
      state.carNumber = action.payload.carNumber;
      state.error = '';
    },
    getMyCarNumberFailure: (state, action: PayloadAction<Error | string>) => {
      state.carNumber = '';
      state.error = action.payload;
    },

    resetMyCarNumber: state => {
      state.carNumber = '';
      state.error = '';
    },

    updateMyCarNumberRequest: (state, action: PayloadAction<null | string>) => {
      state.error = '';
    },
    updateMyCarNumberSuccess: (state, action: PayloadAction) => {
      state.error = '';
    },
    updateMyCarNumberFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
  },
});

export const {
  getMyCarNumberRequest,
  getMyCarNumberSuccess,
  getMyCarNumberFailure,
  resetMyCarNumber,
  updateMyCarNumberRequest,
  updateMyCarNumberSuccess,
  updateMyCarNumberFailure,
} = myCarNumberSlice.actions;

export default myCarNumberSlice.reducer;
