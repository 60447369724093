import { all, takeEvery, take, put } from 'redux-saga/effects';
import {
  addOffset,
  getMyItemsFailure,
  getMyItemsRequest,
  getMyItemsSuccess,
  getMyItemFailure,
  getMyItemRequest,
  getMyItemSuccess,
} from 'store/reducers/myItems';
import { getMyItems, getMyItem } from 'api';
import { PayloadAction } from '@reduxjs/toolkit';
import { IGetMyItemsRqPayload } from 'store/types';
import { createFetchAction } from './createFetchAction';

function* getMyItemSaga() {
  yield takeEvery(
    getMyItemRequest,
    createFetchAction(getMyItem, getMyItemSuccess, getMyItemFailure),
  );
}

function* getMyItemsSaga() {
  yield takeEvery(
    getMyItemsRequest,
    createFetchAction(getMyItems, getMyItemsSuccess, getMyItemsFailure),
  );
}

function* getMoreMyItemsSaga() {
  while (true) {
    const action: PayloadAction<IGetMyItemsRqPayload> = yield take(
      addOffset.type,
    );

    yield put(getMyItemsRequest(action.payload));
  }
}

export default function* myItemsSaga() {
  yield all([getMyItemsSaga(), getMoreMyItemsSaga(), getMyItemSaga()]);
}
