import { useState, useCallback } from 'react';

const useModal = (defaultValue = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultValue);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  return {
    openModal,
    closeModal,
    isOpen,
  };
};

export default useModal;
