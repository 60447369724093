import { takeEvery } from 'redux-saga/effects';
import {
  getOpenSchedulesRequest,
  getOpenSchedulesSuccess,
  getOpenSchedulesFailure,
} from 'store/reducers/openSchedules';
import { getOpenSchedules } from 'api';
import { createFetchAction } from './createFetchAction';

function* getOpenSchedulesSaga() {
  yield takeEvery(
    getOpenSchedulesRequest,
    createFetchAction(
      getOpenSchedules,
      getOpenSchedulesSuccess,
      getOpenSchedulesFailure,
    ),
  );
}

export default getOpenSchedulesSaga;
