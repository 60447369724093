import {
  getMyCouponsFailure,
  getMyCouponsRequest,
  getMyCouponsSuccess,
  getMyCouponsCntFailure,
  getMyCouponsCntRequest,
  getMyCouponsCntSuccess,
  getCouponsOnBookingFailure,
  getCouponsOnBookingRequest,
  getCouponsOnBookingSuccess,
  addOffset,
  selectCoupon,
  calculateCouponDiscount,
} from 'store/reducers/coupon';
import { takeEvery, all, take, put, select } from 'redux-saga/effects';
import { findUserCoupons, countUserCoupons, getCouponOnBooking } from 'api';
import { PayloadAction } from '@reduxjs/toolkit';
import { ICoupon } from '@types';
import { createFetchAction } from './createFetchAction';
import { RootState } from '../reducers';

function* getMyCouponsSaga() {
  yield takeEvery(
    getMyCouponsRequest,
    createFetchAction(
      findUserCoupons,
      getMyCouponsSuccess,
      getMyCouponsFailure,
    ),
  );
}

function* getMyCouponsCntSaga() {
  yield takeEvery(
    getMyCouponsCntRequest,
    createFetchAction(
      countUserCoupons,
      getMyCouponsCntSuccess,
      getMyCouponsCntFailure,
    ),
  );
}

function* getMoreMyCouponsSaga() {
  while (true) {
    const action: PayloadAction<number> = yield take(addOffset.type);

    yield put(getMyCouponsRequest({ skip: action.payload, limit: 10 }));
  }
}

function* getMyCouponsOnBookingSaga() {
  yield takeEvery(
    getCouponsOnBookingRequest,
    createFetchAction(
      getCouponOnBooking,
      getCouponsOnBookingSuccess,
      getCouponsOnBookingFailure,
    ),
  );
}

function* calculateCouponDiscountSaga(action: PayloadAction<string>) {
  const couponsOnBooking: ICoupon[] = yield select(
    (state: RootState) => state.couponReducer.couponsOnBooking,
  );

  const { bookingDetail } = yield select(
    (state: RootState) => state.reservationReducer,
  );

  const availableCoupons = couponsOnBooking?.filter(
    coupon =>
      coupon.isAvailable &&
      coupon.minApplyPrice <= bookingDetail.basicAccommodationCharge,
  );

  const selectedCoupon = availableCoupons?.find(c => action.payload === c.name);

  if (!selectedCoupon) {
    yield put(calculateCouponDiscount(0));
    return;
  }

  let discount: number = 0;

  discount = selectedCoupon?.isDiscountPrice
    ? selectedCoupon?.discountPrice
    : (bookingDetail.basicAccommodationCharge *
        selectedCoupon?.discountPercent) /
      100;

  yield put(
    calculateCouponDiscount(
      selectedCoupon?.isDiscountPrice
        ? bookingDetail.basicAccommodationCharge < discount
          ? bookingDetail.basicAccommodationCharge
          : discount
        : selectedCoupon?.maxDiscountAmount
        ? selectedCoupon?.maxDiscountAmount < discount
          ? selectedCoupon?.maxDiscountAmount
          : discount
        : 0,
    ),
  );
}

function* couponSaga() {
  yield all([
    getMyCouponsCntSaga(),
    getMyCouponsSaga(),
    getMoreMyCouponsSaga(),
    getMyCouponsOnBookingSaga(),
    takeEvery(selectCoupon.type, calculateCouponDiscountSaga),
  ]);
}

export default couponSaga;
