import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CalendarState } from 'store/types';
import { IHoliday } from '@types';
import { getYear, isFuture, isToday } from 'date-fns';

const initialState: CalendarState = {
  holidays: [],
  error: '',
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getHolidaysRequest: (state, action: PayloadAction) => {
      state.holidays = [];
      state.error = '';
    },
    getHolidaysSuccess: (state, action: PayloadAction<IHoliday[]>) => {
      const thisYear = new Date().getFullYear();
      const getYear = (month: number, day: number) => {
        return isFuture(new Date(thisYear, month - 1, day)) ||
          isToday(new Date(thisYear, month - 1, day))
          ? thisYear
          : thisYear + 1;
      };

      const newHolidays = action.payload.map(h => {
        const year = h.isEveryYear ? getYear(h.month, h.day) : h.year;

        return {
          year,
          month: h.month,
          day: h.day,
          isEveryYear: h.isEveryYear,
          name: h.name,
        };
      });

      state.holidays = newHolidays;
      state.error = '';
    },
    getHolidaysFailure: (state, action: PayloadAction<Error | string>) => {
      state.holidays = [];
      state.error = action.payload;
    },
  },
});

export const { getHolidaysRequest, getHolidaysSuccess, getHolidaysFailure } =
  calendarSlice.actions;

export default calendarSlice.reducer;
