import { getZonesCoupons } from 'api';
import { all, takeLatest } from 'redux-saga/effects';
import {
  getZonesCouponsFailure,
  getZonesCouponsRequest,
  getZonesCouponsSuccess,
} from 'store/reducers/zone';
import { createFetchAction } from 'store/sagas/createFetchAction';

function* getZonesCouponsSaga() {
  yield takeLatest(
    getZonesCouponsRequest.type,
    createFetchAction(
      getZonesCoupons,
      getZonesCouponsSuccess,
      getZonesCouponsFailure,
    ),
  );
}

export default function* zoneSaga() {
  yield all([getZonesCouponsSaga()]);
}
