import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IGetRelatedProductRequestPayload,
  IGetRelatedProductSuccessPayload,
  RelatedProductState,
} from 'store/types';

const initialState: RelatedProductState = {
  relatedProductList: [],
  offset: 0,
  hasMore: true,
  totalCount: 0,
  error: '',
};

export const relatedProcitListSlice = createSlice({
  name: 'relatedProductList',
  initialState,
  reducers: {
    getRelatedProductListRequest: (
      state,
      action: PayloadAction<IGetRelatedProductRequestPayload>,
    ) => {
      state.relatedProductList = action.payload.skip
        ? state.relatedProductList
        : [];
      state.offset = action.payload.skip;
      state.error = '';
    },
    getRelatedProductListSuccess: (
      state,
      action: PayloadAction<IGetRelatedProductSuccessPayload>,
    ) => {
      state.relatedProductList = state.relatedProductList.concat(
        action.payload.data,
      );
      state.hasMore = state.relatedProductList.length < state.totalCount;
      state.error = '';
    },
    getRelatedProductListFainlure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    getRelatedProductListCountRequest: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.totalCount = 0;
      state.error = '';
    },
    getRelatedProductListCountSuccess: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.totalCount = action.payload;
      state.error = '';
    },
    getRelatedProductListCountFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.totalCount = 0;
      state.error = action.payload;
    },

    addOffset: (
      state,
      action: PayloadAction<IGetRelatedProductRequestPayload>,
    ) => {
      state.offset = action.payload.skip;
    },
  },
});

export const {
  getRelatedProductListRequest,
  getRelatedProductListSuccess,
  getRelatedProductListFainlure,

  getRelatedProductListCountRequest,
  getRelatedProductListCountSuccess,
  getRelatedProductListCountFailure,

  addOffset,
} = relatedProcitListSlice.actions;

export default relatedProcitListSlice.reducer;
