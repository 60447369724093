import { PayloadAction } from '@reduxjs/toolkit';
import { sendPostScore } from 'api/feed';
import { all, put, select, take } from 'redux-saga/effects';
import { RootState } from 'store/reducers';
import { initPostScore, setNeedToSendPostScore } from 'store/reducers/feed';
import { IFeedPostScore } from 'types/feed';

function* sendPostScoreSaga() {
  while (true) {
    const action: PayloadAction<any> = yield take(setNeedToSendPostScore.type);
    const payload = action.payload;

    const postScore: IFeedPostScore = yield select(
      (state: RootState) => state.feedReducer.postScore,
    );

    if (payload) {
      const feedId = payload;

      sendPostScore(postScore);
      yield put(initPostScore(feedId));
    }
  }
}

export default function* feedSaga() {
  yield all([sendPostScoreSaga()]);
}
