import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeErrorModal } from 'store/reducers/common';
import useModal from 'hooks/Reusable/useModal';
import Typography from 'components/atoms/Typography';
import ModalWithButtons from 'components/organisms/ModalWithButtons';
import { AlertIcon } from 'static/newIcons/filled';
import { theme } from 'static/styles/theme';

export interface IErrorModalComponent {
  errorModalContent?:
    | {
        title?: string;

        contents?: string;
        onClose?: () => void;
      }
    | undefined;
}

function ErrorModalComponent({ errorModalContent }: IErrorModalComponent) {
  const dispatch = useDispatch();

  const { isOpen, openModal, closeModal } = useModal();

  useEffect(() => {
    errorModalContent && openModal();
  }, [errorModalContent]);

  const onCloseModal = () => {
    closeModal();
    dispatch(closeErrorModal());
    errorModalContent?.onClose && errorModalContent.onClose();
  };

  return (
    <>
      {isOpen && (
        <ModalWithButtons
          headMessage={errorModalContent?.title}
          headMessageIcon={<AlertIcon width={24} height={24} />}
          primaryBtnText="확인"
          primaryBtnColor={theme.newColors.red}
          primaryBtnTextColor={theme.newColors.white}
          primaryBtnType="contained"
          onClickPrimary={onCloseModal}
          onClose={onCloseModal}
        >
          <Typography weight="regular" variant="body2" color="grey4">
            {errorModalContent?.contents}
          </Typography>
        </ModalWithButtons>
      )}
    </>
  );
}

export default ErrorModalComponent;
