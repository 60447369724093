import styled from 'styled-components';

interface Style {
  ratio: number;
  gradiant?: boolean;
  containerZIndex?: number;
  imgZIndex?: number;
}

// 이미지의 padding-top 을 ratio로 적용해주는 컴포넌트입니다. / ywroh / 2022.02.22
const ImageContainer = styled.div<Style>`
  position: relative;
  overflow: hidden;
  padding-top: ${({ ratio }) => `${ratio}%`};

  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
  }

  > video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    border-top-right-radius: 8px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const NewImageContainer = styled.div<Style>`
  position: relative;
  overflow: hidden;
  z-index: ${({ containerZIndex }) => containerZIndex};
  padding-top: ${({ ratio }) => `${ratio}%`};

  > img {
    position: absolute;
    z-index: ${({ imgZIndex }) => imgZIndex};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
  }

  > video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    border-top-right-radius: 8px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default ImageContainer;
