import { useLocation, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducers';
import { checkPopPath } from 'store/reducers/search';

interface PosItem {
  pathName: string;
  pos: number;
}

const useScrollPos = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const popPath = useSelector(
    (state: RootState) => state.searchReducer.popPath,
  );

  const pos: string | null = sessionStorage.getItem('scrollRestoration');
  const posList: PosItem[] = pos ? JSON.parse(pos as string) : [];

  const resetPopPath = () => {
    dispatch(checkPopPath(''));
  };

  const savePosition = (position: number) => {
    const pageIdx = location.search
      ? posList?.findIndex(({ pathName }) => pathName === location.search)
      : posList?.findIndex(({ pathName }) => pathName === location.pathname);

    if (pageIdx === -1) {
      const newPosList = posList.concat({
        pathName: location.search || location.pathname,
        pos: position,
      });

      sessionStorage.setItem('scrollRestoration', JSON.stringify(newPosList));
    } else {
      const newPosList = posList?.map(item => {
        const { pathName } = item;
        return pathName === location.search || pathName === location.pathname
          ? { ...item, pos: position }
          : item;
      });

      sessionStorage.setItem('scrollRestoration', JSON.stringify(newPosList));
    }
  };

  const savePos = () => {
    const pageYOffset = window.scrollY;
    savePosition(pageYOffset);
  };

  const saveCertainPos = (pos: number) => {
    savePosition(pos);
  };

  const loadPos = (behavior?: ScrollBehavior) => {
    if (posList.length && popPath) {
      const position = location.search
        ? posList.find(({ pathName }) => pathName === location.search)?.pos
        : posList.find(({ pathName }) => pathName === location.pathname)?.pos;

      if (position && history.action === 'POP') {
        window.history.scrollRestoration = 'manual';

        setTimeout(() => {
          window.scrollTo({ top: position, behavior: behavior || 'auto' });
          resetPopPath();
        }, 0);
      }
    }
  };

  const loadPosWithoutPoppath = () => {
    if (posList.length) {
      const position = location.search
        ? posList.find(({ pathName }) => pathName === location.search)?.pos
        : posList.find(({ pathName }) => pathName === location.pathname)?.pos;

      if (position) {
        window.history.scrollRestoration = 'manual';

        setTimeout(() => {
          window.scrollTo({ top: position, behavior: 'auto' });
        }, 0);
      }
    }
  };

  return {
    savePos,
    saveCertainPos,
    loadPos,
    loadPosWithoutPoppath,
    pathname: location.pathname,
    popPath,
    history,
    resetPopPath,
  };
};

export default useScrollPos;
