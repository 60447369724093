import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ProductSearchState,
  ISearchProductsRqPayload,
  IAddProductsPagePayload,
  ISortProductPayload,
} from 'store/types';
import { IProductSearchData, ISearchedProducts } from '@types';

const initialState: ProductSearchState = {
  categories: [],
  banners: [],
  keywords: [],
  products: [],
  numOfProducts: 0,
  hasMore: true,
  page: 0,
  sortedType: 'recommendDesc',
  error: '',
};

export const productSearchSlice = createSlice({
  name: 'productSearch',
  initialState,
  reducers: {
    getProductSearchDataRequest: (state, action: PayloadAction) => {
      state.categories = [];
      state.banners = [];
      state.keywords = [];
      state.error = '';
    },
    getProductSearchDataSuccess: (
      state,
      action: PayloadAction<IProductSearchData>,
    ) => {
      const { categories, banners, keywords } = action.payload;

      state.categories = categories;
      state.banners = banners;
      state.keywords = keywords;
      state.error = '';
    },
    getProductSearchDataFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.categories = [];
      state.banners = [];
      state.keywords = [];
      state.error = action.payload;
    },
    searchProductsRequest: (
      state,
      action: PayloadAction<ISearchProductsRqPayload>,
    ) => {
      action.payload.p > 1 ? state.products : (state.products = []);
      state.numOfProducts = 0;
      state.page = action.payload.p;
      state.error = '';
    },
    searchProductsSuccess: (
      state,
      action: PayloadAction<ISearchedProducts>,
    ) => {
      state.products = state.products.concat(action.payload.products);
      state.numOfProducts = action.payload.numOfProducts;
      state.hasMore = action.payload.products.length === 10;
      state.error = '';
    },
    searchProductsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    searchProductsReset: state => {
      state.products = [];
      state.numOfProducts = 0;
      state.hasMore = true;
      state.page = 0;
      state.error = '';
    },
    addPage: (state, action: PayloadAction<IAddProductsPagePayload>) => {
      state.page = action.payload.page;
    },
    sortProdcuts: (state, action: PayloadAction<ISortProductPayload>) => {
      state.sortedType = action.payload.sort;
    },
  },
});

export const {
  getProductSearchDataRequest,
  getProductSearchDataSuccess,
  getProductSearchDataFailure,
  searchProductsRequest,
  searchProductsSuccess,
  searchProductsFailure,
  addPage,
  sortProdcuts,
  searchProductsReset,
} = productSearchSlice.actions;

export default productSearchSlice.reducer;
