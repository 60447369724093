import { takeLatest, all, select } from 'redux-saga/effects';
import {
  getCurationsRequest,
  getCurationsSuccess,
  getCurationsFailure,
} from 'store/curations/reducer';
import { createFetchActionWithKey } from 'store/sagas/createFetchAction';
import { getV2Curations } from 'api';

function* getCurationsSaga() {
  yield takeLatest(
    getCurationsRequest.type,
    createFetchActionWithKey(
      getV2Curations,
      getCurationsSuccess,
      getCurationsFailure,
    ),
  );
}

export default function* curationListSaga() {
  yield getCurationsSaga();
}
