import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductQnaState, IGetProductQuestionsRqPayload } from 'store/types';
import { TTopic, IProductQuestion } from '@types';

const initialState: ProductQnaState = {
  questions: [],
  hasMore: true,
  timestamp: 0,
  topic: '전체',
  error: '',
};

export const productQnaSlice = createSlice({
  name: 'productQna',
  initialState,
  reducers: {
    selectTopic: (state, action: PayloadAction<TTopic>) => {
      state.topic = action.payload;
    },
    getProductQuestionsRequest: (
      state,
      action: PayloadAction<IGetProductQuestionsRqPayload>,
    ) => {
      action.payload.timestamp ? state.questions : (state.questions = []);
      state.error = '';
    },
    getProductQuestionsSuccess: (
      state,
      action: PayloadAction<IProductQuestion[]>,
    ) => {
      state.questions = state.questions.concat(action.payload);
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },
    getProductQuestionsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
    updateTimestamp: (state, action: PayloadAction<number>) => {
      state.timestamp = action.payload;
    },
  },
});

export const {
  selectTopic,
  getProductQuestionsRequest,
  getProductQuestionsSuccess,
  getProductQuestionsFailure,
  updateTimestamp,
} = productQnaSlice.actions;

export default productQnaSlice.reducer;
