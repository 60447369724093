import { takeEvery, all } from 'redux-saga/effects';
import {
  getRealtimeReservationDataRequest,
  getRealtimeReservationDataRequestSuccess,
  getRealtimeReservationDataRequestFailure,
  getMdPickCampListRequest,
  getMdPickCampListSuccess,
  getMdPickCampListFailure,
  getWeeklyPickCampListRequest,
  getWeeklyPickCampListSuccess,
  getWeeklyPickCampListFailure,
} from 'store/reducers/realtimeReservation';
import { createFetchAction } from 'store/sagas/createFetchAction';
import { findExhibitionCamps, getRealtimeReservationData } from 'api';

function* getRealtimeReservationDataSaga() {
  yield takeEvery(
    getRealtimeReservationDataRequest.type,
    createFetchAction(
      getRealtimeReservationData,
      getRealtimeReservationDataRequestSuccess,
      getRealtimeReservationDataRequestFailure,
    ),
  );
}

function* getMdPickCampListSaga() {
  yield takeEvery(
    getMdPickCampListRequest.type,
    createFetchAction(
      findExhibitionCamps,
      getMdPickCampListSuccess,
      getMdPickCampListFailure,
    ),
  );
}

function* getWeeklyPickCampListSaga() {
  yield takeEvery(
    getWeeklyPickCampListRequest.type,
    createFetchAction(
      findExhibitionCamps,
      getWeeklyPickCampListSuccess,
      getWeeklyPickCampListFailure,
    ),
  );
}

export default function* realtimeReservationSaga() {
  yield all([
    getRealtimeReservationDataSaga(),
    getMdPickCampListSaga(),
    getWeeklyPickCampListSaga(),
  ]);
}
