import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CouponState,
  IGetArrayPayload,
  IGetCouponOnBookingRequestPayload,
} from 'store/types';
import { ICoupon } from '@types';

const initialState: CouponState = {
  myCoupons: [],
  couponsCnt: 0,
  offset: 0,
  hasMore: true,
  couponsOnBooking: [],
  couponDiscount: 0,
  error: '',
};

export const couponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    getMyCouponsRequest: (state, action: PayloadAction<IGetArrayPayload>) => {
      action.payload.skip ? state.myCoupons : (state.myCoupons = []);
      state.error = '';
    },

    getMyCouponsSuccess: (state, action: PayloadAction<ICoupon[]>) => {
      state.myCoupons = state.myCoupons.concat(action.payload);
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },

    getMyCouponsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    getMyCouponsCntRequest: (state, action: PayloadAction) => {
      state.couponsCnt = 0;
      state.error = '';
    },

    getMyCouponsCntSuccess: (state, action: PayloadAction<number>) => {
      state.couponsCnt = action.payload;
      state.error = '';
    },

    getMyCouponsCntFailure: (state, action: PayloadAction<Error | string>) => {
      state.couponsCnt = 0;
      state.error = action.payload;
    },

    addOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },

    getCouponsOnBookingRequest: (
      state,
      action: PayloadAction<IGetCouponOnBookingRequestPayload>,
    ) => {
      state.couponsOnBooking = [];
      state.error = '';
    },
    getCouponsOnBookingSuccess: (state, action: PayloadAction<ICoupon[]>) => {
      state.couponsOnBooking = action.payload;
      state.error = '';
    },
    getCouponsOnBookingFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.couponsOnBooking = [];
      state.error = action.payload;
    },

    resetCouponsOnBooking: state => {
      state.couponsOnBooking = [];
    },

    selectCoupon: (state, action: PayloadAction<string>) => {
      state;
    },

    calculateCouponDiscount: (state, action: PayloadAction<number>) => {
      state.couponDiscount = action.payload;
    },
  },
});

export const {
  getMyCouponsRequest,
  getMyCouponsFailure,
  getMyCouponsSuccess,
  getMyCouponsCntRequest,
  getMyCouponsCntFailure,
  getMyCouponsCntSuccess,
  addOffset,
  getCouponsOnBookingRequest,
  getCouponsOnBookingSuccess,
  getCouponsOnBookingFailure,
  resetCouponsOnBooking,
  selectCoupon,
  calculateCouponDiscount,
} = couponSlice.actions;

export default couponSlice.reducer;
