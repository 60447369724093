import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';
import useRoute from 'hooks/useRoute';
import useActiveHeaderOption from 'hooks/useActiveHeaderOption';
import { theme } from 'static/styles/theme';
import {
  BigChevronRightIcon,
  HomeIcon,
  CancelIcon,
} from 'static/newIcons/outline/regular';

interface Props {
  isGobackIcon?: boolean;
  isHomeIcon?: boolean;
  isCancelIcon?: boolean;
  LeftIconList?: React.ReactNode[];
  RightIconList?: React.ReactNode[];
  title: string | React.ReactNode;
  customGoBack?: () => void;
  homeIconUrl?: string;
  customCancel?: () => void;
  bottomDivider?: boolean;
  isScrollDivider?: boolean;
  isFixed?: boolean;
  isSticky?: boolean;
  isBlankDiv?: boolean;
  backgroundColor?: string;
  cssObject?: CSSProp;
  heightWithUnit?: string;
}

const BasicSubHeader = ({
  isGobackIcon,
  isHomeIcon,
  isCancelIcon,
  LeftIconList,
  RightIconList,
  title,
  customGoBack,
  homeIconUrl,
  customCancel,
  bottomDivider,
  isScrollDivider = false,
  isFixed = true,
  isSticky = false,
  isBlankDiv = true,
  backgroundColor = theme.newColors.white,
  cssObject,
  heightWithUnit,
}: Props) => {
  const { goBack } = useRoute();

  // 양쪽 아이콘 갯수로 타이틀 센터 정렬을 위한 밸런스를 구하기
  const iconBalance = useMemo(() => {
    let balance = 0;

    if (isGobackIcon) balance += 1;
    if (isHomeIcon) balance += 1;
    if (isCancelIcon) balance += 1;
    if (LeftIconList?.length) balance += LeftIconList?.length;
    if (RightIconList?.length) balance -= RightIconList?.length;

    return balance;
  }, [isGobackIcon, isHomeIcon, isCancelIcon, LeftIconList, RightIconList]);

  const handleScroll = () => {
    handleWhiteHeader(0);
  };
  const { handleWhiteHeader, activeScrollY } = useActiveHeaderOption(
    handleScroll,
    isScrollDivider,
  );

  const getScrollActive = () => {
    if (activeScrollY > 5) return true;
    return false;
  };

  return (
    <>
      <BasicSubHeaderContainer
        iconBalance={iconBalance}
        bottomDivider={bottomDivider}
        isScrollActive={getScrollActive()}
        isFixed={isFixed}
        isSticky={isSticky}
        backgroundColor={backgroundColor}
        css={cssObject}
        heightWithUnit={heightWithUnit}
      >
        {iconBalance !== -3 && (
          <LeftIconWrapper>
            {isGobackIcon && (
              <li>
                <button
                  className="goback"
                  onClick={customGoBack || goBack}
                  aria-label="뒤로가기"
                >
                  <BigChevronRightIcon
                    width={24}
                    height={24}
                    fill={theme.newColors.black}
                  />
                </button>
              </li>
            )}
            {isCancelIcon && (
              <li>
                <CancelIcon
                  width={24}
                  height={24}
                  fill={theme.newColors.black}
                  onClick={customCancel}
                />
              </li>
            )}
            {isHomeIcon && (
              <li>
                <Link to={homeIconUrl || '/'}>
                  <HomeIcon
                    width={24}
                    height={24}
                    fill={theme.newColors.black}
                  />
                </Link>
              </li>
            )}
            {LeftIconList?.map((rightIcon, index) => {
              return <li key={index}>{rightIcon}</li>;
            })}
            {iconBalance < 0 &&
              new Array(Math.abs(iconBalance)).fill(1).map((_, index) => (
                <li key={index} className="left-balance-dumy">
                  {null}
                </li>
              ))}
          </LeftIconWrapper>
        )}
        <Title
          iconBalance={iconBalance}
          className={
            iconBalance === -3 ? 'typography-headline' : 'typography-callout'
          }
        >
          {title}
        </Title>
        <RightIconWrapper>
          {iconBalance > 0 &&
            new Array(iconBalance).fill(1).map((_, index) => (
              <li key={index} className="right-balance-dumy">
                {null}
              </li>
            ))}
          {RightIconList?.map((rightIcon, index) => {
            return <li key={index}>{rightIcon}</li>;
          })}
        </RightIconWrapper>
      </BasicSubHeaderContainer>
      {isBlankDiv && <BlankDiv heightWithUnit={heightWithUnit} />}
    </>
  );
};

export default memo(BasicSubHeader);

interface IStyle {
  iconBalance: number;
  isFixed?: boolean;
  isSticky?: boolean;
  bottomDivider?: boolean;
  isScrollActive?: boolean;
  backgroundColor?: string;
  css?: CSSProp;
  heightWithUnit?: string;
}

const BasicSubHeaderContainer = styled.header<IStyle>`
  position: ${({ isFixed, isSticky }) =>
    isSticky ? 'sticky' : isFixed ? 'fixed' : 'absolute'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  z-index: 1000;
  width: 100%;
  max-width: 530px;
  height: ${({ theme, heightWithUnit }) =>
    heightWithUnit || theme.header.heightPx};
  padding: ${({ iconBalance }) =>
    iconBalance === -3 ? '1px 16px 1px 14px' : '1px 14px 1px 14px'};
  padding-bottom: ${({ bottomDivider, isScrollActive }) =>
    bottomDivider || isScrollActive ? '0' : '1px'};
  ${({ bottomDivider, isScrollActive }) =>
    (bottomDivider || isScrollActive) && theme.divider.newBottom};
  background: ${({ backgroundColor }) => backgroundColor};

  svg {
    display: block;
  }

  ${({ css }) => css}
`;

const BlankDiv = styled.div<{ heightWithUnit?: string }>`
  height: ${({ theme, heightWithUnit }) =>
    heightWithUnit || theme.header.heightPx};
`;

const Title = styled.h1<IStyle>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: ${({ iconBalance }) =>
    iconBalance === -3 ? '2px 0 0 -3px;' : '2px 0 0 0'};
  font-weight: ${({ iconBalance }) => (iconBalance === -3 ? '700' : '500')};
  color: ${({ theme }) => theme.newColors.black};
`;

const LeftIconWrapper = styled.ul`
  display: flex;
  align-items: center;

  & > li {
    display: flex;
    align-items: center;
    margin: 0 16px 0 0;
    cursor: pointer;
  }
  .goback {
    transform: rotate(180deg);
  }

  .left-balance-dumy {
    width: 24px;
    height: 24px;
    visibility: hidden;
    cursor: auto;
  }
`;

const RightIconWrapper = styled.ul`
  display: flex;
  align-items: center;

  & > li {
    display: flex;
    align-items: center;
    margin: 0 0 0 16px;
    cursor: pointer;
  }

  .right-balance-dumy {
    width: 24px;
    height: 24px;
    visibility: hidden;
    cursor: auto;
  }
`;
