import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VacancyAlarmsState, ITurnOnVAlarmsRequestPayload } from 'store/types';
import { IVacancyAlarm } from '@types';

const initialState: VacancyAlarmsState = {
  appliedVAlarms: [],
  offset: 0,
  hasMore: true,
  count: 0,
  error: '',
};

export const noticeSlice = createSlice({
  name: 'alarms',
  initialState,
  reducers: {
    getVacancyAlarmsRequest: state => {
      state.appliedVAlarms = [];
      state.error = '';
    },
    getVacancyAlarmsSuccess: (
      state,
      action: PayloadAction<IVacancyAlarm[]>,
    ) => {
      state.appliedVAlarms = action.payload;
      state.error = '';
    },
    getVacancyAlarmsFailure: (state, action: PayloadAction<Error | string>) => {
      state.appliedVAlarms = [];
      state.error = action.payload;
    },

    getVacancyAlarmsCountRequest: (state, action: PayloadAction<boolean>) => {
      state.count = 0;
      state.error = '';
    },
    getVacancyAlarmsCountSuccess: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
      state.error = '';
    },
    getVacancyAlarmsCountFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.count = 0;
      state.error = action.payload;
    },

    applyVacancyAlarmsRequest: (
      state,
      action: PayloadAction<ITurnOnVAlarmsRequestPayload>,
    ) => {
      state.error = '';
    },
    applyVacancyAlarmsSuccess: state => {
      state.error = '';
    },
    applyVacancyAlarmsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    deleteVacancyAlarmsRequest: (state, action: PayloadAction<string>) => {
      state.error = '';
    },
    deleteVacancyAlarmsSuccess: state => {
      state.error = '';
    },
    deleteVacancyAlarmsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
  },
});

export const {
  getVacancyAlarmsRequest,
  getVacancyAlarmsSuccess,
  getVacancyAlarmsFailure,
  getVacancyAlarmsCountRequest,
  getVacancyAlarmsCountSuccess,
  getVacancyAlarmsCountFailure,
  applyVacancyAlarmsRequest,
  applyVacancyAlarmsSuccess,
  applyVacancyAlarmsFailure,
  deleteVacancyAlarmsRequest,
  deleteVacancyAlarmsSuccess,
  deleteVacancyAlarmsFailure,
} = noticeSlice.actions;

export default noticeSlice.reducer;
