import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IShopbyReviewDetail, ITShopbyErrorRes } from '@types';
import {
  IGetShopbyMyReviewListRequestPayload,
  IGetShopbyMyReviewListResponsePayload,
  IGetShopbyReviewDetailRequestPayload,
  IGetShopbyReviewableItemListRequestPayload,
  IGetShopbyReviewableItemListResponsePayload,
  IPostShopbyReviewableItemRequestPayload,
  IPostShopbyReviewableItemResponsePayload,
  IShopbyMyReviewListState,
  IUpdateShopbyMyReviewRequestPayload,
} from 'store/types';

const initialState: IShopbyMyReviewListState = {
  activeTab: 0,

  reviewableItemListInfo: {
    totalCount: undefined,
    reviewableItemList: [],
    paging: {
      pageNumber: 1,
      pageSize: 10,
      hasMore: true,
    },
    shopbyError: undefined,
  },

  reviewedItemListInfo: {
    totalCount: undefined,
    reviewedItemList: [],
    paging: {
      pageNumber: 1,
      pageSize: 10,
      hasMore: true,
    },
    shopbyError: undefined,
  },

  reviewDetailInfo: {
    reviewDetail: undefined,
    shopbyError: undefined,
  },

  shopbyPostError: undefined,
};

export const shopbyMyReviewListSlice = createSlice({
  name: 'shopbyMyReviewList',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },

    getShopbyReviewableItemListRequest: (
      state,
      action: PayloadAction<IGetShopbyReviewableItemListRequestPayload>,
    ) => {
      state.reviewableItemListInfo.totalCount = 0;
      state.reviewableItemListInfo.reviewableItemList = [];

      state.reviewableItemListInfo.paging.pageNumber = 1;
      state.reviewableItemListInfo.paging.hasMore = true;

      state.reviewableItemListInfo.shopbyError = undefined;
    },
    getShopbyReviewableItemListMoreRequest: (
      state,
      action: PayloadAction<IGetShopbyReviewableItemListRequestPayload>,
    ) => {
      state.reviewableItemListInfo.paging.pageNumber =
        action.payload.pageNumber;
      state.reviewableItemListInfo.paging.hasMore = true;
      state.reviewableItemListInfo.shopbyError = undefined;
    },
    getShopbyReviewableItemListsuccess: (
      state,
      action: PayloadAction<{
        data: IGetShopbyReviewableItemListResponsePayload;
      }>,
    ) => {
      const data = action.payload.data;
      if (state.reviewableItemListInfo.paging.pageNumber === 1) {
        state.reviewableItemListInfo.totalCount = data.totalCount;
        state.reviewableItemListInfo.reviewableItemList = data.items;
      } else {
        state.reviewableItemListInfo.reviewableItemList =
          state.reviewableItemListInfo.reviewableItemList.concat(data.items);
      }
      state.reviewableItemListInfo.paging.hasMore =
        state.reviewableItemListInfo.reviewableItemList.length <
        data.totalCount;
      state.reviewableItemListInfo.shopbyError = undefined;
    },
    getShopbyReviewableItemListFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.reviewableItemListInfo.shopbyError = action.payload;
    },

    postShopbyReviewableItemRequest: (
      state,
      action: PayloadAction<IPostShopbyReviewableItemRequestPayload>,
    ) => {
      state.shopbyPostError = undefined;
    },
    postShopbyReviewableItemSuccess: (
      state,
      action: PayloadAction<IPostShopbyReviewableItemResponsePayload>,
    ) => {
      state.shopbyPostError = undefined;
    },
    postShopbyReviewableItemFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.shopbyPostError = action.payload;
    },

    getShopbyMyReviewListRequest: (
      state,
      action: PayloadAction<IGetShopbyMyReviewListRequestPayload>,
    ) => {
      state.reviewedItemListInfo.reviewedItemList = [];

      state.reviewedItemListInfo.paging.pageNumber = 1;
      state.reviewedItemListInfo.paging.hasMore = true;

      state.reviewedItemListInfo.shopbyError = undefined;
    },
    getShopbyMyReviewListMoreRequest: (
      state,
      action: PayloadAction<IGetShopbyMyReviewListRequestPayload>,
    ) => {
      state.reviewedItemListInfo.paging.pageNumber = action.payload.pageNumber;
      state.reviewedItemListInfo.paging.hasMore = true;
      state.reviewedItemListInfo.shopbyError = undefined;
    },
    getShopbyMyReviewListSuccess: (
      state,
      action: PayloadAction<{
        data: IGetShopbyMyReviewListResponsePayload;
      }>,
    ) => {
      const data = action.payload.data;
      if (state.reviewedItemListInfo.paging.pageNumber === 1) {
        state.reviewedItemListInfo.totalCount = data.totalCount;
        state.reviewedItemListInfo.reviewedItemList = data.items;
      } else {
        state.reviewedItemListInfo.reviewedItemList =
          state.reviewedItemListInfo.reviewedItemList.concat(data.items);
      }
      state.reviewedItemListInfo.paging.hasMore =
        state.reviewedItemListInfo.reviewedItemList.length < data.totalCount;
      state.reviewedItemListInfo.shopbyError = undefined;
    },
    getShopbyMyReviewListFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.reviewedItemListInfo.shopbyError = action.payload;
    },

    resetShopbyMyReviewListError: (
      state,
      action: PayloadAction<
        'shopbyPostError' | 'reviewableItemListInfo' | 'reviewedItemListInfo'
      >,
    ) => {
      if (action.payload === 'shopbyPostError') {
        state.shopbyPostError = undefined;
      }

      if (action.payload === 'reviewableItemListInfo') {
        state.reviewableItemListInfo.shopbyError = undefined;
      }

      if (action.payload === 'reviewedItemListInfo') {
        state.reviewedItemListInfo.shopbyError = undefined;
      }
    },

    getShopbyMyReviewDetailRequest: (
      state,
      action: PayloadAction<IGetShopbyReviewDetailRequestPayload>,
    ) => {
      state.reviewDetailInfo.reviewDetail = undefined;
      state.reviewDetailInfo.shopbyError = undefined;
    },
    getShopbyMyReviewDetailSuccess: (
      state,
      action: PayloadAction<{ data: IShopbyReviewDetail }>,
    ) => {
      state.reviewDetailInfo.reviewDetail = action.payload.data;
      state.reviewDetailInfo.shopbyError = undefined;
    },
    getShopbyMyReviewDetailFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.reviewDetailInfo.shopbyError = action.payload;
    },

    updateShopbyMyReviewRequest: (
      state,
      action: PayloadAction<IUpdateShopbyMyReviewRequestPayload>,
    ) => {
      state.shopbyPostError = undefined;
    },
    updateShopbyMyReviewSuccess: (state, action) => {
      state.shopbyPostError = undefined;
    },
    updateShopbyMyReviewFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.shopbyPostError = action.payload;
    },
  },
});

export const {
  setActiveTab,

  getShopbyReviewableItemListRequest,
  getShopbyReviewableItemListMoreRequest,
  getShopbyReviewableItemListsuccess,
  getShopbyReviewableItemListFailure,

  postShopbyReviewableItemRequest,
  postShopbyReviewableItemSuccess,
  postShopbyReviewableItemFailure,

  getShopbyMyReviewListRequest,
  getShopbyMyReviewListMoreRequest,
  getShopbyMyReviewListSuccess,
  getShopbyMyReviewListFailure,

  resetShopbyMyReviewListError,

  getShopbyMyReviewDetailRequest,
  getShopbyMyReviewDetailSuccess,
  getShopbyMyReviewDetailFailure,

  updateShopbyMyReviewRequest,
  updateShopbyMyReviewSuccess,
  updateShopbyMyReviewFailure,
} = shopbyMyReviewListSlice.actions;

export default shopbyMyReviewListSlice.reducer;
