import { setToastContent } from 'store/reducers/common';
import { takeEvery, takeLatest, all, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getVacancyAlarmsFailure,
  getVacancyAlarmsRequest,
  getVacancyAlarmsSuccess,
  deleteVacancyAlarmsFailure,
  deleteVacancyAlarmsRequest,
  deleteVacancyAlarmsSuccess,
  applyVacancyAlarmsFailure,
  applyVacancyAlarmsRequest,
  applyVacancyAlarmsSuccess,
} from 'store/reducers/vacancyAlarms';
import {
  getVacancyAlarms,
  requestVacancyAlarms,
  deleteVacancyAlarms,
} from 'api';
import { RESERVATION } from 'constants/globalPhrases';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

function* getVacancyAlarmsSaga() {
  yield takeEvery(
    getVacancyAlarmsRequest.type,
    createFetchAction(
      getVacancyAlarms,
      getVacancyAlarmsSuccess,
      getVacancyAlarmsFailure,
      undefined,
      failure,
    ),
  );
}

function* requestVacancyAlarmsSaga() {
  yield takeLatest(
    applyVacancyAlarmsRequest.type,
    createFetchAction(
      requestVacancyAlarms,
      applyVacancyAlarmsSuccess,
      applyVacancyAlarmsFailure,
      function* success() {
        yield alert(RESERVATION.VACANCY_ALARM_REQUEST_SUCCESS_INFO);
      },
      failure,
    ),
  );
}

function* deleteVacancyAlarmsSaga() {
  yield takeLatest(
    deleteVacancyAlarmsRequest.type,
    createFetchAction(
      deleteVacancyAlarms,
      deleteVacancyAlarmsSuccess,
      deleteVacancyAlarmsFailure,
      function* success() {
        yield put(getVacancyAlarmsRequest());
        // NOTE: 여러개 삭제 시, 중복으로 토스트 안올라오도록 아래와 같이 구현
        yield put(
          setToastContent({
            id: 'vacancyAlaramRequestSuccess',
            message: RESERVATION.VACANCY_ALARM_CANCEL_REQUEST_SUCCESS_INFO,
            option: {
              style: { bottom: '-15px' },
            },
          }),
        );
      },
      failure,
    ),
  );
}

function* vacancyAlarmSaga() {
  yield all([
    getVacancyAlarmsSaga(),
    requestVacancyAlarmsSaga(),
    deleteVacancyAlarmsSaga(),
  ]);
}

export default vacancyAlarmSaga;
