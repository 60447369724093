import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CampAnnouncesState,
  IGetCampAnnouncesRequestPayload,
  IGetArrayPagePayload,
} from 'store/types';
import { ISingleCampAnnounce, ICampAnnounce } from '@types';

const initialState: CampAnnouncesState = {
  campAnnounces: [],
  campAnnouncesByCamp: [],
  campAnnounce: {} as ISingleCampAnnounce,
  count: 0,
  page: 1,
  hasMore: true,
  error: '',
};

export const campSlice = createSlice({
  name: 'camp',
  initialState,
  reducers: {
    getCampAnnouncesRequest: (
      state,
      action: PayloadAction<
        IGetCampAnnouncesRequestPayload & IGetArrayPagePayload
      >,
    ) => {
      state.error = '';

      if (action.payload.page > 1) {
        return;
      }

      state.campAnnounces = [];
      state.page = 1;
    },
    getCampAnnouncesSuccess: (
      state,
      action: PayloadAction<ICampAnnounce[]>,
    ) => {
      state.campAnnounces = state.campAnnounces.concat(action.payload);
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },
    getCampAnnouncesFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
      state.hasMore = false;
    },

    addPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    getCampAnnouncesByCampRequest: (
      state,
      action: PayloadAction<
        IGetCampAnnouncesRequestPayload & IGetArrayPagePayload
      >,
    ) => {
      state.campAnnouncesByCamp = [];
      state.error = '';
    },
    getCampAnnouncesByCampSuccess: (
      state,
      action: PayloadAction<ICampAnnounce[]>,
    ) => {
      state.campAnnouncesByCamp = action.payload;
      state.error = '';
    },
    getCampAnnouncesByCampFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.campAnnouncesByCamp = [];
      state.error = action.payload;
    },

    getCampAnnouncesCntRequest: (
      state,
      action: PayloadAction<IGetCampAnnouncesRequestPayload>,
    ) => {
      state.count = 0;
      state.error = '';
    },
    getCampAnnouncesCntSuccess: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
      state.error = '';
    },
    getCampAnnouncesCntFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.count = 0;
      state.error = action.payload;
    },

    getSingleCampAnnounceRequest: (state, action: PayloadAction<string>) => {
      state.campAnnounce = {} as ISingleCampAnnounce;
      state.error = '';
    },
    getSingleCampAnnounceSuccess: (
      state,
      action: PayloadAction<ISingleCampAnnounce>,
    ) => {
      state.campAnnounce = action.payload;
      state.error = '';
    },
    getSingleCampAnnounceFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.campAnnounce = {} as ISingleCampAnnounce;
      state.error = action.payload;
    },
  },
});

export const {
  getCampAnnouncesRequest,
  getCampAnnouncesSuccess,
  getCampAnnouncesFailure,
  getCampAnnouncesByCampRequest,
  getCampAnnouncesByCampSuccess,
  getCampAnnouncesByCampFailure,
  getCampAnnouncesCntRequest,
  getCampAnnouncesCntSuccess,
  getCampAnnouncesCntFailure,
  getSingleCampAnnounceRequest,
  getSingleCampAnnounceSuccess,
  getSingleCampAnnounceFailure,
  addPage,
} = campSlice.actions;

export default campSlice.reducer;
