import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastState } from 'store/types';

const initialState: ToastState = {
  isRequestSucceed: false,
  message: '',
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<string>) => {
      state.isRequestSucceed = true;
      state.message = action.payload;
    },
    deleteToast: (state, action: PayloadAction) => {
      state.isRequestSucceed = false;
      state.message = '';
    },
  },
});

export const { showToast, deleteToast } = toastSlice.actions;

export default toastSlice.reducer;
