import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SiteState, IGetAvailableForOneNightRequestPayload } from 'store/types';
import { ISite, IGetSitesAvailableForOneNightResponse } from '@types';

const initialState: SiteState = {
  siteCnt: 0,
  siteList: [],
  sitesAvailableForOneNight: [],
  error: null,
};

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    getSiteCntRequest: state => {
      // state.siteCnt = 0;
      state.siteList = [];
      state.error = null;
    },
    getSiteCntSuccess: (state, action: PayloadAction<number>) => {
      state.siteCnt = action.payload;
      state.error = null;
    },
    getSiteCntFailure: (state, action: PayloadAction<AxiosError>) => {
      state.siteCnt = 0;
      state.error = action.payload;
    },

    // getLongTermSiteCntRequest: state => {
    //   // state.siteCnt = 0;
    //   state.siteList = [];
    //   state.error = null;
    // },
    getLongTermSiteCntSuccess: (state, action: PayloadAction<number>) => {
      state.siteCnt = action.payload;
      state.error = null;
    },
    // getLongTermSiteCntFailure: (state, action: PayloadAction<AxiosError>) => {
    //   state.siteCnt = 0;
    //   state.error = action.payload;
    // },

    getSitesRequest: state => {
      state.siteList = [];
      state.sitesAvailableForOneNight = [];
      state.error = null;
    },
    getSitesSuccess: (state, action: PayloadAction<ISite[]>) => {
      state.siteCnt = action.payload.length;
      state.siteList = action.payload;
      state.error = null;
    },
    getSitesFailure: (state, action: PayloadAction<AxiosError>) => {
      state.siteList = [];
      state.error = action.payload;
    },

    toggleSiteRequest: (
      state,
      action: PayloadAction<{ id: string; bookingType?: string }>,
    ) => {
      state.siteList = [];
    },
    toggleSiteSuccess: (state, action: PayloadAction<ISite[]>) => {
      state.siteList = action.payload;
      state.error = null;
    },
    toggleSiteFailure: (state, action: PayloadAction<AxiosError>) => {
      state.siteList = [];
      state.error = action.payload;
    },

    getLongTermSitesRequest: state => {},
    getLongTermSitesSuccess: (state, action: PayloadAction<ISite[]>) => {
      state.siteList = action.payload;
    },
    getLongTermSitesFailure: (state, action: PayloadAction<AxiosError>) => {
      state.siteList = [];
    },

    getAvailableForOneNightRequest: (
      state,
      action: PayloadAction<IGetAvailableForOneNightRequestPayload>,
    ) => {
      state.sitesAvailableForOneNight = [];
      state.error = null;
    },
    getAvailableForOneNightSuccess: (
      state,
      action: PayloadAction<IGetSitesAvailableForOneNightResponse>,
    ) => {
      state.sitesAvailableForOneNight = action.payload.availableSites;
      state.error = null;
    },
    getAvailableForOneNightFailure: (state, action) => {
      state.sitesAvailableForOneNight = [];
      state.error = action.payload;
    },
  },
});

export const {
  getSiteCntRequest,
  getSiteCntSuccess,
  getSiteCntFailure,

  // getLongTermSiteCntRequest,
  getLongTermSiteCntSuccess,
  // getLongTermSiteCntFailure,

  getSitesRequest,
  getSitesSuccess,
  getSitesFailure,

  toggleSiteRequest,
  toggleSiteSuccess,
  toggleSiteFailure,

  getLongTermSitesRequest,
  getLongTermSitesSuccess,
  getLongTermSitesFailure,

  getAvailableForOneNightRequest,
  getAvailableForOneNightSuccess,
  getAvailableForOneNightFailure,
} = siteSlice.actions;

export default siteSlice.reducer;
