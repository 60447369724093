import { deleteMyPets, getMyPets, setMyPets, updateMyPets } from 'api';
import { customHistory } from 'App';
import { all, put, takeEvery } from 'redux-saga/effects';
import {
  getMyPetsRequest,
  getMyPetsSuccess,
  getMyPetsFailure,
  setMyPetsRequest,
  setMyPetsSuccess,
  setMyPetsFailure,
  deleteMyPetsRequest,
  deleteMyPetsSuccess,
  deleteMyPetsFailure,
  updateMyPetsRequest,
  updateMyPetsSuccess,
  updateMyPetsFailure,
} from 'store/reducers/myPets';
import { showToast } from 'store/reducers/toast';
import { toast } from 'react-toastify';
import { createFetchAction } from './createFetchAction';

function* getMyPetsSaga() {
  yield takeEvery(
    getMyPetsRequest.type,
    createFetchAction(getMyPets, getMyPetsSuccess, getMyPetsFailure),
  );
}

function* setMyPetsSaga() {
  yield takeEvery(
    setMyPetsRequest.type,
    createFetchAction(
      setMyPets,
      setMyPetsSuccess,
      setMyPetsFailure,
      function* success() {
        yield customHistory.goBack();
        yield window.sessionStorage.setItem('successType', 'set');
      },
    ),
  );
}

function* deleteMyPetsSaga() {
  yield takeEvery(
    deleteMyPetsRequest.type,
    createFetchAction(
      deleteMyPets,
      deleteMyPetsSuccess,
      deleteMyPetsFailure,
      function* sucess() {
        yield window.location.reload();
        yield window.sessionStorage.setItem('successType', 'delete');
      },
    ),
  );
}

function* updateMypetsSaga() {
  yield takeEvery(
    updateMyPetsRequest.type,
    createFetchAction(
      updateMyPets,
      updateMyPetsSuccess,
      updateMyPetsFailure,
      function* success() {
        yield customHistory.goBack();
        yield window.sessionStorage.setItem('successType', 'update');
      },
    ),
  );
}
export default function* myPetsSaga() {
  yield all([
    getMyPetsSaga(),
    setMyPetsSaga(),
    deleteMyPetsSaga(),
    updateMypetsSaga(),
  ]);
}
