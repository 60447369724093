export function getRandomElementsFromArray<T>(
  arr: T[],
  numElements: number,
): T[] {
  const copyArray = [...arr];

  const randomElements: T[] = [];
  for (let i = 0; i < numElements; i += 1) {
    const randomIndex = Math.floor(Math.random() * copyArray.length);

    randomElements.push(copyArray.splice(randomIndex, 1)[0]);
  }

  return randomElements;
}

export default getRandomElementsFromArray;
