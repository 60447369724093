import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const ScrollToTop = () => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      // 캠핑장 상세 체크 정규표현식 (캠핑장 상세에서는 scrollTop 무효화)
      const regExp = /^\/camp\/\w+$/;
      if (regExp.test(pathname)) return;

      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
};

export default ScrollToTop;
