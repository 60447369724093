import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/reducers';
import { getFeatureFlagsRequest } from 'store/reducers/featureFlags';

const useFeatureFlags = (flagKey: string) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const flag = useSelector(
    (state: RootState) => state.featureFlagsReducer.flags[flagKey],
  );

  const isLoading = useSelector(
    (state: RootState) => state.loadingReducer[getFeatureFlagsRequest.type],
  );

  useEffect(() => {
    if (history.action === 'POP') return;
    dispatch(getFeatureFlagsRequest({ key: flagKey }));
  }, [flagKey]);

  return { enableFeature: flag?.enabled, options: flag?.options, isLoading };
};

export default useFeatureFlags;
