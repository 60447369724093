import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Type {
  /**
   *  배너별 id와 보여진 배너 인덱스를 저장하기 위한 Map obj.
   */
  dynamicBannersMap: Map<string, number>;
}

const initialState: Type = {
  dynamicBannersMap: new Map<string, number>(),
};
/**
 * 배너마다 swipe 순서를 동적으로 하기 위한 slice.
 * ex) 메인페이지의 배너가 3개 중 2개까지 노출된 상황에서, 유저가 다른 페이지에 갔다가 다시 메인페이지에 왔을 경우, 1번째 배너를 다시 보여주는 것이 아니라 3번째 배너를 보여주도록 하기 위한 리듀서.
 */
export const dynamicBannersSlice = createSlice({
  name: 'dynamicBanners',
  initialState,
  reducers: {
    updateDynamicBannersIndex: (
      state,
      action: PayloadAction<{ bannerIds: string; nextIndex: number }>,
    ) => {
      const { bannerIds, nextIndex } = action.payload;
      state.dynamicBannersMap.set(bannerIds, nextIndex);
    },
  },
});

export const { updateDynamicBannersIndex } = dynamicBannersSlice.actions;

export default dynamicBannersSlice.reducer;
