import { CategoryState } from 'store/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICategoryQuestion } from '@types';

const initialState: CategoryState = {
  questions: [],
  error: '',
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    getQuestionsRequest: (state, action: PayloadAction<string>) => {
      state.questions = [];
      state.error = '';
    },
    getQuestionsSuccess: (
      state,
      action: PayloadAction<ICategoryQuestion[]>,
    ) => {
      state.questions = action.payload;
      state.error = '';
    },
    getQuestionsFailure: (state, action: PayloadAction<Error | string>) => {
      state.questions = [];
      state.error = action.payload;
    },
  },
});

export const { getQuestionsRequest, getQuestionsSuccess, getQuestionsFailure } =
  categorySlice.actions;

export default categorySlice.reducer;
