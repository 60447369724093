import { all, call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  authenticationAndUpdatePhoneNumberFailure,
  authenticationAndUpdatePhoneNumberRequest,
  authenticationAndUpdatePhoneNumberSuccess,
  resetSelfAuthentication,
  selfAuthenticationRequest,
  selfAuthenticationResultFailure,
  selfAuthenticationResultRequest,
  selfAuthenticationResultSuccess,
} from 'store/reducers/selfAuthentication';
import {
  getSelfAuthenticationResult,
  putAuthenticationAndUpdatePhoneNumber,
} from 'api';
import { ISelfAuthenticationPayload } from 'store/types';
import { getSourcePath, removeSourcePath } from 'utils/sessionStorageUtils';
import { customHistory } from 'App';
import { setNeedCertification } from 'store/reducers/login';
import { createFetchAction } from './createFetchAction';

const BootPay = require('bootpay-js');

interface LocationState {
  isAuthorized?: boolean;
  from: { pathname: string };
}

function processSelfAuthentication(params: {
  pageName: string;
  userInfo: {
    // username: string;
    phone: string;
  };
  functions: {
    onSuccess: (data: any) => void;
    onCancel: (data: any) => void;
    onError: (data: any) => void;
  };
}) {
  const id = Math.floor(Math.random() * 10000);

  const { userInfo, functions } = params;

  const { onSuccess, onCancel, onError } = functions;

  BootPay.default
    .request({
      price: 0,
      application_id: process.env.REACT_APP_BOOTPAY_APPLICATION_ID as string,
      pg: 'danal',
      name: '본인인증',
      method: 'auth',
      show_agree_window: 0,
      order_id: `${Date.now()}${id}`,
      user_info: {
        phone: userInfo.phone,
      },
      extra: {
        age_limit: '14',
      },
    })
    .ready((data: any) => {
      // NOTE: 해당 함수 처리가 없으면, 앱에서 인증창이 뜨지 않음
      console.log(data);
    })
    .confirm((data: any) => {
      console.log(data);
      BootPay.default.transactionConfirm(data);
    })
    .error((data: any) => {
      console.log(data);
      onError && onError(data);
    })
    .cancel((data: any) => {
      console.log(data);
      onCancel && onCancel(data);
    })
    .done((data: ISelfAuthenticationPayload) => {
      onSuccess(data);
    });
}
function* getSelfAuthenticationSaga() {
  yield takeLatest(
    selfAuthenticationRequest.type,
    function* process(action: PayloadAction<any>) {
      const { payload } = action;
      yield call(processSelfAuthentication, payload);
    },
  );
}

function* getSelfAuthenticationResultSaga() {
  yield takeLatest(
    selfAuthenticationResultRequest.type,
    createFetchAction(
      getSelfAuthenticationResult,
      selfAuthenticationResultSuccess,
      selfAuthenticationResultFailure,
      function* success() {
        const isLoggedIn = localStorage.getItem('jwt');

        if (!isLoggedIn) {
          // 로그인이 안된경우, 즉. 회원가입 플로우에서의 본인인증 로직
          return;
        }

        const sourcePath = getSourcePath();
        const locationState: LocationState = yield customHistory.location.state;

        if (sourcePath) {
          yield removeSourcePath();
          yield customHistory.push(sourcePath);
        } else if (locationState?.isAuthorized !== undefined) {
          yield customHistory.push('/reservation'); // 예약 플로우
        } else if (locationState?.from.pathname) {
          yield customHistory.push(locationState?.from.pathname);
        }
      },
    ),
  );
}

function* authenticationAndUpdatePhoneNumberSaga() {
  yield takeLatest(
    authenticationAndUpdatePhoneNumberRequest.type,
    createFetchAction(
      putAuthenticationAndUpdatePhoneNumber,
      authenticationAndUpdatePhoneNumberSuccess,
      authenticationAndUpdatePhoneNumberFailure,
      function* success() {
        yield put(setNeedCertification(false));

        const sourcePath = getSourcePath();
        removeSourcePath();

        if (sourcePath === '/mypage/info/edit') {
          // 강제 본인인증
          yield customHistory.push('/mypage');
          yield toast.success('본인인증이 완료되었습니다');
          yield put(resetSelfAuthentication());
        }

        if (sourcePath === '/settings/phone') {
          // 전화번호 변경
          yield toast.success('전화번호가 변경되었습니다');
        }
      },
    ),
  );
}

export default function* selfAuthenticationSaga() {
  yield all([
    getSelfAuthenticationResultSaga(),
    getSelfAuthenticationSaga(),
    authenticationAndUpdatePhoneNumberSaga(),
  ]);
}
