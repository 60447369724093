import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILogInResponse } from '@types';
import { ILoginRequestPayload, LoginState } from 'store/types';

const initialState: LoginState = {
  error: '',
  isNeedCertification: false,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginRequest: (state, action: PayloadAction<ILoginRequestPayload>) => {
      state.error = '';
    },
    loginSuccess: (state, action: PayloadAction<ILogInResponse>) => {
      state.error = '';
    },
    loginFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    setNeedCertification: (state, action: PayloadAction<boolean>) => {
      state.isNeedCertification = action.payload;
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  setNeedCertification,
} = loginSlice.actions;

export default loginSlice.reducer;
