import { takeEvery, all, takeLatest } from 'redux-saga/effects';
import {
  getNotionDbRequest,
  getNotionDbSuccess,
  getNotionDbFailure,
  getNotionPageRequest,
  getNotionPageSuccess,
  getNotionPageFailure,
  getNotionBlockRequest,
  getNotionBlockSuccess,
  getNotionBlockFailure,
  getNotionBlockChildrenFailure,
  getNotionBlockChildrenRequest,
  getNotionBlockChildrenSuccess,
  createNotionPageRequest,
  createNotionPageSuccess,
  createNotionPageFailure,
} from 'store/notion/reducer';
import { createFetchAction } from 'store/sagas/createFetchAction';
import {
  createNotionPage,
  // getNotionPage,
  getNotionBlock,
  getNotionBlockChildren,
  getNotionDatabase,
} from 'api';

function* getNotionDbSaga() {
  yield takeEvery(
    getNotionDbRequest.type,
    createFetchAction(
      getNotionDatabase,
      getNotionDbSuccess,
      getNotionDbFailure,
      function success(data: any) {
        console.log('getNotionDbSaga success :: ', data);
      },
    ),
  );
}

function* getNotionPageSaga() {
  yield takeEvery(
    getNotionPageRequest.type,
    createFetchAction(
      getNotionBlockChildren,
      getNotionPageSuccess,
      getNotionPageFailure,
      function success(data: any) {
        console.log('getNotionPageSaga success :: ', data);
      },
    ),
  );
}

function* getNotionBlockSaga() {
  yield takeEvery(
    getNotionBlockRequest.type,
    createFetchAction(
      getNotionBlock,
      getNotionBlockSuccess,
      getNotionBlockFailure,
      function success(data: any) {
        console.log('getNotionBlockSaga success :: ', data);
      },
    ),
  );
}

function* getNotionBlockChildrenSaga() {
  yield takeEvery(
    getNotionBlockChildrenRequest.type,
    createFetchAction(
      getNotionBlockChildren,
      getNotionBlockChildrenSuccess,
      getNotionBlockChildrenFailure,
      function success(data: any) {
        console.log('getNotionBlockSaga success :: ', data);
      },
    ),
  );
}

function* createNotionPageSaga() {
  yield takeLatest(
    createNotionPageRequest.type,
    createFetchAction(
      createNotionPage,
      createNotionPageSuccess,
      createNotionPageFailure,
      function success(data: any) {
        console.log('createNotionPageSaga success :: ', data);
      },
    ),
  );
}
export default function* eventListSaga() {
  yield all([
    getNotionDbSaga(),
    getNotionPageSaga(),
    getNotionBlockSaga(),
    getNotionBlockChildrenSaga(),
    createNotionPageSaga(),
  ]);
}
