import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MyListState, IGetArrayPayload } from 'store/types';
import { ICampingLog, IBoard, IMyComment, TMyListLNBSubject } from '@types';

const initialState: MyListState = {
  myReviews: [],
  myPosts: [],
  myComments: [],
  myReviewCnt: 0,
  myPostsCnt: 0,
  myCommentsCnt: 0,
  hasMoreReviews: true,
  hasMorePosts: true,
  hasMoreComments: true,
  reviewsOffset: 0,
  postsOffset: 0,
  commentsOffset: 0,
  mainPageActiveTab: 0,
  activeTab: 0,
  activeSubject: '',
  error: '',
};

export const myListSlice = createSlice({
  name: 'myList',
  initialState,
  reducers: {
    getMyReviewsRequest: (state, action: PayloadAction<IGetArrayPayload>) => {
      action.payload.skip ? state.myReviews : (state.myReviews = []);
      state.error = '';
    },
    getMyReviewsSuccess: (state, action: PayloadAction<ICampingLog[]>) => {
      state.myReviews = state.myReviews.concat(action.payload);
      state.hasMoreReviews = action.payload.length === 10;
      state.error = '';
    },
    getMyReviewsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    getMyReviewsCntRequest: state => {
      state.myReviewCnt = 0;
      state.reviewsOffset = 0;
      state.error = '';
    },
    getMyReviewsCntSuccess: (state, action: PayloadAction<number>) => {
      state.myReviewCnt = action.payload;
      state.error = '';
    },
    getMyReviewsCntFailure: (state, action: PayloadAction<Error | string>) => {
      state.myReviewCnt = 0;
      state.error = action.payload;
    },

    getMyPostsRequest: (state, action: PayloadAction<IGetArrayPayload>) => {
      action.payload.skip ? state.myPosts : (state.myPosts = []);
      state.error = '';
    },
    getMyPostsSuccess: (state, action: PayloadAction<IBoard[]>) => {
      state.myPosts = state.myPosts.concat(action.payload);
      state.hasMorePosts = action.payload.length === 10;
      state.error = '';
    },
    getMyPostsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    getMyPostsCntRequest: state => {
      state.postsOffset = 0;
      state.myPostsCnt = 0;
      state.error = '';
    },
    getMyPostsCntSuccess: (state, action: PayloadAction<number>) => {
      state.myPostsCnt = action.payload;
      state.error = '';
    },
    getMyPostsCntFailure: (state, action: PayloadAction<Error | string>) => {
      state.myPostsCnt = 0;
      state.error = action.payload;
    },

    addReviewsOffset: (state, action: PayloadAction<number>) => {
      state.reviewsOffset = action.payload;
    },
    addPostsOffset: (state, action: PayloadAction<number>) => {
      state.postsOffset = action.payload;
    },
    addCommentsOffset: (state, action: PayloadAction<number>) => {
      state.commentsOffset = action.payload;
    },

    setTabMenu: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
    setMainPageTabMenu: (state, action: PayloadAction<number>) => {
      state.mainPageActiveTab = action.payload;
    },

    setActiveSubject: (state, action: PayloadAction<TMyListLNBSubject>) => {
      state.activeSubject = action.payload;
    },

    getMyCommentsRequest: (state, action: PayloadAction<IGetArrayPayload>) => {
      action.payload.skip ? state.myComments : (state.myComments = []);
      state.error = '';
    },
    getMyCommentsSuccess: (state, action: PayloadAction<IMyComment[]>) => {
      state.myComments = state.myComments.concat(action.payload);
      state.hasMoreComments = action.payload.length === 10;
      state.error = '';
    },
    getMyCommentsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    getMyCommentsCntRequest: state => {
      state.commentsOffset = 0;
      state.myCommentsCnt = 0;
      state.error = '';
    },
    getMyCommentsCntSuccess: (state, action: PayloadAction<number>) => {
      state.myCommentsCnt = action.payload;
      state.error = '';
    },
    getMyCommentsCntFailure: (state, action: PayloadAction<Error | string>) => {
      state.myCommentsCnt = 0;
      state.error = action.payload;
    },
  },
});

export const {
  getMyReviewsRequest,
  getMyReviewsSuccess,
  getMyReviewsFailure,
  getMyReviewsCntRequest,
  getMyReviewsCntSuccess,
  getMyReviewsCntFailure,
  getMyPostsRequest,
  getMyPostsSuccess,
  getMyPostsFailure,
  getMyPostsCntRequest,
  getMyPostsCntSuccess,
  getMyPostsCntFailure,
  getMyCommentsRequest,
  getMyCommentsSuccess,
  getMyCommentsFailure,
  getMyCommentsCntRequest,
  getMyCommentsCntSuccess,
  getMyCommentsCntFailure,
  addReviewsOffset,
  addPostsOffset,
  addCommentsOffset,
  setMainPageTabMenu,
  setTabMenu,
  setActiveSubject,
} = myListSlice.actions;

export default myListSlice.reducer;
