import { AxiosResponse } from 'axios';
import { all, select, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  deleteShopbyUserAddressRequest,
  deleteShopbyUserAddressSuccess,
  deleteShopbyUserAddressFailure,
  getShopbyUserAddressListRequest,
  getShopbyUserAddressListSuccess,
  getShopbyUserAddressListFailure,
  setDefaultShopbyUserAddressRequest,
  setDefaultShopbyUserAddressSuccess,
  setDefaultShopbyUserAddressFailure,
  createShopbyUserAddressRequest,
  createShopbyUserAddressSuccess,
  createShopbyUserAddressFailure,
  updateShopbyUserAddressRequest,
  updateShopbyUserAddressSuccess,
  updateShopbyUserAddressFailure,
} from 'store/reducers/shopbyUserAddress';
import { createShopbyFetchAction } from 'store/sagas/createFetchAction';
import { RootState } from 'store/reducers';
import {
  deleteShopbyUserAddress,
  getShopbyUserAddress,
  setDefalutShopbyUserAddress,
  createShopbyUserAddress,
  updateShopbyUserAddress,
} from 'api/shopby';
import { customHistory } from 'App';
import { ITShopbyErrorRes } from '@types';

interface LocationState {
  from: string;
}

function* getShopbyUserAddressListSaga() {
  yield takeEvery(
    getShopbyUserAddressListRequest.type,
    createShopbyFetchAction(
      getShopbyUserAddress,
      getShopbyUserAddressListSuccess,
      getShopbyUserAddressListFailure,
      undefined,
      undefined,
      function onError(errorData: ITShopbyErrorRes) {
        alert(errorData.message);
      },
    ),
  );
}

function* createShopbyUserAddressSaga() {
  yield takeEvery(
    createShopbyUserAddressRequest.type,
    createShopbyFetchAction(
      createShopbyUserAddress,
      createShopbyUserAddressSuccess,
      createShopbyUserAddressFailure,
      undefined,
      function* success() {
        const locationState: LocationState = yield customHistory.location.state;
        const { count } = yield select(
          (state: RootState) => state.shopbyUserAddressReducer,
        );

        yield window.sessionStorage.setItem('successType', 'set');
        yield window.sessionStorage.setItem('addressEdit', 'success');

        if (locationState?.from === '/store/ordersheet') {
          yield customHistory.goBack();
        } else if (count) {
          yield customHistory.goBack();
        } else {
          yield customHistory.replace('/addressList');
        }
      },
      function onError(errorData: ITShopbyErrorRes) {
        alert(errorData.message);
      },
    ),
  );
}

function* updateShopbyUserAddressSaga() {
  yield takeEvery(
    updateShopbyUserAddressRequest.type,
    createShopbyFetchAction(
      updateShopbyUserAddress,
      updateShopbyUserAddressSuccess,
      updateShopbyUserAddressFailure,
      undefined,
      function* success() {
        yield window.sessionStorage.setItem('successType', 'updateAddress');
        yield window.sessionStorage.setItem('addressEdit', 'success');
        yield customHistory.goBack();
      },
      function onError(errorData: ITShopbyErrorRes) {
        alert(errorData.message);
      },
    ),
  );
}

function* setDefaultShopbyUserAddressSaga() {
  yield takeEvery(
    setDefaultShopbyUserAddressRequest.type,
    createShopbyFetchAction(
      setDefalutShopbyUserAddress,
      setDefaultShopbyUserAddressSuccess,
      setDefaultShopbyUserAddressFailure,
      undefined,
      function* success() {
        yield toast.success('기본 배송지 설정 변경 완료');
        yield window.sessionStorage.setItem('addressEdit', 'success');
      },
      function onError(errorData: ITShopbyErrorRes) {
        alert(errorData.message);
      },
    ),
  );
}

function* deleteShopbyUserAddressSaga() {
  yield takeEvery(
    deleteShopbyUserAddressRequest.type,
    createShopbyFetchAction(
      deleteShopbyUserAddress,
      deleteShopbyUserAddressSuccess,
      deleteShopbyUserAddressFailure,
      undefined,
      function* success() {
        yield toast.success('배송 주소록 삭제 완료');
        yield window.sessionStorage.setItem('addressEdit', 'success');
      },
      function onError(errorData: ITShopbyErrorRes) {
        alert(errorData.message);
      },
    ),
  );
}

export default function* shopbyUserAddressSaga() {
  yield all([
    getShopbyUserAddressListSaga(),
    createShopbyUserAddressSaga(),
    updateShopbyUserAddressSaga(),
    setDefaultShopbyUserAddressSaga(),
    deleteShopbyUserAddressSaga(),
  ]);
}
