import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IShopbyMyInquiryState } from 'store/types';
import {
  IShopbyInquiry,
  ITShopbyErrorRes,
  IEditShopbyItemInquiryRequestPayload,
  IGetShopbyMyInquiryRequestPayload,
  IPostShopbyItemInquiryRequestPayload,
} from '@types';

const initialState: IShopbyMyInquiryState = {
  totalCount: 0,
  myInquiry: [] as IShopbyInquiry[],
  pageNumber: 0,
  pageSize: 10,
  hasMore: true,
  shopbyError: undefined,
};

export const shopbyMyInquirySlice = createSlice({
  name: 'shopbyMyInquiry',
  initialState,
  reducers: {
    getShopbyMyInquiryRequest: (
      state,
      action: PayloadAction<IGetShopbyMyInquiryRequestPayload>,
    ) => {
      if (action.payload.pageNumber === 1) {
        state.myInquiry = [];
        state.pageNumber = 1;
        state.totalCount = 0;
      }

      state.hasMore = true;
      state.shopbyError = undefined;
    },
    getShopbyMyInquiryMoreRequest: (
      state,
      action: PayloadAction<IGetShopbyMyInquiryRequestPayload>,
    ) => {
      state.pageNumber = action.payload.pageNumber;
      state.hasMore = true;
      state.shopbyError = undefined;
    },
    getShopbyMyInquirySuccess: (state, action) => {
      const data = action.payload.data;
      if (state.pageNumber === 1) {
        state.totalCount = data.totalCount;
        state.myInquiry = data.items;
      } else {
        state.myInquiry = state.myInquiry.concat(data.items);
      }

      state.hasMore = state.myInquiry.length < data.totalCount;
    },
    getShopbyMyInquiryFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.shopbyError = action.payload;
    },

    postShopbyItemInquiryRequest: (
      state,
      action: PayloadAction<IPostShopbyItemInquiryRequestPayload>,
    ) => {
      state.shopbyError = undefined;
    },
    postShopbyItemInquirySuccess: (state, action: PayloadAction) => {
      state.shopbyError = undefined;
    },
    postShopbyItemInquiryFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.shopbyError = action.payload;
    },

    deleteShopbyMyinquiryRequest: (state, action: PayloadAction<number>) => {
      state.shopbyError = undefined;
    },
    deleteShopbyMyinquirySuccess: (state, action: PayloadAction) => {
      state.shopbyError = undefined;
    },
    deleteShopbyMyinquiryFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.shopbyError = action.payload;
    },

    editShopbyMyinquiryRequest: (
      state,
      action: PayloadAction<{
        inquiryNo: number;
        data: IEditShopbyItemInquiryRequestPayload;
      }>,
    ) => {
      state.shopbyError = undefined;
    },
    editShopbyMyinquirySuccess: (state, action: PayloadAction) => {
      state.shopbyError = undefined;
    },
    editShopbyMyinquiryFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.shopbyError = action.payload;
    },

    updateShopbyMyInquiryList: (state, action: PayloadAction<number>) => {
      const tartgetMyinquiryIndex = state.myInquiry.findIndex(
        inquiry => inquiry.inquiryNo === action.payload,
      );

      if (tartgetMyinquiryIndex === -1) {
        return;
      }

      const copyMyInquiry = [...state.myInquiry];
      copyMyInquiry.splice(tartgetMyinquiryIndex, 1);

      state.myInquiry = [...copyMyInquiry];
      state.totalCount -= 1;
    },
  },
});

export const {
  getShopbyMyInquiryRequest,
  getShopbyMyInquiryMoreRequest,
  getShopbyMyInquirySuccess,
  getShopbyMyInquiryFailure,

  postShopbyItemInquiryRequest,
  postShopbyItemInquirySuccess,
  postShopbyItemInquiryFailure,

  deleteShopbyMyinquiryRequest,
  deleteShopbyMyinquirySuccess,
  deleteShopbyMyinquiryFailure,

  editShopbyMyinquiryRequest,
  editShopbyMyinquirySuccess,
  editShopbyMyinquiryFailure,

  updateShopbyMyInquiryList,
} = shopbyMyInquirySlice.actions;

export default shopbyMyInquirySlice.reducer;
