import { TOptionDefault, TShopbyPolicy } from '@types';
import { SHOPBY } from './globalPhrases';

export const SHOPBY_ORDER_REQUEST_TYPE = {
  // DEPOSIT_WAIT: '입금대기',
  PAY_DONE: '결제 완료',
  PRODUCT_PREPARE: '상품 준비 중',
  DELIVERY_PREPARE: '배송 준비 중',
  DELIVERY_ING: '배송중',
  DELIVERY_DONE: '배송 완료',
  BUY_CONFIRM: '구매확정',
  CANCEL_DONE: '취소 완료',
  RETURN_DONE: '반품 완료',
  EXCHANGE_DONE: '교환 완료',
  CANCEL_PROCESSING: '취소 처리중',
  RETURN_PROCESSING: '반품 처리 중',
  EXCHANGE_WAIT: '교환 대기 중',
  EXCHANGE_PROCESSING: '교환 처리 중',
  REFUND_DONE: '환불완료',
} as const;

export const SHOPBY_PG_TYPE = {
  DUMMY: '없음',
  PAYCO: '페이코',
  PAYPAL: '페이팔',
  STRIPE: 'STRIPE',
  KCP: 'KCP',
  INICIS: '이니시스',
  NONE: 'PG없음',
  KCP_MOBILE: 'KCP(모바일)',
  KCP_APP: 'KCP(앱)',
  NAVER_PAY: '네이버페이(주문형)',
  LIIVMATE: '리브메이트',
  PAYPALPRO: 'PAYPAL PRO',
  ATHOR_NET: 'AthorizeNet',
  KAKAO_PAY: '카카오페이',
  NAVER_EASY_PAY: '네이버페이(간편결제)',
  LG_U_PLUS: '토스페이먼츠',
  TOSS_PAYMENTS: '토스페이먼츠',
  CHAI: '차이',
  SMARTRO_PAY: '스마트로페이',
  NICEPAY: '나이스페이',
  MY_PAY: '마이페이',
  VERITRANS: '베리트랜스',
} as const;

export const SHOPBY_PAY_TYPE = {
  CASH: '현금',
  CREDIT_CARD: '신용카드',
  ACCOUNT: '무통장입금',
  MOBILE: '휴대폰결제',
  REALTIME_ACCOUNT_TRANSFER: '실시간계좌이체',
  VIRTUAL_ACCOUNT: '가상계좌',
  GIFT: '상품권',
  ATM: 'ATM',
  PAYCO: 'PAYCO',
  ZERO_PAY: '0원결제',
  ACCUMULATION: '적립금 전액 사용',
  PHONE_BILL: '전화결제',
  POINT: '포인트결제',
  YPAY: '옐로페이',
  KPAY: '케이페이',
  PAYPIN: '페이핀',
  INIPAY: 'INIPay 간편결제',
  PAYPAL: 'PAYPAL',
  STRIPE: 'STRIPE',
  NAVER_PAY: '네이버페이 주문형',
  KAKAO_PAY: '카카오페이',
  NAVER_EASY_PAY: '네이버페이 결제형',
  SAMSUNG_PAY: '삼성페이',
  CHAI: '차이',
  TOSS_PAY: '토스페이',
  SK_PAY: 'SK페이',
  APPLE_PAY: '애플페이',
  ESCROW_REALTIME_ACCOUNT_TRANSFER: '실시간계좌이체-에스크로',
  ESCROW_VIRTUAL_ACCOUNT: '가상계좌-에스크로',
  RENTAL: '렌탈결제',
  VERITRANS_CARD: 'Veritrans CreditCard',
  TOASTCAM: '토스트캠',
  ETC: '기타결제수단',
} as const;

export const SHOPBY_CLAIM_TYPE = {
  CANCEL: '취소',
  EXCHANGE: '교환',
  RETURN: '반품',
};

export const SHOPBY_NEXT_ACTION_TYPE = {
  // CANCEL_ALL: '주문취소',
  CANCEL: SHOPBY.NEXT_ACTION_CANCEL,
  EXCHANGE: SHOPBY.NEXT_ACTION_EXCHANGE,
  RETURN: SHOPBY.NEXT_ACTION_RETURN,
  WITHDRAW_CANCEL: SHOPBY.NEXT_ACTION_WITHDRAW_CANCEL,
  WITHDRAW_EXCHANGE: SHOPBY.NEXT_ACTION_WITHDRAW_EXCHANGE,
  WITHDRAW_RETURN: SHOPBY.NEXT_ACTION_WITHDRAW_RETURN,
  // NOTE: VIEW_CLAIM의 경우 취소,교환,반품 구분해서 어미 붙여서 써야함.
  VIEW_CLAIM: SHOPBY.NEXT_ACTION_VIEW_CLAIM,
  VIEW_DELIVERY: SHOPBY.NEXT_ACTION_VIEW_DELIVERY,
  CONFIRM_ORDER: SHOPBY.NEXT_ACTION_CONFIRM_ORDER,
  WRITE_REVIEW: SHOPBY.NEXT_ACTION_WRITE_REVIEW,
  // NOTE : 상품 상세 페이지 내 배송지 정보 변경 버튼에만 사용됨.
  CHANGE_ADDRESS: SHOPBY.NEXT_ACTION_CHANGE_ADDRESS,
} as const;

export const SHOPBY_NOT_RENDERED_NEXT_ACTION_TYPE = {
  CHANGE_ADDRESS: '배송지변경',
  DELIVERY_DONE: '수취확인',
  ISSUE_CASH_RECEIPT: '현금영수증 발행',
  VIEW_RECEIPT: '현금영수증 조회',
} as const;

export const SHOPBY_NOT_RENDERED_NEXT_ACTION_TYPE_ARRAY = Object.keys(
  SHOPBY_NOT_RENDERED_NEXT_ACTION_TYPE,
);

export const SHOPBY_SEPERATELY_RENDERED_NEXT_ACTION_TYPE_ARRAY = [
  'CONFIRM_ORDER',
];

export const SHOPBY_REFUND_TYPE = {
  PG: 'PG 환불',
  CANCEL_DEPOSIT: '입금 전 취소',
  ACCOUNT: '무통장입금 환불',
  ACCUMULATION: '적립금 환불',
  ZERO_REFUND: '0원 환불',
  CASH: '현금 환불',
  PAYCO: '페이코 환불',
  PAYPAL: 'PAYPAL 환불',
  STRIPE: 'STRIPE 환불',
  KCP: 'KCP 환불',
  CREDIT_CARD: '신용카드 환불',
  LIIVMATE: '리브메이트 환불',
  INICIS: '이니시스 환불',
  NAVER_EASY_PAY: '네이버페이(간펼결제) 환불',
  KAKAO_PAY: '카카오페이 환불',
  NAVER_PAY: '네이버(주문형) 환불',
  LG_U_PLUS: '엘지유플러스 환불',
  TOSS_PAYMENTS: '토스페이먼츠 환불',
  FORCE_REFUND: '강제 환불',
  DUMMY: '없음',
} as const;

export const SHOPBY_ORDER_STATUS_TYPE = {
  DEPOSIT_WAIT: SHOPBY.ORDER_STATUS_DEPOSIT_WAIT,
  PAY_DONE: SHOPBY.ORDER_STATUS_PAY_DONE,
  PRODUCT_PREPARE: SHOPBY.ORDER_STATUS_PRODUCT_PREPARE,
  DELIVERY_PREPARE: SHOPBY.ORDER_STATUS_DELIVERY_PREPARE,
  DELIVERY_ING: SHOPBY.ORDER_STATUS_DELIVERY_ING,
  DELIVERY_DONE: SHOPBY.ORDER_STATUS_DELIVERY_DONE,
  BUY_CONFIRM: SHOPBY.ORDER_STATUS_BUY_CONFIRM,
  CANCEL_DONE: SHOPBY.ORDER_STATUS_CANCEL_DONE,
  RETURN_DONE: SHOPBY.ORDER_STATUS_RETURN_DONE,
  EXCHANGE_DONE: SHOPBY.ORDER_STATUS_EXCHANGE_DONE,
  PAY_WAIT: SHOPBY.ORDER_STATUS_PAY_WAIT,
  PAY_CANCEL: SHOPBY.ORDER_STATUS_PAY_CANCEL,
  PAY_FAIL: SHOPBY.ORDER_STATUS_PAY_FAIL,
  DELETE: SHOPBY.ORDER_STATUS_DELETE,
  EXCHANGE_WAIT: SHOPBY.ORDER_STATUS_EXCHANGE_WAIT,
  REFUND_DONE: SHOPBY.ORDER_STATUS_REFUND_DONE,
} as const;

export const SHOPBY_CLAIM_STATUS_TYPE = {
  // 주문 목록 조회, SHOPBY_ORDER_MENU_MATCHED_REQUEST 기준 : CANCEL_PROCESSING
  CANCEL_REQUEST: SHOPBY.CLAIM_STATUS_CANCEL_REQUEST, // 승인대기
  CANCEL_NO_REFUND: SHOPBY.CLAIM_STATUS_CANCEL_NO_REFUND, // 환불없음
  CANCEL_PROC_REQUEST_REFUND: SHOPBY.CLAIM_STATUS_CANCEL_PROC_REQUEST_REFUND, // 환불보류
  CANCEL_PROC_WAITING_REFUND: SHOPBY.CLAIM_STATUS_CANCEL_PROC_WAITING_REFUND, // 환불대기

  // 주문 목록 조회, SHOPBY_ORDER_MENU_MATCHED_REQUEST 기준 : CANCEL_DONE
  CANCEL_DONE: SHOPBY.CLAIM_STATUS_CANCEL_DONE, // 환불완료

  // 주문 목록 조회, SHOPBY_ORDER_MENU_MATCHED_REQUEST 기준 : RETURN_PROCESSING
  RETURN_REQUEST: SHOPBY.CLAIM_STATUS_RETURN_REQUEST, // 승인대기
  RETURN_REJECT_REQUEST: SHOPBY.CLAIM_STATUS_RETURN_REJECT_REQUEST, // 철회대기
  RETURN_PROC_BEFORE_RECEIVE: SHOPBY.CLAIM_STATUS_RETURN_PROC_BEFORE_RECEIVE, // 수거진행
  RETURN_PROC_REQUEST_REFUND: SHOPBY.CLAIM_STATUS_RETURN_PROC_REQUEST_REFUND, // 환불보류
  RETURN_PROC_WAITING_REFUND: SHOPBY.CLAIM_STATUS_RETURN_PROC_WAITING_REFUND, // 환불대기
  RETURN_REFUND_AMT_ADJUST_REQUESTED:
    SHOPBY.CLAIM_STATUS_RETURN_REFUND_AMT_ADJUST_REQUESTED, // 조정요청

  // 주문 목록 조회, SHOPBY_ORDER_MENU_MATCHED_REQUEST 기준 : RETURN_DONE
  RETURN_DONE: SHOPBY.CLAIM_STATUS_RETURN_DONE, // 환불완료
  RETURN_NO_REFUND: SHOPBY.CLAIM_STATUS_RETURN_NO_REFUND, // 환불없음

  // 주문 목록 조회, SHOPBY_ORDER_MENU_MATCHED_REQUEST 기준 : EXCHANGE_WAITING
  EXCHANGE_REQUEST: SHOPBY.CLAIM_STATUS_EXCHANGE_REQUEST, // 승인대기

  // 주문 목록 조회, SHOPBY_ORDER_MENU_MATCHED_REQUEST 기준 : EXCHANGE_PROCESSING
  EXCHANGE_REJECT_REQUEST: SHOPBY.CLAIM_STATUS_EXCHANGE_REJECT_REQUEST, // 철회대기
  EXCHANGE_PROC_BEFORE_RECEIVE:
    SHOPBY.CLAIM_STATUS_EXCHANGE_PROC_BEFORE_RECEIVE, // 수거진행
  EXCHANGE_PROC_REQUEST_PAY: SHOPBY.CLAIM_STATUS_EXCHANGE_PROC_REQUEST_PAY, // 결제대기
  EXCHANGE_PROC_REQUEST_REFUND:
    SHOPBY.CLAIM_STATUS_EXCHANGE_PROC_REQUEST_REFUND, // 환불보류
  EXCHANGE_PROC_WAITING: SHOPBY.CLAIM_STATUS_EXCHANGE_PROC_WAITING, // 처리대기
  EXCHANGE_PROC_WAITING_PAY: SHOPBY.CLAIM_STATUS_EXCHANGE_PROC_WAITING_PAY, // 입금처리대기
  EXCHANGE_PROC_WAITING_REFUND:
    SHOPBY.CLAIM_STATUS_EXCHANGE_PROC_WAITING_REFUND, // 환불대기

  // 주문 목록 조회, SHOPBY_ORDER_MENU_MATCHED_REQUEST 기준 : EXCHANGE_DONE
  EXCHANGE_DONE: SHOPBY.CLAIM_STATUS_EXCHANGE_DONE, // 차액없음
  EXCHANGE_DONE_PAY_DONE: SHOPBY.CLAIM_STATUS_EXCHANGE_DONE_PAY_DONE, // 결제완료
  EXCHANGE_DONE_REFUND_DONE: SHOPBY.CLAIM_STATUS_EXCHANGE_DONE_REFUND_DONE, // 환불완료
} as const;

export const SHOPBY_ORDER_MENU_MATCHED_REQUEST = {
  ALL: ['ALL'],
  ORDER_OR_PAY: ['DEPOSIT_WAIT', 'PAY_DONE'],
  PREPARE_ING: ['PRODUCT_PREPARE', 'DELIVERY_PREPARE'],
  DELIVERY_ING: ['DELIVERY_ING'],
  DELIVERY_DONE: ['DELIVERY_DONE'],
  BUY_CONFIRM: ['BUY_CONFIRM'],
  CANCEL_RETURN_EXCHANGE: [
    'CANCEL_DONE',
    'CANCEL_PROCESSING',
    'RETURN_DONE',
    'RETURN_PROCESSING',
    'EXCHANGE_DONE',
    'EXCHANGE_PROCESSING',
  ],
} as const;

export const SHOPBY_CLAIM_CLASS_TYPE = {
  LEGACY: '레거시',
  ORDER_CANCEL: '전체취소',
  OPTION_CANCEL: '부분취소',
  RETURN_EXCHANGE: '출고 후 교환',
  CANCEL_EXCHANGE: '출고 전 교환',
  RETURN: '반품',
} as const;

export const SHOPBY_CARD_COMPANY_TYPE = {
  ANONYMOUS: '미분류카드',
  BC: 'BC카드',
  KB: 'KB국민카드',
  NH: 'NH농협카드',
  KWANGJU: '광주카드',
  LOTTE_AMEX: '롯데아멕스카드',
  LOTTE: '롯데카드',
  KDB: '산업카드',
  SAMSUNG: '삼성카드',
  SUHYUP: '수협카드',
  SHINSEGAE: '신세계카드',
  SHINHAN: '신한카드',
  SHINHYUP: '신협카드',
  CITY: '씨티카드',
  WOORI: '우리카드',
  UNIONPAY: '은련카드',
  JEOCHOOK: '저축카드',
  JEONBUK: '전북카드',
  JEJU: '제주카드',
  HANA_KEB: '하나(외환)카드',
  HANA: '하나카드',
  HANMI: '한미카드',
  JCB: 'JCB카드',
  MASTER: 'MASTER카드',
  VISA: 'VISA카드',
  HYUNDAI: '현대카드',
  AMEX: 'AMEX카드',
  DINERS: '다이너스카드',
  GWANGJU: '광주카드',
  KAKAO: '카카오뱅크카드',
  KBANK: '케이뱅크카드',
  IBK: '기업은행카드',
  HCB: '국민은행(구주택)',
  NHLOCAL: '단위농협',
  NHLIVESTOCK: '축협중앙회',
  JH: '신한은행(조흥은행)',
  SC: '제일은행',
  DAEGU: '대구은행',
  PUSAN: '부산은행',
  KANGWON: '강원은행',
  GYEONGNAM: '경남은행',
  HSB: '홍콩상하이은행',
  EPOST: '우체국',
  HANA_SEOUL: '하나은행(서울은행)',
  PB: '평화은행',
  SHINHAN_JH: '신한은행(조흥 통합)',
  PAYCO: 'PAYCO',
  KFCC: '새마을금고',
  DISCOVER: 'DISCOVER카드',
  HYUNDAI_STOCK: '현대증권카드',
  NAVER_POINT: '네이버포인트',
  TOSS_MONEY: '토스머니',
  SSG_MONEY: 'SSG머니',
  L_POINT: '엘포인트',
  KAKAO_MONEY: '카카오머니',
} as const;

export const SHOPBY_BANK = {
  ANONYMOUS: '미확인은행',
  KDB: '산업은행',
  IBK: '기업은행',
  KB: '국민은행',
  KEB: '외환은행',
  SUHYUP: '수협',
  KEXIM: '수출입은행',
  NH: 'NH농협은행',
  NHLOCAL: '지역농축협',
  WOORI: '우리은행',
  SC: 'SC제일은행',
  CITY: '한국씨티은행',
  DAEGU: '대구은행',
  PUSAN: '부산은행',
  GWANGJU: '광주은행',
  JEJU: '제주은행',
  JEONBUK: '전북은행',
  GYEONGNAM: '경남은행',
  KFCC: '새마을금고',
  CU: '신협',
  SANGHO: '상호저축은행',
  HSBC: 'HSBC은행',
  DEUTSCHE: '도이치은행',
  ABN: 'ABN',
  JP_MORGAN: 'JP모건',
  MIZUHO: '미즈호',
  UFJ: '엠유에프제이',
  BOA: '뱅크오브아메리카',
  NFCF: '산림조합중앙회',
  EPOST: '우체국',
  KEBHANA: 'KEB하나은행',
  SHINHAN: '신한은행',
  KBANK: '케이뱅크',
  KAKAO: '카카오뱅크',
  TOSS: '토스뱅크',
  YUANTA: '유안타증권',
  KBSEC: 'KB증권',
  MIRAE: '미래에셋증권',
  MIRAEDAEWOO: '미래에셋대우증권',
  SAMSUNG: '삼성증권',
  HANKOOK: '한국투자증권',
  NH_INVEST: 'NH투자증권',
  KYOBO: '교보증권',
  HI_INVEST: '하이투자증권',
  HMC_INVEST: 'HMC투자증권',
  KIWOOM: '키움증권',
  EBEST: '이베스트투자증권',
  SK: 'SK증권',
  DAISHIN: '대신증권',
  SOLOMON_INVEST: '솔로몬증권',
  HANHWA: '한화투자증권',
  HANA_INVEST: '하나금융투자',
  SHINHAN_INVEST: '신한금융투자',
  DONGBU: 'DB금융투자',
  EUGENE_INVEST: '유진투자증권',
  MERITZ_COMPREHENSIVE: '메리츠종합금융증권',
  BOOKOOK: '부국증권',
  SHINYOUNG: '신영증권',
  CAPE: '케이프투자증권',
  SMBC: '미쓰이스미토모은행',
  RESONA: '리소나은행',
  NAVER_POINT: '네이버포인트',
  TOSS_MONEY: '토스머니',
  SSG_MONEY: '신세계포인트',
  L_POINT: '엘포인트',
  KAKAO_MONEY: '카카오머니',
  PAYCO: '페이코포인트',
  BW: '뱅크월렛',
} as const;

export const SHOPBY_AGREEMENT = {
  TERMS_OF_USE: '이용약관',
  PRIVACY_USAGE_AGREEMENT: '개인정보 이용동의',
  NONE_MEMBER_PRIVACY_USAGE_AGREEMENT: '비회원 개인정보 수집·이용 동의',
  SELLER_PRIVACY_USAGE_AGREEMENT: '개인정보 판매자 제공 동의',
  CUSTOMS_CLEARANCE_AGREEMENT: '통관정보 수집·이용 동의',
  OVERSEA_PRIVACY_USAGE_AGREEMENT: '개인정보 국외 이전 동의',
  ORDER_INFO_AGREE: '주문 상품 정보 동의',
  PI_LIQUOR_PURCHASE_PROVISION: '주류구매 개인정보 제공 동의',
  REGULAR_PAYMENT_USE: '정기결제(배송) 이용약관',
  AUTO_APPROVAL_USE: '자동 승인 이용약관',
} as const;

export const SHOPBY_CLAIM_REASON_TYPE = {
  CHANGE_MIND: SHOPBY.CLAIM_REASON_DEFECTIVE_PRODUCT,
  DEFECTIVE_PRODUCT: SHOPBY.CLAIM_REASON_DEFECTIVE_PRODUCT,
  WRONG_DELIVERY: SHOPBY.CLAIM_REASON_WRONG_DELIVERY,
  OUT_OF_STOCK_SYSTEM: SHOPBY.CLAIM_REASON_OUT_OF_STOCK_SYSTEM,
  // CANCEL_BEFORE_PAY: '입금전취소',
  WRONG_PRODUCT_DETAIL: SHOPBY.CLAIM_REASON_WRONG_PRODUCT_DETAIL,
  // DELAY_DELIVERY: '판매자 배송 지연',
  OUT_OF_STOCK: SHOPBY.CLAIM_REASON_OUT_OF_STOCK,
  OTHERS_SELLER: SHOPBY.CLAIM_REASON_OTHERS_SELLER,
  OTHERS_BUYER: SHOPBY.CLAIM_REASON_OTHERS_BUYER,
  LATER_INPUT_ORDER: SHOPBY.CLAIM_REASON_LATER_INPUT_ORDER,
} as const;

export const SHOPBY_DELIVERY_MEMO_LIST = [
  { label: '문 앞에 놓아주세요', value: '문 앞에 놓아주세요' },
  { label: '택배함에 놓아주세요', value: '택배함에 놓아주세요' },
  { label: '경비실에 맡겨주세요', value: '경비실에 맡겨주세요' },
  { label: '직접입력', value: '직접입력' },
] as TOptionDefault[];

export const SHOPBY_REASON_OPTION_LIST: {
  [key in keyof typeof SHOPBY_CLAIM_TYPE]: TOptionDefault[];
} = {
  CANCEL: [
    { label: '단순변심', value: 'CHANGE_MIND' },
    { label: '판매자 배송 지연', value: 'DELAY_DELIVERY' },
    { label: '기타', value: 'OTHERS_BUYER' },
  ],
  RETURN: [
    // CASE1: 판매자 귀책 사유 선택지 - 사진 업로드 필수
    { label: '상품불량/파손', value: 'DEFECTIVE_PRODUCT' },
    { label: '누락/오배송', value: 'WRONG_DELIVERY' },
    { label: '상품상세 정보와 다름', value: 'WRONG_PRODUCT_DETAIL' },
    // CASE2: 구매자 귀책 사유 선택지
    { label: '단순변심', value: 'CHANGE_MIND' },
  ],
  EXCHANGE: [
    // CASE1: 판매자 귀책 사유 선택지 - 사진 업로드 필수
    { label: '상품불량/파손', value: 'DEFECTIVE_PRODUCT' },
    { label: '누락/오배송', value: 'WRONG_DELIVERY' },
    { label: '상품상세 정보와 다름', value: 'WRONG_PRODUCT_DETAIL' },
    // CASE2: 구매자 귀책 사유 선택지
    { label: '단순변심', value: 'CHANGE_MIND' },
  ],
};

export const SHOPBY_CLAIM_REASON_FROM_SELLER: (keyof typeof SHOPBY_CLAIM_REASON_TYPE)[] =
  [
    // 'DELAY_DELIVERY', // 판매자 귀책 사유 중, 캠핏 유저가 플로우상 사용하지 않는 클레임 타입
    'DEFECTIVE_PRODUCT',
    'WRONG_DELIVERY',
    'WRONG_PRODUCT_DETAIL',
    'OUT_OF_STOCK_SYSTEM',
    'OUT_OF_STOCK',
    'OTHERS_SELLER',
  ];

export const SHOPBY_CLAIM_REASON_FROM_BUYER: (keyof typeof SHOPBY_CLAIM_REASON_TYPE)[] =
  ['CHANGE_MIND', 'OTHERS_BUYER', 'LATER_INPUT_ORDER'];

export const SHOPBY_ACCEPTABLE_IMAGE_EXTENSIONS =
  'image/bmp, image/tif, image/tiff, image/miff, image/gif, image/jpe, image/jpeg, image/jps, image/pjpeg, image/jng, image/mng, image/png' as const;

export const SHOPBY_LAUNCHIG_DATE = '2023-05-01';

export const SHOPBY_POINT_TABS = [
  { label: '전체', value: 'ALL' },
  { label: '적립', value: 'ADD' },
  { label: '사용', value: 'SUB' },
] as TOptionDefault[];

export const SHOPBY_ACCUMULATION_CONFIG = {
  IMMEDIATE: '즉시적립',
  NEXTDAY: '익일',
  DAY_AFTER_TOMMOROW: '2일',
  AFTER_A_WEEK: '7일',
  AFTER_TWO_WEEK: '14일',
  AFTER_TWENTY_DAYS: '20일',
  AFTER_THIRTY_DAYS: '30일',
  NEXT_MONTH: '익월',
};

export const SHOPBY_PAGE_PATHNAME_KEYWORD = [
  'store',
  'product',
  'products',
  'mypage',
];

// 클레임 가능 여부 판단 API를 거쳐 반환하는 타입에 따라 철회 가능 여부 반환 (false 시, 프론트에서 서버에러 모달창 표기)
export const SHOPBY_WITHDRAWABLE_STATE_FROM_VALIDATION_TYPE = {
  EXISTS_AFTER_CLAIM_WITH_CART_COUPON: false,
  EXISTS_SAME_SHIPPING_NO_CLAIM: false,
  WITHDRAWABLE: true,
};

// 클레임 가능 여부 판단 API를 거쳐 반환하는 타입에 따라 프론트에서 서버에러 모달창으로 표기하는 에러 정보
export const SHOPBY_ERROR_MESSAGE_FROM_VALIDATION_TYPE = {
  EXISTS_AFTER_CLAIM_WITH_CART_COUPON:
    SHOPBY.ERROR_MESSAGE_FROM_VALIDATION_EXISTS_AFTER_CLAIM_WITH_CART_COUPON,
  EXISTS_SAME_SHIPPING_NO_CLAIM:
    SHOPBY.ERROR_MESSAGE_FROM_VALIDATION_EXISTS_SAME_SHIPPING_NO_CLAIM,
  WITHDRAWABLE: undefined,
};

// 샵바이 워딩 > 캠핏 워딩 : 지급 > 적립, 차감 > 사용
export const SHOPBY_ACCUMULATION_REASON_TYPE = {
  ADD: SHOPBY.ACCUMULATION_REASON_ADD,
  SUB: SHOPBY.ACCUMULATION_REASON_SUB,
};

// 적립 지급/차감 구분
export const SHOPBY_ACCUMULATION_STATUS_GROUP_TYPE = {
  PAYMENT: SHOPBY.ACCUMULATION_STATUS_GROUP_PAYMENT,
  DEDUCTION: SHOPBY.ACCUMULATION_STATUS_GROUP_DEDUCTION,
};

// 적립상태
export const SHOPBY_ACCUMULATION_STATUS_TYPE = {
  GIVE: '적립',
  GIVE_BY_CANCELED: '사용 취소로 인한 재적립',
  SUBTRACTION: '반환',
  SUBTRACTION_BY_CANCELED: '적립 취소로 인한 반환',
};

// 적립사유 코드 및 그에 따른 표시명
export const SHOPBY_ACCUMULATION_RESERVE_REASON_TYPE = {
  ADD_AFTER_PAYMENT: SHOPBY.ACCUMULATION_RESERVE_REASON_ADD_AFTER_PAYMENT,
  ADD_AFTER_EVENT_PAYMENT:
    SHOPBY.ACCUMULATION_RESERVE_REASON_ADD_AFTER_EVENT_PAYMENT,
  ADD_AFTER_REPLACE_PAYMENT:
    SHOPBY.ACCUMULATION_RESERVE_REASON_ADD_AFTER_REPLACE_PAYMENT,
  ADD_POSTING: SHOPBY.ACCUMULATION_RESERVE_REASON_ADD_POSTING,
  ADD_CANCEL: SHOPBY.ACCUMULATION_RESERVE_REASON_ADD_CANCEL,
  ADD_RETURN: SHOPBY.ACCUMULATION_RESERVE_REASON_ADD_RETURN,
  ADD_MANUAL: SHOPBY.ACCUMULATION_RESERVE_REASON_ADD_MANUAL,
  ADD_EVENT: SHOPBY.ACCUMULATION_RESERVE_REASON_ADD_EVENT,
  ADD_SIGNUP: SHOPBY.ACCUMULATION_RESERVE_REASON_ADD_SIGNUP,
  ADD_BIRTHDAY: SHOPBY.ACCUMULATION_RESERVE_REASON_ADD_BIRTHDAY,
  ADD_APP_INSTALL: SHOPBY.ACCUMULATION_RESERVE_REASON_ADD_APP_INSTALL,
  ADD_GRADE: SHOPBY.ACCUMULATION_RESERVE_REASON_ADD_GRADE,
  ADD_GRADE_BENEFIT: SHOPBY.ACCUMULATION_RESERVE_REASON_ADD_GRADE_BENEFIT,
  SUB_PAYMENT_USED: SHOPBY.ACCUMULATION_RESERVE_REASON_SUB_PAYMENT_USED,
  SUB_EXTRA_PAYMENT_USED:
    SHOPBY.ACCUMULATION_RESERVE_REASON_SUB_EXTRA_PAYMENT_USED,
  SUB_CANCEL: SHOPBY.ACCUMULATION_RESERVE_REASON_SUB_CANCEL,
  SUB_RETURN: SHOPBY.ACCUMULATION_RESERVE_REASON_SUB_RETURN,
  SUB_DELETE_POSTING: SHOPBY.ACCUMULATION_RESERVE_REASON_SUB_DELETE_POSTING,
  SUB_EXPIRED: SHOPBY.ACCUMULATION_RESERVE_REASON_SUB_EXPIRED,
  SUB_MANUAL: SHOPBY.ACCUMULATION_RESERVE_REASON_SUB_MANUAL,
  SUB_DELETE_ACCOUNT: SHOPBY.ACCUMULATION_RESERVE_REASON_SUB_DELETE_ACCOUNT,
  EXTERNAL_ACCUMULATION:
    SHOPBY.ACCUMULATION_RESERVE_REASON_EXTERNAL_ACCUMULATION,
};

export const SHOPBY_ITEM_INQUIRY_TYPE_LIST = [
  { label: '상품', value: 'PRODUCT' },
  { label: '배송', value: 'DELIVERY' },
  { label: '취소', value: 'CANCEL' },
  { label: '반품', value: 'RETURN' },
  { label: '교환', value: 'EXCHANGE' },
  { label: '환불', value: 'REFUND' },
  { label: '기타', value: 'OTHER' },
] as TOptionDefault[];

export const SHOPBY_REVIEW_REPORT_REASON_TYPE = {
  COPYRIGHT: SHOPBY.REVIEW_REPORT_REASON_COPYRIGHT,
  SLANDER: SHOPBY.REVIEW_REPORT_REASON_SLANDER,
};

export const SHOPBY_MODAL_CONTENTS = {
  refundAdjustInfo: {
    topInfo: {
      title: '판매자 조정 금액 발생 사유',
      contents: null,
    },
    title: '판매자 조정 금액이란?',
    contents:
      '- 판매자가 고객에게 추가금을 요구하거나 추가금을 지불해야 하는 경우 발생됩니다.\n- 판매자 조정 금액은 판매자에게 직접 문의해주세요. ',
  },
  deliveryPriceInfo: {
    title1: '최초 배송비',
    contents1:
      '- 구매자가 상품을 구매할 때 최초로 결제한 배송비\n- 반품, 부분 취소로 인해 무료 혜택 조건이 해지되는 경우 발생하는 배송비',

    title2: '반품 배송비',
    contents2: '- 상품을 판매자에게 다시 보낼 때 발생되는 배송비',
  },
};

export const SHOPBY_ITEM_STATUS_TYPE_LIST = [
  { label: '판매대기', value: 'READY' },
  { label: '판매중', value: 'ONSALE' },
  { label: '판매종료', value: 'FINISHED' },
  { label: '판매중지', value: 'STOP' },
  { label: '판매금지', value: 'PROHIBITION' },
] as TOptionDefault[];

export const SHOPBY_REVIEW_POLICY: TShopbyPolicy[] = [
  {
    title: '리뷰 작성 전 유의사항',
    paragraphList: [
      {
        firstDepth:
          '상품과 관련 없거나 부적절한 리뷰는 앱 내에 등록되지 않거나 고객에게 통보 없이 삭제 될 수 있습니다.',
        hasDot: true,
      },
      {
        firstDepth:
          '회원 탈퇴 이후 고객이 작성하였던 게시물 및 댓글 등은 삭제되지 않으며, 회원 탈퇴로 인하여 고객정보가 삭제되어 작성자 본인을 확인할 수 없어 게시물 편집 및 삭제가 원천적으로 불가합니다. 고객이 작성한 게시물의 삭제를 원할 경우에는 회원 탈퇴 이전에 게시물을 모두 삭제해야 합니다.',
        hasDot: true,
      },
    ],
  },
  {
    title: '리뷰 작성 시 유의사항',
    paragraphList: [
      {
        firstDepth:
          '고객이 작성한 리뷰에 대한 모든 권리 및 책임은 이를 게시한 고객에게 있으며, 캠핏(이하 ‘회사’)은 고객이 게시하거나 등록하는 리뷰가 다음 각 호에 해당한다고 판단되는 경우에 사전 통지 없이 앱 내에 등록되지 않거나 임시조치 및 삭제할 수 있으며, 이에 대하여 회사는 어떠한 책임도 지지 않습니다',
        hasDot: true,
        secondDepth: [
          '1. 공서양속에 위반 되는 내용의 리뷰를 게시한 경우',
          '2. 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용일 경우',
          '3. 범죄적 행위에 결부된다고 인정되는 경우',
          '4. 정당한 사유 없이 당사의 영업을 방해하는 내용을 기재하는 경우',
          '5. 특정 내용의 리뷰 작성 조건으로 대가를 제공받고 이를 표시하지 않거나, 기타 특정업체의 영리적 목적을 위하여 리뷰를 게시한 경우',
          '6. 동일 상품에 대해 반복적인 리뷰를 게시한 경우',
          '7. 허위/과장된 내용 또는 직접 작성하지 않았거나 구매한 상품과 관련 없는 내용을 게시한 경우',
          '8. 정당한 권한 없이 타인의 권리(개인정보, 지식재산권, 소유권, 명예, 신용 등)을 침해하는 내용을 게시 및 노출한 경우',
          '9. 욕설, 폭언, 비방, 등 타인의 명예를 훼손하거나 불쾌 또는 위협이 되는 내용을 게시한 경우',
          '10. 음란물 또는 청소년 유해 매체물, 범죄행위나 불법적인 행동을 전시 또는 조장하는 내용을 게시한 경우',
          '11. 정보통신기기의 오작동을 일으킬 수 있는 악성코드나 데이터를 포함하는 리뷰를 게시한 경우',
          '12. 사기성 상품, 서비스, 사업 계획 등을 판촉하는 리뷰를 게시한 경우',
          '13. 기타 관련 법령 및 이용약관, 운영정책에 위배되는 리뷰를 게시한 경우',
        ],
      },
      {
        firstDepth:
          '‘회사’는 권리자의 요청이 없는 경우라도 회사 또는 제3자의 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 고객에게 통보 없이 해당 리뷰에 대해 임시조치(블라인드) 및 삭제 등의 조치를 취할 수 있습니다.',
      },
    ],
  },
  {
    title: '개인정보 수집 및 활용 안내',
    paragraphList: [
      {
        firstDepth:
          '작성해주신 리뷰는 서비스 품질 개선, 개발, 후기 등의 목적으로 회원 탈퇴 또는 리뷰 삭제 시까지 수집, 이용됩니다.',
        hasDot: true,
      },
      {
        firstDepth:
          '작성된 리뷰의 저작권은 작성한 고객에게 귀속되며, 저작권법에 의해 보호를 받습니다. 회사가 작성한 게시물에 대한 저작권 및 기타 지적재산권은 회사에 귀속합니다.',
        hasDot: true,
      },
      {
        firstDepth:
          '회사가 서비스내 게재 이외의 목적으로 고객의 리뷰를 사용할 경우 해당 리뷰의 게시자를 반드시 명시합니다. 단, 게시자를 알 수 없는 익명 리뷰나 비영리적인 경우에는 그러하지 아니합니다.',
        hasDot: true,
        secondDepth: [
          '1. 서비스(제 3자가 운영하는 사이트 또는 미디어의 일정 영역 내에 입점하여 서비스가 제공되는 경우 포함)내에서 리뷰를 사용하기 위하여 크기를 변환하거나 단순화하는 등의 방식으로 수정하는 것',
          '2. 회사 또는 관계사가 운영하는 본 서비스 및 연동 서비스에 리뷰를 복제, 전송, 전시하는 것. 다만, 고객이 게시물의 복제, 전송, 전시에 반대 의견을 E-mail을 통해 관리자에게 통지할 경우에는 그러하지 않습니다.',
          '3. 회사의 서비스를 홍보하기 위한 목적으로 미디어, 소셜미디어를 포함한 디지털 마케팅 채널, 통신사 등에게 게시물의 내용을 보도, 방영하게 하는 것',
        ],
      },
      {
        firstDepth:
          '회사는 고객이 서비스 내에 게시한 리뷰가 타인의 저작권 등을 침해하더라도 이에 대한 민, 형사상의 책임을 부담하지 않습니다. 만일 고객이 타인의 저작권 등을 침해하였음을 이유로 회사가 타인으로부터 손해배상청구 등 이의 제기를 받은 경우 고객은 회사의 면책을 위하여 노력하여야 하며, 회사가 면책되지 못한 경우 고객은 그로 인해 회사에 발생한 모든 손해를 부담하여야 합니다',
        hasDot: true,
      },
      {
        firstDepth:
          '위 모든 정책은 정보통신망 이용촉진 및 정보보호에 관한 법률(“정보통신망법”) 제44조의2, 제44조의3을 근거로 회사에서 규제 등의 조치를 할 수 있습니다.',
      },
    ],
  },
];

export const SHOPBY_CLAIM_POLICY: TShopbyPolicy[] = [
  {
    title: '구매안내',
    paragraphList: [
      {
        firstDepth:
          '주문 완료 후 재고 부족 등의 사유로 주문 취소가 될 수 있는 점 양해 부탁드립니다.',
        hasDot: true,
      },
      {
        firstDepth:
          '제주 / 도서산간 지역의 경우 추가 배송비가 발생될 수 있습니다.',
        hasDot: true,
      },
      {
        firstDepth:
          '상품 불량에 의한 반품, 교환, A/S, 환불, 품질보증 및 피해 보상 등에 관한 사항은 소비자 분쟁 해결기준 (공정거래위원회 고시)에 따라 받으실 수 있습니다.',
        hasDot: true,
      },
      {
        firstDepth: '변심 취소, 반품으로 인한 배송비가 부과될 수 있습니다.',
        hasDot: true,
      },
      {
        firstDepth:
          '교환/반품 가능 여부는 제조사에 따라 다르므로 관련 문의는 캠핏 고객센터로 문의 바랍니다.',
        hasDot: true,
      },
      {
        firstDepth:
          '무통장 입금 시 주문자명과 입금자명이 다를 경우 입금 완료 처리가 되지 않습니다. ( 주문 금약 초과 및 미달 시 입금 처리가 되지 않으며, 자동 취소 처리될 수 있습니다 ) 상품별 반품지, 출고지 상이하여 자동수거지시 이용 불가합니다. 교환/반품 시 판매자 고객센터로 연락 바랍니다.',
        hasDot: true,
      },
    ],
  },
  {
    title: '상품 결제 정보',
    paragraphList: [
      {
        firstDepth:
          '고액 결제의 경우 안전을 위해 카드사에서 확인 전화를 드릴 수도 있습니다.확인 과정에서 도난 카드의 사용이나 타인 명의의 주문 등 정상적인 주문이 아니라고 판단될 경우 임의로 주문을 보류 또는 취소할 수 있습니다. 무통장 입금은 상품 구매 대금은 PC 뱅킹, 인터넷뱅킹, 텔레뱅킹 혹은 가까운 은행에서 직접 입금하시면 됩니다.',
        hasDot: true,
      },
      {
        firstDepth:
          '주문 시 입력한 입금자명과 실제 입금자의 성명이 반드시 일치하여야 하며, 7일 이내로 입금을 하셔야 하며 입금되지 않은 주문은 자동 취소됩니다.',
        hasDot: true,
      },
    ],
  },
  {
    title: '교환 및 반품이 가능한 경우',
    paragraphList: [
      {
        firstDepth:
          '교환/반품 신청 후 판매처에서 내용 확인 후 접수를 진행합니다.',
        hasDot: true,
      },
      {
        firstDepth: '제품을 반품/교환은 각 제품 판매처로 문의해 주셔야 합니다.',
        hasDot: true,
      },
      {
        firstDepth:
          '사전 교환/반품 접수 없이 임의로 제품을 발송하는 경우 교환/반품이 거부될 수 있습니다.',
        hasDot: true,
      },
      {
        firstDepth:
          '교환/반품은 귀책 사유에 따라 추가 비용이 발생할 수 있습니다.',
        hasDot: true,
      },
      {
        firstDepth: '교환/반품 시 상품의 수량 변경은 불가합니다.',
        hasDot: true,
      },
      {
        firstDepth:
          '교환/반품에 따른 배송비 및 제주/도서 산간 비용이 발생할 수 있습니다.',
        hasDot: true,
      },
      {
        firstDepth:
          '단순 변심으로 인한 교환은 불가합니다. 단순 교환은 반품 접수 후 새 주문으로 진행 부탁드립니다.',
        hasDot: true,
      },
      {
        firstDepth:
          '불량 사유의 교환 처리 시, 상품의 옵션을 변경하는 경우 금액 변동이 발생할 수 있습니다.',
        hasDot: true,
      },
      {
        firstDepth:
          '교환은 판매처와 제품에 따라 회수 후 재출고, 회수 없이 재출고 또는 맞교환 형식으로 진행됩니다. (진행 방법은 판매처에 확인해 주세요.)',
        hasDot: true,
      },
      {
        firstDepth:
          '구매 확정 적립금은 주문서의 결제 금액 기준으로 적립됩니다. (별도 차액 발생 시 판매처에 직접 입금한 경우 적립 대상에서 제외됩니다.)',
        hasDot: true,
      },
      {
        firstDepth:
          '고객센터 운영 시간 내 요청 건 당일 판매처에 접수되며, 판매처는 접수 내용 확인 후 회수 접수를 진행합니다.(판매처 사정에 따라 익일 회수 접수가 될 수 있습니다.)',
        hasDot: true,
      },
      {
        firstDepth:
          '환불은 상품의 반품 완료(판매처 입고 확인) 시 진행되며, 결제 수단에 따라 영업일 기준 1~5일 정도 소요될 수 있습니다. (단, 옵션 변경 등으로 차액이 발생한 경우 판매처에서 입금을 진행합니다.)',
        hasDot: true,
      },
      {
        firstDepth:
          '이외 교환/반품에 대한 문의는 캠핏 고객센터로 문의 바랍니다.',
        hasDot: true,
      },
    ],
  },
  {
    title: '교환 및 반품이 불가능한 경우',
    paragraphList: [
      {
        firstDepth:
          '고객님의 책임 있는 사유로 상품 등이 멸실 또는 훼손된 경우. 단, 상품의 내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외.',
        hasDot: true,
      },
      {
        firstDepth:
          '포장을 개봉하였거나 포장이 훼손되어 상품가치가 상실된 경우. (예: 가전제품, 식품, 음반 등, 단 액정화면이 부착된 노트북, LCD 모니터, 디지털카메라 등의 불량화소에 따른 반품/교환은 제조사 기준에 따릅니다.)',
        hasDot: true,
      },
      {
        firstDepth:
          '고객님의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히 감소한 경우 단, 화장품 등의 경우 시용 제품을 제공한 경우에 한 합니다.',
        hasDot: true,
      },
      {
        firstDepth:
          '시간의 경과에 의하여 재판매가 곤란할 정도로 상품 등의 가치가 현저히 감소한 경우.',
        hasDot: true,
      },
      {
        firstDepth:
          '복제가 가능한 상품 등의 포장을 훼손한 경우. (자세한 내용은 고객만족센터 1:1 E-MAIL 상담을 이용해 주시기 바랍니다.)',
        hasDot: true,
      },
      {
        firstDepth:
          '※ 고객님의 마음이 바뀌어 교환, 반품을 하실 경우 상품 반송 비용은 고객님께서 부담하셔야 합니다. (색상 교환, 사이즈 교환 등 포함)고객센터 안내 업무 시간에만 상담 가능하며 주말, 공휴일은 휴무입니다.',
        hasDot: false,
      },
    ],
  },
];
