import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IErrorPayloadWithKey } from 'store/types';

type TFeatureFlag = {
  name: string;
  enabled: boolean;
  options?: any;
};

interface IFeatureFlagsInitialState {
  flags: { [key: string]: TFeatureFlag };
  error: Error | string;
}

const initialState: IFeatureFlagsInitialState = {
  flags: {} as { [key: string]: TFeatureFlag },
  error: '',
};

export const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    getFeatureFlagsRequest: (state, action: PayloadAction<{ key: string }>) => {
      const { key } = action.payload;

      state.flags[key] = {
        name: key,
        enabled: false,
      };
      state.error = '';
    },
    getFeatureFlagsSuccess: (
      state,
      action: PayloadAction<{
        key: string;
        data: { data: TFeatureFlag };
      }>,
    ) => {
      const { key, data } = action.payload;
      const { data: featureFlagsInfo } = data;

      state.flags[key] = featureFlagsInfo;
      state.error = '';
    },
    getFeatureFlagsFailure: (
      state,
      action: PayloadAction<IErrorPayloadWithKey>,
    ) => {
      const { key, error } = action.payload;

      state.flags[key] = {
        name: key,
        enabled: false,
      };
      state.error = error;
    },
  },
});

export const {
  getFeatureFlagsRequest,
  getFeatureFlagsSuccess,
  getFeatureFlagsFailure,
} = featureFlagsSlice.actions;

export default featureFlagsSlice.reducer;
