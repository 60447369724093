import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICheckProductResponse, IMyItem } from '@types';
import {
  MyItemsState,
  IGetMyItemsRqPayload,
  IReviseMyItemRqPayload,
} from 'store/types';

const initialState: MyItemsState = {
  myItems: [],
  myItem: {} as IMyItem,
  hasMore: true,
  offset: 0,
  isMyItem: false,
  equipmentId: '',
  error: '',
};

export const myItemsSlice = createSlice({
  name: 'myItems',
  initialState,
  reducers: {
    uploadMyItemRequest: (state, action: PayloadAction<FormData>) => {
      state.error = '';
    },
    uploadMyItemSuccess: (state, action) => {
      state.error = '';
    },
    uploadMyItemFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    uploadMyReviewRequest: (state, action: PayloadAction<FormData>) => {
      state.error = '';
    },
    getMyItemsRequest: (state, action: PayloadAction<IGetMyItemsRqPayload>) => {
      action.payload.skip ? state.myItems : (state.myItems = []);
      state.error = '';
    },
    getMyItemsSuccess: (state, action: PayloadAction<IMyItem[]>) => {
      state.myItems = state.myItems.concat(action.payload);
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },
    getMyItemsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    addOffset: (state, action: PayloadAction<IGetMyItemsRqPayload>) => {
      state.offset = action.payload.skip;
    },
    getMyItemRequest: (state, action: PayloadAction<string>) => {
      state.myItem = {} as IMyItem;
      state.error = '';
    },
    getMyItemSuccess: (state, action: PayloadAction<IMyItem>) => {
      state.myItem = action.payload;
      state.error = '';
    },
    getMyItemFailure: (state, action: PayloadAction<Error | string>) => {
      state.myItem = {} as IMyItem;
      state.error = action.payload;
    },
    reviseMyItemRequest: (
      state,
      action: PayloadAction<IReviseMyItemRqPayload>,
    ) => {
      state.error = '';
    },
    reviseMyItemSuccess: state => {
      state.error = '';
    },
    reviseMyItemFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    reviseMyItemAsEquipmentRequest: (
      state,
      action: PayloadAction<IReviseMyItemRqPayload>,
    ) => {
      state.error = '';
    },
    deleteMyItemRequest: (state, action: PayloadAction<string>) => {
      state.error = '';
    },
    deleteMyItemSuccess: state => {
      state.error = '';
    },
    deleteMyItemFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    checkProductRequest: (state, action: PayloadAction<string>) => {
      state.isMyItem = false;
      state.equipmentId = '';
      state.error = '';
    },
    checkProductSuccess: (
      state,
      action: PayloadAction<ICheckProductResponse>,
    ) => {
      state.isMyItem = action.payload.isMyEquipment;
      state.equipmentId = action.payload.equipmentId;
      state.error = '';
    },
    checkProductFailure: (state, action: PayloadAction<Error | string>) => {
      state.isMyItem = false;
      state.equipmentId = '';
      state.error = action.payload;
    },
  },
});

export const {
  getMyItemsRequest,
  getMyItemsSuccess,
  getMyItemsFailure,
  uploadMyItemRequest,
  uploadMyItemSuccess,
  uploadMyItemFailure,
  uploadMyReviewRequest,
  getMyItemRequest,
  getMyItemSuccess,
  getMyItemFailure,
  reviseMyItemRequest,
  reviseMyItemSuccess,
  reviseMyItemFailure,
  reviseMyItemAsEquipmentRequest,
  deleteMyItemRequest,
  deleteMyItemSuccess,
  deleteMyItemFailure,
  checkProductRequest,
  checkProductSuccess,
  checkProductFailure,
  addOffset,
} = myItemsSlice.actions;

export default myItemsSlice.reducer;
