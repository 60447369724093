import { Ref, RefCallback, RefObject } from 'react';
import styled, { css } from 'styled-components';

interface ClickableStyle {
  onClick?: () => void;
  margin?: string;
  padding?: string;
  justifyContent?: string;
  alignItems?: string;
  ref?:
    | Ref<HTMLElement>
    | RefObject<HTMLElement>
    | RefCallback<HTMLElement>
    | undefined;
}

export const RowCss = css`
  display: flex;
  flex-direction: row;
`;

export const ColumnCss = css`
  display: flex;
  flex-direction: column;
`;

export const RowBox = styled.span<ClickableStyle>`
  ${RowCss}
  align-items: ${props => props.alignItems || 'center'};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
`;

export const RowContainer = styled.div<ClickableStyle>`
  ${RowCss}
  width: 100%;
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems || 'center'};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
`;

export const RowCenterContainer = styled.div<ClickableStyle>`
  ${RowCss}
  width: 100%;
  justify-content: center;
  align-items: ${props => props.alignItems || 'center'};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
`;

export const ColumnContainer = styled.div<ClickableStyle>`
  ${ColumnCss}
  width: 100%;
  margin: ${props => props.margin};
  padding: ${props => props.padding};
`;

export const ColumnCenterContainer = styled.div<ClickableStyle>`
  ${ColumnCss}
  width: 100%;
  justify-content: center;
  align-items: ${props => props.alignItems || 'center'};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
`;
