import { PayloadAction } from '@reduxjs/toolkit';
import {
  blockPost,
  blockUser,
  getBlockedUserssCount,
  getBlockedUserss,
} from 'api';
import { takeLatest, all, put, take } from 'redux-saga/effects';
import {
  blockPostFailure,
  blockPostRequest,
  blockPostSuccess,
} from 'store/reducers/board';
import {
  blockUserFailure,
  blockUserRequest,
  blockUserSuccess,
  getBlockedUsersCntFailure,
  getBlockedUsersCntRequest,
  getBlockedUsersCntSuccess,
  getBlockedUsersFailure,
  getBlockedUsersRequest,
  getBlockedUsersSuccess,
  addOffset,
} from 'store/reducers/communityBlock';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

function* blockPostSaga() {
  yield takeLatest(
    blockPostRequest.type,
    createFetchAction(
      blockPost,
      blockPostSuccess,
      blockPostFailure,
      function* success() {
        yield window.location.reload();
      },
      failure,
    ),
  );
}

function* blockUserSaga() {
  yield takeLatest(
    blockUserRequest.type,
    createFetchAction(
      blockUser,
      blockUserSuccess,
      blockUserFailure,
      function* success() {
        yield window.location.reload();
      },
      failure,
    ),
  );
}

function* getBlockedUsersCntSaga() {
  yield takeLatest(
    getBlockedUsersCntRequest.type,
    createFetchAction(
      getBlockedUserssCount,
      getBlockedUsersCntSuccess,
      getBlockedUsersCntFailure,
    ),
  );
}

function* getBlockedUsersSaga() {
  yield takeLatest(
    getBlockedUsersRequest.type,
    createFetchAction(
      getBlockedUserss,
      getBlockedUsersSuccess,
      getBlockedUsersFailure,
    ),
  );
}

function* getMoreBlockedUsersSaga() {
  while (true) {
    const action: PayloadAction<number> = yield take(addOffset.type);

    yield put(
      getBlockedUsersRequest({
        skip: action.payload,
        limit: 10,
      }),
    );
  }
}

export default function* communityBlockSaga() {
  yield all([
    blockPostSaga(),
    blockUserSaga(),
    getBlockedUsersCntSaga(),
    getBlockedUsersSaga(),
    getMoreBlockedUsersSaga(),
  ]);
}
