import {
  getReservationDate,
  setBasicPeopleCnt,
} from 'store/reducers/reservation';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  select,
  take,
  call,
  put,
  actionChannel,
  ActionPattern,
  takeEvery,
} from 'redux-saga/effects';
import { RootState } from 'store/reducers';
import {
  startFetchingZone,
  getZoneRequest,
  getZoneSuccess,
  getZoneFailure,
  getAdditionalOptsRequest,
  getAdditionalOptsSuccess,
  getAdditionalOptsFailure,
  getZonesCouponsRequest,
} from 'store/reducers/zone';
import site, {
  getSiteCntFailure,
  getSiteCntRequest,
  getSiteCntSuccess,
  getSitesRequest,
  getSitesSuccess,
  getSitesFailure,
  getAvailableForOneNightRequest,
  getAvailableForOneNightSuccess,
  getAvailableForOneNightFailure,
  getLongTermSitesSuccess,
  getLongTermSiteCntSuccess,
  toggleSiteRequest,
} from 'store/reducers/site';
import { PeopleCnt, ReservationDate } from 'store/types';
import {
  getZoneDetail,
  getServicesByZone,
  countSitesByZone,
  findSitesByZone,
  getSitesAvailableForOneNight,
} from 'api';
import { getLongTermSites } from 'api/longTerm';
import { ISite } from '@types';
import {
  BookingType,
  setBookingType,
  setPrevLongStayPeriod,
} from 'store/reducers/longStay';
import { toast } from 'react-toastify';
import makePeriodChangeToastMessage from 'utils/makePeriodChangeToastMessage';
import { fetchApi, createFetchAction } from './createFetchAction';

const queryString = require('query-string');

export function* getZone(id: string) {
  const bookingType: BookingType = yield select(
    (state: RootState) => state.longStayReducer.bookingType,
  );

  // 장박용
  if (bookingType === 'longStay') {
    const longTermAdultCnt: number = yield select(
      (state: RootState) => state.longStayReducer.adultCnt,
    );
    const longTermTeenCnt: number = yield select(
      (state: RootState) => state.longStayReducer.teenCnt,
    );
    const longTermChildCnt: number = yield select(
      (state: RootState) => state.longStayReducer.childCnt,
    );
    const longTermStartDate: Date | null = yield select(
      (state: RootState) => state.longStayReducer.startDate,
    );
    const longTermEndDate: Date | null = yield select(
      (state: RootState) => state.longStayReducer.endDate,
    );

    yield put(getZoneRequest());
    yield call<any>(
      fetchApi,
      getZoneDetail,
      getZoneRequest.type,
      getZoneSuccess,
      getZoneFailure,
      {
        id,
        adult: longTermAdultCnt,
        teen: longTermTeenCnt,
        child: longTermChildCnt,
        startTimestamp: longTermStartDate?.getTime() || 0,
        endTimestamp: longTermEndDate?.getTime() || 0,
      },
    );
  } else {
    const peopleCnt: PeopleCnt = yield select(
      (state: RootState) => state.reservationReducer.peopleCnt,
    );
    const date: ReservationDate = yield select(
      (state: RootState) => state.reservationReducer.date,
    );
    const { adultCnt, teenCnt, childCnt } = peopleCnt;
    const { start, end } = date;

    yield put(getZoneRequest());
    yield call<any>(
      fetchApi,
      getZoneDetail,
      getZoneRequest.type,
      getZoneSuccess,
      getZoneFailure,
      {
        id,
        adult: adultCnt,
        teen: teenCnt,
        child: childCnt,
        startTimestamp: start ? start.getTime() : 0,
        endTimestamp: end ? end.getTime() : 0,
      },
    );
  }
}

function* getAdditionalOpts(id: string) {
  yield put(getAdditionalOptsRequest());
  yield call<any>(
    fetchApi,
    getServicesByZone,
    getAdditionalOptsRequest.type,
    getAdditionalOptsSuccess,
    getAdditionalOptsFailure,
    { id, limit: 100, skip: 0 },
  );
}

export function* getSites(id: string) {
  const bookingType: BookingType = yield select(
    (state: RootState) => state.longStayReducer.bookingType,
  );

  // 일반 예약 시
  if (bookingType === 'normal') {
    const date: ReservationDate = yield select(
      (state: RootState) => state.reservationReducer.date,
    );

    const { start, end } = date;

    const { price } = yield select(
      (state: RootState) => state.zoneReducer.zone,
    );

    if (start && end) {
      yield put(getSitesRequest());
      yield call<any>(
        fetchApi,
        findSitesByZone,
        getSitesRequest.type,
        getSitesSuccess,
        getSitesFailure,
        {
          id,
          startTimestamp: start ? start.getTime() : 0,
          endTimestamp: end ? end.getTime() : 0,
        },
      );

      yield put(
        getZonesCouponsRequest({
          zoneId: id,
          startTimestamp: start.getTime(),
          endTimestamp: end.getTime(),
          price,
        }),
      );
    }
  }

  // 장박 예약 시
  else if (bookingType === 'longStay') {
    const startDate: Date | null = yield select(
      (state: RootState) => state.longStayReducer.startDate,
    );

    const endDate: Date | null = yield select(
      (state: RootState) => state.longStayReducer.endDate,
    );

    const prevStartDate: Date | null = yield select(
      (state: RootState) => state.longStayReducer.prevStartDate,
    );

    const prevEndDate: Date | null = yield select(
      (state: RootState) => state.longStayReducer.prevEndDate,
    );

    const response: {
      status: 'success' | 'fail';
      data: ISite[];
    } = yield call(getLongTermSites, id, {
      startTimestamp: startDate?.getTime() || 0,
      endTimestamp: endDate?.getTime() || 0,
    });

    yield put(getLongTermSiteCntSuccess(response.data.length));
    yield put(getLongTermSitesSuccess(response.data));

    if (prevStartDate && prevEndDate && startDate && endDate) {
      if (prevStartDate !== startDate || prevEndDate !== endDate) {
        const msg = makePeriodChangeToastMessage({
          startDate,
          endDate,
        });

        toast.success(msg);
      }
    }
    yield put(setPrevLongStayPeriod({ startDate, endDate }));
  }
}

export function* toggleSites() {
  const subChannel: ActionPattern = yield actionChannel(toggleSiteRequest.type);
  while (true) {
    const action: PayloadAction<{ id: string; bookingType: BookingType }> =
      yield take(subChannel);

    const id = action.payload.id;
    const bookingType: BookingType = action.payload.bookingType;

    if (bookingType) {
      yield put(setBookingType(bookingType));
    }

    yield call(getZone, id);
    // const bookingType: BookingType = yield select(
    //   (state: RootState) => state.longStayReducer.bookingType,
    // );

    // 일반 예약 시
    if (bookingType === 'normal') {
      const date: ReservationDate = yield select(
        (state: RootState) => state.reservationReducer.date,
      );

      const { start, end } = date;

      const { price } = yield select(
        (state: RootState) => state.zoneReducer.zone,
      );

      if (start && end) {
        // yield put(getSiteCntRequest());
        // yield call<any>(
        //   fetchApi,
        //   countSitesByZone,
        //   getSiteCntRequest.type,
        //   getSiteCntSuccess,
        //   getSiteCntFailure,
        //   id,
        // );

        yield put(getSitesRequest());
        yield call<any>(
          fetchApi,
          findSitesByZone,
          getSitesRequest.type,
          getSitesSuccess,
          getSitesFailure,
          {
            id,
            startTimestamp: start ? start.getTime() : 0,
            endTimestamp: end ? end.getTime() : 0,
          },
        );

        yield put(
          getZonesCouponsRequest({
            zoneId: id,
            startTimestamp: start.getTime(),
            endTimestamp: end.getTime(),
            price,
          }),
        );
      }
    }

    // 장박 예약 시
    else if (bookingType === 'longStay') {
      const startDate: Date | null = yield select(
        (state: RootState) => state.longStayReducer.startDate,
      );

      const endDate: Date | null = yield select(
        (state: RootState) => state.longStayReducer.endDate,
      );

      const response: {
        status: 'success' | 'fail';
        data: ISite[];
      } = yield call(getLongTermSites, id, {
        startTimestamp: startDate?.getTime() || 0,
        endTimestamp: endDate?.getTime() || 0,
      });

      yield put(getLongTermSiteCntSuccess(response.data.length));
      yield put(getLongTermSitesSuccess(response.data));
      yield put(setPrevLongStayPeriod({ startDate, endDate }));
    }
  }
}

function* fetchZone() {
  const subChannel: ActionPattern = yield actionChannel(startFetchingZone.type);
  while (true) {
    const action: PayloadAction<string> = yield take(subChannel);

    const parsed = queryString.parse(window.location.search);

    if (parsed.startTimestamp && parsed.endTimestamp) {
      yield put(
        getReservationDate({
          start: new Date(+parsed.startTimestamp),
          end: new Date(+parsed.endTimestamp),
        }),
      );

      yield put(setBasicPeopleCnt());
    }

    yield call(getZone, action.payload);

    yield call(getAdditionalOpts, action.payload);

    const bookingType: BookingType = yield select(
      (state: RootState) => state.longStayReducer.bookingType,
    );

    // if (bookingType === 'normal') {
    //   yield put(getSiteCntRequest());
    //   yield call<any>(
    //     fetchApi,
    //     countSitesByZone,
    //     getSiteCntRequest.type,
    //     getSiteCntSuccess,
    //     getSiteCntFailure,
    //     action.payload,
    //   );
    // }

    yield call(getSites, action.payload);
  }
}

export function* fetchZoneDetailSaga() {
  yield all([
    fetchZone(),
    takeEvery(
      getAvailableForOneNightRequest.type,
      createFetchAction(
        getSitesAvailableForOneNight,
        getAvailableForOneNightSuccess,
        getAvailableForOneNightFailure,
      ),
    ),
    toggleSites(),
  ]);
}
