import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ISocialLoginRegisterResponse,
  ISocialLogInResponse,
  TSocialLoginProvider,
} from '@types';
import {
  ISocialLoginConfirmRequestPayload,
  ISocialLoginRegisterRequestPayload,
  ISocialLoginState,
} from 'store/types';

const initialState: ISocialLoginState = {
  provider: null,
  providerEmail: null,
  socialLoginId: '',
  registerResponse: {} as ISocialLoginRegisterResponse,
  serverError: '',
  error: '',
};

export const socialLoginSlice = createSlice({
  name: 'socialLogin',
  initialState,
  reducers: {
    loginWithKakaoRequest: (state, action: PayloadAction<string>) => {
      state.error = '';
    },
    loginWithKakaoSuccess: (
      state,
      action: PayloadAction<ISocialLogInResponse>,
    ) => {
      state.error = '';
    },
    loginWithKakaoFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    loginWithGoogleRequest: (state, action: PayloadAction<string>) => {
      state.error = '';
    },
    loginWithGoogleSuccess: (
      state,
      action: PayloadAction<ISocialLogInResponse>,
    ) => {
      state.error = '';
    },
    loginWithGoogleFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    loginWithAppleRequest: (state, action: PayloadAction<string>) => {
      state.error = '';
    },
    loginWithAppleSuccess: (
      state,
      action: PayloadAction<ISocialLogInResponse>,
    ) => {
      state.error = '';
    },
    loginWithAppleFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    setSocialLoginProvider: (
      state,
      action: PayloadAction<TSocialLoginProvider | null>,
    ) => {
      state.provider = action.payload;
    },
    setSocialLoginProviderEmail: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.providerEmail = action.payload;
    },
    setSocialLoginId: (state, action: PayloadAction<string>) => {
      state.socialLoginId = action.payload;
    },

    resetSocialLoginState: (state, action) => {
      state.provider = null;
      state.providerEmail = null;
      state.socialLoginId = '';
      state.error = '';
    },

    socialLoginRegisterRequest: (
      state,
      action: PayloadAction<ISocialLoginRegisterRequestPayload>,
    ) => {
      state.registerResponse = {} as ISocialLoginRegisterResponse;
      state.serverError = '';
      state.error = '';
    },
    socialLoginRegisterSuccess: (
      state,
      action: PayloadAction<ISocialLoginRegisterResponse>,
    ) => {
      state.registerResponse = action.payload;
      state.serverError = '';
      state.error = '';
    },
    socialLoginRegisterFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.registerResponse = {} as ISocialLoginRegisterResponse;
      state.serverError = action.payload;
    },

    socialLoginConfirmRequest: (
      state,
      action: PayloadAction<ISocialLoginConfirmRequestPayload>,
    ) => {
      state.serverError = '';
      state.error = '';
    },
    socialLoginConfirmSuccess: (
      state,
      action: PayloadAction<ISocialLoginRegisterResponse>,
    ) => {
      state.serverError = '';
      state.error = '';
    },
    socialLoginConfirmFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.serverError = action.payload;
    },
  },
});

export const {
  loginWithKakaoRequest,
  loginWithKakaoSuccess,
  loginWithKakaoFailure,

  loginWithGoogleRequest,
  loginWithGoogleSuccess,
  loginWithGoogleFailure,

  loginWithAppleRequest,
  loginWithAppleSuccess,
  loginWithAppleFailure,

  setSocialLoginProvider,
  setSocialLoginProviderEmail,
  setSocialLoginId,

  resetSocialLoginState,

  socialLoginRegisterRequest,
  socialLoginRegisterSuccess,
  socialLoginRegisterFailure,

  socialLoginConfirmRequest,
  socialLoginConfirmSuccess,
  socialLoginConfirmFailure,
} = socialLoginSlice.actions;

export default socialLoginSlice.reducer;
