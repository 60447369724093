import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ISearchGeoRequestPayload, SearchGeoState } from 'store/types';
import { ISearchGeoResponse } from '@types';

const initialState: SearchGeoState = {
  campGeoList: [],
  totalCount: 0,
  page: 0,
  totalPage: 0,
  pageSize: 0,
  selectedMarkerIndex: -1,
  curLocation: { latitude: 0, longitude: 0 },
  curZoom: 9,
  error: '',
};

export const searchGeoSlice = createSlice({
  name: 'searchGeo',
  initialState,
  reducers: {
    searchGeoRequest: (
      state,
      action: PayloadAction<ISearchGeoRequestPayload>,
    ) => {
      state.error = '';
    },

    searchGeoSuccess: (state, action: PayloadAction<ISearchGeoResponse>) => {
      if (action.payload.page > 1)
        state.campGeoList = state.campGeoList.concat(action.payload.camps);
      else state.campGeoList = action.payload.camps;
      state.page = action.payload.page;
      state.totalPage = action.payload.totalPage;
      state.pageSize = action.payload.pageSize;
      state.totalCount = action.payload.totalCount;
      state.error = '';
    },
    searchGeoFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    searchSetSelectedMarkerIndex: (state, action: PayloadAction<number>) => {
      state.selectedMarkerIndex = action.payload;
    },
    searchSetCurLocation: (
      state,
      action: PayloadAction<{ latitude: number; longitude: number }>,
    ) => {
      state.curLocation.latitude = action.payload.latitude;
      state.curLocation.longitude = action.payload.longitude;
    },
    searchSetCurZoom: (state, action: PayloadAction<number>) => {
      state.curZoom = action.payload;
    },
  },
});

export const {
  searchGeoRequest,
  searchGeoSuccess,
  searchGeoFailure,
  searchSetSelectedMarkerIndex,
  searchSetCurLocation,
  searchSetCurZoom,
} = searchGeoSlice.actions;

export default searchGeoSlice.reducer;
