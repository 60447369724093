import { all, takeEvery } from 'redux-saga/effects';
import {
  postShopbyOrderSheetRequest,
  postShopbyOrderSheetSuccess,
  postShopbyOrderSheetError,
  getOrderSheetInfoRequest,
  getOrderSheetInfoSuccess,
  getOrderSheetInfoFailure,
  calculatedOrderSheetRequest,
  calculatedOrderSheetSuccess,
  calculatedOrderSheetFailure,
  getShopbyOrderListRequest,
  getShopbyOrderListError,
  getShopbyOrderListSuccess,
  getShopbyOrderListMore,
  getShopbyOrderCountSummaryRequest,
  getShopbyOrderCountSummarySuccess,
  getShopbyOrderCountSummaryError,
  getShopbyOrderDetailRequest,
  getShopbyOrderDetailSuccess,
  getShopbyOrderDetailError,
  updateShopbyOrderAddressError,
  updateShopbyOrderAddressRequest,
  updateShopbyOrderAddressSuccess,
  updateShopbyConfirmOrderRequest,
  updateShopbyConfirmOrderSuccess,
  updateShopbyConfirmOrderError,
  getShopbyPartnerContactsRequest,
  getShopbyPartnerContactsSuccess,
  getShopbyPartnerContactsError,
} from 'store/reducers/shopbyOrder';
import { ITShopbyErrorRes, IShopbyPostOrderSheetPayload } from '@types';
import { customHistory } from '../../App';
import { createShopbyFetchAction } from './createFetchAction';
import {
  getOrderSheetNo,
  getShopbyMyOrders,
  calculatedOrderSheet,
  getOrderSheetInfo,
  getShopbyOrderCountSummary,
  getShopbyMyOrderDetail,
  updateShopbyOrderAddress,
  updateShopbyPurchaseConfirm,
  getShopbyItemDetail,
} from '../../api/shopby';

function* postShopbyOrderSheetSaga() {
  yield takeEvery(
    postShopbyOrderSheetRequest.type,
    createShopbyFetchAction(
      getOrderSheetNo,
      postShopbyOrderSheetSuccess,
      postShopbyOrderSheetError,
      undefined,
      function onSuccess(data: IShopbyPostOrderSheetPayload) {
        customHistory.push(`/store/ordersheet/${data.orderSheetNo}`);
      },
    ),
  );
}

function* getOrderSheetInfoSaga() {
  yield takeEvery(
    getOrderSheetInfoRequest.type,
    createShopbyFetchAction(
      getOrderSheetInfo,
      getOrderSheetInfoSuccess,
      getOrderSheetInfoFailure,
      undefined,
    ),
  );
}

function* calculatedOrderSheetSaga() {
  yield takeEvery(
    calculatedOrderSheetRequest.type,
    createShopbyFetchAction(
      calculatedOrderSheet,
      calculatedOrderSheetSuccess,
      calculatedOrderSheetFailure,
      undefined,
    ),
  );
}

function* orderCountSummarySaga() {
  yield takeEvery(
    getShopbyOrderCountSummaryRequest.type,
    createShopbyFetchAction(
      getShopbyOrderCountSummary,
      getShopbyOrderCountSummarySuccess,
      getShopbyOrderCountSummaryError,
      undefined,
    ),
  );
}

function* getShopbyOrderListSaga() {
  yield takeEvery(
    [getShopbyOrderListRequest.type, getShopbyOrderListMore.type],
    createShopbyFetchAction(
      getShopbyMyOrders,
      getShopbyOrderListSuccess,
      getShopbyOrderListError,
      undefined,
      undefined,
      function onError(errorPayload: ITShopbyErrorRes) {
        alert(errorPayload.message);
      },
    ),
  );
}

function* getShopbyOrderDetailSaga() {
  yield takeEvery(
    getShopbyOrderDetailRequest.type,
    createShopbyFetchAction(
      getShopbyMyOrderDetail,
      getShopbyOrderDetailSuccess,
      getShopbyOrderDetailError,
      undefined,
      undefined,
      function onError(errorPayload: ITShopbyErrorRes) {
        alert(errorPayload.message);
      },
    ),
  );
}

function* updateShopbyOrderAddressSaga() {
  yield takeEvery(
    updateShopbyOrderAddressRequest.type,
    createShopbyFetchAction(
      updateShopbyOrderAddress,
      updateShopbyOrderAddressSuccess,
      updateShopbyOrderAddressError,
      undefined,
      undefined,
    ),
  );
}

function* updateShopbyPurchaseConfirmSaga() {
  yield takeEvery(
    updateShopbyConfirmOrderRequest.type,
    createShopbyFetchAction(
      updateShopbyPurchaseConfirm,
      updateShopbyConfirmOrderSuccess,
      updateShopbyConfirmOrderError,
      undefined,
      undefined,
    ),
  );
}

function* getShopbyPartnerContactSaga() {
  yield takeEvery(
    getShopbyPartnerContactsRequest.type,
    createShopbyFetchAction(
      getShopbyItemDetail,
      getShopbyPartnerContactsSuccess,
      getShopbyPartnerContactsError,
    ),
  );
}

export default function* shopbyOrderSaga() {
  yield all([
    getOrderSheetInfoSaga(),
    postShopbyOrderSheetSaga(),
    calculatedOrderSheetSaga(),
    getOrderSheetInfoSaga(),
    orderCountSummarySaga(),
    getShopbyOrderListSaga(),
    getShopbyOrderDetailSaga(),
    updateShopbyOrderAddressSaga(),
    updateShopbyPurchaseConfirmSaga(),
    getShopbyPartnerContactSaga(),
  ]);
}
