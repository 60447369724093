import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  getInfoForClaimRequest,
  getInfoForClaimSuccess,
  getInfoForClaimFailure,
  estimatePriceForClaimRequest,
  estimatePriceForClaimSuccess,
  estimatePriceForClaimFailure,
  estimatePriceForOneOptionClaimRequest,
  estimatePriceForOneOptionClaimSuccess,
  estimatePriceForOneOptionClaimFailure,
  postClaimRequest,
  postClaimSuccess,
  postClaimFailure,
  postExchangeClaimRequest,
  postExchangeClaimSuccess,
  postExchangeClaimFailure,
  getClaimDetailRequestByClaimNo,
  getClaimDetailRequestByOrderOptionNo,
  getClaimDetailSuccess,
  getClaimDetailFailure,
  checkIsClaimWithdrawableRequest,
  checkIsClaimWithdrawableSuccess,
  checkIsClaimWithdrawableFailure,
  withdrawClaimRequest,
  withdrawClaimSuccess,
  withdrawClaimFailure,
} from 'store/reducers/shopbyClaim';
import {
  ICreateShopbyCancelClaimsRequestPayload,
  ICreateShopbyExchangeClaimsRequestPayload,
  ICreateShopbyReturnClaimsRequestPayload,
  IShopbyCheckClaimWithdrawablePayload,
} from '@types';
import { customHistory } from 'App';
import {
  SHOPBY_ERROR_MESSAGE_FROM_VALIDATION_TYPE,
  SHOPBY_WITHDRAWABLE_STATE_FROM_VALIDATION_TYPE,
} from 'constants/shopbyConstants';
import { changeDateFormat } from 'utils/calculateDate';
import {
  createShopbyFetchAction,
  createShopbyFetchActionWithKey,
} from './createFetchAction';
import {
  getShopbyInfoForClaim,
  estimateShopbyPriceInfoForClaim,
  postShopbyClaim,
  withdrawShopbyClaim,
  checkIsShopbyClaimWithdrawable,
  estimateShopbyPriceInfoForOneOptionClaim,
  postShopbyExchangeClaim,
  getShopbyClaimDetailByClaimNo,
  getShopbyClaimDetailByOrderOptionNo,
} from '../../api/shopby';

const queryString = require('query-string');

function* getInfoForClaimSaga() {
  yield takeEvery(
    getInfoForClaimRequest.type,
    createShopbyFetchActionWithKey(
      getShopbyInfoForClaim,
      getInfoForClaimSuccess,
      getInfoForClaimFailure,
    ),
  );
}

function* estimatePriceInfoForClaimSaga() {
  yield takeEvery(
    estimatePriceForClaimRequest.type,
    createShopbyFetchActionWithKey(
      estimateShopbyPriceInfoForClaim,
      estimatePriceForClaimSuccess,
      estimatePriceForClaimFailure,
    ),
  );
}

function* estimatePriceInfoForOneOptionClaimSaga() {
  yield takeEvery(
    estimatePriceForOneOptionClaimRequest.type,
    createShopbyFetchActionWithKey(
      estimateShopbyPriceInfoForOneOptionClaim,
      estimatePriceForOneOptionClaimSuccess,
      estimatePriceForOneOptionClaimFailure,
    ),
  );
}

function* postClaimSaga() {
  yield takeLatest(
    postClaimRequest.type,
    createShopbyFetchActionWithKey(
      postShopbyClaim,
      postClaimSuccess,
      postClaimFailure,
      undefined,
      function* success(
        data: any,
        requestPayload:
          | ICreateShopbyCancelClaimsRequestPayload
          | ICreateShopbyReturnClaimsRequestPayload,
      ) {
        const pathname = customHistory.location.pathname.split('/');
        const orderOptionNo = pathname[pathname.length - 1];
        yield customHistory.replace(
          `/mypage/store/claim/complete?claimType=${requestPayload.claimType}&orderOptionNo=${orderOptionNo}`,
        );
      },
    ),
  );
}

function* postExchangeClaimSaga() {
  yield takeLatest(
    postExchangeClaimRequest.type,
    createShopbyFetchActionWithKey(
      postShopbyExchangeClaim,
      postExchangeClaimSuccess,
      postExchangeClaimFailure,
      undefined,
      function* success(
        data: any,
        requestPayload: ICreateShopbyExchangeClaimsRequestPayload,
      ) {
        const pathname = customHistory.location.pathname.split('/');
        const orderOptionNo = pathname[pathname.length - 1];
        yield customHistory.replace(
          `/mypage/store/claim/complete?claimType=${requestPayload.claimType}&orderOptionNo=${orderOptionNo}`,
        );
      },
    ),
  );
}

function* getClaimDetailByClaimNoSaga() {
  yield takeEvery(
    getClaimDetailRequestByClaimNo.type,
    createShopbyFetchAction(
      getShopbyClaimDetailByClaimNo,
      getClaimDetailSuccess,
      getClaimDetailFailure,
    ),
  );
}

function* getClaimDetailByOrderOptionNoSaga() {
  yield takeEvery(
    getClaimDetailRequestByOrderOptionNo.type,
    createShopbyFetchAction(
      getShopbyClaimDetailByOrderOptionNo,
      getClaimDetailSuccess,
      getClaimDetailFailure,
    ),
  );
}

function* checkIsClaimWithdrawableSaga() {
  yield takeLatest(
    checkIsClaimWithdrawableRequest.type,
    createShopbyFetchAction(
      checkIsShopbyClaimWithdrawable,
      checkIsClaimWithdrawableSuccess,
      checkIsClaimWithdrawableFailure,
      undefined,
      function* success(
        data: IShopbyCheckClaimWithdrawablePayload,
        requestPayload: number,
      ) {
        if (
          !SHOPBY_WITHDRAWABLE_STATE_FROM_VALIDATION_TYPE[data.validationType]
        ) {
          yield put({
            type: checkIsClaimWithdrawableFailure.type,
            payload: {
              code: '프론트에서 처리하는 임시 에러',
              detail: {
                time: changeDateFormat(new Date(), 'yyyy-mm-dd hh:mm:ss'), // 예: 2023-04-04 12:44:27
                extra: null,
              },
              error: 'Bad Request',
              message: SHOPBY_ERROR_MESSAGE_FROM_VALIDATION_TYPE[
                data.validationType
              ] as string,
              path: `/profile/claims/${requestPayload}/check-withdraw`, // API path, 예: /shop/order-sheets
              status: 400,
            },
          });
          return;
        }

        yield put({
          type: withdrawClaimRequest.type,
          payload: requestPayload,
        });
      },
    ),
  );
}

function* withdrawClaimSaga() {
  yield takeLatest(
    withdrawClaimRequest.type,
    createShopbyFetchAction(
      withdrawShopbyClaim,
      withdrawClaimSuccess,
      withdrawClaimFailure,
    ),
  );
}

export default function* shopbyClaimSaga() {
  yield all([
    getInfoForClaimSaga(),
    estimatePriceInfoForClaimSaga(),
    estimatePriceInfoForOneOptionClaimSaga(),
    postClaimSaga(),
    getClaimDetailByClaimNoSaga(),
    getClaimDetailByOrderOptionNoSaga(),
    checkIsClaimWithdrawableSaga(),
    withdrawClaimSaga(),
    postExchangeClaimSaga(),
  ]);
}
