import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ProductReviewsState,
  IGetProductReviewsRqPayload,
  IAddProductReviewsOffsetPayload,
  IFilterReviewsPayload,
} from 'store/types';
import { IMyItem, IProductReviewStatistics, IWritingReview } from '@types';

const initialState: ProductReviewsState = {
  reviews: [],
  review: {} as IMyItem,
  writingReview: {
    isWriting: false,
  } as IWritingReview,
  hasMore: true,
  offset: 0,
  isOwnerOnly: false,
  isPhotoOnly: false,
  error: '',
  reviewStatistics: {} as IProductReviewStatistics,
};

export const productReviewsSlice = createSlice({
  name: 'productReviews',
  initialState,
  reducers: {
    getProductReviewsRequest: (
      state,
      action: PayloadAction<IGetProductReviewsRqPayload>,
    ) => {
      action.payload.skip ? state.reviews : (state.reviews = []);
      state.offset = action.payload.skip;
      state.error = '';
    },
    getProductReviewsSuccess: (state, action: PayloadAction<IMyItem[]>) => {
      state.reviews = state.reviews.concat(action.payload);
      state.hasMore = action.payload.length === 5;
      state.error = '';
    },
    getProductReviewsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
    getProductStatisticsRequest: (state, action: PayloadAction<string>) => {
      state.reviewStatistics = {} as IProductReviewStatistics;
      state.error = '';
    },
    getProductStatisticsSuccess: (
      state,
      action: PayloadAction<IProductReviewStatistics>,
    ) => {
      state.reviewStatistics = action.payload;
      state.error = '';
    },
    getProductStatisticsFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.reviewStatistics = {} as IProductReviewStatistics;
      state.error = action.payload;
    },
    addOffset: (
      state,
      action: PayloadAction<IAddProductReviewsOffsetPayload>,
    ) => {
      state.offset = action.payload.skip;
    },
    filterReviews: (state, action: PayloadAction<IFilterReviewsPayload>) => {
      state.isOwnerOnly = action.payload.isOwnerOnly || false;
      state.isPhotoOnly = action.payload.isPhotoOnly || false;
    },
    getProductReviewRequest: (state, action: PayloadAction<string>) => {
      state.review = {} as IMyItem;
      state.error = '';
    },
    getProductReviewSuccess: (state, action: PayloadAction<IMyItem>) => {
      state.review = action.payload;
      state.error = '';
    },
    getProductReviewFailure: (state, action: PayloadAction<Error | string>) => {
      state.review = {} as IMyItem;
      state.error = action.payload;
    },
    setWritingReview: (state, action: PayloadAction<IWritingReview>) => {
      state.writingReview = action.payload;
      state.error = '';
    },
    resetWritingReview: state => {
      state.writingReview = initialState.writingReview;
      state.error = '';
    },
  },
});

export const {
  getProductReviewsRequest,
  getProductReviewsSuccess,
  getProductReviewsFailure,
  getProductReviewRequest,
  getProductReviewSuccess,
  getProductReviewFailure,
  getProductStatisticsRequest,
  getProductStatisticsSuccess,
  getProductStatisticsFailure,
  addOffset,
  filterReviews,
  setWritingReview,
  resetWritingReview,
} = productReviewsSlice.actions;

export default productReviewsSlice.reducer;
