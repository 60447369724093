import { takeEvery, all } from 'redux-saga/effects';
import {
  getProductMainByCategoryFailure,
  getProductMainByCategoryRequest,
  getProductMainByCategorySuccess,
} from 'store/reducers/productMainCategory';
import { getProductMainCategoryData } from 'api';
import { createFetchAction } from './createFetchAction';

function* getProductMainByCategorySaga() {
  yield takeEvery(
    getProductMainByCategoryRequest.type,
    createFetchAction(
      getProductMainCategoryData,
      getProductMainByCategorySuccess,
      getProductMainByCategoryFailure,
    ),
  );
}

export default function* productMainCategorySaga() {
  yield all([getProductMainByCategorySaga()]);
}
