import { Route, Redirect } from 'react-router-dom';
import { isInHouse } from 'server';

const TestRoute = (props: any) => {
  const { component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={routeProps => {
        return isInHouse ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        );
      }}
    />
  );
};

export default TestRoute;
