import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Props {
  visitedPages: string[];
}

const initialState: Props = {
  visitedPages: [],
};

export const feedbackLogSlice = createSlice({
  name: 'feedbackLogs',
  initialState,
  reducers: {
    pushVisitedPages: (state, action: PayloadAction<string>) => {
      state.visitedPages.push(action.payload);
    },
  },
});

export const { pushVisitedPages } = feedbackLogSlice.actions;

export default feedbackLogSlice.reducer;
