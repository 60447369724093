const isChrome = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes('chrome')) {
    return true;
  }
  return false;
};

export default isChrome;
