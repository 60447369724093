import {
  IBookingInfo,
  ISite,
  IBooking,
  IRegisterdAdditionalUserInfoResponse,
} from '@types';
import {
  ReservationState,
  ReservationDate,
  PeopleCnt,
  ICalculateBookingRequestPayload,
  IReservePayload,
  ICarInfo,
  IGetBookingInfoPayload,
  ICancelPreBookingRequestPayload,
} from 'store/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ReservationState = {
  date: {} as ReservationDate,
  peopleCnt: {
    adultCnt: 2,
    teenCnt: 0,
    childCnt: 0,
  },
  site: {} as ISite,
  selectedServices: [],
  bookingInfo: {} as IBookingInfo,
  isBookingInfoFetched: false,
  bookingDetail: {} as IBooking,
  holidays: [],
  carInfo: {} as ICarInfo,
  petInfo: [],
  registerdAdditionalUserInfo: {
    RegisterdCarNumber: '',
    RegisterdPetInfo: [],
  },
  error: '',
  selfCheckinStatus: '',
  selfCheckinErrorMessage: '',
  bookedTimestamp: 0,
  bookedId: '',
};

export const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    setReservationState: (state, action: PayloadAction<ReservationState>) => {
      const { date, peopleCnt, site, registerdAdditionalUserInfo } =
        action.payload;
      const { start, end } = date;

      state.date = {
        start: new Date(start),
        end: new Date(end),
      };
      state.peopleCnt = peopleCnt;
      state.site = site;
      state.registerdAdditionalUserInfo = registerdAdditionalUserInfo;
    },
    setReservationDate: (
      state,
      action: PayloadAction<{ start: number; end: number }>,
    ) => {
      const { start, end } = action.payload;
      if (start === 0 || end === 0) {
        state.date = {} as ReservationDate;
      } else {
        state.date = {
          start: new Date(start),
          end: new Date(end),
        };
      }
    },
    getReservationDate: (state, action: PayloadAction<ReservationDate>) => {
      state.date = action.payload;
    },
    increaseNumOfPeople: (state, action: PayloadAction<keyof PeopleCnt>) => {
      state.peopleCnt[action.payload] += 1;
    },
    decreaseNumOfPeople: (state, action: PayloadAction<keyof PeopleCnt>) => {
      state.peopleCnt[action.payload] -= 1;
    },
    selectSite: (state, action: PayloadAction<ISite>) => {
      state.site = action.payload;
    },

    getBookingInfoRequest: (
      state,
      action: PayloadAction<IGetBookingInfoPayload>,
    ) => {
      state.bookingInfo = {} as IBookingInfo;
      state.error = '';
    },
    getBookingInfoSuccess: (state, action: PayloadAction<IBookingInfo>) => {
      state.bookingInfo = action.payload;
      state.isBookingInfoFetched = true;
      state.error = '';
    },
    getBookingInfoFailure: (state, action: PayloadAction<Error | string>) => {
      state.bookingInfo = {} as IBookingInfo;
      state.error = action.payload;
    },

    resetBookingInfo: state => {
      state.bookingInfo = {} as IBookingInfo;
    },

    setIsBookingInfoFetched: (state, action: PayloadAction<boolean>) => {
      state.isBookingInfoFetched = action.payload;
    },

    completeSelection: state => {
      state;
    },

    completeSelectionInZone: (state, action: PayloadAction<string>) => {
      state;
    },

    setBasicPeopleCnt: state => {
      if (
        state.peopleCnt.adultCnt === 0 &&
        state.peopleCnt.childCnt === 0 &&
        state.peopleCnt.teenCnt === 0
      ) {
        state.peopleCnt.adultCnt = 2;
      }
    },

    reserve: (state, action: PayloadAction<IReservePayload>) => {
      const {
        numOfCars,
        services,
        hasTrailer,
        hasCampingCar,
        carNumbers,
        extraCarCnt,
        petInfo,
      } = action.payload;

      state.selectedServices = services;
      state.carInfo = {
        carCnt: numOfCars,
        extraCarCnt,
        carNumbers,
        hasTrailer,
        hasCampingCar,
      };
      state.petInfo = petInfo;
    },

    calculateBookingRequest: (
      state,
      action: PayloadAction<ICalculateBookingRequestPayload>,
    ) => {
      state.bookingDetail = {} as IBooking;
      state.error = '';
    },
    calculateBookingSuccess: (state, action: PayloadAction<IBooking>) => {
      state.bookingDetail = action.payload;
      state.error = '';
    },
    calculateBookingFailure: (state, action: PayloadAction<Error | string>) => {
      state.carInfo = {} as ICarInfo;
      state.selectedServices = [];
      state.bookingDetail = {} as IBooking;
      state.error = action.payload;
    },

    calculateLongTermBookingRequest: (
      state,
      action: PayloadAction<ICalculateBookingRequestPayload>,
    ) => {
      state.bookingDetail = {} as IBooking;
      state.error = '';
    },
    calculateLongTermBookingSuccess: (
      state,
      action: PayloadAction<IBooking>,
    ) => {
      state.bookingDetail = action.payload;
      state.error = '';
    },
    calculateLongTermBookingFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.carInfo = {} as ICarInfo;
      state.selectedServices = [];
      state.bookingDetail = {} as IBooking;
      state.error = action.payload;
    },

    getRegisterdAdditionalUserInfoRequest: (state, action: PayloadAction) => {
      state.registerdAdditionalUserInfo = {
        RegisterdCarNumber: '',
        RegisterdPetInfo: [],
      };
    },
    getRegisterdAdditionalUserInfoSuccess: (
      state,
      action: PayloadAction<IRegisterdAdditionalUserInfoResponse>,
    ) => {
      state.registerdAdditionalUserInfo.RegisterdCarNumber =
        action.payload.carNumber;
      state.registerdAdditionalUserInfo.RegisterdPetInfo = action.payload.pets;
    },
    getRegisterdAdditionalUserInfoFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.registerdAdditionalUserInfo = {
        RegisterdCarNumber: '',
        RegisterdPetInfo: [],
      };
      state.error = action.payload;
    },
    submitSelfCheckinRequest: (state, action: PayloadAction<any>) => {
      state.selfCheckinStatus = 'loading';
      state.selfCheckinErrorMessage = '';
    },
    submitSelfCheckinSuccess: (state, action: PayloadAction<any>) => {
      state.selfCheckinStatus = 'success';
      state.selfCheckinErrorMessage = '';
    },
    submitSelfCheckinFailure: (state, action: PayloadAction<any>) => {
      state.selfCheckinStatus = 'error';
      state.selfCheckinErrorMessage = action.payload;
    },
    setSelfCheckinStatus: (state, action: PayloadAction<string>) => {
      state.selfCheckinStatus = action.payload;
    },
    setSelfCheckinErrorMessage: (state, action: PayloadAction<string>) => {
      state.selfCheckinErrorMessage = action.payload;
    },

    cancelPreBookingRequest: (
      state,
      action: PayloadAction<ICancelPreBookingRequestPayload>,
    ) => {
      state.error = '';
    },
    cancelPreBookingSuccess: (state, action: PayloadAction) => {
      state.error = '';
    },
    cancelPreBookingFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    // 결제대기 상태에서 재 결제시 마이페이지에서 주문서페이지로 이동하면서 데이터 요청.
    // 비로그인 상태에서는 token 값이 필요함.
    getPreBookingInfoRequest: (
      state,
      action: PayloadAction<{ id: string; token?: string }>,
    ) => {},
    getPreBookingInfoSuccess: (state, action: PayloadAction<IBookingInfo>) => {
      const {
        bookedTimestamp,
        numOfCars,
        campingCarCharge,
        onSiteServices,
        onBookingServices,
        extraCarCharge,
        isCarChargeOnBooking,
        servicePrice,
        trailerCharge,
        basicAccommodationCharge,
        extraPeopleCharge,
        accommodationCharge,
        onBookingCharge,
        onSiteCharge,
        email,
        customerKey,
        extraPetCharge,
        totalCharge,
        isBankPaymentAvailable,
        isOnlinePaymentAvailable,
        checkInTimestamp,
        checkoutTimestamp,
        numOfAdults,
        numOfTeens,
        numOfChildren,
        zone,
        site,
        services,
        status,
        pets,
        hasCampingCar,
        hasTrailer,
        carNumbers,
        contact,
        name,
        id,
      } = action.payload;
      state.site = {
        id: site.id,
        name: '',
        isAvailable: true,
        unavailableReason: '',
        siteLength: 0,
        siteWidth: 0,
        siteArea: 0,
      };
      state.date = {
        start: new Date(checkInTimestamp),
        end: new Date(checkoutTimestamp),
      };
      state.peopleCnt = {
        adultCnt: numOfAdults,
        teenCnt: numOfTeens,
        childCnt: numOfChildren,
      };
      state.selectedServices = services;
      state.bookingDetail = {
        status,
        onBookingServices,
        onSiteServices,
        basicAccommodationCharge,
        extraPeopleCharge,
        accommodationCharge,
        onBookingCharge,
        onSiteCharge,
        servicePrice,
        totalCharge,
        isBankPaymentAvailable,
        isOnlinePaymentAvailable,
        name,
        phone: contact,
        email,
        customerKey,
        extraCarCharge,
        extraPetCharge,
        campingCarCharge,
        trailerCharge,
        isCarChargeOnBooking,
      };
      state.carInfo = {
        carCnt: numOfCars,
        extraCarCnt: numOfCars - zone.numOfCars,
        carNumbers: carNumbers?.split(',') || [],
        hasTrailer,
        hasCampingCar,
      };
      state.petInfo = pets;
      state.bookedTimestamp = bookedTimestamp;
      state.bookedId = id;
    },
    getPreBookingInfoFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    resetRePaymentState: state => {
      state.bookedTimestamp = 0;
      state.bookedId = '';
    },
  },
});

export const {
  setReservationState,
  getReservationDate,
  increaseNumOfPeople,
  decreaseNumOfPeople,
  selectSite,
  getBookingInfoRequest,
  getBookingInfoSuccess,
  getBookingInfoFailure,
  resetBookingInfo,
  setIsBookingInfoFetched,
  reserve,
  completeSelection,
  completeSelectionInZone,

  calculateBookingRequest,
  calculateBookingSuccess,
  calculateBookingFailure,

  calculateLongTermBookingRequest,
  calculateLongTermBookingSuccess,
  calculateLongTermBookingFailure,

  setBasicPeopleCnt,
  getRegisterdAdditionalUserInfoRequest,
  getRegisterdAdditionalUserInfoSuccess,
  getRegisterdAdditionalUserInfoFailure,
  submitSelfCheckinRequest,
  submitSelfCheckinSuccess,
  submitSelfCheckinFailure,
  setSelfCheckinStatus,
  setSelfCheckinErrorMessage,

  cancelPreBookingRequest,
  cancelPreBookingSuccess,
  cancelPreBookingFailure,

  getPreBookingInfoRequest,
  getPreBookingInfoSuccess,
  getPreBookingInfoFailure,

  resetRePaymentState,
  setReservationDate,
} = reservationSlice.actions;

export default reservationSlice.reducer;
