import { css } from 'styled-components';

// Note: 여러 줄을 줄일 경우, 노출되는 line만큼에 대한 height 꼭 특정하기  / 보라 / 22.07.21
export const ellipsisLinesCss = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* line clamp 는 필요에 따라 새롭게 지정 */
`;

export const ellipsisWithoutWhiteSpaceCss = css`
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* line clamp 는 필요에 따라 새롭게 지정 */
`;

// Note: 한 줄만 줄일 경우 사용, max-width가 특정되어야 동작 / 보라 / 22.07.21
export const ellipsisLineCss = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

// 해당 util을 ::after 내부에 content와 위치 (top, left 등)만 채워 넣어주면 완성.
export const RedTopBadge = css`
  width: fit-content;
  height: fit-content;
  min-width: 16px;
  min-height: 16px;
  padding: 2px 4px 0;
  font-size: 9px;

  position: absolute;
  top: calc(-14%);
  right: 0;
  display: block;
  font-weight: 700;
  text-align: center;

  color: ${({ theme }) => theme.newColors.white};

  border-radius: 10px;
  background: ${({ theme }) => theme.newColors.red};
`;

export const TopBadge = css`
  width: fit-content;
  height: fit-content;
  min-width: 18px;
  min-height: 18px;
  padding: 2px 4px 0;
  font-size: 11px;

  position: absolute;
  display: block;
  text-align: center;
  border-radius: 50px;
`;

// Note: 약관 등에 사용되는 테이블 공통 css
export const TableCss = css`
  color: ${({ theme }) => theme.newColors.darkGrey2};
  background-color: transparent;

  table {
    white-space: pre-line;
    font-size: 1.2rem;
    text-align: center;

    thead {
      > tr {
        background-color: ${({ theme }) => theme.newColors.background};
      }
    }

    tr {
      border: 1px solid ${({ theme }) => theme.newColors.lightGrey2};
      background-color: transparent;
    }

    th,
    td {
      vertical-align: middle;
      border: 1px solid ${({ theme }) => theme.newColors.lightGrey2};
      padding: 10px;

      > a {
        color: ${({ theme }) => theme.newColors.blue};
      }
    }
  }
`;
