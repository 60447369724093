import { getServerPhase, getEnvironment } from 'utils/common';
import { isMobile } from 'utils/share';

export const getServerURL = () => {
  switch (getServerPhase()) {
    case 'staging':
      return process.env.REACT_APP_BASE_URL_STAGING;
    case 'dev':
      return process.env.REACT_APP_BASE_URL_DEV;
    case 'local':
      return process.env.REACT_APP_BASE_URL_LOCAL;
    case 'production':
    default:
      return process.env.REACT_APP_BASE_URL;
  }
};

export const getAppScheme = () => {
  if (!isMobile()) {
    return;
  }

  if (window.location.hostname.includes('dev')) {
    return 'camfitdev://';
  }

  if (window.location.hostname.includes('qa')) {
    return 'camfitqa://';
  }

  if (window.location.hostname.includes('staging')) {
    return 'camfitstaging://';
  }

  return 'camfit://';
};

export const getShopbyServerURL = () => {
  return process.env.REACT_APP_SHOPBY_URL;
};

export const isInHouse =
  window.location.hostname.includes('dev') ||
  window.location.hostname.includes('qa') ||
  window.location.hostname.includes('staging') ||
  window.location.port === '3000';
