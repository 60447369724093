import {
  algoliaSearchProduct,
  algoliaGetTrendingItems,
  algoliaGetRelatedProducts,
  algoliaGetStoreFacet,
  getUserTrackingInfo,
} from 'api';
import { takeLatest, all, put } from 'redux-saga/effects';
import {
  algoliaGetTrendingItemsRequest,
  algoliaGetTrendingItemsSuccess,
  algoliaGetTrendingItemsFailure,
  algoliaGetRelatedProductsRequest,
  algoliaGetRelatedProductsSuccess,
  algoliaGetRelatedProductsFailure,
} from 'store/reducers/algoliaRecommend';
import {
  algoliaSearchProductRequest,
  algoliaSearchProductSuccess,
  algoliaSearchProductFailure,
  algoliaGetStoreFacetRequest,
  algoliaGetStoreFacetSuccess,
  algoliaGetStoreFacetFailure,
  getUserTrackingInfoRequest,
  getUserTrackingInfoSuccess,
  getUserTrackingInfoFailure,
  pushToProductBufferArrayRequest,
  pushToProductBufferArraySuccess,
  pushToProductBufferArrayFailure,
} from 'store/reducers/algoliaSearch';
import { IUserTrackingInfo } from '@types';
import { createFetchAction } from './createFetchAction';
import { failure } from './failure';

export const pushToProductBufferArray = async () => {
  // saga 사용을 위한 더미 API 함수
  return true;
};

function* algoliaSearchSaga() {
  yield takeLatest(
    algoliaSearchProductRequest.type,
    createFetchAction(
      algoliaSearchProduct,
      algoliaSearchProductSuccess,
      algoliaSearchProductFailure,
      function* success() {
        yield put(pushToProductBufferArrayRequest());
      },
      failure,
    ),
  );
}

function* algoliaGetRelatedProductsSaga() {
  yield takeLatest(
    algoliaGetRelatedProductsRequest.type,
    createFetchAction(
      algoliaGetRelatedProducts,
      algoliaGetRelatedProductsSuccess,
      algoliaGetRelatedProductsFailure,
    ),
  );
}

function* algoliaGetTrendingItemsSaga() {
  yield takeLatest(
    algoliaGetTrendingItemsRequest.type,
    createFetchAction(
      algoliaGetTrendingItems,
      algoliaGetTrendingItemsSuccess,
      algoliaGetTrendingItemsFailure,
    ),
  );
}

function* algoliaGetStoreFacetSaga() {
  yield takeLatest(
    algoliaGetStoreFacetRequest.type,
    createFetchAction(
      algoliaGetStoreFacet,
      algoliaGetStoreFacetSuccess,
      algoliaGetStoreFacetFailure,
    ),
  );
}

function* userTrackingInfoSaga() {
  yield takeLatest(
    getUserTrackingInfoRequest.type,
    createFetchAction(
      getUserTrackingInfo,
      getUserTrackingInfoSuccess,
      getUserTrackingInfoFailure,
      function* success(data: IUserTrackingInfo) {
        delete data.channelTalk.profile.landlineNumber;
        yield localStorage.setItem(
          'camfit_chaanelTalkInfo',
          JSON.stringify(data.channelTalk),
        );
      },
      failure,
    ),
  );
}

function* bufferArraySaga() {
  yield takeLatest(
    pushToProductBufferArrayRequest.type,
    createFetchAction(
      pushToProductBufferArray,
      pushToProductBufferArraySuccess,
      pushToProductBufferArrayFailure,
    ),
  );
}

export default function* algoliaSaga() {
  yield all([
    algoliaSearchSaga(),
    algoliaGetRelatedProductsSaga(),
    algoliaGetTrendingItemsSaga(),
    algoliaGetStoreFacetSaga(),
    userTrackingInfoSaga(),
    bufferArraySaga(),
  ]);
}
