import React from 'react';
import styled from 'styled-components';
import { theme } from 'static/styles/theme';
import Typography from './Typography';

enum Text {
  free = '무료배송',
  prGift = '사은품 증정',
  prSpecial = '특가',
}
export type TIconTag = keyof typeof Text;
interface Props {
  text: keyof typeof Text;
  inActive?: boolean;
}

interface ItemType {
  text: Text;
  color: keyof typeof theme.newColors;
  backgroundColor: keyof typeof theme.newColors;
}

type IconType = {
  [key in Props['text']]: ItemType;
};

const icon: IconType = {
  free: {
    text: Text.free,
    color: 'tertiary',
    backgroundColor: 'acssentGreenTertiary',
  },

  prGift: {
    text: Text.prGift,
    color: 'blue',
    backgroundColor: 'blue80',
  },

  prSpecial: {
    text: Text.prSpecial,
    color: 'acssentOrangePrimary',
    backgroundColor: 'yellow80',
  },
};

const Icon: React.FC<Props> = ({ text, inActive }) => {
  return (
    <Container
      backgroundColor={inActive ? 'lightGrey1' : icon[text].backgroundColor}
    >
      <Typography
        weight="medium"
        variant="caption2"
        color={inActive ? 'grey1' : icon[text].color}
      >
        {icon[text].text}
      </Typography>
    </Container>
  );
};

export default Icon;

const Container = styled.div<{
  backgroundColor: keyof typeof theme.newColors;
}>`
  display: flex;
  align-items: center;
  height: 18px;
  border-radius: 5px;
  padding: 0 6px;
  background-color: ${({ theme, backgroundColor }) =>
    theme.newColors[backgroundColor]};

  width: fit-content;
  margin-right: 4px;
`;
