import { searchProducts } from 'api';
import { all, takeEvery } from 'redux-saga/effects';
import {
  getRecommendProductListRequest,
  getRecommendProductListSuccess,
  getRecommendProductListFailure,
} from 'store/reducers/product';
import { createFetchAction } from './createFetchAction';

function* getRecommendProductListSaga() {
  yield takeEvery(
    getRecommendProductListRequest.type,
    createFetchAction(
      searchProducts,
      getRecommendProductListSuccess,
      getRecommendProductListFailure,
    ),
  );
}

export default function* recommendProductListSaga() {
  yield all([getRecommendProductListSaga()]);
}
