import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IProductMainCategoryData,
  ISearchedProducts,
  TProductSortType,
} from '@types';
import { ProductMainCategoryState } from 'store/types';

const initialState: ProductMainCategoryState = {
  productMainData: {} as IProductMainCategoryData,
  error: '',
};

export const productMainCategorySlice = createSlice({
  name: 'productMainCategory',
  initialState,
  reducers: {
    getProductMainByCategoryRequest: (state, action: PayloadAction<string>) => {
      state.productMainData = {} as IProductMainCategoryData;
      state.error = '';
    },
    getProductMainByCategorySuccess: (
      state,
      action: PayloadAction<IProductMainCategoryData>,
    ) => {
      state.productMainData = action.payload;
      state.error = '';
    },
    getProductMainByCategoryFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.productMainData = {} as IProductMainCategoryData;
      state.error = action.payload;
    },
  },
});

export const {
  getProductMainByCategoryRequest,
  getProductMainByCategorySuccess,
  getProductMainByCategoryFailure,
} = productMainCategorySlice.actions;

export default productMainCategorySlice.reducer;
