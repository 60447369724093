import { useEffect, useState } from 'react';
import { format, add } from 'date-fns';
import BasicSubHeader from 'components/organisms/BasicSubHeader';
import NewBasicButton from 'components/atoms/NewBasicButton';
import Timer, { TimerProps } from 'components/atoms/Timer';
import PageContainer from 'widgets/PageContainer';
import {
  ColumnCenterContainer,
  ColumnContainer,
  RowContainer,
} from 'widgets/FlexBox';
import { theme } from 'static/styles/theme';
import {
  timerCloseImminentCss,
  timerOpenImminentCss,
} from 'components/Shopby/ShopbyItemCell';
import { Select } from 'components/atoms/Form';
import styled, { css } from 'styled-components';

type Ttimer = 'regularSale' | 'end' | 'ongoing' | 'imminent';

const TimerTest = () => {
  const now = new Date();
  const [date, setDate] = useState<string>(format(now, 'yyyy-MM-dd'));
  const [time, setTime] = useState<string>(
    format(add(now, { minutes: 1 }), 'HH:mm'),
  );
  const [targetEndTimeMilliseconds, setTargetEndTimeMilliseconds] =
    useState<number>(0);

  const [imminentTimeMilli, setImminentTimeMilli] = useState<number>(60000);

  const [currentTimerProps, setCurrentTimerProps] = useState<
    undefined | TimerProps
  >(undefined);

  useEffect(() => {
    setCurrentTimerProps(() => getTimerPropsByType('end'));
  }, []);

  const getTimerType = (): Ttimer => {
    if (!targetEndTimeMilliseconds) return 'end';
    const now = new Date().getTime();

    switch (true) {
      case targetEndTimeMilliseconds <= now:
        return 'end';
      case targetEndTimeMilliseconds - now <= imminentTimeMilli &&
        targetEndTimeMilliseconds > now:
        return 'imminent';
      case targetEndTimeMilliseconds > now:
        return 'ongoing';
      default:
        return 'ongoing';
    }
  };

  const onClickSet = () => {
    setTargetEndTimeMilliseconds(() => new Date(`${date} ${time}`).getTime());
    setCurrentTimerProps(() => getTimerPropsByType(getTimerType()));
  };

  const getTimerPropsByType = (type: Ttimer): TimerProps => {
    const commonProps = {
      onUpdateTimerStatus,
    };

    switch (type) {
      case 'imminent':
        return {
          ...commonProps,
          textStyle: 'textTimer',
          text: '임박',
          backgroundColor: theme.newColors.orange40,
          targetEndTimeMilliseconds,
          className: 'close-imminent',
        };
      case 'ongoing':
        return {
          ...commonProps,
          textStyle: 'timerText',
          text: '남음',
          imminentStandardMilliSeconds: imminentTimeMilli,
          backgroundColor: theme.newColors.darkGrey2,
          targetEndTimeMilliseconds,
        };
      case 'end':
        return {
          ...commonProps,
          text: '종료',
          isTimerInvisible: true,
          backgroundColor: theme.newColors.grey2,
          targetEndTimeMilliseconds: 0,
        };
      default:
        return {
          ...commonProps,
          text: '준비',
          isTimerInvisible: true,
          // imminentStandardMilliSeconds: imminentTimeMilli,
          backgroundColor: theme.newColors.darkGrey2,
          targetEndTimeMilliseconds: 0,
        };
    }
  };

  const onUpdateTimerStatus = () => {
    const timerType = getTimerType() as Ttimer;
    setCurrentTimerProps(() => getTimerPropsByType(timerType));
  };

  const onChangeImminentMilliValue = (value: string) => {
    setImminentTimeMilli(Number(value));
  };

  return (
    <PageContainer maxWidth={false} disableGutters $backgroundColor="white">
      <BasicSubHeader
        title="Timer 컴포넌트 테스트용 페이지"
        isGobackIcon
        bottomDivider
      />
      <div style={{ marginTop: '16px', padding: '0 16px' }}>
        <h1 style={{ fontSize: '28px', marginBottom: '8px' }}>Filter</h1>
        <div
          style={{
            padding: '16px',
            borderRadius: '16px',
            backgroundColor: theme.newColors.lightBackground1,
          }}
        >
          <h2
            style={{
              fontSize: '20px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            Timer 종료일시
          </h2>
          <p
            style={{
              fontSize: '12px',
              margin: '6px 0',
              color: 'grey',
            }}
          >
            * 기본값 = 현재 시간으로부터 +1분
          </p>
          <ColumnContainer style={{ gap: '8px' }}>
            <RowContainer style={{ gap: '8px' }}>
              <input
                type="date"
                value={date}
                onChange={e => setDate(e.target.value)}
                required
                style={outlinedStyle}
              />
              <input
                type="time"
                value={time}
                onChange={e => setTime(e.target.value)}
                required
                style={outlinedStyle}
              />
            </RowContainer>
            <Container>
              <Select.Label labelPhrase="마감 임박 시간 기준" type="outlined">
                <Select
                  key="select"
                  name="select"
                  value={imminentTimeMilli.toString()}
                  onChange={onChangeImminentMilliValue}
                  type="outlined"
                  zPosteriority={2}
                  options={[
                    { label: '1분 전', value: '60000' },
                    { label: '10분 전', value: '600000' },
                    { label: '1시간 전', value: '3600000' },
                    { label: '24시간 전', value: '86400000' },
                  ]}
                  isOptionFloating
                  autoOpen
                />
              </Select.Label>
            </Container>
            <RowContainer
              style={{
                marginTop: '8px',
              }}
            >
              <NewBasicButton
                size="s"
                width="100%"
                type="contained"
                backgroundColor="primary"
                color="white"
                onClick={onClickSet}
              >
                start
              </NewBasicButton>
            </RowContainer>
          </ColumnContainer>
        </div>

        <h1 style={{ fontSize: '28px', margin: '32px 0 8px 0' }}>Timer</h1>
        <ColumnCenterContainer>
          <Timer
            {...(currentTimerProps as TimerProps)}
            cssObject={css`
              &.open-imminent {
                ${timerOpenImminentCss}
              }
              &.close-imminent {
                ${timerCloseImminentCss}
              }
            `}
          />
        </ColumnCenterContainer>
      </div>
    </PageContainer>
  );
};

export default TimerTest;

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  margin-top: 8px;

  > label {
    width: 100%;
  }
`;

const outlinedStyle = {
  backgroundColor: 'white',
  padding: '13.5px 16px',
  borderRadius: '7px',
  border: `1px solid ${theme.newColors.lightGrey2}`,
  width: '100%',
  height: '48px',
  cursor: 'pointer',
};
