import { all, put, take, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { getRelatedProductList, getRelatedProductListCount } from 'api';
import {
  getRelatedProductListRequest,
  getRelatedProductListSuccess,
  getRelatedProductListFainlure,
  getRelatedProductListCountRequest,
  getRelatedProductListCountSuccess,
  getRelatedProductListCountFailure,
  addOffset,
} from 'store/reducers/relatedProduct';
import { IGetRelatedProductRequestPayload } from 'store/types';
import { createFetchAction } from './createFetchAction';

function* getRelatedProductListSaga() {
  yield takeEvery(
    getRelatedProductListRequest.type,
    createFetchAction(
      getRelatedProductList,
      getRelatedProductListSuccess,
      getRelatedProductListFainlure,
    ),
  );
}

function* getRelatedProductListCountSaga() {
  yield takeEvery(
    getRelatedProductListCountRequest.type,
    createFetchAction(
      getRelatedProductListCount,
      getRelatedProductListCountSuccess,
      getRelatedProductListCountFailure,
    ),
  );
}

function* getMoreRelatedProductListSaga() {
  while (true) {
    const action: PayloadAction<IGetRelatedProductRequestPayload> = yield take(
      addOffset.type,
    );

    const { productId, skip, limit } = action.payload;

    yield put(
      getRelatedProductListRequest({
        productId,
        skip,
        limit,
      }),
    );
  }
}

export default function* relatedProductListSaga() {
  yield all([
    getRelatedProductListSaga(),
    getMoreRelatedProductListSaga(),
    getRelatedProductListCountSaga(),
  ]);
}
