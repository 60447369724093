import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlarmsState, IGetAlarmsRequestPayload } from 'store/types';
import { IMyAlarm } from '@types';

const initialState: AlarmsState = {
  alarms: [],
  offset: 0,
  hasMore: true,
  count: 0,
  isSucceed: false,
  error: '',
};

export const noticeSlice = createSlice({
  name: 'alarms',
  initialState,
  reducers: {
    getAlarmsRequest: (
      state,
      action: PayloadAction<IGetAlarmsRequestPayload>,
    ) => {
      state.alarms = action.payload.skip ? state.alarms : [];
      state.offset = action.payload.skip ? state.offset : 0;
      state.error = '';
    },
    getAlarmsSuccess: (state, action: PayloadAction<IMyAlarm[]>) => {
      state.alarms = state.alarms.concat(action.payload);
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },
    getAlarmsFailure: (state, action: PayloadAction<Error | string>) => {
      state.alarms = [];
      state.error = action.payload;
    },

    addOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },

    getAlarmsCountRequest: (state, action: PayloadAction<boolean>) => {
      state.count = 0;
      state.error = '';
    },
    getAlarmsCountSuccess: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
      state.error = '';
    },
    getAlarmsCountFailure: (state, action: PayloadAction<Error | string>) => {
      state.count = 0;
      state.error = action.payload;
    },

    updateAlarmStatusRequest: (state, action: PayloadAction<string>) => {
      state.error = '';
    },
    updateAlarmStatusSuccess: state => {
      state.error = '';
    },
    updateAlarmStatusFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = '';
    },
    readAllMyAlarmsRequest: state => {
      state.error = '';
    },
    readAllMyAlarmsSuccess: state => {
      state.isSucceed = true;
      state.error = '';
    },
    readAllMyAlarmsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = '';
    },
    setIsSucceedDefault: state => {
      state.isSucceed = false;
    },
  },
});

export const {
  getAlarmsRequest,
  getAlarmsSuccess,
  getAlarmsFailure,
  addOffset,
  getAlarmsCountRequest,
  getAlarmsCountSuccess,
  getAlarmsCountFailure,
  updateAlarmStatusRequest,
  updateAlarmStatusSuccess,
  updateAlarmStatusFailure,
  readAllMyAlarmsRequest,
  readAllMyAlarmsSuccess,
  readAllMyAlarmsFailure,
  setIsSucceedDefault,
} = noticeSlice.actions;

export default noticeSlice.reducer;
