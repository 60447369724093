import { takeLatest, all, select, call, put } from 'redux-saga/effects';
import {
  getHostsAndSuppliersRequest,
  getHostsAndSuppliersSuccess,
  getHostsAndSuppliersFailure,
  setPage,
  getHostsAndSuppliersCountRequest,
  getHostsAndSuppliersCountSuccess,
  getHostsAndSuppliersCountFailure,
} from 'store/reducers/hostsAndSuppliers';
import {
  getPolicyHosts,
  getPolicyHostCount,
  getPolicySupplier,
  getPolicySupplierCount,
} from 'api';
import { RootState } from 'store/reducers';
import { startLoading, finishLoading } from 'store/reducers/loading';

function* getHostsAndSuppliersSaga() {
  const { paging } = yield select(
    (state: RootState) => state.hostsAndSuppliersReducer,
  );
  const { page, pageSize } = paging;
  const params = {
    page,
    pageSize,
  };

  function* fetchApi(action: {
    type: any;
    payload: { name: string; paging: any };
  }) {
    const { type, payload } = action;
    yield put(startLoading(type));
    try {
      const data: string[] = yield call(
        payload.name === 'hosts' ? getPolicyHosts : getPolicySupplier,
        { ...params, ...payload.paging },
      );

      yield put(getHostsAndSuppliersSuccess(data));
    } catch (e: any) {
      if (e.response.status === 429) {
        window.location.replace('/error');
      }

      yield put(getHostsAndSuppliersFailure(e));
    }
    yield put(finishLoading(type));
  }

  yield takeLatest(getHostsAndSuppliersRequest.type, fetchApi);
}

function* getHostsAndSuppliersCountSaga() {
  function* fetchApi(action: { type: any; payload: any }) {
    const { type, payload } = action;
    yield put(startLoading(type));
    try {
      const data: number = yield call(
        payload === 'hosts' ? getPolicyHostCount : getPolicySupplierCount,
      );

      yield put(getHostsAndSuppliersCountSuccess(data));
    } catch (e: any) {
      if (e.response.status === 429) {
        window.location.replace('/error');
      }

      yield put(getHostsAndSuppliersCountFailure(e));
    }
    yield put(finishLoading(type));
  }

  yield takeLatest(getHostsAndSuppliersCountRequest.type, fetchApi);
}

export default function* hostsAndSuppliersSaga() {
  yield all([getHostsAndSuppliersSaga(), getHostsAndSuppliersCountSaga()]);
}
