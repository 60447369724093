import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IShopbyCategory,
  IShopbyFlatCategory,
  IShopbyItems,
  IShopbyProductSearchRequest,
  IShopbyProductSearchResponse,
  ITShopbyErrorRes,
  TOptionDefault,
} from '@types';
import {
  IShopbyCategoryResponsePayload,
  IShopbyCategoryState,
} from 'store/types';

const initialState: IShopbyCategoryState = {
  multiLevelCategories: [] as IShopbyCategory[],
  flatCategories: [] as IShopbyFlatCategory[],

  majorCategory: {} as IShopbyCategory,
  mediumCategory: {} as IShopbyCategory,

  mediumLNBTabList: [] as TOptionDefault[],
  activeMediumLNBTabKey: '',

  minorLNBTabList: [] as TOptionDefault[],
  activeMinorLNBTabKey: '',

  items: [] as IShopbyItems[],
  totalItemCount: 0,
  pageNumber: 1,

  shopbyError: undefined,
  isTouring: false,
};

const DEFAULT_LNB_OBJECT: TOptionDefault = {
  label: '전체',
  value: '',
};

export const shopbyCategorySlice = createSlice({
  name: 'shopbyCategory',
  initialState,
  reducers: {
    getShopbyCategoryInfoRequest: state => {
      state.multiLevelCategories = [] as IShopbyCategory[];
      state.flatCategories = [] as IShopbyFlatCategory[];
      state.shopbyError = undefined;
    },
    getShopbyCategoryInfoSuccess: (
      state,
      action: PayloadAction<{ data: IShopbyCategoryResponsePayload }>,
    ) => {
      const { multiLevelCategories, flatCategories } = action.payload.data;

      state.multiLevelCategories = multiLevelCategories;
      state.flatCategories = flatCategories;
      state.shopbyError = undefined;
    },
    getShopbyCategoryInfoFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.shopbyError = action.payload;
    },

    setMajorCategory: (state, action: PayloadAction<IShopbyCategory>) => {
      const majorCategoryInfo = action.payload;
      state.majorCategory = majorCategoryInfo;

      // 현재 majorCategory에 children이 있으면 LNB 설정

      if (majorCategoryInfo.children?.length > 0) {
        const addedLNB: TOptionDefault[] = majorCategoryInfo.children.map(
          mediumCategory => {
            return {
              label: mediumCategory.label,
              value: mediumCategory.categoryNo.toString(),
            };
          },
        );
        state.mediumLNBTabList = [DEFAULT_LNB_OBJECT, ...addedLNB];
      } else {
        state.mediumLNBTabList = [];
      }
    },

    setMediumCategory: (state, action: PayloadAction<IShopbyCategory>) => {
      const mediumCategoryInfo = action.payload;
      state.mediumCategory = mediumCategoryInfo;

      // 현재 mediumCategory에 children이 있으면 roundLNB 설정
      if (mediumCategoryInfo.children?.length > 0) {
        const addedLNB: TOptionDefault[] = mediumCategoryInfo.children.map(
          minorCategory => {
            return {
              label: minorCategory.label,
              value: minorCategory.categoryNo.toString(),
            };
          },
        );
        state.minorLNBTabList = [DEFAULT_LNB_OBJECT, ...addedLNB];
      } else {
        state.minorLNBTabList = [];
      }
    },

    setActiveMediumLNBKey: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      state.activeMediumLNBTabKey = key;
    },

    setActiveMinorLnbKey: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      state.activeMinorLNBTabKey = key;
    },

    resetMinorLNBTabList: state => {
      state.minorLNBTabList = [];
    },

    resetItemList: state => {
      state.items = [] as IShopbyItems[];
    },
    resetPageNumber: state => {
      state.pageNumber = 1;
    },

    getShopbyCategoryItemListRequest: (
      state,
      action: PayloadAction<IShopbyProductSearchRequest>,
    ) => {
      const { pageNumber } = action.payload;

      state.pageNumber = pageNumber;

      if (state.pageNumber === 1) {
        state.items = [];
        state.totalItemCount = 0;
      }

      state.shopbyError = undefined;
    },
    getShopbyCategoryItemListSuccess: (
      state,
      action: PayloadAction<{ data: IShopbyProductSearchResponse }>,
    ) => {
      const { items, totalCount } = action.payload.data;

      if (state.pageNumber === 1) {
        state.items = items;
      } else {
        state.items.push(...items);
      }

      state.totalItemCount = totalCount;

      state.shopbyError = undefined;
    },
    getShopbyCategoryItemListFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.shopbyError = action.payload;
    },

    onTouring: state => {
      state.isTouring = true;
    },
    offTouring: state => {
      state.isTouring = false;
    },
  },
});

export const {
  getShopbyCategoryInfoRequest,
  getShopbyCategoryInfoSuccess,
  getShopbyCategoryInfoFailure,

  setMajorCategory,
  setMediumCategory,

  setActiveMediumLNBKey,
  setActiveMinorLnbKey,

  resetMinorLNBTabList,

  resetItemList,
  resetPageNumber,

  getShopbyCategoryItemListRequest,
  getShopbyCategoryItemListSuccess,
  getShopbyCategoryItemListFailure,

  onTouring,
  offTouring,
} = shopbyCategorySlice.actions;

export default shopbyCategorySlice.reducer;
