import { IShopbyExhibitionItemInfo } from '@types';
import { TProgressStandard } from 'components/Shopby/ShopbyItemCell';
import { differenceInDays } from 'date-fns';
import { ONEDAY_MILLISECONDS, ONEHOUR_MILLISECONDS } from 'utils/calculateTime';

const getEventItemRanking = (item: IShopbyExhibitionItemInfo): number => {
  const immediateDiscountStartYmdt = item.immediateDiscountStartYmdt;
  const immediateDiscountEndYmdt = item.immediateDiscountEndYmdt;

  if (!immediateDiscountStartYmdt || !immediateDiscountEndYmdt) {
    // NOTE: 품절 : 5 / 상시판매 = 진행 : 1
    return item.isSoldOut ? 5 : 1;
  }

  const discountStartDate = immediateDiscountStartYmdt
    ? new Date(immediateDiscountStartYmdt).getTime()
    : 0;
  const discountEndDate = immediateDiscountEndYmdt
    ? new Date(immediateDiscountEndYmdt).getTime()
    : 0;

  const now = new Date().getTime();
  switch (true) {
    case item.isSoldOut: // 품절
      return 5;
    case discountEndDate > now &&
      discountEndDate - now > 0 &&
      discountEndDate - now <= ONEHOUR_MILLISECONDS * 6: // 마감임박
      return 0;
    case discountStartDate < now && discountEndDate > now: // 진행
      return 1;
    case discountStartDate > now &&
      discountStartDate - now <= ONEDAY_MILLISECONDS: // 오픈임박
      return 2;
    case discountStartDate > now &&
      discountStartDate - now > ONEDAY_MILLISECONDS: // 오픈예정
      return 3;
    case discountEndDate <= now: // 종료
      return 4;
    default: // 기본값 = 진행
      return 1;
  }
};

export const getSortedEventItems = (
  a: IShopbyExhibitionItemInfo,
  b: IShopbyExhibitionItemInfo,
) => {
  return getEventItemRanking(a) - getEventItemRanking(b);
};

export const getTimerType = (
  discountStartDate: number,
  discountEndDate: number,
): TProgressStandard => {
  const now = new Date().getTime();

  switch (true) {
    case discountStartDate <= now &&
      discountEndDate > now &&
      discountEndDate - now <= 6 * ONEHOUR_MILLISECONDS: // 마감임박
      return 'closeImminent';
    case discountStartDate < now && discountEndDate > now: // 진행
      return 'ongoing';
    case discountStartDate > now &&
      discountStartDate - now <= ONEDAY_MILLISECONDS: // 오픈임박
      return 'openImminent';
    case discountStartDate > now &&
      discountStartDate - now > ONEDAY_MILLISECONDS: // 오픈예정
      return 'scheduled';
    case discountEndDate <= now: // 종료
      if (differenceInDays(discountEndDate, now) < 0) {
        return 'dismiss';
      }
      return 'end';
    default: // 기본값 = 진행
      return 'ongoing';
  }
};
