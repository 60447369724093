import { Helmet } from 'react-helmet-async';

interface Props {
  title?: string;
  ogTitle?: string;
  description?: string;
  url?: string;
  image?: string;
  type?: string;
}

/*
 * Note
 * SEO최적화를 위한 Helmet component, 기존 <Helmet />과 동일하게 title, meta Tag등 변경이 필요한 Page에서 사용.
 * 새로운 meta TAG 필요시 index.html에 추가 후 data-rh="true" 포함시킬 것. 중복 TAG 방지
 * 이양우 2023.01.31
 */

const SEOHelmet = ({
  title = '막힘없이 캠핑가자, 캠핏',
  ogTitle = '막힘없이 캠핑가자, 캠핏',
  description = '하나되는 캠핑 정보로 전국 캠핑장을 가장 쉽고 빠르게 예약 | 캠핑 예약, 캠핑 정보 추천까지 모두 한 곳에서',
  url = 'https://camfit.co.kr/',
  image = '%PUBLIC_URL%/camfitOgMeta800600.png',
  type = 'websit',
}: Props) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={ogTitle} />
      <meta
        name="description"
        property="og:description"
        content={description}
      />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content={type} />
    </Helmet>
  );
};

export default SEOHelmet;
